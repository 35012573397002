import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";
import styles from "./Header.module.scss";
import { I18nContext } from "../../i18n/I18nContext";
import BuildingsModal from "../BuildingsModal/BuildingsModal";

const Header = () => {
  const { t } = useContext(I18nContext);
  const buildings = useSelector((state) => state.buildings.choosenBuildings);
  const company = useSelector((state) => state.profile.profile?.companyName);
  const [isOpen, setIsOpen] = useState(false);

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  return (
    <div className={styles.header}>
      <div className={styles.headerLeft}>
        <div
          className={styles.buildingSelectButton}
          onClick={() => setIsOpen(true)}
        >
          <span>{t("select buildings")}</span>
        </div>
        <div className={styles.buildingsCount}>
          {buildings && buildings.length > 1
            ? `${buildings.length} ${t("buildings selected")}`
            : buildings && !buildings.length
            ? `${t("all")} ${t("buildings selected", "lower")}`
            : `${t("selected building")}: ${buildings[0]?.name}`}
        </div>
      </div>
      <div className={styles.headerRight}>
        <div className={styles.company}>{company}</div>
      </div>
      {isOpen && <BuildingsModal isOpen={isOpen} onClose={handleCloseModal} />}
    </div>
  );
};

export default Header;
