import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import cx from "classnames";
import { useAccordionItem } from "./AccordionContext";
import Icon from "../Icon";
import styles from "./AccordionHeader.module.scss";
import { I18nContext } from "../../i18n/I18nContext";
import LayoutViewer from "../LayoutViewer/LayoutViewer";
import { selectAssetLayout, fetchAssets } from "../../redux";

const AccordionHeader = ({
  asset,
  hasParents,
  isGrouping,
  hasPlan,
  childHasPlan,
  onClose,
  children,
  badge,
  badge2,
  text,
  subtext,
  onClick,
  className,
  divider,
  rightIcon,
  hasItem,
  level = 0,
  isNotActive,
  message,
  activationStatus = true,
  forCreation = false,
  icon,
  iconColor,
  plusItem,
  showPlusWhenNoData,
  expandedByDefault,
}) => {
  const { t } = useContext(I18nContext);
  const dispatch = useDispatch();
  const selectedLayout = useSelector((state) => state.assets.selectedLayout);
  const { toggleItem, id, isOpen } = useAccordionItem();
  const [openLayoutViewer, setOpenLayoutViewer] = useState(false);
  const [disablePlan, setDisablePlan] = useState(false);

  const containerClassNames = cx(styles.container, className);

  const classNamesBadge = cx(styles.badge, {
    [styles.badge_open]: isOpen && level === 0,
  });

  const classNamesBadge2 = cx(styles.badge, {
    [styles.badge_open]: isOpen && level === 0,
    [styles.badge_not_visible]: !isGrouping,
  });

  const classNamesTxt = cx(styles.txt, {
    [styles.txt_open]: isGrouping,
    [styles.isNotActive]: isNotActive,
    [styles.notTopLevel]: hasParents,
    [styles.inactive]: iconColor === "GREY",
  });

  const classNamesSubtxt = cx(styles.txt, {
    [styles.txt_open]: isOpen && isGrouping,
    [styles.isNotActive]: isNotActive,
    [styles.notTopLevel]: hasParents,
    [styles.inactive]: iconColor === "GREY",
  });

  const classNamesActivationStatus = cx(styles.txt, {
    [styles.txt_open]: isOpen && isGrouping,
    [styles.isNotActive]: isNotActive,
    [styles.notTopLevel]: hasParents,
    [styles.inactive]: iconColor === "GREY",
  });

  const iconColors = {
    BLUE: ["#2264d1", "#EBEFF5"],
    BLACK: ["#000000", "#E8E8E8"],
    GREY: ["#CCCCCC", "#FFFFFF"],
    GREEN: ["#64B90C", "#F0F8E7"],
    YELLOW: ["#FFD700", "#FFFBE5"],
    ORANGE: ["#EE7203", "#FFF8F1"],
    RED: ["#C10000", "#FFF5F5"],
  };

  useEffect(() => {
    if (expandedByDefault && hasItem) {
      toggleItem();
    }
  }, [hasItem]);

  useEffect(() => {
    if (asset && asset?.hasPlan) {
      setDisablePlan(false);
    }
  }, [asset]);

  useEffect(() => {
    if (
      asset &&
      selectedLayout &&
      asset?.hasPlan &&
      !disablePlan &&
      selectedLayout.assetId === asset.id
    ) {
      setOpenLayoutViewer(true);
    }
  }, []);

  const handleClick = () => {
    toggleItem();
    if (onClick) {
      onClick(id);
    }
  };

  const handleClose = () => {
    if (!hasParents) {
      onClose();
    }
    toggleItem();
  };

  return (
    <div
      className={containerClassNames}
      data-testid={`accordion-header-${id}`}
      onClick={
        hasItem
          ? onClose
            ? isOpen
              ? handleClose
              : handleClick
            : handleClick
          : null
      }
    >
      <div className={styles.contentWrapper}>
        <div className={styles.icon} style={{ marginLeft: level * 50 }}>
          {hasItem && (
            <>
              {isOpen ? (
                <Icon name="chevron-down" />
              ) : (
                <Icon name="chevron-right" />
              )}
            </>
          )}
        </div>
        <div className={styles.nameWrapper}>
          {!!icon && (
            <div
              className={styles.iconWrapper}
              style={
                iconColor
                  ? {
                      backgroundColor: iconColors[iconColor][1],
                      borderColor: iconColors[iconColor][0],
                    }
                  : {
                      backgroundColor: "#F8F8F8",
                      borderColor: "#575757",
                    }
              }
            >
              <svg
                data-src={icon}
                fill={iconColor ? iconColors[iconColor][0] : "#575757"}
                width="24px"
                height="24px"
              />
            </div>
          )}
          <div className={classNamesTxt}>
            {text}
            <span className={classNamesSubtxt}>
              {subtext ? " / " + subtext : ""}
            </span>
            <span className={classNamesActivationStatus}>
              {forCreation
                ? ` (${t("for creation")})`
                : activationStatus
                ? ``
                : ` (${t("not active")})`}
            </span>
          </div>
          {message && <span className={styles.alert}>{message}</span>}
        </div>
        <div className={styles.content}>{children}</div>
        {(!!isOpen || (!!showPlusWhenNoData && !hasItem)) && !!plusItem && (
          <div className={styles.content}>{plusItem}</div>
        )}
        {hasPlan && !disablePlan && (
          <div
            className={styles.layout_button_wrapper}
            onClick={() => setOpenLayoutViewer(true)}
          >
            <Icon name="eye-orange" className={styles.layout_button} />
            <div className={styles.infoTooltip}>
              {t("click to open asset plan")}
            </div>
          </div>
        )}
        {!hasPlan && childHasPlan && (
          <div className={styles.layout_button_wrapper}>
            <Icon name="eye-grey" className={styles.layout_indicator} />
            <div className={styles.infoTooltip}>
              {t("expand to view asset plans of sub-assets")}
            </div>
          </div>
        )}
        {badge !== undefined && (
          <div
            className={classNamesBadge}
            data-testid={`accordion-header-badge-${id}`}
          >
            {badge}
          </div>
        )}
        {badge2 !== undefined && (
          <div
            className={classNamesBadge2}
            data-testid={`accordion-header-badge-${id}`}
          >
            {isGrouping ? badge2 : ""}
          </div>
        )}
      </div>
      {rightIcon && (
        <>
          {divider && <span className={styles.divider}></span>}
          <div className={styles.icon}>{rightIcon}</div>
        </>
      )}
      {openLayoutViewer && (
        <LayoutViewer
          assetId={asset.id}
          onClose={() => {
            dispatch(fetchAssets());
            dispatch(fetchAssets(asset.parentId));
            setOpenLayoutViewer(false);
            dispatch(selectAssetLayout(null));
          }}
          disablePlan={() => setDisablePlan(true)}
        />
      )}
    </div>
  );
};

AccordionHeader.propTypes = {
  children: PropTypes.node,
  badge: PropTypes.number,
  badge2: PropTypes.number,
  tapPoint: PropTypes.bool,
  hasItem: PropTypes.bool,
  onClick: PropTypes.func,
  divider: PropTypes.bool,
};

export default AccordionHeader;
