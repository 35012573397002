import React from "react";
import cx from "classnames";
import { useTabs } from "./TabContext";
import styles from "./Tabs.module.scss";

const Tab = ({ value, children, ...rest }) => {
  const { changeTab, currentValue } = useTabs();

  const classNames = cx(styles.tab_box, {
    [styles.tab_active]: value === currentValue,
  });
  return (
    <div {...rest} className={classNames} onClick={() => changeTab(value)}>
      {children}
    </div>
  );
};

export default Tab;
