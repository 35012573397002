import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "../Button";
import CompanyRowForm from "./CompanyRowForm";
import AccessFilteredSearch from "./AccessFilteredSearch";
import CompanyFormModal from "./CompanyFormModal";
import styles from "./AccessCompaniesContent.module.scss";
import AccessTitles from "./AccessTitles";
import { I18nContext } from "../../i18n/I18nContext";

const SORT_VALUE = {
  ASC_DATE: "ASC_DATE",
  DESC_DATE: "DESC_DATE",
  NAME_AZ: "NAME_AZ",
  NAME_ZA: "NAME_ZA",
  DEPRECATED: "DEPRECATED",
};

const sorters = {
  [SORT_VALUE.ASC_DATE]: (a, b) =>
    new Date(a.updatedAt) - new Date(b.updatedAt),
  [SORT_VALUE.DESC_DATE]: (a, b) =>
    new Date(b.updatedAt) - new Date(a.updatedAt),
  [SORT_VALUE.NAME_AZ]: (a, b) => a.name.localeCompare(b.name),
  [SORT_VALUE.NAME_ZA]: (a, b) => b.name.localeCompare(a.name),
  [SORT_VALUE.DEPRECATED]: (a, b) => a.deprecated - b.deprecated,
  getOrDefault(sort, defaultSorter) {
    return this[sort] ?? defaultSorter;
  },
};

const sortItems = (items = [], sort) => {
  const newList = [...items];
  return newList.sort(sorters.getOrDefault(sort, (a, b) => 0));
};

const AccessCompaniesContent = ({ items = [] }) => {
  const { t } = useContext(I18nContext);
  const [openAddCompanyModal, setOpenAddCompanyModal] = useState(false);
  const [editedCompany, setEditedCompany] = useState(null);
  const [searchString, setSearchString] = useState(null);
  const [filteredItems, setFilteredItems] = useState(items);
  const [meatballOpened, setMeatballOpened] = useState(null);
  const [sort, setSort] = useState(SORT_VALUE.NAME_AZ);

  useEffect(() => {
    let filtered = [];
    if (searchString) {
      filtered = sortItems(items, sort).filter((item) =>
        item.name.toLowerCase().includes(searchString.toLowerCase())
      );
    } else {
      filtered = sortItems(items, sort);
    }
    setFilteredItems(filtered);
  }, [searchString, items, sort]);

  return (
    <>
      <div className={styles.column}>
        <div className={styles.filters_section}>
          <div>
            <Button
              variant="orange"
              size="s"
              onClick={() => {
                if (sort === SORT_VALUE.NAME_AZ) {
                  setSort(SORT_VALUE.DESC_DATE);
                } else {
                  setSort(SORT_VALUE.NAME_AZ);
                }
              }}
            >
              {sort === SORT_VALUE.NAME_AZ
                ? `${t("sort by update date")}`
                : `${t("sort by name A-Z")}`}
            </Button>
            <AccessFilteredSearch onSearchChange={setSearchString} company />
          </div>
          <div className={styles.row}>
            <div>
              <Button
                variant="orange"
                size="s"
                onClick={() => {
                  setOpenAddCompanyModal(true);
                  setEditedCompany(null);
                }}
              >
                + {t("add new company")}
              </Button>
            </div>
          </div>
        </div>
        <AccessTitles content="companies" />
        {filteredItems.map((company, index) => {
          return (
            <CompanyRowForm
              key={`${company.id}_${index}`}
              company={company}
              onEdit={() => {
                setEditedCompany(company);
                setOpenAddCompanyModal(true);
              }}
              meatballOpened={meatballOpened}
              meatballOpenedObserver={(state) => setMeatballOpened(state)}
            />
          );
        })}
      </div>
      <CompanyFormModal
        isOpen={openAddCompanyModal}
        company={editedCompany}
        onClose={() => {
          setEditedCompany(null);
          setOpenAddCompanyModal(false);
        }}
        companies={items}
      />
    </>
  );
};

AccessCompaniesContent.propTypes = {
  items: PropTypes.array,
};

export default AccessCompaniesContent;
