import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import PropTypes from "prop-types";

import { appUrls, outerUrls } from "../../urls";
import { useFormikContext } from "formik";
import {
  FieldError,
  FieldInput,
  FieldLabel,
  Form,
  FormControl,
} from "../../components/Input";
import Button from "../../components/Button";
import Entry from "../../layouts/Entry";
import styles from "./ForgotPassword.module.scss";
import { useDispatch } from "react-redux";
import Icon from "../../components/Icon";
import { resetPassword } from "../../redux";
import { I18nContext } from "../../i18n/I18nContext";

export const ErrorListener = ({ onError }) => {
  const formik = useFormikContext();

  useEffect(() => {
    onError(formik.errors);
    // eslint-disable-next-line
  }, [formik.errors]);

  return null;
};

const ForgotPassword = () => {
  const { t } = useContext(I18nContext);
  const dispatch = useDispatch();
  const [hasError, setHasError] = useState();

  const initialValues = {
    email: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email(t("wrong e-mail format"))
      .required(t("e-mail needs to be provided")),
  });

  const handleSubmit = (values) => {
    dispatch(resetPassword(t, values.email));
  };

  return (
    <Entry>
      <div className={styles.container}>
        {!hasError ? (
          <span className={styles.icon_circle}>
            <Icon className={styles.icon} name="padlock" />
          </span>
        ) : (
          <span className={styles.icon_circleRed}>
            <Icon className={styles.icon} name="warning" />
          </span>
        )}
        <span className={styles.header}>{t("forgot password")}?</span>
        <span className={styles.txt_container_no_margin_bottom}>
          {t("have you forgotten your password or want to change it")}?
        </span>
        <span className={styles.txt_container}>
          {t(
            "then please enter your email address below and we will send you a link to reset your password"
          )}
          .
        </span>
        <Form
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          <FormControl classNameCustom={styles.spacing}>
            <FieldLabel>
              {t("e-mail")}
              <span className={styles.red}>*</span>
            </FieldLabel>
            <FieldInput name="email" />
            <FieldError name="email" />
          </FormControl>
          <ErrorListener onError={(errors) => setHasError(!!errors.email)} />
          <Button type="submit" variant="orange" size="l">
            {t("password reset")}
          </Button>
          <div className={styles.contact_container}>
            <span className={styles.txt_account}>Don’t have an account?</span>
            <a
              className={styles.link_account}
              href={outerUrls.CONTACT_US}
              target="_blank"
              rel="noreferrer"
            >
              {t("contact us")}
            </a>
          </div>
        </Form>
        <Link className={styles.link_return} to={appUrls.LOGIN}>
          {t("back to login")}
        </Link>
      </div>
    </Entry>
  );
};
ForgotPassword.propTypes = {
  user: PropTypes.object,
};

export default ForgotPassword;
