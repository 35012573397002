import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "../Button";
import UserRowForm from "./UserRowForm";
import AccessFilteredSearch from "./AccessFilteredSearch";
import UserFormModal from "./UserFormModal";
import styles from "./AccessUserContent.module.scss";
import AccessTitles from "./AccessTitles";
import { I18nContext } from "../../i18n/I18nContext";
import { Role } from "../../constants/Access";
import ImportDataModal from "../ImportDataModal/ImportDataModal";
import { sendMultipleInvitations, fetchRegisteredUsersList } from "../../redux";
import { useDispatch } from "react-redux";

const AccessUserContent = ({ items = [], loadUsers }) => {
  const { t } = useContext(I18nContext);
  const dispatch = useDispatch();
  const [openAddUsersModal, setOpenAddUsersModal] = useState(false);
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const [searchString, setSearchString] = useState(null);
  const [role, setRole] = useState(null);
  const [filteredItems, setFilteredItems] = useState(items);
  const [meatballOpened, setMeatballOpened] = useState(null);
  const [checkSelectedUsers, setCheckSelectedUsers] = useState([]);

  useEffect(() => {
    let filtered = items;
    if (role) {
      filtered = filtered.filter((item) => item.access.role === role);
    }
    if (searchString) {
      filtered = filtered.filter(
        (item) =>
          item.username.toLowerCase().includes(searchString.toLowerCase()) ||
          item.email.toLowerCase().includes(searchString.toLowerCase())
      );
    }
    setFilteredItems(filtered);
  }, [role, searchString, items]);

  const checkSelectUser = (userId, isChecked) => {
    const newCheckSelectedUsers = [...checkSelectedUsers];
    if (isChecked) {
      newCheckSelectedUsers.push(userId);
    } else {
      const index = newCheckSelectedUsers.indexOf(userId);
      newCheckSelectedUsers.splice(index, 1);
    }
    setCheckSelectedUsers(newCheckSelectedUsers);
  };

  const sendInvitations = async (userId) => {
    await dispatch(
      sendMultipleInvitations(t, userId ? [userId] : checkSelectedUsers)
    );
    dispatch(fetchRegisteredUsersList());
    setCheckSelectedUsers([]);
  };

  const toggleCheckSelectAll = (isChecked) => {
    if (isChecked) {
      const selectableUserIds = filteredItems
        .filter((user) => user.isInvitationDelayed && !user.isActive)
        .map((user) => user.id);
      setCheckSelectedUsers(selectableUserIds);
    } else {
      setCheckSelectedUsers([]);
    }
  };

  const checkIfAllUsersCheckSelected = () => {
    const selectableUsers = filteredItems.filter(
      (user) => user.isInvitationDelayed && !user.isActive
    );
    return (
      selectableUsers.length &&
      selectableUsers.length === checkSelectedUsers.length
    );
  };

  return (
    <>
      <div className={styles.column}>
        <div className={styles.filters_section}>
          <div>
            <AccessFilteredSearch
              showFilteredRole
              onSearchChange={setSearchString}
              onRoleChange={setRole}
            />
          </div>
          <div className={styles.row}>
            <div className={styles.buttonLeft}>
              <Button
                variant="orange"
                size="s"
                onClick={() => setOpenAddUsersModal(true)}
              >
                + {t("add new user")}
              </Button>
            </div>
            <div className={styles.buttonLeft}>
              <Button
                variant="orange"
                size="s"
                onClick={() => setIsImportModalOpen(true)}
              >
                {t("import users")}
              </Button>
            </div>
            <div>
              <Button
                variant={!checkSelectedUsers.length ? "disabled" : "orange"}
                size="s"
                onClick={() => sendInvitations()}
                disabled={!checkSelectedUsers.length}
              >
                {t("send invitations")}
              </Button>
            </div>
          </div>
        </div>

        {!!filteredItems.filter(
          (user) => user.isInvitationDelayed && !user.isActive
        ).length && (
          <div className={styles.checkbox_container}>
            <input
              id="select_all_users"
              name="select_all_users"
              type="checkbox"
              className={styles.checkbox}
              onChange={(e) => toggleCheckSelectAll(e.target.checked)}
              checked={checkIfAllUsersCheckSelected()}
              disabled={
                !filteredItems.filter(
                  (user) => user.isInvitationDelayed && !user.isActive
                ).length
              }
            />
            <label htmlFor="select_all_users" className={styles.checkbox_label}>
              {t("select all users")}
            </label>
          </div>
        )}

        <AccessTitles content="users" />
        {filteredItems.map((user, index) => {
          const showEditRights = filteredItems
            .filter((currentUser) => currentUser.id === user.id)
            .every((currentUser) => {
              return currentUser.access.role !== Role.SUPER_ADMIN;
            });
          return (
            <UserRowForm
              key={`${user.id}_${index}`}
              user={user}
              showEditRights={showEditRights}
              showRight={false}
              showSendEmail={true}
              id={user.id}
              meatballOpened={meatballOpened}
              meatballOpenedObserver={(state) => setMeatballOpened(state)}
              checkSelectUser={checkSelectUser}
              checked={checkSelectedUsers.includes(user.id)}
              sendInvitation={sendInvitations}
            />
          );
        })}
      </div>
      {!!openAddUsersModal && (
        <UserFormModal
          isOpen={openAddUsersModal}
          onClose={() => {
            setOpenAddUsersModal(false);
          }}
        />
      )}
      {!!isImportModalOpen && (
        <ImportDataModal
          isOpen={isImportModalOpen}
          onClose={() => setIsImportModalOpen(false)}
          mode="users"
        />
      )}
    </>
  );
};

AccessUserContent.propTypes = {
  items: PropTypes.array,
};

export default AccessUserContent;
