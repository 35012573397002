import React from "react";
import PropTypes from "prop-types";
//assets
import { ReactComponent as ChevronDown } from "../../assets/icons/chevron-down.svg";
import { ReactComponent as ChevronRight } from "../../assets/icons/chevron-right.svg";
import { ReactComponent as ChevronLeft } from "../../assets/icons/chevron-left.svg";
import { ReactComponent as Add } from "../../assets/icons/add.svg";
import { ReactComponent as AddFormModal } from "../../assets/icons/add-form-modal.svg";
import { ReactComponent as AddOrange } from "../../assets/icons/add-orange.svg";
import { ReactComponent as Vector } from "../../assets/icons/vector.svg";
import { ReactComponent as Navigation } from "../../assets/icons/navigation.svg";
import { ReactComponent as Settings } from "../../assets/icons/settings.svg";
import { ReactComponent as Trash } from "../../assets/icons/trash.svg";
import { ReactComponent as TrashWhite } from "../../assets/icons/trash-white.svg";
import { ReactComponent as MeatballMenu } from "../../assets/icons/meatball-menu.svg";
import { ReactComponent as ImgDetails } from "../../assets/icons/img-details.svg";
import { ReactComponent as AttachedFiles } from "../../assets/icons/attached-files.svg";
import { ReactComponent as XDelete } from "../../assets/icons/x-delete.svg";
import { ReactComponent as XDeleteBlack } from "../../assets/icons/x-delete-black.svg";
import { ReactComponent as DragDrop } from "../../assets/icons/drag-drop.svg";
import { ReactComponent as Close } from "../../assets/icons/close.svg";
import { ReactComponent as Invert } from "../../assets/icons/invert.svg";
import { ReactComponent as Chart } from "../../assets/icons/chart.svg";

import { ReactComponent as AssetRed } from "../../assets/icons/asset-red.svg";
import { ReactComponent as AssetGreen } from "../../assets/icons/asset-green.svg";
import { ReactComponent as AssetBlue } from "../../assets/icons/asset-blue.svg";
import { ReactComponent as AssetOrange } from "../../assets/icons/asset-orange.svg";
import { ReactComponent as AssetYellow } from "../../assets/icons/asset-yellow.svg";

import { ReactComponent as RedTap } from "../../assets/icons/red-tap.svg";
import { ReactComponent as GreenTap } from "../../assets/icons/green-tap.svg";
import { ReactComponent as BlueTap } from "../../assets/icons/blue-tap.svg";
import { ReactComponent as OrangeTap } from "../../assets/icons/orange-tap.svg";
import { ReactComponent as YellowTap } from "../../assets/icons/yellow-asset.svg";

//sidebar
import { ReactComponent as Dashboard } from "../../assets/icons/sidebar-dashboard.svg";
import { ReactComponent as Assets } from "../../assets/icons/sidebar-assets.svg";
import { ReactComponent as Tasks } from "../../assets/icons/sidebar-task.svg";
import { ReactComponent as Configuration } from "../../assets/icons/sidebar-configuration.svg";
import { ReactComponent as Access } from "../../assets/icons/sidebar-access.svg";
import { ReactComponent as License } from "../../assets/icons/sidebar-license.svg";
import { ReactComponent as SettingsSidebar } from "../../assets/icons/sidebar-settings.svg";
import { ReactComponent as Help } from "../../assets/icons/sidebar-help.svg";
import { ReactComponent as Profile } from "../../assets/icons/sidebar-profile.svg";

//meatball menu
import { ReactComponent as Eye } from "../../assets/icons/eye-menu.svg";
import { ReactComponent as EyeGrey } from "../../assets/icons/eye-grey.svg";
import { ReactComponent as EyeWhite } from "../../assets/icons/eye-white.svg";
import { ReactComponent as EyeOrange } from "../../assets/icons/eye-orange.svg";
import { ReactComponent as AddMenu } from "../../assets/icons/add-menu.svg";
import { ReactComponent as Edit } from "../../assets/icons/edit-menu.svg";
import { ReactComponent as Download } from "../../assets/icons/download.svg";
import { ReactComponent as DownloadGrey } from "../../assets/icons/download-grey.svg";
import { ReactComponent as DownloadWhite } from "../../assets/icons/download-white.svg";
import { ReactComponent as Upload } from "../../assets/icons/upload.svg";
import { ReactComponent as EnvelopeOrange } from "../../assets/icons/envelope-orange.svg";
import { ReactComponent as EnvelopeLicense } from "../../assets/icons/envelope-license.svg";
import { ReactComponent as EditPencilOrange } from "../../assets/icons/editPencil-orange.svg";
import { ReactComponent as EditPencilGrey } from "../../assets/icons/editPencil-grey.svg";
import { ReactComponent as TrashOrange } from "../../assets/icons/trash-orange.svg";
import { ReactComponent as MaintainerOrange } from "../../assets/icons/maintainer-orange.svg";

//login
import { ReactComponent as Envelope } from "../../assets/icons/envelope.svg";
import { ReactComponent as Padlock } from "../../assets/icons/padlock.svg";
import { ReactComponent as PadlockGrey } from "../../assets/icons/padlock-grey.svg";
import { ReactComponent as PadlockBlack } from "../../assets/icons/padlock-black.svg";
import { ReactComponent as Warning } from "../../assets/icons/password-warning.svg";
import { ReactComponent as Logout } from "../../assets/icons/logout.svg";

//general
import { ReactComponent as Calendar } from "../../assets/icons/calendar.svg";
import { ReactComponent as CalendarGray } from "../../assets/icons/calendar-gray.svg";
import { ReactComponent as Ready } from "../../assets/icons/ready-task.svg";
import { ReactComponent as Unready } from "../../assets/icons/unready-task.svg";
import { ReactComponent as Loupe } from "../../assets/icons/loupe.svg";
import { ReactComponent as LoupePlus } from "../../assets/icons/loupe-plus.svg";
import { ReactComponent as LoupeMinus } from "../../assets/icons/loupe-minus.svg";

import { ReactComponent as CheckedGroupTask } from "../../assets/icons/checked-group-task.svg";
import { ReactComponent as UncheckedGroupTask } from "../../assets/icons/unchecked-group-task.svg";
import { ReactComponent as HalfCheckedGroupTask } from "../../assets/icons/half-checked-group-task.svg";
import { ReactComponent as GuestionMarkOrange } from "../../assets/icons/questionMark-orange.svg";
import { ReactComponent as PhoneOrange } from "../../assets/icons/phone-orange.svg";

//help
import { ReactComponent as HelpResources } from "../../assets/icons/help-resources.svg";
import { ReactComponent as HelpArticles } from "../../assets/icons/help-articles.svg";
import { ReactComponent as HelpOpinion } from "../../assets/icons/help-opinion.svg";
import { ReactComponent as KeyboardOrange } from "../../assets/icons/keyboard-orange.svg";
import { ReactComponent as HelpAlerts } from "../../assets/icons/help-alerts.svg";
import { ReactComponent as HelpReports } from "../../assets/icons/help-reports.svg";
import { ReactComponent as HelpMeasure } from "../../assets/icons/help-measure.svg";
import { ReactComponent as HelpWhiteStar } from "../../assets/icons/help-white-star.svg";

const Icon = ({ name, height, ...rest }) => {
  switch (name) {
    case "chevron-down":
      return <ChevronDown {...rest} />;
    case "chevron-right":
      return <ChevronRight {...rest} />;
    case "chevron-left":
      return <ChevronLeft {...rest} />;
    case "add":
      return <Add {...rest} />;
    case "add-form-modal":
      return <AddFormModal {...rest} />;
    case "add-orange":
      return <AddOrange {...rest} />;
    case "vector":
      return <Vector {...rest} />;
    case "navigation":
      return <Navigation {...rest} />;
    case "settings":
      return <Settings {...rest} />;
    case "asset-red":
      return <AssetRed {...rest} />;
    case "asset-green":
      return <AssetGreen {...rest} />;
    case "asset-blue":
      return <AssetBlue {...rest} />;
    case "asset-orange":
      return <AssetOrange {...rest} />;
    case "asset-yellow":
      return <AssetYellow {...rest} />;
    case "trash":
      return <Trash {...rest} />;
    case "trash-white":
      return <TrashWhite {...rest} />;
    case "meatball-menu":
      return <MeatballMenu {...rest} />;
    case "img-details":
      return <ImgDetails {...rest} />;
    case "attached-files":
      return <AttachedFiles {...rest} />;
    case "x-delete":
      return <XDelete {...rest} />;
    case "x-delete-black":
      return <XDeleteBlack {...rest} />;
    case "drag-drop":
      return <DragDrop {...rest} />;
    case "invert":
      return <Invert {...rest} />;
    case "tap-red":
      return <RedTap {...rest} />;
    case "tap-green":
      return <GreenTap {...rest} />;
    case "tap-blue":
      return <BlueTap {...rest} />;
    case "tap-orange":
      return <OrangeTap {...rest} />;
    case "tap-yellow":
      return <YellowTap {...rest} />;
    //sidebar
    case "dashboard":
      return <Dashboard {...rest} />;
    case "assets":
      return <Assets {...rest} />;
    case "tasks":
      return <Tasks {...rest} />;
    case "configuration":
      return <Configuration {...rest} />;
    case "access":
      return <Access {...rest} />;
    case "reports":
      return <Chart {...rest} />;
    case "license":
      return <License {...rest} />;
    case "setting-sidebar":
      return <SettingsSidebar {...rest} />;
    case "help":
      return <Help {...rest} />;
    case "profile":
      return <Profile {...rest} />;
    //menu meatball
    case "eye":
      return <Eye {...rest} />;
    case "eye-grey":
      return <EyeGrey {...rest} />;
    case "eye-white":
      return <EyeWhite {...rest} />;
    case "eye-orange":
      return <EyeOrange {...rest} />;
    case "add-menu":
      return <AddMenu {...rest} />;
    case "edit":
      return <Edit {...rest} />;
    case "download":
      return <Download {...rest} />;
    case "download-grey":
      return <DownloadGrey {...rest} />;
    case "download-white":
      return <DownloadWhite {...rest} />;
    case "upload":
      return <Upload {...rest} />;
    case "envelope-orange":
      return <EnvelopeOrange {...rest} />;
    case "envelope-license":
      return <EnvelopeLicense {...rest} />;
    case "editPencil-orange":
      return <EditPencilOrange {...rest} />;
    case "editPencil-grey":
      return <EditPencilGrey {...rest} />;
    case "trash-orange":
      return <TrashOrange {...rest} />;
    case "maintainer-orange":
      return <MaintainerOrange {...rest} />;
    case "envelope":
      return <Envelope {...rest} />;
    case "padlock":
      return <Padlock {...rest} />;
    case "padlock-grey":
      return <PadlockGrey {...rest} />;
    case "padlock-black":
      return <PadlockBlack {...rest} />;
    case "warning":
      return <Warning {...rest} />;
    case "calendar":
      return <Calendar {...rest} />;
    case "calendar-gray":
      return <CalendarGray {...rest} />;
    case "ready":
      return <Ready {...rest} />;
    case "unready":
      return <Unready {...rest} />;
    case "loupe":
      return <Loupe {...rest} />;
    case "checked-group-task":
      return <CheckedGroupTask {...rest} />;
    case "unchecked-group-task":
      return <UncheckedGroupTask {...rest} />;
    case "half-checked-group-task":
      return <HalfCheckedGroupTask {...rest} />;
    case "close":
      return <Close {...rest} />;
    case "loupe-minus":
      return <LoupeMinus {...rest} />;
    case "loupe-plus":
      return <LoupePlus {...rest} />;
    case "logout":
      return <Logout {...rest} />;
    case "questionMark-orange":
      return <GuestionMarkOrange {...rest} />;
    case "phone-orange":
      return <PhoneOrange {...rest} />;
    // help
    case "help-resources":
      return <HelpResources {...rest} />;
    case "help-articles":
      return <HelpArticles {...rest} />;
    case "help-opinion":
      return <HelpOpinion {...rest} />;
    case "keyboard-orange":
      return <KeyboardOrange {...rest} />;
    case "help-alerts":
      return <HelpAlerts {...rest} />;
    case "help-reports":
      return <HelpReports {...rest} />;
    case "help-measure":
      return <HelpMeasure {...rest} />;
    case "help-white-star":
      return <HelpWhiteStar {...rest} />;
    default:
      return null;
  }
};

Icon.propTypes = {
  name: PropTypes.oneOf([
    "chevron-down",
    "chevron-right",
    "chevron-left",
    "add",
    "add-form-modal",
    "add-orange",
    "vector",
    "navigation",
    "settings",
    "trash",
    "trash-white",
    "red-tap",
    "green-tap",
    "yellow-asset",
    "orange-tap",
    "blue-tap",
    "asset-red",
    "asset-green",
    "asset-blue",
    "asset-orange",
    "asset-yellow",
    "meatball-menu",
    "img-details",
    "attached-files",
    "x-delete",
    "drag-drop",
    "dashboard",
    "assets",
    "tasks",
    "configuration",
    "access",
    "license",
    "setting-sidebar",
    "help",
    "profile",
    "eye",
    "add-menu",
    "edit",
    "download",
    "envelope-orange",
    "envelope-license",
    "editPencil-orange",
    "trash-orange",
    "maintainer-orange",
    "envelope",
    "padlock",
    "padlock-grey",
    "padlock-black",
    "warning",
    "calendar",
    "calendar-gray",
    "ready",
    "unready",
    "loupe",
    "checked-group-task",
    "unchecked-group-task",
    "questionMark-orange",
    "phone-orange",
    "help-resources",
    "help-articles",
    "help-opinion",
    "keyboard-orange",
    "help-alerts",
    "help-reports",
    "help-measure",
    "help-white-star",
    "chart",
  ]).isRequired,
};

Icon.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
};

export default Icon;
