import React, { useContext } from "react";
import Modal from "../Modal";
import RuleEditForm from "./RuleEditForm";
import styles from "./AddRulesModal.module.scss";
import { I18nContext } from "../../i18n/I18nContext";

const formId = "add-rule-modal-form";

const AddRulesModal = ({ onClose, isOpen, onCreate }) => {
  const { t } = useContext(I18nContext);

  return (
    <Modal
      className={styles.modal}
      isOpen={isOpen}
      onClose={onClose}
      classNameTitle={styles.title}
      title={t("add new rule")}
      form={formId}
      divider={false}
    >
      <RuleEditForm
        onClose={onClose}
        formId={formId}
        showEditing={false}
        onCreate={onCreate}
      />
    </Modal>
  );
};

export default AddRulesModal;
