import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import {
  deleteMaintainer,
  getMaintainersOrganizationList,
} from "../../redux/maintainers/maintainersActions";
import Button from "../Button";
import Modal from "../Modal";
import { I18nContext } from "../../i18n/I18nContext";
import styles from "./DeleteUserModal.module.scss";

const DeleteMaintainerModal = ({ isOpen, onClose, user, orgId }) => {
  const { t } = useContext(I18nContext);
  const dispatch = useDispatch();

  const handleClear = () => {
    onClose();
  };
  const handleDeleteUser = async () => {
    await dispatch(deleteMaintainer(t, user.id, orgId));
    dispatch(getMaintainersOrganizationList());
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={`${t("delete maintainer")}?`}
      form="delete-form-maintainer"
      icon
      footer={
        <div className={styles.button_section}>
          <Button
            className={styles.btn_separator}
            onClick={handleClear}
            type="reset"
            form={"delete-form-maintainer"}
            variant="ghost"
            size="s"
          >
            {t("cancel")}
          </Button>
          <Button
            type="submit"
            variant="orange"
            size="s"
            form={"delete-form-maintainer"}
            onClick={handleDeleteUser}
          >
            {t("delete")}
          </Button>
        </div>
      }
    >
      <div className={styles.delete_txt}>
        {t("are you sure you want to permanently delete maintainer")}
        <div className={styles.spacing_txt}>{user?.username}?</div>
      </div>
    </Modal>
  );
};

export default DeleteMaintainerModal;
