import React, { useContext } from "react";
import Select from "react-select";

import PropTypes from "prop-types";
import { Field } from "formik";
import { I18nContext } from "../../i18n/I18nContext";
import { getFieldPosition, getWindowHeight } from "../../helpers/helpers";

const focusColor = "#EE7203";

const SelectField = ({
  name,
  options = [],
  placeholder,
  size,
  onChange,
  onBlur,
  disabled,
  isClearable = false,
  isMultiple = false,
  singleValue,
  customLabel,
  menuOnTop,
}) => {
  const { t } = useContext(I18nContext);

  const customStyles = {
    option: (styles, { isSelected, data }) => {
      return {
        ...styles,
        display: "flex",
        alignItems: "center",
        color: "#393939",
        backgroundColor: data.global
          ? "#fff8f1"
          : data.bgColor
          ? data.bgColor
          : "#ffffff",
        fontWeight: isSelected ? "500" : "400",
        // height: size === "s" ? "38px" : "50px",
        height: "auto",
        "&:hover": {
          backgroundColor: "#eaeaea",
          color: "#393939",
          fontWeight: "500",
          cursor: "pointer",
        },
      };
    },
    control: (base, state, provided) => ({
      ...provided,
      paddingLeft: "0px",
      fontWeight: "400",
      fontSize: "14px",
      height: size === "s" ? "38px" : "50px",
      minHeight: "36px",
      ...base,
      boxShadow: state.isFocused ? 0 : 0,
      borderColor: state.isFocused ? focusColor : base.borderColor,
      "&:hover": {
        borderColor: state.isFocused ? focusColor : base.borderColor,
        cursor: "pointer",
      },
    }),
    valueContainer: (base, state, provided) => ({
      ...provided,
      ...base,
      backgroundColor: state.isDisabled ? "#f4f4f4" : "#ffffff",
      borderRadius: "3px",
      height: "100%",
      overflowY: "auto",
    }),
    multiValue: (styles) => {
      return {
        ...styles,
        backgroundColor: "#D8E6FF",
      };
    },
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      backgroundColor: "#D8E6FF",
      color: "#191919",
      "&:hover": {
        backgroundColor: "#D8E6FF",
        color: "#191919",
      },
    }),
    menu: (provided) => {
      return {
        ...provided,
        zIndex: "10",
        border: `1px solid ${focusColor}`,
      };
    },
    singleValue: (provided) => {
      return {
        ...provided,
        marginLeft: "2px",
      };
    },
    indicatorSeparator: (provided) => {
      return {
        ...provided,
        backgroundColor: "none",
      };
    },
    dropdownIndicator: (base, state) => {
      return {
        ...base,
        color: state.isFocused ? "#EE7203" : "#AAAAAA",
      };
    },
    placeholder: (provided) => {
      return {
        ...provided,
        marginLeft: "2px",
        color: "#AAAAAA",
        fontWeight: "400",
      };
    },
  };

  return (
    <Field name={name} singleValue={singleValue}>
      {({
        field: { value },
        form: { touched, errors, handleChange, handleBlur, setFieldTouched },
      }) => {
        return (
          <div id={name} onClick={() => getFieldPosition(name)}>
            <Select
              styles={customStyles}
              menuPosition={"relative"}
              isMulti={isMultiple}
              isClearable={isClearable}
              isDisabled={disabled}
              value={
                isMultiple
                  ? options.filter((opt) => value?.includes(opt.value)) ?? []
                  : singleValue
                  ? options.find((opt) => opt.value === singleValue)
                  : options.find((opt) => opt.value === value) ?? ""
              }
              options={options}
              error={errors[name]}
              placeholder={placeholder || t("select")}
              noOptionsMessage={() => t("no options")}
              touched={touched[name]}
              onChange={(selected) => {
                if (!isMultiple) {
                  const value = selected?.value;
                  const event = { target: { name, value } };
                  handleChange(event);
                  onChange && onChange(value);
                } else {
                  const value = selected?.map((selected) => selected.value);
                  const event = { target: { name, value } };
                  handleChange(event);
                  onChange && onChange(value);
                }
              }}
              onBlur={() => {
                const event = { target: { name } };
                handleBlur(event);
                onBlur && onBlur(event);
              }}
              maxMenuHeight={200}
              formatOptionLabel={customLabel ? customLabel : null}
              closeMenuOnSelect={!isMultiple}
              menuPlacement={
                !!menuOnTop || getFieldPosition(name) > getWindowHeight() / 2
                  ? "top"
                  : "bottom"
              }
            />
          </div>
        );
      }}
    </Field>
  );
};

SelectField.propTypes = {
  onSelect: PropTypes.func,
  placeholder: PropTypes.string,
  options: PropTypes.array.isRequired,
};

export default SelectField;
