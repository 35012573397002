import Actions from "../actions";
import axios from "../auth";
import { createToast } from "../toast/toastActions";

const fetchProfileSuccess = (profile) => {
  return {
    type: Actions.FETCH_PROFILE_SUCCESS,
    payload: profile,
  };
};

const fetchProfileFailure = (error) => {
  return {
    type: Actions.FETCH_PROFILE_FAILURE,
    payload: error,
  };
};

export const fetchProfileInfo = () => {
  return (dispatch) => {
    return axios
      .get(`/profile/me`)
      .then((response) => {
        dispatch(fetchProfileSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchProfileFailure(error));
      });
  };
};

export const editPasswordProfile = (t, body) => {
  return (dispatch) => {
    return axios
      .put(`/profile/editPassword`, body)
      .then(() => {
        dispatch(
          createToast({
            type: "success",
            title: t("successfully changed password"),
          })
        );
      })
      .catch((e) => {
        if (e.response.status === 417) {
          return t("Password is invalid");
        }
        if (e.response.status === 409) {
          dispatch(
            createToast({
              type: "error",
              title: t(
                "please make sure the new password has not been used within last 5 passwords"
              ),
            })
          );
        }
      });
  };
};

export const editProfileInfo = (t, body) => {
  return (dispatch) => {
    return axios.post(`/profile`, body).then(() => {
      dispatch(
        createToast({
          type: "success",
          title: t("successfully changed profile information"),
        })
      );
      dispatch(fetchProfileInfo());
    });
  };
};

export const requestEmailChange = (t, email) => {
  return (dispatch) => {
    return axios.post(`/profile/email?email=${email}`).then(() => {
      dispatch(
        createToast({
          type: "success",
          title: t(
            "email change request has been sent, please check your new inbox to accept",
            "asIs"
          ),
        })
      );
      dispatch(fetchProfileInfo());
    });
  };
};

export const deleteProfile = () => {
  return (dispatch) => {
    return axios.delete(`/profile/`);
  };
};
