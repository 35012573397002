import React, { useState, useEffect, useContext } from "react";
import * as Yup from "yup";
import { useFormikContext } from "formik";
import {
  FieldError,
  FieldInput,
  FieldLabel,
  Form,
  FormControl,
} from "../Input";
import Button from "../Button";

import { useDispatch, useSelector } from "react-redux";
import styles from "./EditPasswordProfile.module.scss";
import { I18nContext } from "../../i18n/I18nContext";
import { editPasswordProfile } from "../../redux";

export const ErrorListener = ({ onError }) => {
  const formik = useFormikContext();

  useEffect(() => {
    onError(formik.errors);
    // eslint-disable-next-line
  }, [formik.errors]);

  return null;
};

const EditPasswordProfile = () => {
  const { t } = useContext(I18nContext);
  const dispatch = useDispatch();

  const profile = useSelector((state) => state.profile.profile);

  const [error, setError] = useState();
  const [isPasswordOldShown, setPasswordOldShown] = useState(false);
  const [isPasswordShown, setPasswordShown] = useState(false);
  const [isPasswordConfirmationShown, setPasswordConfirmationShown] =
    useState(false);

  const handlePasswordOldVisibility = () => {
    setPasswordOldShown(!isPasswordOldShown);
  };
  const handlePasswordVisibility = () => {
    setPasswordShown(!isPasswordShown);
  };

  const handlePasswordConfirmationVisibility = () => {
    setPasswordConfirmationShown(!isPasswordConfirmationShown);
  };
  const initialValues = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  const validationSchema = Yup.object({
    oldPassword: Yup.string()
      .required(t("enter password"))
      .min(8, t("password must contain at least 8 characters"))
      .matches(/[a-z]/, {
        message: t("password must contain at least one lowercase character"),
      })
      .matches(/[A-Z]/, {
        message: t("password must contain at least one uppercase character"),
      })
      .matches(/[a-zA-Z]+[^a-zA-Z\s]+/, {
        message: t(
          "password must contain at least 1 number or special character"
        ),
      })
      .max(20, t("password can contain up to 20 characters")),
    newPassword: Yup.string()
      .required(t("enter password"))
      .min(8, t("password must contain at least 8 characters"))
      .matches(/[a-z]/, {
        message: t("password must contain at least one lowercase character"),
      })
      .matches(/[A-Z]/, {
        message: t("password must contain at least one uppercase character"),
      })
      .matches(/[a-zA-Z]+[^a-zA-Z\s]+/, {
        message: t(
          "password must contain at least 1 number or special character"
        ),
      })
      .max(20, t("password can contain up to 20 characters")),
    confirmPassword: Yup.string()
      .required(t("confirm your password"))
      .oneOf([Yup.ref("newPassword"), null], t("passwords must match")),
  });

  const handleSubmit = async ({
    confirmPassword,
    newPassword,
    oldPassword,
  }) => {
    const error = await dispatch(
      editPasswordProfile(t, {
        confirmPassword,
        newPassword,
        oldPassword,
      })
    );
    if (error) {
      setError(error);
    } else {
      setError(null);
    }
  };
  return (
    <div className={styles.container}>
      <div className={styles.header}>{t("access data")}</div>
      <span className={styles.txt_container}>
        {t("your current email is")}:
        <span className={styles.txt_orange}>{profile.email}</span>
      </span>
      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        <div className={styles.password_wrapper}>
          <FormControl>
            <FieldLabel>
              {t("previous password")}
              <span className={styles.red}>*</span>
            </FieldLabel>
            <FieldInput
              placeholder={t("enter the current password")}
              name="oldPassword"
              size="l"
              type={isPasswordOldShown ? "text" : "password"}
            />
            <FieldError name="oldPassword" />
            <div
              className={styles.show_password}
              onClick={handlePasswordOldVisibility}
            >
              {isPasswordOldShown ? (
                <div className={styles.txt_show}>{t("hide", "upper")}</div>
              ) : (
                <div className={styles.txt_show}>{t("show", "upper")}</div>
              )}
            </div>
            {error && error}
          </FormControl>
        </div>
        <div className={styles.password_wrapper}>
          <FormControl>
            <FieldLabel>
              {t("new password")}
              <span className={styles.red}>*</span>
            </FieldLabel>
            <FieldInput
              placeholder={t("enter a new password")}
              name="newPassword"
              size="l"
              autoComplete="current-password"
              type={isPasswordShown ? "text" : "password"}
              data-testid="newPassword"
            />
            <FieldError name="newPassword" />
            <div
              className={styles.show_password}
              onClick={handlePasswordVisibility}
            >
              {isPasswordShown ? (
                <div className={styles.txt_show}>{t("hide", "upper")}</div>
              ) : (
                <div className={styles.txt_show}>{t("show", "upper")}</div>
              )}
            </div>
          </FormControl>
        </div>
        <ErrorListener onError={(errors) => setError(!!errors.email)} />
        <div className={styles.password_wrapper}>
          <FormControl>
            <FieldLabel>
              {t("confirm new password")}
              <span className={styles.red}>*</span>
            </FieldLabel>
            <FieldInput
              placeholder={t("confirm new password")}
              name="confirmPassword"
              size="l"
              type={isPasswordConfirmationShown ? "text" : "password"}
            />
            <FieldError name="confirmPassword" />
            <div
              className={styles.show_password}
              onClick={handlePasswordConfirmationVisibility}
            >
              {isPasswordConfirmationShown ? (
                <div className={styles.txt_show}>{t("hide", "upper")}</div>
              ) : (
                <div className={styles.txt_show}>{t("show", "upper")}</div>
              )}
            </div>
          </FormControl>
        </div>
        <ErrorListener onError={(errors) => setError(!!errors.email)} />
        <Button
          className={styles.btn_separator}
          type="submit"
          variant="orange"
          size="s"
        >
          {t("save changes", "title")}
        </Button>
      </Form>
    </div>
  );
};

export default EditPasswordProfile;
