import React, { useState, useEffect, useContext, useRef } from "react";
import * as Yup from "yup";
import {
  FieldLabel,
  Form,
  FormControl,
  FieldError,
  DatePicker,
  FieldSelect,
  FieldInput,
} from "../Input";
import Modal from "../Modal";
import { I18nContext } from "../../i18n/I18nContext";
import { useDispatch, useSelector } from "react-redux";
import styles from "./GroupTasksModal.module.scss";
import {
  fetchTasksPrototypesList,
  fetchAssetPrototypesUsedWithinParentAsset,
  fetchAssetsForGroupTasks,
  addGroupTask,
  editGroupTask,
  fetchGroupTasks,
  fetchRecurrenceTasksList,
} from "../../redux";
import GroupTasksAssetsList from "./GroupTasksAssetsList";
import { add, differenceInCalendarDays } from "date-fns";
import ActivityIndicator from "../ActivityIndicator";
import useDeepCompareEffect from "use-deep-compare-effect";

const basicValues = {
  startDate: add(new Date(), { days: 1 }),
  endDate: null,
  assetsNumber: 1,
  assetPrototypeId: "",
  templateId: "",
};

const GroupTasksModal = ({
  onClose,
  isOpen,
  asset,
  group,
  updateAsset,
  updateTopAssets,
  groupFilters,
  recurrenceFilters,
}) => {
  const { t } = useContext(I18nContext);
  const dispatch = useDispatch();
  const allChosen = useSelector((state) => state.buildings.choosenBuildings);
  const [selectedTaskTemplate, setSelectedTaskTemplate] = useState(null);
  const [selectedAssetPrototype, setSelectedAssetPrototype] = useState(null);
  const [initialValues, setInitialValues] = useState(basicValues);
  const [templateNotSelected, setTemplateNotSelected] = useState(false);
  const [assetsList, setAssetsList] = useState([]);
  const [filters, setFilters] = useState([]);
  const [sort, setSort] = useState({ sortBy: "name", sortVal: "ASC" });
  const [assetIds, setAssetIds] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingAssets, setIsLoadingAssets] = useState(false);

  const taskPrototypes = useSelector((state) => state.taskPrototypes.items);
  const assetPrototypes = useSelector((state) => state.prototypes.withinParent);
  const assetsForGroupTask = useSelector(
    (state) => state.assets.assetsForGroupTask
  );
  const today = new Date();

  const formRef = useRef();

  useEffect(() => {
    getPrototypes();
  }, [isOpen]);

  const getPrototypes = async () => {
    setIsLoading(true);
    if (group || asset) {
      const res1 = await dispatch(
        fetchAssetPrototypesUsedWithinParentAsset(
          group ? (group.assetParentId ? group.assetParentId : "") : asset.id
        )
      );
    }
    const res2 = dispatch(fetchTasksPrototypesList());
    setIsLoading(false);
  };

  useEffect(() => {
    if (!!group) {
      setAssetIds(group.assetIds);
      setSelectedAssetPrototype(group.assetPrototype);

      setInitialValues({
        startDate: new Date(group.scheduler.startDate),
        endDate: !!group.scheduler.finishRecurrenceAt
          ? new Date(group.scheduler.finishRecurrenceAt)
          : null,
        assetsNumber: group.tasksPerRound,
        assetPrototypeId: group.assetPrototype.id,
        templateId: group.taskPrototype.id,
      });

      setSelectedTaskTemplate(group.taskPrototype);
    }
  }, [group]);

  useEffect(() => {
    if (!!selectedAssetPrototype) {
      getAssetsList();
    }
  }, [selectedAssetPrototype]);

  useDeepCompareEffect(() => {
    setAssetsList(assetsForGroupTask);
  }, [assetsForGroupTask]);

  const getAssetsList = async () => {
    setFilters([]);
    setIsLoadingAssets(true);
    const res = await dispatch(
      fetchAssetsForGroupTasks(
        selectedAssetPrototype.id,
        group ? (group.assetParentId ? group.assetParentId : "") : asset.id,
        {
          filters: [],
        }
      )
    );
    setIsLoadingAssets(false);
  };

  const validationSchema = Yup.object({
    templateId: Yup.string().required(t("please select one of the options")),
    assetPrototypeId: Yup.string().required(
      t("please select one of the options")
    ),
    startDate: Yup.date()
      .required(t("start date is required"))
      .test(
        "startDate",
        t("start date cannot be in the past"),
        (value) => !!group || differenceInCalendarDays(value, new Date()) >= 0
      ),
    endDate: Yup.date()
      .required(t("end date is required"))
      .when(
        "startDate",
        (startDate, Yup) =>
          startDate &&
          Yup.min(startDate, t("end date cannot be before start date"))
      ),
    assetsNumber: Yup.number()
      .required(t("assets number is required"))
      .test(
        "assetsNumber",
        t("assets number cannot be lower than 1"),
        (value) => value > 0
      )
      .test(
        "assetsNumber",
        t("assets number cannot be higher than selected assets number"),
        (value) => value <= assetIds.length
      ),
  });

  const validationSchemaRecurring = Yup.object({
    templateId: Yup.string().required(t("please select one of the options")),
    assetPrototypeId: Yup.string().required(
      t("please select one of the options")
    ),
    startDate: Yup.date()
      .required(t("start date is required"))
      .test(
        "startDate",
        t("start date cannot be in the past"),
        (value) => !!group || differenceInCalendarDays(value, new Date()) >= 0
      ),
    endDate: Yup.date()
      .nullable()
      .default(null)
      .when(
        "startDate",
        (startDate, Yup) =>
          startDate &&
          Yup.min(startDate, t("end date cannot be before start date"))
      ),
    assetsNumber: Yup.number()
      .required(t("assets number is required"))
      .test(
        "assetsNumber",
        t("assets number cannot be lower than 1"),
        (value) => value > 0
      )
      .test(
        "assetsNumber",
        t("assets number cannot be higher than selected assets number"),
        (value) => value <= assetIds.length
      ),
  });

  const handleSubmit = async (values, formik) => {
    // const template = edited ?? selectedTaskTemplate;

    if (assetIds.length) {
      if (!group) {
        await dispatch(
          addGroupTask(
            t,
            values.templateId,
            assetIds,
            values.assetsNumber,
            values.startDate,
            !values.noEndDate
              ? values.endDate
              : new Date("December 31, 2099 23:59:59"),
            asset.id,
            values.assetPrototypeId
          )
        );
      } else {
        await dispatch(
          editGroupTask(
            t,
            values.templateId,
            assetIds,
            values.assetsNumber,
            values.startDate,
            !values.noEndDate
              ? values.endDate
              : new Date("December 31, 2099 23:59:59"),
            group.assetParentId,
            values.assetPrototypeId,
            group.id
          )
        );
      }

      setSelectedTaskTemplate(null);
      setSelectedAssetPrototype(null);
      setTemplateNotSelected(false);
      setInitialValues(basicValues);

      if (!!updateAsset) {
        let refreshedParents = [];
        assetsList
          .filter((x) => assetIds.includes(x.id))
          .forEach((asset) => {
            if (!refreshedParents.includes(asset.parentId)) {
              updateAsset(asset.parentId);
            }
            refreshedParents.push(asset.parentId);
          });
      }

      if (!!updateTopAssets) {
        updateTopAssets();
      }

      if (groupFilters) {
        dispatch(
          fetchGroupTasks(
            allChosen.map((el) => el.id),
            groupFilters.taskTypeFilter,
            groupFilters.statusFilter,
            groupFilters.sort
          )
        );
      } else {
        dispatch(fetchGroupTasks(allChosen.map((el) => el.id)));
      }

      if (recurrenceFilters) {
        dispatch(
          fetchRecurrenceTasksList(
            allChosen.map((el) => el.id),
            recurrenceFilters.taskTypeFilter,
            recurrenceFilters.statusFilter,
            recurrenceFilters.sort
          )
        );
      } else {
        dispatch(fetchRecurrenceTasksList(allChosen.map((el) => el.id)));
      }

      formik.resetForm();
      onClose();
    }
  };

  const handleClose = () => {
    setSelectedTaskTemplate(null);
    setSelectedAssetPrototype(null);
    setTemplateNotSelected(false);
    setInitialValues(basicValues);
    onClose();
  };

  const applyFilters = (filtersList) => {
    setFilters(filtersList);

    let newAssetsList = [...assetsForGroupTask];
    filtersList.forEach((filter) => {
      if ("state" in filter) {
        newAssetsList = newAssetsList.filter(
          (asset) =>
            asset.properties.find((x) => x.id === filter.id).state ===
            filter.state
        );
      } else if (!!filter.contents) {
        newAssetsList = newAssetsList.filter((asset) =>
          asset.properties
            .find((x) => x.id === filter.id)
            .contents.includes(filter.contents)
        );
      } else if (!!filter.options) {
        newAssetsList = newAssetsList.filter((asset) =>
          asset.properties
            .find((x) => x.id === filter.id)
            .chosenOptions.some((item) => filter.options.includes(item))
        );
      } else if (!!filter.valueFrom) {
        newAssetsList = newAssetsList.filter(
          (asset) =>
            asset.properties.find((x) => x.id === filter.id).value >=
              filter.valueFrom &&
            asset.properties.find((x) => x.id === filter.id).value <=
              filter.valueTo
        );
      } else if (!!filter.selectedDateFrom) {
        newAssetsList = newAssetsList.filter(
          (asset) =>
            asset.properties.find((x) => x.id === filter.id).selectedDate >=
              filter.selectedDateFrom &&
            asset.properties.find((x) => x.id === filter.id).selectedDate <=
              filter.selectedDateTo
        );
      }
    });
    setAssetsList(newAssetsList);
  };

  const selectTaskType = (templateId, values) => {
    setTemplateNotSelected(false);
    const selectedTaskType = taskPrototypes.find(
      (template) => template.id === templateId
    );
    setSelectedTaskTemplate(selectedTaskType);
    if (!selectedTaskType.recurrence && !!assetIds?.length) {
      const newValues = { ...values, assetsNumber: assetIds.length };
      setInitialValues(newValues);
    }
  };

  const selectAssetType = (prototypeId) => {
    setTemplateNotSelected(false);
    setSelectedAssetPrototype(
      assetPrototypes.find((prototype) => prototype.id === prototypeId)
    );
  };

  const selectAssets = (ids) => {
    setAssetIds(ids);
    if (!selectedTaskTemplate?.recurrence) {
      const newValues = { ...formRef.current.values, assetsNumber: ids.length };
      setInitialValues(newValues);
    }
  };

  const applySorting = (sort) => {
    setSort(sort);
  };

  const sortAssets = (originalAssets) => {
    const assets = [...originalAssets];
    const propType = sort.type ?? "name";
    const propId = sort.sortBy;
    const sortDirection = sort.sortVal;
    let sortField = "";

    switch (propType) {
      case "boolean":
        sortField = "state";
        break;
      case "selection":
        sortField = "chosenOptions";
        break;
      case "text":
        sortField = "contents";
        break;
      case "numeric":
        sortField = "value";
        break;
      case "date":
        sortField = "selectedDate";
        break;
      default:
        sortField = "name";
    }

    if (propType === "name") {
      assets.sort((a, b) =>
        sortDirection === "ASC"
          ? a.name.localeCompare(b.name)
          : b.name.localeCompare(a.name)
      );
    }

    if (propType === "numeric") {
      assets.sort((a, b) =>
        sortDirection === "ASC"
          ? a.properties.find((x) => x.id === propId)[sortField] -
            b.properties.find((x) => x.id === propId)[sortField]
          : b.properties.find((x) => x.id === propId)[sortField] -
            a.properties.find((x) => x.id === propId)[sortField]
      );
    }

    if (propType === "text" || propType === "date") {
      assets.sort((a, b) =>
        sortDirection === "ASC"
          ? a.properties
              .find((x) => x.id === propId)
              [sortField].localeCompare(
                b.properties.find((x) => x.id === propId)[sortField]
              )
          : b.properties
              .find((x) => x.id === propId)
              [sortField].localeCompare(
                a.properties.find((x) => x.id === propId)[sortField]
              )
      );
    }

    if (propType === "selection") {
      assets.sort((a, b) =>
        sortDirection === "ASC"
          ? a.properties
              .find((x) => x.id === propId)
              [sortField].join()
              .localeCompare(
                b.properties.find((x) => x.id === propId)[sortField].join()
              )
          : b.properties
              .find((x) => x.id === propId)
              [sortField].join()
              .localeCompare(
                a.properties.find((x) => x.id === propId)[sortField].join()
              )
      );
    }

    if (propType === "boolean") {
      assets.sort((a, b) =>
        sortDirection === "ASC"
          ? a.properties
              .find((x) => x.id === propId)
              [sortField].toString()
              .localeCompare(
                b.properties.find((x) => x.id === propId)[sortField].toString()
              )
          : b.properties
              .find((x) => x.id === propId)
              [sortField].toString()
              .localeCompare(
                a.properties.find((x) => x.id === propId)[sortField].toString()
              )
      );
    }

    return assets;
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      lengthInfo
      divider
      title={
        asset?.name || group?.assetParentName
          ? `${t("group task")} ${t("in", "lower")} ${
              asset?.name || group?.assetParentName
            }`
          : t("group task")
      }
      form="group-task-form"
      noPadding
      editMode={!!group}
      fullWidth
      pending={!!isLoadingAssets}
    >
      {!!isLoading && (
        <div className={styles.loaderWrapper}>
          <ActivityIndicator />
        </div>
      )}
      {!isLoading && (
        <Form
          id="group-task-form"
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={
            !!selectedTaskTemplate?.recurrence
              ? validationSchemaRecurring
              : validationSchema
          }
          enableReinitialize={true}
          refForm={formRef}
        >
          {(props) => {
            if (templateNotSelected && !props.touched.templateId) {
              props.setFieldTouched("templateId");
            }
            return (
              <div className={styles.content}>
                <div className={styles.messageTop}>
                  {`${t("select a task type, then select an asset type")}. ${t(
                    "filter the asset list based on the asset properties and select the correct assets"
                  )}. ${t(
                    "optionally choose other asset types and filter/select more assets"
                  )}.`}
                </div>
                <div className={styles.messageTop2}>
                  {t(
                    "when the selection is complete, set the number of checks per round to the correct number (when the number of checks per round is lower than the number of selected assets, every round for a random sample of the selected assets tasks will be created)"
                  )}
                  .
                </div>
                <div className={styles.row}>
                  <FormControl>
                    <FieldLabel>
                      {t("task template")}
                      <span className={styles.red}> *</span>
                    </FieldLabel>
                    <div className={styles.width}>
                      <FieldSelect
                        size="s"
                        name="templateId"
                        options={taskPrototypes
                          .sort((a, b) => a.name.localeCompare(b.name))
                          .map((proto) => ({
                            label: proto.name,
                            value: proto.id,
                            global: proto.global,
                          }))}
                        onChange={(templateId) =>
                          selectTaskType(templateId, props.values)
                        }
                        placeholder={t("task template")}
                      />
                    </div>
                    <FieldError name="templateId" />
                  </FormControl>
                  <FormControl>
                    <FieldLabel>
                      {t("asset prototype")}
                      <span className={styles.red}> *</span>
                    </FieldLabel>
                    <div className={styles.width}>
                      <FieldSelect
                        size="s"
                        name="assetPrototypeId"
                        options={assetPrototypes
                          .sort((a, b) => a.name.localeCompare(b.name))
                          .map((proto) => ({
                            label: proto.name,
                            value: proto.id,
                            global: proto.global,
                          }))}
                        onChange={(prototypeId) => selectAssetType(prototypeId)}
                        placeholder={t("asset prototype")}
                      />
                    </div>
                    <FieldError name="assetPrototypeId" />
                  </FormControl>
                  <FormControl className={styles.number_section}>
                    <FieldLabel>
                      {t("number of assets to check")}
                      <span className={styles.red}> *</span>
                    </FieldLabel>
                    <FieldInput name="assetsNumber" type="number" size="xxs" />
                    <FieldError name="assetsNumber" />
                  </FormControl>
                  <FormControl className={styles.number_section}>
                    <FieldLabel>{t("number of assets in group")}</FieldLabel>
                    <div className={styles.asset_number}>{assetIds.length}</div>
                  </FormControl>
                  <FormControl>
                    <FieldLabel>
                      {t("start date")} <span className={styles.red}>*</span>
                    </FieldLabel>
                    <DatePicker
                      name="startDate"
                      withTime={false}
                      disabled={
                        !!group && new Date(group.scheduler.startDate) <= today
                      }
                    />
                    <FieldError name="startDate" />
                  </FormControl>
                  <FormControl>
                    <FieldLabel>
                      {!!selectedTaskTemplate?.recurrence
                        ? t("end date")
                        : t("due date")}
                      {!selectedTaskTemplate?.recurrence && (
                        <span className={styles.red}>*</span>
                      )}
                    </FieldLabel>
                    <DatePicker
                      name="endDate"
                      withTime={false}
                      noDefaultValue
                    />
                    <FieldError name="endDate" />
                  </FormControl>
                </div>
                {new Date(props.values.startDate.toDateString()) <=
                  new Date(today.toDateString()) && (
                  <div className={styles.message}>
                    {t(
                      "creating tasks for multiple assets can take some time, so the tasks might not be visible in the system straight away"
                    )}
                    .
                  </div>
                )}
              </div>
            );
          }}
        </Form>
      )}
      {!!isLoadingAssets && (
        <div className={styles.loaderWrapperAssetsList}>
          <ActivityIndicator />
        </div>
      )}
      {!isLoadingAssets && (
        <div className={styles.assets_list_wrapper}>
          {((!group && !!selectedAssetPrototype && !!assetsList) ||
            (!!group &&
              !!assetIds?.length &&
              !!selectedAssetPrototype &&
              !!assetsForGroupTask)) && (
            <GroupTasksAssetsList
              assetPrototype={selectedAssetPrototype}
              assets={sortAssets(assetsList)}
              unfilteredAssets={assetsForGroupTask}
              filters={filters}
              applyFilters={applyFilters}
              selectAssets={selectAssets}
              selectedAssets={assetIds}
              sort={sort}
              applySorting={applySorting}
            />
          )}
        </div>
      )}
    </Modal>
  );
};

export default GroupTasksModal;
