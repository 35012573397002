import React, { useEffect, useContext, useRef } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { useDetectOutsideClick } from "../../hooks/useDetectOutsideClick";
import Icon from "../Icon";
import styles from "./MeatballMenuAccess.module.scss";
import { I18nContext } from "../../i18n/I18nContext";

const MeatballMenuAccess = ({
  items,
  disabled,
  id,
  meatballOpened,
  meatballOpenedObserver,
}) => {
  const { t } = useContext(I18nContext);
  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);

  useEffect(() => {
    if (meatballOpened !== id) {
      setIsActive(false);
    }
  }, [meatballOpened]);

  const onClick = (event) => {
    event.stopPropagation();
    if (meatballOpenedObserver) {
      !isActive ? meatballOpenedObserver(id) : meatballOpenedObserver(null);
    }
    setIsActive(!isActive);
  };

  const classOpenMeatball = cx(styles.menu, {
    [styles.active]: isActive,
    [styles.inactive]: !isActive,
  });

  return (
    <div
      className={styles.menu_container}
      onClick={disabled ? null : onClick}
      ref={dropdownRef}
    >
      {!disabled ? (
        <div className={styles.menu_trigger}>
          <Icon name="meatball-menu" />
        </div>
      ) : (
        <div className={styles.disabled_placeholder} />
      )}

      <div className={classOpenMeatball}>
        {items
          .filter((item) => item.show)
          .map((item, idx) => (
            <div
              className={styles.item_container}
              key={idx}
              onClick={item.onClick}
            >
              <span> {item.icon} </span>
              <span className={styles.label_menu}>{t(item.label)}</span>
            </div>
          ))}
      </div>
    </div>
  );
};

MeatballMenuAccess.propTypes = {
  showAdd: PropTypes.bool,
  id: PropTypes.string,
  parentId: PropTypes.string,
  meatballMaintainer: PropTypes.bool,
};

export default MeatballMenuAccess;
