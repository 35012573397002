import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import {
  getInitialState,
  PrototypeForm,
  updateAssetProperties,
  getInitialStateFromForms,
  updateFormProperties,
  addFormIdToFields,
} from "../PrototypeForm";
import * as Yup from "yup";
import { Form } from "../Input";
import Button from "../Button";
import {
  AccordionItem,
  AccordionHeader,
  AccordionPanel,
} from "../../components/Accordion";
import styles from "./BatchTasksProperties.module.scss";
import { I18nContext } from "../../i18n/I18nContext";
import { fetchBatchTasksList, updateBatchTask } from "../../redux";
import { useDispatch, useSelector } from "react-redux";
import { useRef } from "react";
import Modal from "../Modal";
import CannotBeDoneTaskForm from "../Configuration/CannotBeDoneTaskForm";

const BatchTasksProperties = ({ task, selected, getTasks, getBatchTasks }) => {
  const { t } = useContext(I18nContext);
  const dispatch = useDispatch();
  const currentAsset = useRef();
  const [isCannotBeDoneModalOpen, setIsCannotBeDoneModalOpen] = useState(false);
  const [cannotBeDoneTask, setCannotBeDoneTask] = useState(null);

  const allChoosen = useSelector((state) => state.buildings.choosenBuildings);

  const validationSchema = Yup.object({
    usage: Yup.number()
      .nullable()
      .min(0, t("min value 0"))
      .max(30, t("max value 100"))
      .positive()
      .integer(),
  });

  const { asset, tasks } = task;

  const handleSubmitTaskTemplate = (task) => async (values) => {
    const { current: currentAssetValues } = currentAsset;
    const initialProperties = asset.properties.map((property) => ({
      property,
    }));
    const assetProperties = updateAssetProperties(
      initialProperties,
      currentAssetValues
    ).map(({ property }) => property);

    const assetUpdated = {
      id: asset.id,
      tags: asset.tags,
      name: asset.name,
      desc: asset.desc,
      properties: assetProperties,
    };

    const formsUpdated = updateFormProperties(task.forms, values);

    const taskUpdated = {
      assetId: task.assetId,
      taskId: task.taskId,
      taskName: task.taskName,
      forms: formsUpdated,
    };

    await dispatch(
      updateBatchTask(t, {
        assetNodes: assetUpdated,
        task: taskUpdated,
      })
    );

    getTasks();

    if (allChoosen.length > 0) {
      dispatch(
        fetchBatchTasksList(
          selected.id,
          allChoosen.map((el) => el.id)
        )
      );
    } else {
      dispatch(fetchBatchTasksList(selected.id));
    }
  };

  const assetProperties = asset.properties.map((property) => property);
  return (
    <div>
      <AccordionItem key={asset.id} id={asset.id}>
        <AccordionHeader
          text={asset.name}
          className={styles.accordion_header}
          hasItem={true}
        ></AccordionHeader>
        <AccordionPanel className={styles.accordion_panel}>
          <Form
            initialValues={getInitialState(assetProperties)}
            validationSchema={validationSchema}
            enableReinitialize
          >
            {({ values }) => {
              currentAsset.current = values;
              return (
                <PrototypeForm
                  className={styles.form}
                  assetProperties={assetProperties}
                />
              );
            }}
          </Form>
          {tasks.map((task) => {
            const forms = task.forms;
            const initialValues = getInitialStateFromForms(forms, {});

            return (
              <Form
                key={task.taskId}
                onSubmit={handleSubmitTaskTemplate(task)}
                initialValues={initialValues}
                validationSchema={validationSchema}
                enableReinitialize
              >
                <div className={styles.assets_title}>
                  <span className={styles.txt1}>{t("task name")}</span>
                  <span className={styles.txt3}>{t("properties")}</span>
                </div>
                <div className={styles.task_content}>
                  <div className={styles.task_name}>{task.taskName}</div>
                  <div className={styles.form_part}>
                    {forms?.map((el) => {
                      return (
                        <div key={el.id}>
                          {el.sections.map((section, index) => (
                            <div key={index}>
                              {section.name && (
                                <div className={styles.section_name}>
                                  {section.name}
                                </div>
                              )}
                              <PrototypeForm
                                sectionName={`${el.id}__${section.name}`}
                                assetProperties={addFormIdToFields(
                                  section.fields,
                                  el.id
                                ).map((field) => ({
                                  ...field.property,
                                  id: field?.id,
                                }))}
                              />
                            </div>
                          ))}
                        </div>
                      );
                    })}
                  </div>
                  <div className={styles.column}>
                    <div className={styles.button_section}>
                      <Button
                        type="reset"
                        variant="ghost"
                        size="s"
                        data-testid="modal-cancel"
                        onClick={() => {
                          setCannotBeDoneTask(task);
                          setIsCannotBeDoneModalOpen(true);
                        }}
                      >
                        {t("cannot be done")}
                      </Button>
                      <Button
                        className={styles.btn_separator}
                        type="submit"
                        variant="orange"
                        size="s"
                        data-testid="modal-create"
                      >
                        {t("done")}
                      </Button>
                    </div>
                  </div>
                </div>
              </Form>
            );
          })}
        </AccordionPanel>
      </AccordionItem>
      <Modal
        className={styles.modal}
        isOpen={isCannotBeDoneModalOpen}
        onClose={() => {
          setCannotBeDoneTask(null);
          setIsCannotBeDoneModalOpen(false);
        }}
        form={"cannot-be-done-task"}
        classNameTitle={styles.title}
        title={t("task cannot be done")}
        divider={false}
        footer={
          <div className={styles.btn_section}>
            <Button
              type="submit"
              variant="ghost"
              size="s"
              form={"cannot-be-done-task"}
            >
              {t("confirm")}
            </Button>
            <Button
              onClick={() => {
                setIsCannotBeDoneModalOpen(false);
                setCannotBeDoneTask(null);
              }}
              type="reset"
              variant="ghost"
              size="s"
              className={styles.btn_separator}
            >
              {t("cancel")}
            </Button>
          </div>
        }
      >
        <CannotBeDoneTaskForm
          formId={"cannot-be-done-task"}
          itemId={cannotBeDoneTask?.taskId}
          onClose={() => {
            setCannotBeDoneTask(null);
            setIsCannotBeDoneModalOpen(false);
          }}
          getBatchTasks={getBatchTasks}
        />
      </Modal>
    </div>
  );
};

BatchTasksProperties.propTypes = {
  task: PropTypes.object,
};

export default BatchTasksProperties;
