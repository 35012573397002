import React, { useContext, useState, useEffect } from "react";
import { I18nContext } from "../../i18n/I18nContext";
import styles from "./ConditionCreator.module.scss";
import RuleCondition from "./RuleCondition";
import Select from "react-select";
import Icon from "../Icon";

const ConditionCreator = (props) => {
  const {
    precondition,
    index,
    properties,
    createCondition,
    removeCondition,
    readOnly,
  } = props;
  const { t } = useContext(I18nContext);

  const propertiesOptions = properties.map((property) => ({
    value: property,
    label: property.name.split("__").pop(),
  }));

  const [condition, setCondition] = useState(
    precondition ? precondition : null
  );

  const [selectedProperty, setSelectedProperty] = useState(
    precondition
      ? propertiesOptions.find((propertyOption) =>
          !!precondition.propertyId
            ? propertyOption.value.id === precondition.propertyId
            : propertyOption.value.id === precondition.property.value.id
        )
      : null
  );

  useEffect(() => {
    if (!!precondition) {
      setCondition(precondition);
      setSelectedProperty(
        precondition
          ? propertiesOptions.find((propertyOption) =>
              !!precondition.propertyId
                ? propertyOption.value.id === precondition.propertyId
                : propertyOption.value.id === precondition.property.value.id
            )
          : null
      );
    }
  }, [precondition]);

  const selectStyles = {
    option: (provided, state, base) => {
      return {
        ...base,
        ...provided,
        display: "flex",
        alignItems: "center",
        color: "#393939",
        backgroundColor: "#ffffff",
        fontWeight: state.isSelected ? "500" : "400",
        height: "auto",
        "&:hover": {
          backgroundColor: "#eaeaea",
          color: "#393939",
          fontWeight: "500",
          cursor: "pointer",
        },
      };
    },
    control: (base, state, provided) => ({
      ...provided,
      paddingLeft: "0px",
      fontWeight: "400",
      fontSize: "14px",
      minWidth: "200px",
      height: "38px",
      minHeight: "36px",
      ...base,
      boxShadow: state.isFocused ? 0 : 0,
      borderColor: state.isFocused ? "#EE7203" : base.borderColor,
      "&:hover": {
        borderColor: state.isFocused ? "#EE7203" : base.borderColor,
        cursor: "pointer",
      },
    }),
    valueContainer: (base, state, provided) => ({
      ...provided,
      ...base,
      backgroundColor: state.isDisabled ? "#f4f4f4" : "#ffffff",
      borderRadius: "3px",
      height: "100%",
      overflowY: "auto",
    }),
    multiValue: (styles) => {
      return {
        ...styles,
        backgroundColor: "#D8E6FF",
      };
    },
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      backgroundColor: "#D8E6FF",
      color: "#191919",
      "&:hover": {
        backgroundColor: "#D8E6FF",
        color: "#191919",
      },
    }),
    menu: (provided) => {
      return {
        ...provided,
        zIndex: "10",
        border: "1px solid #EE7203",
      };
    },
    singleValue: (provided) => {
      return {
        ...provided,
        marginLeft: "2px",
        lineHeight: "16px",
      };
    },
    indicatorSeparator: (provided) => {
      return {
        ...provided,
        backgroundColor: "none",
      };
    },
    dropdownIndicator: (base, state) => {
      return {
        ...base,
        color: state.isFocused ? "#EE7203" : "#AAAAAA",
      };
    },
    placeholder: (provided) => {
      return {
        ...provided,
        marginLeft: "2px",
        color: "#AAAAAA",
        fontWeight: "400",
      };
    },
  };

  return (
    <>
      <div className={styles.header}>
        <div className={styles.label}>{t("property")}</div>
        {typeof index !== "undefined" && !readOnly && (
          <div
            className={styles.delete_btn}
            onClick={() => removeCondition(index)}
          >
            <Icon name="x-delete" />
          </div>
        )}
      </div>
      <Select
        styles={selectStyles}
        menuPosition={"relative"}
        isDisabled={!!precondition || readOnly}
        value={selectedProperty}
        maxMenuHeight={200}
        onChange={(selected) => setSelectedProperty(selected)}
        options={propertiesOptions}
        placeholder={t("select property")}
        noOptionsMessage={() => t("no options")}
      />
      {!!selectedProperty && (
        <RuleCondition
          condition={condition ? condition : null}
          selectedProperty={selectedProperty.value}
          selectStyles={selectStyles}
          selectCondition={(condition) => {
            setCondition(condition);
            createCondition(selectedProperty, condition, index);
          }}
          readOnly={readOnly}
        />
      )}
    </>
  );
};

export default ConditionCreator;
