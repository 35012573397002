import React, { useContext, useState, useEffect } from "react";
import { I18nContext } from "../../i18n/I18nContext";
import styles from "./RuleCondition.module.scss";
import ReactDatePicker from "react-datepicker";

const DateCondition = (props) => {
  const { condition, readOnly, selectCondition } = props;
  const { t } = useContext(I18nContext);
  const [conditionValue, setConditionValue] = useState(
    condition ? condition.expectedValue : Date.now()
  );

  useEffect(() => {
    const currentDate = Date.now();
    selectCondition(
      condition
        ? { value: condition.expectedValue, type: "date_precondition" }
        : { value: new Date(currentDate), type: "date_precondition" }
    );
  }, []);

  return (
    <div className={styles.condition}>
      <div className={styles.property_value}>{t("date value")}</div>
      <ReactDatePicker
        wrapperClassName={styles.wrapper}
        selected={conditionValue}
        readOnly={readOnly}
        onChange={(value) => {
          setConditionValue(value);
          selectCondition({
            value: value.toLocaleString(),
            type: "date_precondition",
          });
        }}
      />
    </div>
  );
};

export default DateCondition;
