import Actions from "../actions";

const initialValues = {
  items: [],
  loading: false,
  error: null,
  taskTypeEditError: "",
  taskTypeEditErrorStatus: null,
};

const taskPrototypesReducer = (state = initialValues, action) => {
  switch (action.type) {
    case Actions.FETCH_TASK_PROTOTYPES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case Actions.FETCH_TASK_PROTOTYPES_ERROR: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case Actions.FETCH_TASK_PROTOTYPES_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.tasks,
      };
    case Actions.TASK_PROTOTYPES_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        taskTypeEditError: action.error,
        taskTypeEditErrorStatus: action.status,
      };
    case Actions.TASK_PROTOTYPES_EDIT_ERROR_CLEAR:
      return {
        ...state,
        taskTypeEditError: "",
        taskTypeEditErrorStatus: null,
      };
    default:
      return state;
  }
};

export default taskPrototypesReducer;
