import React, { useContext, useState } from "react";
import * as Yup from "yup";
import {
  FieldLabel,
  Form,
  FormControl,
  FieldError,
  FieldTextArea,
} from "../Input";
import { useDispatch, useSelector } from "react-redux";
import styles from "./TaskPrototypeForm.module.scss";
import { I18nContext } from "../../i18n/I18nContext";

import { fetchGroupTasks, fetchTasksList, taskCannotBeDone } from "../../redux";
import { TaskState } from "../../constants/Tasks";

const defaultInitialValues = {
  description: "",
};

const validationSchema = (t) =>
  Yup.object({
    description: Yup.string()
      .max(140, t("the reason must be at most 140 characters"))
      .required(t("please provide the reason")),
  });

const CannotBeDoneTaskForm = ({
  formId,
  itemId,
  onClose,
  getBatchTasks,
  getAssetDetails,
}) => {
  const { t } = useContext(I18nContext);
  const dispatch = useDispatch();
  const allChosen = useSelector((state) => state.buildings.choosenBuildings);

  const [descriptionProvided, setDescriptionProvided] = useState(true);

  const handleSubmit = async (values, formik) => {
    setDescriptionProvided(true);
    const { description } = values;

    const descriptionWithoutSpaces = description.replaceAll(" ", "");

    if (descriptionWithoutSpaces.length < 1) {
      setDescriptionProvided(false);
    } else {
      try {
        const response = await dispatch(
          taskCannotBeDone(t, itemId, description)
        );

        dispatch(
          fetchTasksList(
            TaskState.TODO,
            allChosen.map((el) => el.id)
          )
        );
        dispatch(
          fetchTasksList(
            TaskState.DONE,
            allChosen.map((el) => el.id)
          )
        );
        dispatch(fetchGroupTasks(allChosen.map((el) => el.id)));
        if (!!getAssetDetails) {
          getAssetDetails();
        }

        formik.resetForm();
        onClose();
      } catch (err) {
        console.log(err);
      }

      if (getBatchTasks) {
        getBatchTasks();
      }
    }
  };

  return (
    <>
      <Form
        id={formId}
        onSubmit={handleSubmit}
        initialValues={defaultInitialValues}
        validationSchema={validationSchema(t)}
        enableReinitialize
      >
        {({ values, errors }) => {
          return (
            <>
              <FormControl>
                <FieldLabel>
                  {t("please provide the reason")}
                  <span className={styles.red}> *</span>
                </FieldLabel>
                <FieldTextArea name="description" />
                <FieldError name="description" />
                {!descriptionProvided && !!values.description.length && (
                  <div className={styles.error}>
                    {t("please provide the reason")}
                  </div>
                )}
              </FormControl>
            </>
          );
        }}
      </Form>
    </>
  );
};

export default CannotBeDoneTaskForm;
