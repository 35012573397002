import Actions from "../actions";
import axios from "../auth";
import { createToast } from "../toast/toastActions";

const fetchFormsSuccess = (forms) => {
  return {
    type: Actions.FETCH_FORMS_SUCCESS,
    forms,
  };
};

const fetchFormsByCompanySuccess = (forms) => {
  return {
    type: Actions.FETCH_FORMS_BY_COMPANY_SUCCESS,
    forms,
  };
};

const formsRequestSuccess = () => {
  return {
    type: Actions.FORMS_REQUEST_SUCCESS,
  };
};

const formsRequestFailure = (status, error) => {
  return {
    type: Actions.FORMS_REQUEST_FAILURE,
    status,
    error,
  };
};

const fetchFormsRequest = () => {
  return {
    type: Actions.FETCH_FORMS_REQUEST,
  };
};

export const addForm = (t, form) => {
  return (dispatch) => {
    dispatch(fetchFormsRequest());
    return axios.post("/forms", form).then(() => {
      dispatch(
        createToast({
          type: "success",
          title: t("successfully added form"),
        })
      );
      dispatch(formsRequestSuccess());
    });
  };
};

export const fetchForms = () => {
  return async (dispatch) => {
    dispatch(fetchFormsRequest());
    try {
      const response = await axios.get("/forms");
      const { data } = response;
      dispatch(fetchFormsSuccess(data));
    } catch (err) {
      console.log(err);
    }
  };
};

export const fetchFormsByCompany = (companyId) => {
  return async (dispatch) => {
    dispatch(fetchFormsRequest());
    try {
      const response = await axios.get(`/forms/company/${companyId}`);
      const { data } = response;
      dispatch(fetchFormsByCompanySuccess(data));
    } catch (err) {
      console.log(err);
    }
  };
};

export const updateForm = (t, form) => {
  return (dispatch) => {
    dispatch(fetchFormsRequest());
    return axios
      .put(`/forms`, form)
      .then((response) => {
        dispatch(
          createToast({
            type: "success",
            title: t("successfully edited form"),
          })
        );
        dispatch(formsRequestSuccess());
        return response;
      })
      .catch((error) => {
        dispatch(
          formsRequestFailure(error.response.status, error.response.data)
        );
      });
  };
};

export const formsEditErrorClear = () => {
  return {
    type: Actions.FORMS_EDIT_ERROR_CLEAR,
  };
};

export const deleteForm = (t, formId) => {
  return (dispatch) => {
    dispatch(fetchFormsRequest());
    return axios
      .delete(`/forms/${formId}`)
      .then(() => {
        dispatch(
          createToast({
            type: "success",
            title: t("successfully deleted form"),
          })
        );
        dispatch(formsRequestSuccess());
      })
      .catch((error) => {
        dispatch(
          formsRequestFailure(error.response.status, error.response.data)
        );
      });
  };
};

export const getOneForm = (formId) => {
  return () => {
    return axios.get(`/forms/${formId}`);
  };
};
