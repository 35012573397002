import Actions from "../actions";

const initialState = {
  items: [],
  itemsList: [],
  loading: false,
};

const licenseReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.FETCH_LICENSE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case Actions.FETCH_LICENSE_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.items,
      };
    case Actions.FETCH_LICENSE_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case Actions.FETCH_LICENSE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        itemsList: action.items,
      };
    default:
      return state;
  }
};

export default licenseReducer;
