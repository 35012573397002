import Vector2 from "../../Utils/Vector2";
import CanvasResizer from "../../Core/Resizer/CanvasResizer";
import ControllerModeType from "../../Core/Controller/ControllerModeType";
import CanvasInspector from "../../Core/CanvasInspector";
import MouseDownStrategyProvider from "./MouseDownStrategies/MouseDownStrategyProvider";
import MouseMoveStrategyProvider from "./MouseMoveStrategies/MouseMoveStrategyProvider";
import MouseUpStrategyProvider from "./MouseUpStrategies/MouseUpStrategyProvider";
class Controller {
  constructor(scene, renderer, camera) {
    this._isMouseDown = false;
    this._mode = ControllerModeType.VIEWER;
    this._selectedElement = null;
    this._scene = scene;
    this._renderer = renderer;
    this._camera = camera;
    this._resizer = new CanvasResizer(this.renderer);
    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.handleRedirectEvent = this.handleRedirectEvent.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.handleMouseUp = this.handleMouseUp.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.disableContextMenu = this.disableContextMenu.bind(this);
  }
  init() {
    this.renderer.canvas.addEventListener("mousedown", this.handleMouseDown);
    this.renderer.canvas.addEventListener(
      "mousedown",
      this.handleRedirectEvent
    );
    this.renderer.canvas.addEventListener("mousemove", this.handleMouseMove);
    this.renderer.canvas.addEventListener("mouseup", this.handleMouseUp);
    this.renderer.canvas.addEventListener("mouseleave", this.handleMouseLeave);
    this.renderer.canvas.addEventListener(
      "contextmenu",
      this.disableContextMenu
    );
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  }
  stop() {
    this.renderer.canvas.removeEventListener("mousedown", this.handleMouseDown);
    this.renderer.canvas.removeEventListener("mousemove", this.handleMouseMove);
    this.renderer.canvas.removeEventListener("mouseup", this.handleMouseUp);
    this.renderer.canvas.removeEventListener(
      "mouseleave",
      this.handleMouseLeave
    );
    this.renderer.canvas.removeEventListener(
      "contextmenu",
      this.disableContextMenu
    );
    window.removeEventListener("resize", this.handleResize);
  }
  handleMouseDown(e) {
    var _a;
    e.preventDefault();
    this._isMouseDown = true;
    const imagePosition = this.getCoordinatesFromEvent(e);
    const intersection = this.intersectScene(imagePosition);
    (_a = MouseDownStrategyProvider.get(this.mode, intersection)) === null ||
    _a === void 0
      ? void 0
      : _a.execute(this, intersection);
    this.renderer.render(this.scene, this.camera);
  }

  handleMouseMove(e) {
    var _a;
    e.preventDefault();
    if (this._isMouseDown) {
      const imagePosition = this.getCoordinatesFromEvent(e);
      (_a = MouseMoveStrategyProvider.get(this.mode, this)) === null ||
      _a === void 0
        ? void 0
        : _a.execute(imagePosition, this);
      this.renderer.render(this.scene, this.camera);
    }
  }
  handleMouseUp(e) {
    var _a;
    e.preventDefault();
    this._isMouseDown = false;
    (_a = MouseUpStrategyProvider.get(this.mode)) === null || _a === void 0
      ? void 0
      : _a.execute(this);
    this.renderer.render(this.scene, this.camera);
  }
  handleMouseLeave(e) {
    var _a;
    e.preventDefault();
    if (this._isMouseDown) {
      this._isMouseDown = false;
      (_a = MouseUpStrategyProvider.get(this.mode)) === null || _a === void 0
        ? void 0
        : _a.execute(this);
      this.renderer.render(this.scene, this.camera);
    }
  }

  handleRedirectEvent(e) {
    e.preventDefault();
    this._isMouseDown = true;
    const imagePosition = this.getCoordinatesFromEvent(e);
    const intersection = this.intersectScene(imagePosition);

    if (this._mode !== ControllerModeType.VIEWER) return;
    if (intersection.element) {
      window.location.href = `${window.location.origin}/assets/details/${intersection.element.id}`;
    }
  }
  disableContextMenu(e) {
    e.preventDefault();
    return false;
  }
  handleResize() {
    const size = this._resizer.resize();
    this.renderer.setRenderSize(size);
    this.camera.setRenderMatrix(this.renderer.size, size);
  }
  getCoordinatesFromEvent(e) {
    const canvas = this.renderer.canvas;
    const screenPosition = new Vector2(
      e.pageX - canvas.offsetLeft,
      e.pageY - canvas.offsetTop
    );
    return this.camera.getImageCoordinates(screenPosition);
  }
  intersectScene(mousePos) {
    return new CanvasInspector().findClickedElement(
      mousePos,
      this.scene.layers
    );
  }
  get selectedElement() {
    return this._selectedElement;
  }
  set selectedElement(selectedElement) {
    this._selectedElement = selectedElement;
  }
  get mode() {
    return this._mode;
  }
  set mode(mode) {
    this._mode = mode;
  }
  get savedPosition() {
    return this._savedPosition;
  }
  set savedPosition(position) {
    this._savedPosition = position;
  }
  get scene() {
    return this._scene;
  }
  get renderer() {
    return this._renderer;
  }
  get camera() {
    return this._camera;
  }
}
export default Controller;
