import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { appUrls } from "../../urls";
import { I18nContext } from "../../i18n/I18nContext";
import styles from "./SidebarSettings.module.scss";

const SidebarSettings = () => {
  const { t } = useContext(I18nContext);

  const sidebarItems = [
    {
      label: t("profiles"),
      to: appUrls.SIDEBAR_SECTION.SETTINGS_PROFILE,
    },
    {
      label: t("access data"),
      to: appUrls.SIDEBAR_SECTION.SETTINGS_DATA,
    },
    {
      label: t("account preferences"),
      to: appUrls.SIDEBAR_SECTION.SETTINGS_PREFERENCES,
    },
  ];

  return (
    <div className={styles.sidebar}>
      <div>
        <div className={styles.title}>T@pper account</div>
        {sidebarItems.map((item, idx) => (
          <NavLink
            key={idx}
            to={item.to}
            activeClassName={styles.selected_link}
            strict
          >
            <span className={styles.label_sidebar}>{item.label}</span>
          </NavLink>
        ))}
      </div>
    </div>
  );
};

export default SidebarSettings;
