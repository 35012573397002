import Actions from "../actions";

const initialState = {
  pending: false,
  pendingDelete: false,
  items: [],
  folders: [],
  types: [],
  error: null,
};

const attachmentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.FETCH_ATTACHMENTS_START:
      return {
        ...state,
        pending: true,
      };
    case Actions.FETCH_ATTACHMENTS_SUCCESS:
      return {
        ...state,
        pending: false,
        items: action.payload,
      };
    case Actions.FETCH_ATTACHMENTS_FOLDERS_SUCCESS:
      return {
        ...state,
        folders: action.payload,
      };
    case Actions.FETCH_ATTACHMENTS_TYPES_SUCCESS:
      return {
        ...state,
        types: action.payload,
      };
    case Actions.FETCH_ATTACHMENTS_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload,
      };
    case Actions.ATTACHMENTS_DELETE_START:
      return {
        ...state,
        pendingDelete: true,
      };
    case Actions.ATTACHMENTS_DELETE_STOP:
      return {
        ...state,
        pendingDelete: false,
      };
    default:
      return state;
  }
};

export default attachmentsReducer;
