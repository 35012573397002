class Vector2 {
    constructor(x = 0, y = 0) {
        this._length = 2;
        this._x = x;
        this._y = y;
    }
    add(vector) {
        this.x += vector.x;
        this.y += vector.y;
        return this;
    }
    sub(vector) {
        this.x -= vector.x;
        this.y -= vector.y;
        return this;
    }
    divBy(factor) {
        this.x /= factor;
        this.y /= factor;
        return this;
    }
    rotate(angle) {
        let c = Math.cos(angle);
        let s = Math.sin(angle);
        let x = this.x * c - this.y * s;
        let y = this.x * s + this.y * c;
        this.x = Number(x.toFixed(2));
        this.y = Number(y.toFixed(2));
        return this;
    }
    clone() {
        return new Vector2(this._x, this._y);
    }
    rotateAboutOrigin(origin, angle) {
        this.sub(origin);
        this.rotate(angle);
        this.add(origin);
        return this;
    }
    angleBetween(vector) {
        return Math.atan2(this.y, this.x) - Math.atan2(vector.y, vector.x);
    }
    transformWithMatrix(matrix) {
        const mV = matrix.values;
        let x = this.x * mV[0][0] + this.y * mV[0][1] + mV[0][2];
        let y = this.x * mV[1][0] + this.y * mV[1][1] + mV[1][2];
        return new Vector2(x, y);
    }
    isEmptyVector() {
        return this._x === 0 && this.y === 0;
    }
    get x() { return this._x; }
    get y() { return this._y; }
    get length() { return this._length; }
    set x(x) { this._x = x; }
    set y(y) { this._y = y; }
}
export default Vector2;
