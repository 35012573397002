class Matrix3 {
    constructor(values) {
        this._rows = 3;
        this._columns = 3;
        this._values = values || Array.from({ length: this._rows }, () => new Array(this._columns).fill(0));
    }
    static ones() {
        return new Matrix3([[1, 0, 0], [0, 1, 0], [0, 0, 1]]);
    }
    multiplyByMatrix(matrix) {
        if (this.columns !== matrix.rows)
            throw Error("Wrong dimensions of matrices");
        const result = new Matrix3();
        const aV = this.values;
        const bV = matrix.values;
        result.values[0][0] = aV[0][0] * bV[0][0] + aV[0][1] * bV[1][0] + aV[0][2] * bV[2][0];
        result.values[0][1] = aV[0][0] * bV[0][1] + aV[0][1] * bV[1][1] + aV[0][2] * bV[2][1];
        result.values[0][2] = aV[0][0] * bV[0][2] + aV[0][1] * bV[1][2] + aV[0][2] * bV[2][2];
        result.values[1][0] = aV[1][0] * bV[0][0] + aV[1][1] * bV[1][0] + aV[1][2] * bV[2][0];
        result.values[1][1] = aV[1][0] * bV[0][1] + aV[1][1] * bV[1][1] + aV[1][2] * bV[2][1];
        result.values[1][2] = aV[1][0] * bV[0][2] + aV[1][1] * bV[1][2] + aV[1][2] * bV[2][2];
        result.values[2][0] = aV[2][0] * bV[0][0] + aV[2][1] * bV[1][0] + aV[2][2] * bV[2][0];
        result.values[2][1] = aV[2][0] * bV[0][1] + aV[2][1] * bV[1][1] + aV[2][2] * bV[2][1];
        result.values[2][2] = aV[2][0] * bV[0][2] + aV[2][1] * bV[1][2] + aV[2][2] * bV[2][2];
        return result;
    }
    multiplyByScalar(scalar) {
        const result = new Matrix3();
        const aV = this.values;
        result.values[0][0] = aV[0][0] * scalar;
        result.values[0][1] = aV[0][1] * scalar;
        result.values[0][2] = aV[0][2] * scalar;
        result.values[1][0] = aV[1][0] * scalar;
        result.values[1][1] = aV[1][1] * scalar;
        result.values[1][2] = aV[1][2] * scalar;
        result.values[2][0] = aV[2][0] * scalar;
        result.values[2][1] = aV[2][1] * scalar;
        result.values[2][2] = aV[2][2] * scalar;
        return result;
    }
    getInverseMatrix() {
        const transposedComplementMatrix = this.getComplementMatrix().getTransposedMatrix();
        const determinant = this.getDeterminant();
        if (determinant === 0)
            return new Matrix3();
        return transposedComplementMatrix.multiplyByScalar(1 / determinant);
    }
    getComplementMatrix() {
        const result = new Matrix3();
        const aV = this.values;
        result.values[0][0] = aV[1][1] * aV[2][2] - aV[1][2] * aV[2][1];
        result.values[0][1] = aV[2][0] * aV[1][2] - aV[1][0] * aV[2][2];
        result.values[0][2] = aV[1][0] * aV[2][1] - aV[1][1] * aV[2][0];
        result.values[1][0] = aV[0][2] * aV[2][1] - aV[0][1] * aV[2][2];
        result.values[1][1] = aV[0][0] * aV[2][2] - aV[0][2] * aV[2][0];
        result.values[1][2] = aV[0][1] * aV[2][0] - aV[0][0] * aV[2][1];
        result.values[2][0] = aV[0][1] * aV[1][2] - aV[0][2] * aV[1][1];
        result.values[2][1] = aV[0][2] * aV[1][0] - aV[0][0] * aV[1][2];
        result.values[2][2] = aV[0][0] * aV[1][1] - aV[0][1] * aV[1][0];
        return result;
    }
    getTransposedMatrix() {
        const result = new Matrix3();
        const aV = this.values;
        result.values[0][0] = aV[0][0];
        result.values[0][1] = aV[1][0];
        result.values[0][2] = aV[2][0];
        result.values[1][0] = aV[0][1];
        result.values[1][1] = aV[1][1];
        result.values[1][2] = aV[2][1];
        result.values[2][0] = aV[0][2];
        result.values[2][1] = aV[1][2];
        result.values[2][2] = aV[2][2];
        return result;
    }
    getDeterminant() {
        const aV = this.values;
        return ((aV[0][0] * aV[1][1] * aV[2][2] + aV[0][1] * aV[1][2] * aV[2][0] + aV[0][2] * aV[1][0] * aV[2][1]) -
            (aV[0][2] * aV[1][1] * aV[2][0] + aV[0][0] * aV[1][2] * aV[2][1] + aV[0][1] * aV[1][0] * aV[2][2]));
    }
    translate(vector) {
        const result = new Matrix3(this.values);
        result.values[0][2] += vector.x;
        result.values[1][2] += vector.y;
        return result;
    }
    get values() { return this._values; }
    get rows() { return this._rows; }
    get columns() { return this._columns; }
}
export default Matrix3;
