import Actions from "../actions";

const initialState = {
  items: [],
  itemsByCompany: [],
  loading: false,
  formEditError: "",
  formEditErrorStatus: null,
};

const formsReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.FETCH_FORMS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case Actions.FORMS_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case Actions.FORMS_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        formEditError: action.error,
        formEditErrorStatus: action.status,
      };
    case Actions.FORMS_EDIT_ERROR_CLEAR:
      return {
        ...state,
        formEditError: "",
        formEditErrorStatus: null,
      };
    case Actions.FETCH_FORMS_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.forms,
      };
    case Actions.FETCH_FORMS_BY_COMPANY_SUCCESS:
      return {
        ...state,
        loading: false,
        itemsByCompany: action.forms,
      };
    default:
      return state;
  }
};

export default formsReducer;
