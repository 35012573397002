import Vector2 from "../../Utils/Vector2";
class ResizeStrategyProvider {
    static get(isContainerPanoramic, isCanvasPanoramic) {
        if (isContainerPanoramic) {
            if (isCanvasPanoramic)
                return containerPanoramicCanvasPanoramic;
            else
                return containerPanoramicCanvasNotPanoramic;
        }
        else {
            if (isCanvasPanoramic)
                return containerNotPanoramicCanvasPanoramic;
            else
                return containerNotPanoramicCanvasNotPanoramic;
        }
    }
}
export default ResizeStrategyProvider;
const containerPanoramicCanvasPanoramic = (containerSize, canvasSize, ratio) => {
    if (containerSize.x / ratio >= containerSize.y) {
        let maxHeihgt = Math.min(containerSize.y, canvasSize.y);
        return new Vector2(maxHeihgt * ratio, maxHeihgt);
    }
    else {
        let maxWidth = Math.min(containerSize.x, canvasSize.x);
        return new Vector2(maxWidth, maxWidth / ratio);
    }
};
const containerPanoramicCanvasNotPanoramic = (containerSize, canvasSize, ratio) => {
    let maxHeihgt = Math.min(containerSize.y, canvasSize.y);
    return new Vector2(maxHeihgt * ratio, maxHeihgt);
};
const containerNotPanoramicCanvasPanoramic = (containerSize, canvasSize, ratio) => {
    let maxWidth = Math.min(containerSize.x, canvasSize.x);
    return new Vector2(maxWidth, maxWidth / ratio);
};
const containerNotPanoramicCanvasNotPanoramic = (containerSize, canvasSize, ratio) => {
    if (containerSize.y * ratio >= containerSize.x) {
        let maxWidth = Math.min(containerSize.x, canvasSize.x);
        return new Vector2(maxWidth, maxWidth / ratio);
    }
    else {
        let maxHeihgt = Math.min(containerSize.y, canvasSize.y);
        return new Vector2(maxHeihgt * ratio, maxHeihgt);
    }
};
