import React from "react";
import styles from "./PieChartCompletedTasksLegend.module.scss";
import { getTasksCount } from "../utils";
import PieChartLegendItem from "./PieChartLegendItem";

const PieChartCompletedTasksLegend = ({ completedTasksData, texts, colors }) => {
  const count = getTasksCount(Object.values(completedTasksData));
  const getPercentage = (item, actionsCount) => {
    if (item.count === 0) {
      return 0;
    }
    return Math.round((item.count / actionsCount) * 100);
  };

  if (!completedTasksData) {
    return null;
  }

  let dataArray = [];
  for (const [key, value] of Object.entries(completedTasksData)) {
    dataArray.push({...value, state: key});
  }

  return (
    <div>
      {dataArray.map((el, index) => (
        <PieChartLegendItem
          key={index}
          item={el}
          tasksCount={getTasksCount(dataArray)}
          texts={texts}
          colors={colors}
        />
      ))}
    </div>
  );
};

export default PieChartCompletedTasksLegend;
