var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
class ImageLoader {
    static loadSVG(url, color) {
        return __awaiter(this, void 0, void 0, function* () {
            let res = yield fetch(url);
            let text = yield res.text();
            text = text.replace(/fill="#000000"/g, `fill="${color}"`);
            text = text.replace(/stroke="#000000"/g, `stroke="${color}"`);
            if (!text.includes("fill")) {
              text = text.replace(/path/g, `path fill="${color}"`);
            }
            if (!text.includes("stroke")) {
              text = text.replace(/path/g, `path stroke="${color}"`);
            }

            return yield this.loadFromUrl(`data:image/svg+xml;charset=utf-8,${encodeURIComponent(text)}`);
        });
    }
    static loadFromUrl(url) {
        return __awaiter(this, void 0, void 0, function* () {
            return new Promise(resolve => {
                const image = new Image();
                image.src = url;
                image.crossOrigin = '';
                image.addEventListener('load', (b) => { resolve(image); });
            });
        });
    }
}
export default ImageLoader;
