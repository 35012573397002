import Actions from "../actions";
import axios from "../auth";
import { createToast } from "../toast/toastActions";

const fetchIconsSuccess = (icons) => {
  return {
    type: Actions.FETCH_ICONS_SUCCESS,
    payload: icons,
  };
};

const fetchIconsFailure = (error) => {
  return {
    type: Actions.FETCH_ICONS_FAILURE,
    payload: error,
  };
};

export const fetchIconsList = () => {
  return (dispatch) => {
    return axios
      .get(`/icon`)
      .then((response) => {
        dispatch(fetchIconsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchIconsFailure(error));
      });
  };
};

export const addIcon = (t, icon) => {
  const formData = new FormData();
  formData.append("name", icon.name);
  formData.append("file", icon.file, icon.file.name);
  return (dispatch) => {
    return axios.post(`/icon`, formData).then((response) => {
      dispatch(
        createToast({
          type: "success",
          title: t("successfully added new icon"),
        })
      );
    });
  };
};

export const editIcon = (t, icon) => {
  const formData = new FormData();
  if (icon.file) {
    formData.append("file", icon.file, icon.file.name);
  }
  return (dispatch) => {
    return axios
      .put(`/icon?id=${icon.id}&name=${icon.name}`, icon.file ? formData : {})
      .then((response) => {
        dispatch(
          createToast({
            type: "success",
            title: t("successfully edited icon"),
          })
        );
      });
  };
};

export const deleteIcon = (t, id) => {
  return (dispatch) => {
    return axios.delete(`/icon/${id}`).then((response) => {
      dispatch(
        createToast({
          type: "success",
          title: t("successfully deleted icon"),
        })
      );
    });
  };
};
