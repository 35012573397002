import React from "react";
import styles from "./AssetDetailsModal.module.scss";
import AssetDetails from "../AssetDetails";

const AssetDetailsModal = ({
  id,
  isOpen,
  onClose,
  onDeleteBuilding,
  updateAsset,
  parentsIds,
}) => {
  if (!isOpen) return null;
  return (
    <div className={styles.asset_details_modal}>
      <AssetDetails
        id={id}
        closeModal={onClose}
        onDeleteBuilding={onDeleteBuilding}
        updateAsset={updateAsset}
        parentsIds={parentsIds}
      />
    </div>
  );
};

export default AssetDetailsModal;
