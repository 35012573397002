import React, { useContext, useEffect, useRef, useState } from "react";
import axios from "../../redux/auth";
import * as Yup from "yup";
import {
  FieldLabel,
  Form,
  FormControl,
  FieldError,
  FieldSelect,
  FieldTextArea,
  FieldInput,
  DropzoneInput,
} from "../../components/Input";
import { useDispatch, useSelector } from "react-redux";
import {
  editAsset,
  addAssetLayoutImage,
  deleteSingleAttachment,
  fetchAssets,
  createToast,
} from "../../redux";
import Modal from "../../components/Modal";
import styles from "./EditAssetModal.module.scss";
import {
  getInitialState,
  PrototypeForm,
  updateAssetProperties,
} from "../../components/PrototypeForm";
import { I18nContext } from "../../i18n/I18nContext";
import DroppedFile from "../../components/DroppedFile";
import Button from "../../components/Button";
import Icon from "../../components/Icon";
import DeleteLayoutModal from "./DeleteLayoutModal";
import { createPropertySchema } from "./utils";

const basicValues = {
  assetNameUnique: "",
  assetDesc: "",
  prototypeId: "",
  licenseId: "",
};

const maxAttachmentsSize = 209715200; //200MB

const EditAssetModal = ({ asset, onClose, isOpen }) => {
  const { t } = useContext(I18nContext);
  const dispatch = useDispatch();
  const currentValues = useRef(basicValues);
  const [pending, setPending] = useState(false);
  const [initialValues, setInitialValues] = useState(basicValues);
  const [attachments, setAttachments] = useState([]);
  const [layoutImage, setLayoutImage] = useState("");
  const [assetProperties, setAssetProperties] = useState({
    properties: [],
    procedures: [],
  });
  const [openDeleteLayoutModal, setOpenDeleteLayoutModal] = useState(false);

  const prototypes = useSelector((state) => state.prototypes.items);

  useEffect(() => {
    if (asset?.hasPlan) {
      getLayout();
    }
  }, [asset]);

  const getLayout = async () => {
    setPending(true);
    const { data } = await axios.get(`/assets/${asset.id}/layout`);
    let urls = await getUrlsForKeys([data.key]);
    setLayoutImage(urls[0]);
    setPending(false);
  };

  const getUrlsForKeys = async (keys) => {
    const { data } = await axios.post("/files", keys);
    return data;
  };

  useEffect(() => {
    if (!asset) {
      return;
    }
    currentValues.current = {
      prototypeId: asset.prototypeId,
      assetDesc: asset.desc,
      tags: asset.tags,
      assetNameUnique: asset.name,
      licenseId: asset.licenseId,
    };

    setInitialValues(
      getInitialState(asset?.assetProperties, currentValues.current)
    );
    setTimeout(() => {
      setAssetProperties(asset?.assetProperties);
    }, 100);
  }, [asset]);

  useEffect(() => {
    setAttachments(asset?.attachments ?? []);
  }, [asset]);

  useEffect(() => {
    setInitialValues(getInitialState(assetProperties, currentValues.current));
  }, [assetProperties]);

  const handleDeleteFile = async (fileToRemove) => {
    await dispatch(deleteSingleAttachment(t, asset.id, fileToRemove.key));
    setAttachments(attachments.filter((file) => file !== fileToRemove));
  };

  const createValidationSchema = (t, assetProperties) => {
    const propertiesSchema = createPropertySchema(
      t,
      assetProperties.properties
    );

    return Yup.object({
      prototypeId: Yup.string().required(t("please select one of the options")),
      assetNameUnique: Yup.string().required(
        t("please enter the name of the asset")
      ),
      ...propertiesSchema,
    });
  };

  const handleClear = () => {
    setAssetProperties({ properties: [], procedures: [] });
    setAttachments([]);
    onClose();
  };

  const handleSubmit = async (values, { resetForm }) => {
    setPending(true);
    const {
      prototypeId,
      licenseId,
      assetNameUnique: name,
      assetDesc: desc,
      ...propertiesValues
    } = values;

    const updatedAssetProperties = updateAssetProperties(
      assetProperties.properties,
      propertiesValues
    );

    try {
      const data = await dispatch(
        editAsset(t, asset.id, {
          tags: asset.tags,
          active: asset.assetState === "ACTIVE",
          name,
          desc,
          licenseId,
          assetProperties: {
            procedures: assetProperties.procedures,
            properties: updatedAssetProperties,
          },
          displayedProperties: asset.displayedProperties || [],
        })
      );

      if (values.layout && values.layout.length) {
        const response = await dispatch(
          addAssetLayoutImage(t, data.id, values.layout[0])
        );
        if (response) {
          await dispatch(fetchAssets(asset.parentId));
        }
      }

      await dispatch(fetchAssets(asset.parentId));
      await dispatch(fetchAssets());
      setPending(false);
      onClose();
    } catch (err) {
      if (asset.parentId) {
        dispatch(
          createToast({
            type: "error",
            title: t("editing asset failed"),
          })
        );
      }
      setPending(false);
    }
    resetForm();
    onClose();
    setPending(false);
  };

  const handleDeleteProperty = (propertyToRemove) => {
    setAssetProperties(
      assetProperties.filter(
        ({ property }) => property.name !== propertyToRemove.name
      )
    );
  };

  return (
    <Modal
      className={styles.modal}
      isOpen={isOpen}
      onClose={onClose}
      title={t("edit asset", "title")}
      form="edit-asset-form"
      footer={
        <div className={styles.button_section}>
          <Button
            onClick={handleClear}
            type="reset"
            form="edit-asset-form"
            variant={pending ? "disabled" : "ghost"}
            size="s"
            disabled={pending}
          >
            {t("cancel")}
          </Button>
          <Button
            className={styles.btn_separator}
            type="submit"
            variant={pending ? "disabled" : "orange"}
            size="s"
            form="edit-asset-form"
            disabled={pending}
          >
            {t("save changes", "title")}
          </Button>
        </div>
      }
    >
      <Form
        id="edit-asset-form"
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={createValidationSchema(t, assetProperties)}
        enableReinitialize={true}
      >
        {({ values }) => {
          currentValues.current = values;

          return (
            <>
              <FormControl>
                <FieldLabel>
                  {asset.grouping ? t("name") : t("id", "upper")}
                  <span className={styles.red}> *</span>
                </FieldLabel>
                <FieldInput
                  name="assetNameUnique"
                  type="text"
                  placeholder={
                    asset.grouping ? t("add asset name") : t("add asset id")
                  }
                  size="m"
                />
                <FieldError name="assetNameUnique" />
              </FormControl>
              <FormControl>
                <FieldLabel>
                  {t("prototype")}
                  <span className={styles.red}> *</span>
                </FieldLabel>
                <div className={styles.width}>
                  <FieldSelect
                    size="s"
                    name="prototypeId"
                    options={prototypes.map((proto) => ({
                      label: proto.name,
                      value: proto.id,
                    }))}
                    placeholder={t("prototype")}
                    disabled
                  />
                </div>
                <FieldError name="prototypeId" />
              </FormControl>

              {asset && asset.grouping && (
                <div className={styles.margin_bottom}>
                  <FieldLabel>{t("asset layout image")}</FieldLabel>
                  {!!layoutImage && (
                    <div className={styles.layoutImageWrapper}>
                      <img
                        src={layoutImage}
                        className={styles.layoutImagePreview}
                        alt="layout image preview"
                      />
                      <div className={styles.layoutImageDeleteButton}>
                        <Icon
                          name="trash"
                          onClick={() => setOpenDeleteLayoutModal(true)}
                        />
                      </div>
                    </div>
                  )}
                  <DropzoneInput
                    maxSize={maxAttachmentsSize}
                    name="layout"
                    text={t("drag and drop an image to upload, or")}
                    notMultiple
                  />
                </div>
              )}

              <FormControl>
                <FieldLabel>
                  {t("description")}
                  <span className={styles.red}> *</span>
                </FieldLabel>
                <FieldTextArea name="assetDesc" disabled />
                <FieldError name="assetDesc" />
              </FormControl>
              <div className={styles.separator}></div>

              <PrototypeForm
                assetProperties={assetProperties.properties}
                onDeleteProperty={handleDeleteProperty}
                editable={true}
                notDraggable={true}
              />
            </>
          );
        }}
      </Form>
      <div className={styles.start_row}>
        {attachments.map((file, index) => (
          <DroppedFile file={file} key={index} onDelete={handleDeleteFile} />
        ))}
      </div>
      {!!openDeleteLayoutModal && (
        <DeleteLayoutModal
          key="delete-unit"
          asset={asset}
          isOpen={openDeleteLayoutModal}
          onClose={() => {
            setOpenDeleteLayoutModal(false);
          }}
          setLayoutImage={setLayoutImage}
        />
      )}
    </Modal>
  );
};

export default EditAssetModal;
