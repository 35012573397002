import Icon from "../../Icon";
import styles from "./HelpMenuItem.module.scss";

const HelpMenuItem = ({ title, iconName, onClick }) => {
  return (
    <div className={styles.wrapper} onClick={onClick}>
      <Icon name={iconName} className={styles.icon} />
      <span>{title}</span>
    </div>
  );
};

export default HelpMenuItem;
