import React, { useContext, useEffect, useState } from "react";
import styles from "./UsersAccess.module.scss";
import { I18nContext } from "../../i18n/I18nContext";
import {
  fetchUsersWithAssetAccess,
  fetchRegisteredUsersList,
  saveAssetAccess,
} from "../../redux";
import { useDispatch, useSelector } from "react-redux";
import Button from "../Button";
import Icon from "../Icon";
import Modal from "../Modal";

const UsersAccess = ({ assetDetails }) => {
  const { t } = useContext(I18nContext);
  const assetId = assetDetails.id;
  const dispatch = useDispatch();
  const usersList = useSelector((state) => state.access.userItems);
  const usersWithAssetAccess = useSelector(
    (state) => state.access.usersWithAssetAccess
  );
  const [isOpen, setIsOpen] = useState(false);
  const [access, setAccess] = useState([]);
  const [openAddUserAccessModal, setOpenAddUserAccessModal] = useState(false);
  const [addUsersList, setAddUsersList] = useState([]);
  const [usersFilter, setUsersFilter] = useState("");
  const [addUsersFilter, setAddUsersFilter] = useState("");
  const [changed, setChanged] = useState(false);

  useEffect(() => {
    dispatch(fetchRegisteredUsersList());
    dispatch(fetchUsersWithAssetAccess(assetId));
  }, [assetId]);

  useEffect(() => {
    setAccess(usersWithAssetAccess);
  }, [usersWithAssetAccess]);

  const addToAccessList = (user) => {
    const newList = [...addUsersList];
    newList.push(user);
    setAddUsersList(newList);
  };

  const handleAddUsersAccess = () => {
    const accessCopy = [...access];
    addUsersList.forEach((user) => accessCopy.push(user));
    setAccess(accessCopy);
    setChanged(true);
    setAddUsersList([]);
    setOpenAddUserAccessModal(false);
  };

  const removeUser = (userId) => {
    const accessCopy = [...access];
    const index = access.indexOf(access.find((x) => x.id === userId));
    accessCopy.splice(index, 1);
    setAccess(accessCopy);
    setChanged(true);
  };

  const handleSaveAccessClick = () => {
    const userIds = access.map((user) => user.id);
    dispatch(saveAssetAccess(t, { assetId, userIds }));
  };

  return (
    <div className={styles.users_access}>
      <div
        className={
          isOpen ? styles.btn_containerRight_open : styles.btn_containerRight
        }
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className={styles.title}>
          <div className={styles.icon}>
            {!!isOpen ? (
              <Icon name="chevron-down" />
            ) : (
              <Icon name="chevron-right" />
            )}
          </div>
          {t("users' access")} ({access.length})
        </div>
        {!!isOpen && (
          <div
            className={styles.add_button}
            onClick={(e) => {
              e.stopPropagation();
              setOpenAddUserAccessModal(true);
            }}
          >
            <Icon name="add" className={styles.add_icon} />
          </div>
        )}
      </div>
      {!!isOpen && !!access.length && (
        <input
          className={styles.search_input}
          type="text"
          value={usersFilter}
          onChange={(e) => setUsersFilter(e.target.value)}
        />
      )}
      {!!isOpen && !!access.length && (
        <ul className={styles.users_list}>
          {access
            .filter((user) =>
              user.email.toLowerCase().includes(usersFilter.toLowerCase())
            )
            .map((user) => (
              <li key={user.id} className={styles.list_item}>
                <div
                  className={styles.remove_user_access_button}
                  onClick={() => removeUser(user.id)}
                >
                  <Icon name="x-delete" />
                </div>
                <label className={styles.checkbox_label} htmlFor={user.id}>
                  {user.email}
                </label>
              </li>
            ))}
        </ul>
      )}
      {!!isOpen && (
        <div className={styles.button_wrapper}>
          <Button
            type="button"
            variant={changed ? "orange" : "disabled"}
            size="s"
            onClick={handleSaveAccessClick}
            disabled={!changed}
          >
            {t("save")}
          </Button>
        </div>
      )}
      {!!openAddUserAccessModal && (
        <Modal
          isOpen={openAddUserAccessModal}
          onClose={() => setOpenAddUserAccessModal(false)}
          title={t("add user")}
          form="add-user-form"
          zeroPadding
          footer={
            <div className={styles.button_section}>
              <Button
                onClick={() => setOpenAddUserAccessModal(false)}
                type="reset"
                form={"add-user-form"}
                variant="ghost"
                size="s"
              >
                {t("cancel")}
              </Button>
              <Button
                className={styles.btn_separator}
                type="submit"
                variant="orange"
                size="s"
                form={"add-user-form"}
                onClick={handleAddUsersAccess}
              >
                {t("add")}
              </Button>
            </div>
          }
        >
          <input
            className={styles.search_input}
            type="text"
            value={addUsersFilter}
            onChange={(e) => setAddUsersFilter(e.target.value)}
          />
          <ul className={styles.users_list}>
            {usersList
              .filter((user) =>
                user.email.toLowerCase().includes(addUsersFilter.toLowerCase())
              )
              .map((user) => (
                <li key={user.id} className={styles.list_item}>
                  <input
                    id={user.id}
                    name={user.id}
                    type="checkbox"
                    className={styles.checkbox}
                    onChange={(e) => addToAccessList(user)}
                    checked={addUsersList.includes(user)}
                  />
                  <label className={styles.checkbox_label} htmlFor={user.id}>
                    {user.email}
                  </label>
                </li>
              ))}
          </ul>
        </Modal>
      )}
    </div>
  );
};

export default UsersAccess;
