import React, { useContext } from "react";
import { jsPDF } from "jspdf";
import { useQRCode } from "react-qrcodes";
import { I18nContext } from "../../i18n/I18nContext";
import styles from "./QrCode.module.scss";
import Button from "../Button/";

const QrCode = ({ qrCodeText, assetName }) => {
  const { t } = useContext(I18nContext);
  const [inputRef] = useQRCode({
    text: qrCodeText ? qrCodeText : window.location.href,
    options: {
      level: "M",
      margin: 10,
      scale: 1,
      width: 200,
      color: {
        dark: "#191919",
        light: "#FFFFFF",
      },
    },
  });

  const handleDownloadImage = () => {
    const canvas = document.getElementById("qrcode");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${assetName ? assetName : "image"}_QRcode.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const handleDownloadPdf = () => {
    const canvas = document.getElementById("qrcode");
    const imageData = canvas.toDataURL("image/png", 1.0);
    const pdf = new jsPDF();
    pdf.addImage(imageData, "PNG", 0, 0);
    if (assetName) pdf.text(assetName, 10, 8);
    pdf.text(qrCodeText, 10, 60);
    pdf.save(`${assetName ? assetName : "download_QRcode"}.pdf`);
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.container}>
        <div className={styles.qrwrapper}>
          <canvas ref={inputRef} id="qrcode" />
        </div>
        <div className={styles.column_txt}>
          <span className={styles.txt}>{t("qr code", "lower")}</span>
          <span className={styles.txt1}>
            {t("scan qr code in the app to load all tasks", "lower")}.
          </span>
        </div>
      </div>
      <div className={styles.buttonsContainer}>
        <div className={styles.buttonWrapper}>
          <Button variant="orange" size="s" onClick={handleDownloadPdf}>
            {t("download as pdf")}
          </Button>
        </div>
        <div className={styles.buttonWrapper}>
          <Button variant="orange" size="s" onClick={handleDownloadImage}>
            {t("download as image")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default QrCode;
