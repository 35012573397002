import React, { useContext } from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import Icon from "../Icon";
import { FieldError, FieldSelect, Form, FormControl } from "../Input";
import { I18nContext } from "../../i18n/I18nContext";
import styles from "./OrganizationRowForm.module.scss";

const OrganizationRowForm = ({ org }) => {
  const { t } = useContext(I18nContext);
  const rightOptions = useSelector((state) =>
    state.rights.items.map((r) => ({ value: r.id, label: r.category }))
  );

  const initialValues = {
    right: org.rights.map((r) => r.id),
  };

  const validationSchema = Yup.object({
    right: Yup.array()
      .required(t("please choose one option"))
      .of(Yup.string().oneOf(rightOptions.map((item) => item.value))),
  });

  const handleSubmit = (values) => {
    console.log(values);
  };

  return (
    <>
      <Form
        id="add-new-organization"
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
      >
        <div className={styles.box_container}>
          <div className={styles.txt_email}></div>
          <div className={styles.maintainer_icon}>
            <Icon name="maintainer-orange" className={styles.icon_menu} />
            <span>{org.users?.length}</span>
          </div>
          <div className={styles.date}>
            {new Date(org.date).toLocaleDateString(undefined, {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            })}
          </div>
        </div>
      </Form>
    </>
  );
};

export default OrganizationRowForm;
