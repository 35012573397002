import { useDropzone } from "react-dropzone";
import { useField } from "formik";
import Icon from "../Icon";
import DroppedFile from "../DroppedFile/DroppedFile";
import styles from "./DropzoneInput.module.scss";
import { I18nContext } from "../../i18n/I18nContext";
import { useContext } from "react";

const maxAttachmentsSize = 209715200; //200MB

const DropzoneInput = ({
  maxSize = maxAttachmentsSize,
  name = "files",
  text,
  fileType,
  onDelete,
  disabled,
  view,
  notMultiple,
  filesToDisplay,
}) => {
  const [, { value = [], error }, { setValue, setError }] = useField({
    name,
  });

  const handleAddFiles = (addedFiles) => {
    const newFiles = addedFiles.filter(
      (file) => !value.find((current) => current.name === file.name)
    );
    setValue([...value, ...newFiles]);
  };

  const handleDelete = async (fileToRemove) => {
    onDelete && onDelete(fileToRemove);
    setValue(value.filter((file) => file !== fileToRemove));
  };

  return (
    <div>
      {!disabled && (
        <div className={styles.row}>
          <UploadComponent
            addFiles={handleAddFiles}
            setError={setError}
            files={value}
            maxSize={maxSize}
            text={text}
            fileType={fileType}
            notMultiple={notMultiple}
          />
        </div>
      )}

      {error && <div className={styles.error}>{error} </div>}
      <div className={styles.file_row}>
        {!filesToDisplay &&
          value.map((file, index) => (
            <DroppedFile
              file={file}
              key={index}
              disabled={disabled}
              onDelete={handleDelete}
              view={view}
            />
          ))}

        {!!filesToDisplay?.length &&
          filesToDisplay.map((file, index) => (
            <DroppedFile
              file={file}
              key={index}
              disabled={disabled}
              onDelete={handleDelete}
              view={view}
            />
          ))}
      </div>
    </div>
  );
};

const UploadComponent = ({
  addFiles,
  files,
  maxSize,
  setError,
  text,
  fileType,
  notMultiple,
}) => {
  const { t } = useContext(I18nContext);
  const filesSize = files.reduce((current, file) => current + file.size, 0);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept:
      fileType === "image"
        ? "image/*"
        : fileType === "csv"
        ? ".csv"
        : fileType === "excel"
        ? ".xlsx"
        : "",
    multiple: fileType !== "image" && !notMultiple,

    onDrop: (acceptedFiles) => {
      const acceptedSize = acceptedFiles.reduce(
        (cur, file) => cur + file.size,
        0
      );

      if (filesSize + acceptedSize > maxSize) {
        setError(t("too big files, limit 200MB"));
      } else {
        setError(undefined);
        addFiles([...acceptedFiles, ...files]);
      }
    },
  });

  return (
    <div {...getRootProps({ className: "dropzone" })}>
      <input
        {...getInputProps()}
        data-testid="file-input"
        multiple={!notMultiple}
      />
      {isDragActive ? (
        <p>{t("drop the files here...")}</p>
      ) : (
        <div className={styles.drag_container}>
          <div className={styles.icon_drag}>
            <Icon name="drag-drop" />
          </div>
          <div className={styles.text}>
            {text ? text : t("drag and drop a file to upload, or", "lower")}
            <span className={styles.underline}> {t("browse", "lower")}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default DropzoneInput;
