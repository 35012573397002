import React from "react";

export const TabContext = React.createContext({
  currentValue: null,
  changeTab: () => {},
});

export const useTabs = () => {
  const context = React.useContext(TabContext);
  return context;
};
