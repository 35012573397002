import React, { useContext, useState } from "react";
import { I18nContext } from "../../i18n/I18nContext";
import styles from "./RuleCondition.module.scss";
import Select from "react-select";

const BooleanCondition = (props) => {
  const { condition, readOnly, selectStyles, selectCondition } = props;
  const { t } = useContext(I18nContext);

  const booleanOptions = [
    { value: false, label: t("false") },
    { value: true, label: t("true") },
  ];

  const [conditionValue, setConditionValue] = useState(
    condition
      ? booleanOptions.find(
          (option) => option.value === condition.expectedValue
        )
      : null
  );

  return (
    <div className={styles.condition}>
      <div className={styles.property_value}>{t("property value")}</div>
      <Select
        styles={selectStyles}
        menuPosition={"relative"}
        isDisabled={readOnly}
        value={conditionValue}
        maxMenuHeight={200}
        onChange={(selected) => {
          setConditionValue(selected);
          selectCondition({ value: selected, type: "boolean_precondition" });
        }}
        options={booleanOptions}
        placeholder={t("select property value")}
        noOptionsMessage={() => t("no options")}
      />
    </div>
  );
};

export default BooleanCondition;
