import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearBatchTaskList,
  fetchBatchTasksList,
  getBatchTasks,
  getBatchTaskPrototypes,
} from "../../redux";
import { FilterDropdown } from "../Dropdown";
import styles from "./BatchTasks.module.scss";
import BatchTasksProperties from "./BatchTasksProperties";
import { Accordion } from "../../components/Accordion";
import { I18nContext } from "../../i18n/I18nContext";
import { useState } from "react";

const BatchTasks = ({ getTasks }) => {
  const { t } = useContext(I18nContext);
  const dispatch = useDispatch();
  const [selected, setSelectedTemplate] = useState(null);
  // const taskPrototypes = useSelector(
  //   (state) => state.taskPrototypes.items ?? []
  // );
  const [tasksProto, setTasksProto] = useState([]);

  const allChoosen = useSelector((state) => state.buildings.choosenBuildings);

  const batchTasks = useSelector(getBatchTasks);

  const fetchData = async () => {
    const { data } = await getBatchTaskPrototypes();
    setTasksProto(data);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (selected) {
      if (allChoosen.length > 0) {
        dispatch(
          fetchBatchTasksList(
            selected.id,
            allChoosen.map((el) => el.id)
          )
        );
      } else {
        dispatch(fetchBatchTasksList(selected.id));
      }
    } else {
      dispatch(clearBatchTaskList());
    }
  }, [dispatch, selected]);

  useEffect(() => {
    if (selected) {
      if (allChoosen.length > 0) {
        dispatch(
          fetchBatchTasksList(
            selected.id,
            allChoosen.map((el) => el.id)
          )
        );
      } else {
        dispatch(fetchBatchTasksList(selected.id));
      }
    } else {
      dispatch(clearBatchTaskList());
    }
  }, [dispatch, allChoosen]);

  return (
    <div className={styles.column}>
      <div className={styles.dropdown}>
        <FilterDropdown
          size="s"
          name="prototypeId"
          options={tasksProto.map((proto) => ({
            label: proto.name,
            value: proto.id,
          }))}
          onSelect={(option) => {
            setSelectedTemplate(
              tasksProto.find((template) => template.id === option?.value)
            );
          }}
          placeholder={t("choose task template")}
        />
      </div>
      {selected && (
        <div className={styles.assets_title}>
          <span className={styles.txt1}>{t("asset name")}</span>
        </div>
      )}
      {!selected && (
        <div className={styles.assets_title}>
          <span className={styles.txt1}>
            {t(
              "please use a filter to display results based on your task template"
            )}
          </span>
        </div>
      )}
      {batchTasks?.map((task) => (
        <Accordion key={task.asset.id}>
          <BatchTasksProperties
            selected={selected}
            task={task}
            getTasks={getTasks}
            getBatchTasks={() => {
              fetchData();
              dispatch(fetchBatchTasksList(selected.id));
            }}
          />
        </Accordion>
      ))}
    </div>
  );
};

export default BatchTasks;
