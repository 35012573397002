import Actions from "../actions";
import axios from "../auth";
import { createToast } from "../toast/toastActions";

const fetchReportRequest = () => {
  return {
    type: Actions.FETCH_REPORT_REQUEST,
  };
};

const fetchReportFinished = () => {
  return {
    type: Actions.FETCH_REPORT_FINISHED,
  };
};

export const fetchTasksReport = (t, type = "tasks_open", rootIds = []) => {
  const url =
    type === "tasks_closed" ? "/tasks/report/closed" : "/tasks/report/open";

  return (dispatch) => {
    dispatch(fetchReportRequest());
    return axios
      .post(url, { rootIds }, { responseType: "arraybuffer" })
      .then((response) => {
        dispatch(
          createToast({
            type: "success",
            title: t("successfully generated report"),
          })
        );
        dispatch(fetchReportFinished());
        return response.data;
      })
      .catch((error) => {
        dispatch(fetchReportFinished());
        dispatch(
          createToast({
            type: "error",
            title: t("report generating failed"),
          })
        );
      });
  };
};

export const fetchAssetsReport = (t, type = "assets", rootIds = []) => {
  const url = type === "assets" ? "/assets/report" : "/assets/changes/report";

  return (dispatch) => {
    dispatch(fetchReportRequest());
    return axios
      .post(url, { rootIds }, { responseType: "arraybuffer" })
      .then((response) => {
        dispatch(
          createToast({
            type: "success",
            title: t("successfully generated report"),
          })
        );
        dispatch(fetchReportFinished());
        return response.data;
      })
      .catch((error) => {
        dispatch(fetchReportFinished());
        dispatch(
          createToast({
            type: "error",
            title: t("report generating failed"),
          })
        );
      });
  };
};

export const fetchAttachmentsReport = (
  t,
  type = "attachments",
  rootIds = []
) => {
  const url = "/attachments/report";

  return (dispatch) => {
    dispatch(fetchReportRequest());
    return axios
      .post(url, { rootIds }, { responseType: "arraybuffer" })
      .then((response) => {
        dispatch(
          createToast({
            type: "success",
            title: t("successfully generated report"),
          })
        );
        dispatch(fetchReportFinished());
        return response.data;
      })
      .catch((error) => {
        dispatch(fetchReportFinished());
        dispatch(
          createToast({
            type: "error",
            title: t("report generating failed"),
          })
        );
      });
  };
};

export const fetchRecurrenceTasksReport = (
  t,
  type = "recurrence_tasks",
  rootIds = []
) => {
  const url = "/taskPool/report";

  return (dispatch) => {
    dispatch(fetchReportRequest());
    return axios
      .post(url, { rootIds }, { responseType: "arraybuffer" })
      .then((response) => {
        dispatch(
          createToast({
            type: "success",
            title: t("successfully generated report"),
          })
        );
        dispatch(fetchReportFinished());
        return response.data;
      })
      .catch((error) => {
        dispatch(fetchReportFinished());
        dispatch(
          createToast({
            type: "error",
            title: t("report generating failed"),
          })
        );
      });
  };
};

export const fetchTasksPrintReport = (
  t,
  type = "tasks_print",
  rootIds = []
) => {
  const url = "/tasks/report/print";

  return (dispatch) => {
    dispatch(fetchReportRequest());
    return axios
      .post(url, { rootIds }, { responseType: "arraybuffer" })
      .then((response) => {
        dispatch(
          createToast({
            type: "success",
            title: t("successfully generated report"),
          })
        );
        dispatch(fetchReportFinished());
        return response.data;
      })
      .catch((error) => {
        dispatch(fetchReportFinished());
        dispatch(
          createToast({
            type: "error",
            title: t("report generating failed"),
          })
        );
      });
  };
};
