import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import styles from "./Button.module.scss";
import Icon from "../Icon";

const Button = ({
  children,
  className,
  variant = "primary",
  size = "m",
  iconName,
  iconPosition,
  iconClassName,
  onClick,
  margin,
  disabled,
  disabledTooltipText,
  ...rest
}) => {
  const [coords, setCoords] = useState({ x: -1, y: -1 });
  const [isRippling, setIsRippling] = useState(false);

  const handleClick = (e) => {
    const rect = e.target.getBoundingClientRect();
    setCoords({ x: e.clientX - rect.left, y: e.clientY - rect.top });
    onClick && onClick(e);
  };

  useEffect(() => {
    if (coords.x !== -1 && coords.y !== -1) {
      setIsRippling(true);
      setTimeout(() => setIsRippling(false), 500);
    } else setIsRippling(false);
  }, [coords]);

  useEffect(() => {
    if (!isRippling) setCoords({ x: -1, y: -1 });
  }, [isRippling]);

  const classNames = cx(styles.base, styles[variant], styles[size], className);

  return (
    <div className={styles.wrapper}>
      <button
        className={classNames}
        data-testid="button"
        onClick={(e) => handleClick(e)}
        style={margin === "right" ? { marginRight: "12px" } : {}}
        {...rest}
        disabled={disabled}
      >
        {iconPosition === "left" && (
          <Icon name={iconName} className={cx(styles.icon, iconClassName)} />
        )}
        {isRippling ? (
          <span
            className={styles.ripple}
            style={{
              left: coords.x,
              top: coords.y,
            }}
          />
        ) : (
          ""
        )}
        <span className={styles.content}>{children}</span>
        {iconPosition === "right" && (
          <Icon name={iconName} className={cx(styles.icon, iconClassName)} />
        )}
      </button>
      {!!disabled && !!disabledTooltipText && (
        <div className={styles.tooltip}>{disabledTooltipText}</div>
      )}
    </div>
  );
};

Button.propTypes = {
  children: PropTypes.node,
  variant: PropTypes.oneOf(["primary", "ghost", "orange", "disabled"]),
  size: PropTypes.oneOf(["s", "m", "l", "S"]),
  iconPosition: PropTypes.oneOf(["left", "right"]),
  iconClassName: PropTypes.string,
};

export default Button;
