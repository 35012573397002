import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Route, Switch } from "react-router-dom";
import SettingData from "../../components/Settings/EditPasswordProfile";
import SettingsPreferences from "../../components/Settings/SettingsPreferences";
import SettingsProfile from "../../components/Settings/SettingsProfile";
import Settings from "../../layouts/Settings";
import { fetchProfileInfo } from "../../redux";
import { appUrls } from "../../urls";

const SettingsV = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchProfileInfo());
  }, [dispatch]);

  return (
    <Settings>
      <Switch>
        <Route
          exact
          path={appUrls.SIDEBAR_SECTION.SETTINGS_PROFILE}
          component={SettingsProfile}
        />
        <Route
          exact
          path={appUrls.SIDEBAR_SECTION.SETTINGS_DATA}
          component={SettingData}
        />
        <Route
          exact
          path={appUrls.SIDEBAR_SECTION.SETTINGS_PREFERENCES}
          component={SettingsPreferences}
        />
      </Switch>
    </Settings>
  );
};
export default SettingsV;
