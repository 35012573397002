import React from "react";
import styles from "./DashboardLayout.module.scss";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header/Header";

const DashboardLayout = ({ children }) => {
  return (
    <div className={styles.container}>
      <Sidebar />
      <div className={styles.main}>
        <Header />
        {children}
      </div>
    </div>
  );
};

export default DashboardLayout;
