import React, { useContext } from "react";
import { generatePath } from "react-router-dom";
import styles from "./GroupTasksItem.module.scss";
import { I18nContext } from "../../i18n/I18nContext";
import Icon from "../Icon";
import history from "../../history";

const GroupTasksItem = ({ taskPool, onEditClick, onDeleteClick }) => {
  const { t } = useContext(I18nContext);
  const today = new Date();

  const taskActionDelayUnitsOptions = [
    { value: "DAYS", label: t("days") },
    { value: "WEEKS", label: t("weeks") },
    { value: "MONTHS", label: t("months") },
    { value: "QUARTER_YEAR", label: t("quarter year") },
    { value: "HALF_YEAR", label: t("half year") },
    { value: "YEARS", label: t("years") },
  ];

  const handleOpenDetails = (assetId) => {
    history.push(generatePath("/assets/details/:assetId", { assetId }));
  };

  return (
    <div className={styles.task_item} key={taskPool.id}>
      <div className={styles.task_info}>
        <div className={styles.task_info_item}>
          {taskPool.taskPrototype.name}
          {!!taskPool.isRecurring && (
            <span className={styles.recurrence}>
              {"("}
              {t("recurs every")}: {taskPool.scheduler.recurringInterval}{" "}
              {
                taskActionDelayUnitsOptions.find(
                  (x) => x.value === taskPool.scheduler.intervalUnit
                )?.label
              }
              {")"}
            </span>
          )}
          <div className={styles.description_tooltip}>
            {taskPool.taskPrototype.desc}
          </div>
        </div>
        <div className={styles.task_info_item}>
          {!!taskPool.assetParentPath &&
            !!taskPool.assetParentPath.length &&
            taskPool.assetParentPath.length > 1 && (
              <span className={styles.path}>
                {taskPool.assetParentPath.slice(0, -1).join(" / ") + " / "}
                <span
                  className={styles.asset_name}
                  onClick={() => handleOpenDetails(taskPool.assetParentId)}
                >
                  {taskPool.assetParentName}
                </span>
              </span>
            )}
          {!!taskPool.assetParentPath &&
            !!taskPool.assetParentPath.length &&
            taskPool.assetParentPath.length === 1 && (
              <span className={styles.path}>
                <span
                  className={styles.asset_name}
                  onClick={() => handleOpenDetails(taskPool.assetParentId)}
                >
                  {taskPool.assetParentName}
                </span>
              </span>
            )}
        </div>
        {!!taskPool.isRecurring && (
          <div className={styles.task_info_item_small_margin_bottom}>
            <div className={styles.task_info_item_label}>
              {t("current recurrence")}:{" "}
            </div>
            <div className={styles.task_info_item_label}>
              {t("recent tasks")}:
            </div>
            <div className={styles.task_info_item_value}>
              {taskPool?.currentOpenTasks || "-"} /
            </div>
            <div className={styles.task_info_item_label}>
              {t("ready tasks")}:
            </div>
            <div className={styles.task_info_item_value}>
              {taskPool?.currentFinishedTasks || "-"}
            </div>
          </div>
        )}
        {!!taskPool.isRecurring && (
          <div className={styles.task_info_item}>
            <div className={styles.task_info_item_label}>
              {t("next recurrence start date")}:
            </div>
            <div className={styles.task_info_item_value}>
              {taskPool?.nextScheduledTask?.start
                ? new Date(
                    taskPool?.nextScheduledTask?.start
                  ).toLocaleDateString(undefined, {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                  })
                : "-"}
            </div>
          </div>
        )}
        <div className={styles.task_info_item_small_margin_bottom}>
          <div className={styles.task_info_item_label}>
            {new Date(taskPool.scheduler.startDate) > today &&
            !taskPool.finished
              ? t("starts")
              : t("started")}
            :
          </div>
          <div className={styles.task_info_item_value}>
            {new Date(taskPool.scheduler.startDate).toLocaleDateString(
              undefined,
              {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              }
            )}{" "}
            /
          </div>
          <div className={styles.task_info_item_label}>
            {!taskPool.finished ? t("ends") : t("ended")}:
          </div>
          <div className={styles.task_info_item_value}>
            {taskPool.scheduler.finishRecurrenceAt
              ? new Date(
                  taskPool.scheduler.finishRecurrenceAt
                ).toLocaleDateString(undefined, {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                })
              : "-"}{" "}
            /
          </div>
          <div className={styles.task_info_item_label}>
            {taskPool.assetsAvailableIds.length === taskPool.tasksPerRound
              ? `${t("every period all")} ${taskPool.tasksPerRound}  ${t(
                  "assets"
                )}`
              : `${t("every period randomly")} ${taskPool.tasksPerRound} ${t(
                  "of",
                  "lower"
                )} ${taskPool.assetsAvailableIds.length} ${t("assets")}`}
          </div>
        </div>

        {!!taskPool.createdBy && (
          <div className={styles.created}>
            {t("created by")}
            {": "}
            {taskPool.createdBy}
          </div>
        )}
        {!!taskPool.ruleName && (
          <span className={styles.created}>
            {t("task created by rule") + ": " + taskPool.ruleName}
          </span>
        )}
      </div>
      <div className={styles.task_actions}>
        <div className={styles.action_buttons}>
          {!taskPool.finished && (
            <div
              className={styles.action_button}
              onClick={(e) => onEditClick(e, taskPool)}
            >
              <Icon name="edit" className={styles.task_edit} />
            </div>
          )}
          <div
            className={styles.action_button}
            onClick={(e) => onDeleteClick(e, taskPool)}
          >
            <Icon name="trash" className={styles.task_delete} height={"50px"} />
          </div>
        </div>
        {new Date(taskPool.scheduler.startDate) <= today &&
          !taskPool.finished && (
            <div className={styles.group_active}>
              {t("group tasks started", "upper")}
            </div>
          )}
        {new Date(taskPool.scheduler.startDate) > today &&
          !taskPool.finished && (
            <div className={styles.group_pending}>
              {t("group tasks pending", "upper")}
            </div>
          )}
        {!!taskPool.finished && (
          <div className={styles.group_finished}>
            {t("group tasks finished", "upper")}
          </div>
        )}
        {!!taskPool.suspended && (
          <div className={styles.group_suspended}>
            {t("group tasks suspended", "upper")}
          </div>
        )}
      </div>
    </div>
  );
};

export default GroupTasksItem;
