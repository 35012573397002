import React from "react";
import PropTypes from "prop-types";
import styles from "./SectionHeader.module.scss";

const SectionHeader = ({ subtitle, children, separator }) => {
  return (
    <div className={styles.container}>
      <span>{children}</span>
      {separator && <span className={styles.divider}></span>}
      <div className={styles.subtitle}>{subtitle}</div>
    </div>
  );
};

SectionHeader.propTypes = {
  subtitle: PropTypes.string,
  separator: PropTypes.bool,
};

export default SectionHeader;
