import React from "react";
import PropTypes from "prop-types";
import styles from "./FieldLabel.module.scss";

const FieldLabel = ({ children, ...rest }) => {
  return (
    <div className={styles.input_label} {...rest}>
      {children}
    </div>
  );
};

FieldLabel.propTypes = {
  children: PropTypes.node,
};

export default FieldLabel;
