import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import Icon from "../Icon";
import styles from "./AttachedFiles.module.scss";
import {
  fetchAttachmentsFoldersList,
  fetchAttachmentsTypesList,
  fetchAttachmentsList,
} from "../../redux";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../redux/auth";
import { I18nContext } from "../../i18n/I18nContext";
import AttachmentsExplorerModal from "../AttachmentsExplorer/AttachmentsExplorerModal";

const AttachedFiles = ({ currentDetails, attachments = [], onUpdate }) => {
  const { t } = useContext(I18nContext);
  const [isOpen, setIsOpen] = useState(false);
  const [hovered, setHovered] = useState(null);
  const dispatch = useDispatch();
  const [attachmentsExplorerModalOpen, setAttachmentsExplorerModalOpen] =
    useState(false);
  const folders = useSelector((state) => state.attachments.folders).sort(
    (a, b) => -1 * a.name.localeCompare(b.name)
  );
  const types = useSelector((state) => state.attachments.types).sort(
    (a, b) => -1 * a.name.localeCompare(b.name)
  );

  useEffect(() => {
    dispatch(fetchAttachmentsFoldersList());
    dispatch(fetchAttachmentsTypesList());
  }, [dispatch]);

  useEffect(() => {
    if (!!currentDetails?.id) {
      dispatch(fetchAttachmentsList(currentDetails.id));
    }
  }, [currentDetails]);

  const truncate = (input) =>
    input.length > 15 ? `${input.substring(0, 15)}...` : input;

  const openAttachment = (attachment) => {
    axios
      .get(`/files/getBytes?key=${attachment.key}&name=${attachment.name}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const url = URL.createObjectURL(
          new Blob([response.data], {
            type:
              attachment.attachmentFileType === "DOCUMENT" &&
              attachment.key.split(".").pop() !== "gif"
                ? "application/pdf"
                : `image/${attachment.key.split(".").pop()}`,
          })
        );
        window.open(url);
      })
      .catch((error) => console.log(error));
  };

  const downloadAttachment = (attachment) => {
    axios
      .get(`/files/getBytes?key=${attachment.key}&name=${attachment.name}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.download = attachment.name + "." + attachment.key.split(".").pop();
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => console.log(error));
  };

  return (
    <div className={styles.column}>
      <div
        className={
          isOpen ? styles.btn_containerRight_open : styles.btn_containerRight
        }
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className={styles.title}>
          <div className={styles.icon}>
            {!!attachments.length && (
              <>
                {isOpen ? (
                  <Icon name="chevron-down" />
                ) : (
                  <Icon name="chevron-right" />
                )}
              </>
            )}
          </div>
          {t("attached files")} (
          {attachments.filter((file) => !file.isSignificant)
            ? attachments.filter((file) => file.isSignificant).length +
              "/" +
              attachments.length
            : attachments.length}
          )
        </div>
        <div
          className={styles.attachments_explorer_button}
          onClick={(e) => {
            e.stopPropagation();
            setAttachmentsExplorerModalOpen(true);
          }}
        >
          <Icon
            name="meatball-menu"
            className={styles.attachments_explorer_icon}
          />
        </div>
      </div>
      {!!isOpen &&
        attachments.map((file, index) => (
          <div
            key={index}
            className={styles.text_area}
            onMouseEnter={() => setHovered(index)}
            onMouseLeave={() => setHovered(null)}
          >
            <div className={styles.left}>
              <Icon name="attached-files" className={styles.icon_attached} />
              <div className={styles.fileNameContainer}>
                <span className={styles.file_name}> {truncate(file.name)}</span>{" "}
                <span className={styles.date}>
                  (
                  {new Date(file.date).toLocaleDateString(undefined, {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                  })}
                  )
                </span>
              </div>
            </div>
            <span onClick={() => downloadAttachment(file)}>
              {hovered === index && (
                <Icon name="download-grey" className={styles.icon_trash} />
              )}
            </span>
            <span onClick={() => openAttachment(file)}>
              {hovered === index &&
                (file?.attachmentFileType === "IMAGE" ||
                  (file?.attachmentFileType === "DOCUMENT" &&
                    file.key.split(".").pop() === "pdf") ||
                  (file?.attachmentFileType === "DOCUMENT" &&
                    file.key.split(".").pop() === "gif")) && (
                  <Icon name="eye-grey" className={styles.icon_eye} />
                )}
            </span>
            <div className={styles.description_tooltip}>
              {file?.attachmentFolder?.name + " / " + file?.type?.name}
            </div>
          </div>
        ))}
      {!!attachmentsExplorerModalOpen && (
        <AttachmentsExplorerModal
          isOpen={attachmentsExplorerModalOpen}
          onClose={() => setAttachmentsExplorerModalOpen(false)}
          assetId={currentDetails.id}
          assetName={currentDetails.name}
        />
      )}
    </div>
  );
};

AttachedFiles.propTypes = {
  files: PropTypes.array,
  onUpdate: PropTypes.func,
};

export default AttachedFiles;
