import { useContext, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { deleteRightItem, fetchRightsList } from "../../redux";
import Icon from "../Icon";
import styles from "./AccessRightTable.module.scss";
import { I18nContext } from "../../i18n/I18nContext";

const AccessRightTable = ({ right, canDelete }) => {
  const { t } = useContext(I18nContext);
  const dispatch = useDispatch();
  const assetPrototypes = useSelector((state) => state.prototypes.items);
  const taskTemplates = useSelector((state) => state.taskPrototypes.items);

  const handleTaskTemplateDeleteItem = async (item) => {
    await dispatch(
      deleteRightItem({
        rightId: right.id,
        taskTemplateId: item,
      })
    );
    dispatch(fetchRightsList());
  };

  const handleAssetTemplateDeleteItem = async (item) => {
    await dispatch(
      deleteRightItem({
        rightId: right.id,
        assetPrototypeId: item,
      })
    );
    dispatch(fetchRightsList());
  };

  const [hovered, setHovered] = useState(null);
  return (
    <div className={styles.main_table}>
      <div className={styles.table1}>
        <div className={styles.table_title}> {t("task templates")}</div>
        {right.taskTemplates?.map((item, index) => {
          return (
            <div
              className={styles.title}
              key={index}
              onMouseEnter={() => setHovered(index)}
              onMouseLeave={() => setHovered(null)}
            >
              <div>{taskTemplates.find((x) => x.id === item)?.name}</div>
              {hovered === index && !!canDelete && (
                <div>
                  <Icon
                    name="trash"
                    className={styles.icon_trash}
                    onClick={() => handleTaskTemplateDeleteItem(item)}
                  />
                </div>
              )}
            </div>
          );
        })}
      </div>

      <div className={styles.table2}>
        <div className={styles.table_title}>{t("asset prototypes")}</div>
        {right.assetTemplates?.map((item, index) => {
          return (
            <div
              className={styles.title}
              key={index}
              onMouseEnter={() => setHovered(index)}
              onMouseLeave={() => setHovered(null)}
            >
              <div>{assetPrototypes.find((x) => x.id === item)?.name}</div>
              {hovered === index && !!canDelete && (
                <div>
                  <Icon
                    name="trash"
                    className={styles.icon_trash}
                    onClick={() => handleAssetTemplateDeleteItem(item)}
                  />
                </div>
              )}
            </div>
          );
        })}
      </div>
      {/*<div className={styles.table3}>*/}
      {/*  <div className={styles.table_title}>{t("grouping assets")}</div>*/}
      {/*  {right.assets?.map((item, index) => {*/}
      {/*    return (*/}
      {/*      <div className={styles.title} key={index}>*/}
      {/*        <div>{assetPrototypes.find((x) => x.id === item.id)?.name}</div>*/}
      {/*        <div>{`${item.rootName} > ${item.name}`}</div>*/}
      {/*      </div>*/}
      {/*    );*/}
      {/*  })}*/}
      {/*</div>*/}
    </div>
  );
};

export default AccessRightTable;
