import React, { useContext, useState } from "react";
import { I18nContext } from "../../i18n/I18nContext";
import styles from "./RuleCondition.module.scss";
import Icon from "../Icon";

const TextCondition = (props) => {
  const { condition, readOnly, selectCondition } = props;
  const { t } = useContext(I18nContext);
  const [conditionValue, setConditionValue] = useState(
    condition && condition.triggerWords
      ? condition.triggerWords
      : condition && condition.value
      ? condition.value
      : null
  );

  return (
    <div className={styles.condition}>
      <div className={styles.property_value}>
        {t("property value contains")}
      </div>
      <input
        className={styles.text_input}
        type="text"
        onInput={(e) =>
          setConditionValue(e.target.value.replace(", ", ",").split(","))
        }
        disabled={readOnly}
        defaultValue={conditionValue}
      />
      {!condition && !!conditionValue && (
        <button
          className={styles.add_btn}
          onClick={() =>
            selectCondition({
              value: conditionValue,
              type: "text_precondition",
            })
          }
        >
          <Icon className={styles.add_icon} name="add-form-modal" />
        </button>
      )}
      {!!condition &&
        ((condition.triggerWords &&
          conditionValue.join(",") !== condition.triggerWords.join(",")) ||
          (condition.value &&
            conditionValue.join(",") !== condition.value.join(","))) && (
          <button
            className={styles.add_btn}
            onClick={() => {
              selectCondition({
                value: conditionValue,
                type: "text_precondition",
              });
            }}
          >
            <Icon className={styles.add_icon} name="add-form-modal" />
          </button>
        )}
    </div>
  );
};

export default TextCondition;
