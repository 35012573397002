import React from "react";
import PropTypes from "prop-types";
import styles from "./Entry.module.scss";

const Entry = ({ children }) => {
  return <div className={styles.container}>{children}</div>;
};

Entry.propTypes = {
  children: PropTypes.node,
};

export default Entry;
