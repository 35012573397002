class SceneLayer {
    constructor(_index, _element) {
        this._index = _index;
        this._element = _element;
    }
    set index(index) { if (index >= 0)
        this._index = index; }
    get index() { return this._index; }
    get element() { return this._element; }
}
export default SceneLayer;
