import React, { useContext } from "react";
import Button from "../Button";
import Modal from "../Modal";
import styles from "./NotificationGroupDeleteModal.module.scss";
import { I18nContext } from "../../i18n/I18nContext";
import { useDispatch } from "react-redux";
import {
  deleteNotificationGroup,
  fetchNotificationGroups,
} from "./notificationGroupActions";

const DeleteFormModal = ({ isOpen, onClose, notificationGroup }) => {
  const { t } = useContext(I18nContext);
  const dispatch = useDispatch();

  const handleClear = () => {
    onClose();
  };

  const handleDeleteNotificationGroup = async () => {
    console.log(notificationGroup.id);
    await dispatch(deleteNotificationGroup(notificationGroup.id));
    dispatch(fetchNotificationGroups());
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={`${t("delete notification group")}?`}
      form="delete-form"
      icon
      footer={
        <div className={styles.button_section}>
          <Button
            className={styles.btn_separator}
            onClick={handleClear}
            type="reset"
            form={"delete-form"}
            variant="ghost"
            size="s"
          >
            {t("cancel")}
          </Button>
          <Button
            type="submit"
            variant="orange"
            size="s"
            form={"delete-form"}
            onClick={handleDeleteNotificationGroup}
          >
            {t("delete")}
          </Button>
        </div>
      }
    >
      <div className={styles.delete_txt}>
        {t("are you sure you want to permanently delete notification group")}
        <div className={styles.spacing_txt}>{notificationGroup?.name}?</div>
      </div>
    </Modal>
  );
};

export default DeleteFormModal;
