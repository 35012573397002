import React, { useContext } from "react";
import Modal from "../Modal";
import Button from "../Button";
import styles from "./LicenseCustomerModal.module.scss";
import { I18nContext } from "../../i18n/I18nContext";
import Icon from "../Icon";

const LicenseCustomerModal = ({ onClose, isOpen }) => {
  const { t } = useContext(I18nContext);

  const handleClose = () => {
    onClose();
  };

  return (
    <Modal
      className={styles.modal}
      isOpen={isOpen}
      onClose={onClose}
      title={t("contact us")}
      iconContact
      modalS
      divider={false}
      footer={
        <div className={styles.btn_section}>
          <Button onClick={handleClose} type="submit" variant="orange" size="s">
            OK
          </Button>
        </div>
      }
    >
      <div className={styles.txt}>
        {t("if you need another license, please contact support centre.")}
        <div className={styles.row}>
          <div className={styles.row}>
            <Icon name="phone-orange" className={styles.icon} />
            <div className={styles.column}>
              <div className={styles.txt_gray}>Giva us a call</div>
              <div className={styles.txt1}>(457) 234 567</div>
            </div>
          </div>
          <div className={styles.row}>
            <Icon name="envelope-license" className={styles.icon} />
            <div className={styles.column}>
              <div className={styles.txt_gray}>Send us an e-mail</div>
              <div className={styles.txt1}>license@kalsbeek.com</div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default LicenseCustomerModal;
