import React, { useContext } from "react";
import PropTypes from "prop-types";
import ReactModal from "react-modal";
import cx from "classnames";
import Content from "./Content";
import Button from "../Button";
import styles from "./Modal.module.scss";
import Icon from "../Icon";
import { I18nContext } from "../../i18n/I18nContext";

if (document.getElementById("#root")) ReactModal.setAppElement("#root");

const Modal = ({
  isOpen,
  onClose,
  children,
  label,
  title,
  subtitle,
  icon,
  iconContact,
  className,
  overlayClassName,
  classNameTitle,
  divider,
  modalS,
  noPadding,
  zeroPadding,
  form,
  footer,
  previewMode,
  editMode,
  importMode,
  importResult,
  pending,
  fullWidth,
  formModal,
}) => {
  const { t } = useContext(I18nContext);
  const classNames = cx(fullWidth ? styles.fullWidth : styles.modal, className);

  const handleClick = (event) => {
    event.stopPropagation();
  };

  return (
    <ReactModal
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={false}
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel={label}
      portalClassName={`ReactModalPortal_${form || title}`}
      onClick={handleClick}
      className={classNames}
      ariaHideApp={false}
      overlayClassName={cx(styles.overlay, overlayClassName)}
    >
      <div className={styles.header} onClick={handleClick}>
        {icon && (
          <div className={styles.delete_title}>
            <div className={styles.delete_category}>
              <Icon name="x-delete" className={styles.icon_delete} />
            </div>
            <span className={styles.title1}>{title} </span>
            {subtitle && (
              <div>
                <span className={styles.title1}>{subtitle} </span>
              </div>
            )}
          </div>
        )}
        {iconContact && (
          <div className={styles.contact_icon}>
            <Icon name="questionMark-orange" />
            <span className={styles.title1}>{title} </span>
          </div>
        )}
        {!icon && (
          <div>
            <span className={cx(styles.title, classNameTitle)}>{title} </span>
            {subtitle && (
              <div>
                <span className={styles.subtitle}>{subtitle} </span>
              </div>
            )}
          </div>
        )}
      </div>
      {divider && <hr className={styles.divider}></hr>}
      <Content
        onClick={handleClick}
        modalS={modalS}
        noPadding={noPadding}
        zeroPadding={zeroPadding}
      >
        {children}
      </Content>
      <div className={styles.footer} onClick={handleClick}>
        {divider && <hr className={styles.divider}></hr>}

        {!footer ? (
          <div className={styles.button_section}>
            <Button
              className={styles.btn_spacer}
              onClick={onClose}
              type="reset"
              form={form}
              variant="ghost"
              size="s"
              data-testid="modal-cancel"
            >
              {previewMode ? t("close") : t("cancel")}
            </Button>
            {!previewMode && !importMode && (
              <Button
                type="submit"
                variant={pending ? "disabled" : "orange"}
                size="s"
                form={form}
                data-testid="modal-create"
                disabled={pending}
              >
                {!!formModal && !editMode
                  ? t("create form")
                  : !!formModal && editMode
                  ? t("save form")
                  : editMode
                  ? t("save changes")
                  : t("create")}
              </Button>
            )}
            {importMode && !importResult && (
              <Button
                type="submit"
                variant={pending ? "disabled" : "orange"}
                size="s"
                form={form}
                data-testid="modal-create"
                disabled={pending}
              >
                {t("import")}
              </Button>
            )}
          </div>
        ) : (
          footer
        )}
      </div>
    </ReactModal>
  );
};

Modal.propTypes = {
  className: PropTypes.string,
  overlayClassName: PropTypes.string,
  isOpen: PropTypes.bool,
  icon: PropTypes.bool,
  iconContact: PropTypes.bool,
  label: PropTypes.string,
  children: PropTypes.node,
  onClose: PropTypes.func,
  divider: PropTypes.bool,
  lengthInfo: PropTypes.bool,
  modalS: PropTypes.bool,
  footer: PropTypes.node,
};

export default Modal;
