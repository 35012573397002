import ResizeStrategyProvider from './ResizeStrategyProvider';
class CanvasResizer {
    constructor(renderer) {
        this.renderer = renderer;
    }
    resize() {
        const containerSize = this.renderer.containerSize;
        const canvasSize = this.renderer.size;
        const ratio = canvasSize.x / canvasSize.y;
        const isContainerPanoramic = this.isPanoramic(containerSize);
        const isCanvasPanoramic = this.isPanoramic(canvasSize);
        return ResizeStrategyProvider.get(isContainerPanoramic, isCanvasPanoramic)(containerSize, canvasSize, ratio);
    }
    isPanoramic(size) {
        return size.x >= size.y;
    }
}
export default CanvasResizer;
