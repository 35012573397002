class CanvasInspector {
  findClickedElement(clickPosition, layers) {
    let clickedLayer;
    for (let layer of layers) {
      if (this.isElementClicked(clickPosition, layer.element)) {
        clickedLayer = layer;
      }
    }
    return {
      element:
        (clickedLayer === null || clickedLayer === void 0
          ? void 0
          : clickedLayer.element) || null,
      position: clickPosition,
    };
  }
  isElementClicked(clickPosition, element) {
    const { size, position } = element;
    let clickPosInElementCoord = clickPosition
      .clone()
      .rotateAboutOrigin(position, 0);
    return this.isClicked(clickPosInElementCoord, position, size);
  }
  isClicked(clickPosition, elementPosition, elementSize) {
    return (
      clickPosition.x >= elementPosition.x - elementSize.width / 2 &&
      clickPosition.x <= elementPosition.x + elementSize.width / 2 &&
      clickPosition.y >= elementPosition.y - elementSize.height / 2 &&
      clickPosition.y <= elementPosition.y + elementSize.height / 2
    );
  }
}
export default CanvasInspector;
