import React from "react";
import Dashboard from "../../layouts/Dashboard";
import Tasks from "../../components/Tasks";

const TaskV = () => {
  return (
    <Dashboard>
      <Tasks />
    </Dashboard>
  );
};
export default TaskV;
