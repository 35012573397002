import React from "react";
import styles from "./Content.module.scss";

const Content = ({ children, onClick, modalS, noPadding, zeroPadding }) => {
  return (
    <>
      {!modalS && !noPadding && !zeroPadding && (
        <div onClick={onClick} className={styles.content}>
          {children}
        </div>
      )}
      {modalS && (
        <div onClick={onClick} className={styles.contentS}>
          {children}
        </div>
      )}
      {!zeroPadding && noPadding && (
        <div onClick={onClick} className={styles.contentNoPadding}>
          {children}
        </div>
      )}
      {!!zeroPadding && (
        <div onClick={onClick} className={styles.contentZeroPadding}>
          {children}
        </div>
      )}
    </>
  );
};

export default Content;
