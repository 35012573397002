import React, { useContext } from "react";
import { RoleOptions } from "../../constants/Access";
import PropTypes from "prop-types";
import {
  FieldError,
  FieldInput,
  FieldSelect,
  Form,
  FormControl,
} from "../Input";
import styles from "./AccessFilteredSearch.module.scss";
import { I18nContext } from "../../i18n/I18nContext";

const AccessFilteredSearch = ({
  onRoleChange,
  onSearchChange,
  showFilteredRole,
  placeholder,
  company,
}) => {
  const defaultState = {
    userName: "",
  };
  const { t } = useContext(I18nContext);

  return (
    <Form
      initialValues={defaultState}
      enableReinitialize
      id="access-filters-form"
    >
      <div className={styles.row}>
        <FormControl className={styles.input_search}>
          <FieldInput
            name="userName"
            icon
            onChange={onSearchChange}
            type="text"
            placeholder={
              placeholder
                ? t("search task template, asset prototype")
                : company
                ? t("search for company name")
                : t("search for name or e-mail address")
            }
            size="l"
          />
        </FormControl>
        {showFilteredRole && (
          <FormControl className={styles.dropdown_role}>
            <FieldSelect
              name="role"
              size="s"
              isClearable
              onChange={onRoleChange}
              options={RoleOptions(t).filter(
                (role) => role.value !== "MAINTAINER"
              )}
              placeholder={t("role")}
            />
            <FieldError name="role" />
          </FormControl>
        )}
      </div>
    </Form>
  );
};

AccessFilteredSearch.propTypes = {
  onRoleChange: PropTypes.func,
  onSearchChange: PropTypes.func,
  showFilteredRole: PropTypes.bool,
};

export default AccessFilteredSearch;
