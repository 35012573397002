import Actions from "../actions";
import axios from "../auth";
import { createToast } from "../toast/toastActions";

export const addRightCategory = (category) => {
  return () => {
    return axios.post("/rights/addCategory", category);
  };
};

const fetchRightsSuccess = (payload) => {
  return {
    type: Actions.FETCH_RIGHTS_SUCCESS,
    payload,
  };
};

const fetchRightsRequest = () => {
  return {
    type: Actions.FETCH_RIGHTS_REQUEST,
  };
};

const fetchRightsError = () => {
  return {
    type: Actions.FETCH_RIGHTS_ERROR,
  };
};

//right-contoller

export const fetchRightsList = (state) => {
  return (dispatch) => {
    dispatch(fetchRightsRequest());
    return axios
      .get(`/rights/`)
      .then((response) => {
        dispatch(fetchRightsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchRightsError(error));
      });
  };
};

export const addRight = (t, right) => {
  return (dispatch) => {
    return axios.post(`/rights/`, right).then((response) => {
      dispatch(
        createToast({
          type: "success",
          title: t("successfully added new access rights group"),
        })
      );
    });
  };
};

export const editRight = (t, right) => {
  return (dispatch) => {
    return axios.put(`/rights/`, right).then((response) => {
      dispatch(
        createToast({
          type: "success",
          title: t("successfully edited access rights group"),
        })
      );
    });
  };
};

export const deleteRight = (t, id) => {
  return (dispatch) => {
    return axios.delete(`/rights/delete/${id}`).then((response) => {
      dispatch(
        createToast({
          type: "success",
          title: t("successfully deleted access rights group"),
        })
      );
    });
  };
};

export const deleteRightItem = (item) => {
  return () => {
    return axios.delete(`/rights/deleteItem`, { data: item });
  };
};

const fetchRightsTaskTemplateListSuccess = (payload) => {
  return {
    type: Actions.FETCH_RIGHTS_TASK_TEMPLATE_LIST_SUCCESS,
    payload,
  };
};

const fetchRightsTaskTemplateListRequest = () => {
  return {
    type: Actions.FETCH_RIGHTS_TASK_TEMPLATE_LIST_REQUEST,
  };
};

const fetchRightsTaskTemplateListError = () => {
  return {
    type: Actions.FETCH_RIGHTS_TASK_TEMPLATE_LIST_ERROR,
  };
};

export const fetchRightsTaskTemplateList = (state) => {
  return (dispatch) => {
    dispatch(fetchRightsTaskTemplateListRequest());
    return axios
      .get(`/rights/taskTemplates`)
      .then((response) => {
        dispatch(fetchRightsTaskTemplateListSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchRightsTaskTemplateListError(error));
      });
  };
};

const fetchRightsAssetTemplateListSuccess = (payload) => {
  return {
    type: Actions.FETCH_RIGHTS_ASSET_TEMPLATE_LIST_SUCCESS,
    payload,
  };
};

const fetchRightsAssetTemplateListRequest = () => {
  return {
    type: Actions.FETCH_RIGHTS_ASSET_TEMPLATE_LIST_REQUEST,
  };
};

const fetchRightsAssetTemplateListError = () => {
  return {
    type: Actions.FETCH_RIGHTS_ASSET_TEMPLATE_LIST_ERROR,
  };
};

export const fetchRightsAssetTemplateList = (state) => {
  return (dispatch) => {
    dispatch(fetchRightsAssetTemplateListRequest());
    return axios
      .get(`/rights/assetTemplates`)
      .then((response) => {
        dispatch(fetchRightsAssetTemplateListSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchRightsAssetTemplateListError(error));
      });
  };
};
