import styles from "./BuildingRow.module.scss";
import cx from "classnames";

const BuildingRow = ({ onSelect, selected, name, selectedItem }) => {
  const classNames = cx(styles.container, {
    [styles.selected]: selected,
    [styles.selectedItem]: selectedItem,
  });
  return (
    <div className={classNames} onClick={onSelect}>
      <span>{name}</span>
    </div>
  );
};

export default BuildingRow;
