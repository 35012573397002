import React from "react";
import DashboardContent from "../../components/Dashboard/DashboardContent";
import Dashboard from "../../layouts/Dashboard";

const DashboardView = () => {
  return (
    <Dashboard>
      <DashboardContent />
    </Dashboard>
  );
};

export default DashboardView;
