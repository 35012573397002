import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";

import styles from "./DashboardContent.module.scss";
import HeaderItem from "./subComponents/HeaderItem";
import { I18nContext } from "../../i18n/I18nContext";
import {
  fetchAssets,
  fetchProfileInfo,
  fetchChosenAssets,
  fetchTasksPrototypesList,
} from "../../redux";
import SensorsPartContent from "./SensorsPartContent";
import OverrunsPartContent from "./OverrunsPartContent";
import PieChartsPartContent from "./PieChartsPartContent";
import DashboardFooter from "./DashBoardFooter";
import { FilterDropdown } from "../Dropdown";
import ReactDatePicker from "react-datepicker";

const DashboardContent = () => {
  const { t } = useContext(I18nContext);
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profile.profile);
  const taskPrototypes = useSelector((state) => state.taskPrototypes.items);

  const allChosen = useSelector((state) => state.buildings.choosenBuildings);
  const [isWideView, setIsWideView] = useState(false);
  const [selectedTaskPrototypes, setSelectedTaskPrototypes] = useState([]);

  const getTodayDate = () => {
    const now = new Date();
    return now;
  };

  const getLastWeeksDate = () => {
    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7);
  };

  const [filterStartDate, setFilterStartDate] = useState(getLastWeeksDate());
  const [filterEndDate, setFilterEndDate] = useState(getTodayDate());

  useEffect(() => {
    if (allChosen.length > 0) {
      dispatch(fetchChosenAssets(allChosen.map((el) => el.id)));
    } else {
      dispatch(fetchAssets());
    }
    dispatch(fetchProfileInfo());
    dispatch(fetchTasksPrototypesList());
  }, []);

  useEffect(() => {
    if (!allChosen.length || allChosen.length > 1) {
      setIsWideView(true);
    } else {
      setIsWideView(false);
    }
  }, [allChosen]);

  const dateOptions = (t) => [
    { value: "DAYS", label: t("days") },
    {
      value: "WEEKS",
      label: t("weeks"),
    },
    {
      value: "MONTHS",
      label: t("months"),
    },
    { value: "QUARTER_YEAR", label: t("quarter year") },
    { value: "HALF_YEAR", label: t("half year") },
    {
      value: "YEARS",
      label: t("years"),
    },
  ];

  return (
    <>
      <div className={styles.header}>
        <HeaderItem name={profile.username} />
        <div className={styles.filtersWrapper}>
          <div className={styles.filterLabel}>{t("period from")}:</div>
          <ReactDatePicker
            wrapperClassName={styles.wrapper}
            selected={filterStartDate}
            onChange={(value) => setFilterStartDate(value)}
            placholder={t("start date")}
            dateFormat="dd-MM-yyyy"
            className={styles.date_picker_input}
          />
          <div className={styles.filterLabel}>{t("to", "lower")}:</div>
          <ReactDatePicker
            wrapperClassName={styles.wrapper}
            selected={filterEndDate}
            onChange={(value) => setFilterEndDate(value)}
            placholder={t("end date")}
            dateFormat="dd-MM-yyyy"
            className={styles.date_picker_input}
          />
          <FilterDropdown
            options={taskPrototypes
              .filter((proto) => !proto.deprecated)
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((proto) => ({
                value: proto.id,
                label: proto.name,
                global: proto.global,
              }))}
            onSelect={(selected) => setSelectedTaskPrototypes(selected)}
            value={selectedTaskPrototypes}
            isMultiple={true}
            isClearable={true}
            placeholder={t("task template")}
          />
        </div>
      </div>
      <PieChartsPartContent
        allChosen={allChosen}
        taskPrototypes={selectedTaskPrototypes.map((proto) => proto.value)}
        filterStartDate={filterStartDate}
        filterEndDate={filterEndDate}
      />
      {/*<OverrunsPartContent wide={isWideView} />*/}
      {!isWideView && (
        <SensorsPartContent
          chosenBuildingId={allChosen.length ? allChosen[0].id : null}
        />
      )}
      {/*<DashboardFooter />*/}
    </>
  );
};

export default DashboardContent;
