import styles from "./ActivityIndicator.module.scss";

const ActivityIndicator = ({ small, withWrapper }) => {
  if (withWrapper) {
    return (
      <div className={styles.spinner_wrapper}>
        <div className={small ? styles.spinner_small : styles.spinner}></div>
      </div>
    );
  }
  return <div className={small ? styles.spinner_small : styles.spinner}></div>;
};

export default ActivityIndicator;
