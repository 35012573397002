import React, { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import axios from "../../redux/auth";

import LineChartView from "./subComponents/LineChartView";
import { FilterDropdown } from "../Dropdown";
import { I18nContext } from "../../i18n/I18nContext";

import styles from "./OverrunsPartContent.module.scss";

const OverrunsPartContent = ({ wide }) => {
  const { t } = useContext(I18nContext);
  const [standardOverrunsData, setStandardOverrunsData] = useState(null);
  const [standardOverrunsTime, setStandardOverrunsTime] = useState(7);

  const allChosen = useSelector((state) => state.buildings.choosenBuildings);

  const timeOptions = [
    { label: t("last 7 days"), value: 7 },
    { label: t("last 14 days"), value: 14 },
    { label: t("last 30 days"), value: 30 },
    { label: t("last 90 days"), value: 90 },
  ];

  const fetchStandardOverrunsData = async () => {
    const url = `/dashboard/standardOverruns?days=${standardOverrunsTime}`;
    try {
      const buildingIds = allChosen.map((el) => el.id);
      const response = await axios.post(url, { rootIds: buildingIds });
      const overrunsData = response.data;
      let newData = [...overrunsData];
      overrunsData.forEach((item, index) => {
        const newOverruns = item.overruns.map(el => (
          {invokedAt: new Date(el.invokedAt).toLocaleDateString(undefined, {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            }), count: el.count}
        ));
        newData[index].overruns = newOverruns;
      });

      setStandardOverrunsData(newData);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchStandardOverrunsData();
  }, [allChosen, standardOverrunsTime]);

  return (
    <div className={styles.contentWrapper}>
      <div className={styles.contained_cell}>
        <div className={styles.containedHeader}>
          <span>{t("standard overruns")}</span>
          <FilterDropdown
            options={timeOptions}
            withDefault
            isClearable={false}
            onSelect={(value) => setStandardOverrunsTime(value.value)}
          />
        </div>
        <div className={styles.lineChartContainer}>
          {standardOverrunsData && (
            <LineChartView data={standardOverrunsData} />
          )}
        </div>
      </div>
    </div>
  );
};

export default OverrunsPartContent;
