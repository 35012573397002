import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { FieldArray } from "formik";
import {
  FieldLabel,
  Form,
  FormControl,
  FieldError,
  FieldInput,
  FieldSelect,
  Checkbox,
} from "../Input";
import styles from "./RuleEditForm.module.scss";
import Button from "../Button";
import {
  createCompositePreconditionsArray,
  PreconditionsForm,
  updatePreconditions,
  getInitialPreconditionsValues,
  updateTaskActions,
  getInitialTaskActionsValues,
} from "../PreconditionsForm";
import {
  PreconditionType,
  PreconditionTypeApi,
  PreconditionTypeMapping,
} from "../../constants/PreconditionType";
import { addRule, fetchRules, deleteRule, updateRule } from "../../redux";
import Icon from "../Icon";
import { I18nContext } from "../../i18n/I18nContext";
import CompositePreconditionsForm from "../PreconditionsForm/CompositePreconditionsForm";
import _ from "lodash";
import { getProperCompositeValuesArray } from "../PreconditionsForm/utils";

const defaultValuesRule = {
  name: "",
  type: "",
  criticalityOptions: "",
  critical: false,
  sendNotifications: false,
  tasksToStart: [],
  tasksToStop: [],
  compositeRules: [],
};

const RuleEditForm = ({
  onClose,
  rule,
  onCreate,
  readOnly = false,
  formId = "add-rule-form",
  noCancelButton,
}) => {
  const { t } = useContext(I18nContext);
  const dispatch = useDispatch();
  const [initialRule, setInitialRule] = useState(defaultValuesRule);
  const rules = useSelector((state) => state.rules.items);
  const taskPrototypes = useSelector((state) => state.taskPrototypes.items);
  const [preconditions, setPreconditions] = useState([]);

  useEffect(() => {
    if (rule && rule.requirements) {
      let type = rule.requirements.preconditions[0]?.type;

      if (rule.requirements.preconditions[0]?.isMultiselect) {
        type = PreconditionType.selection_multiple;
      }
      if (rule.requirements.preconditions[0]?.isDropdown) {
        type = PreconditionType.selection_dropdown;
      }

      setInitialRule({
        name: rule.name,
        criticalityOptions: rule.alertsToEmit ? rule.alertsToEmit[0] : "",
        type,
        anyMatch: rule.requirements.anyMatch,
        ...getInitialPreconditionsValues(rule.requirements.preconditions),
        critical: rule.critical,
        sendNotifications: rule.sendNotifications,
        ...getInitialTaskActionsValues(rule.taskActions),
        compositeRules:
          rule.requirements.preconditions[0]?.type ===
          PreconditionType.composite
            ? getProperCompositeValuesArray(
                rule.requirements.preconditions[0].preconditions
              )
            : [],
      });

      setPreconditions(rule.requirements.preconditions);
    }
  }, [rule]);

  const taskPrototypesOptions = taskPrototypes.length
    ? taskPrototypes.map((taskPrototype) => ({
        value: taskPrototype.id,
        label: taskPrototype.name,
        global: taskPrototype.global,
      }))
    : [];

  const taskActionDelayUnitsOptions = [
    { value: "DAYS", label: t("days") },
    { value: "WEEKS", label: t("weeks") },
    { value: "MONTHS", label: t("months") },
    { value: "QUARTER_YEAR", label: t("quarter year") },
    { value: "HALF_YEAR", label: t("half year") },
    { value: "YEARS", label: t("years") },
  ];

  const criticalityOptions = [
    { value: "CRITICAL", label: t("critical") },
    { value: "HIGH", label: t("high") },
    { value: "MODERATE", label: t("moderate") },
    { value: "LOW", label: t("low") },
    { value: "NONE", label: t("none") },
  ];

  Yup.addMethod(Yup.array, "uniqueProperty", function (propertyPath, message) {
    return this.test("unique", "", function (list) {
      const errors = [];

      list.forEach((item, index) => {
        const propertyValue = _.get(item, propertyPath);

        if (
          propertyValue &&
          _.filter(list, [propertyPath, propertyValue]).length > 1
        ) {
          errors.push(
            this.createError({
              path: `${this.path}[${index}].${propertyPath}`,
              message,
            })
          );
        }
      });

      if (!_.isEmpty(errors)) {
        throw new Yup.ValidationError(errors);
      }

      return true;
    });
  });

  const validationSchema = Yup.object({
    name: Yup.mixed()
      .notOneOf(rules, t("name must be unique"))
      .required(t("please enter the name of the rule")),
    type: Yup.string().required(t("please choose a field type")),
    critical: Yup.bool(),
    sendNotifications: Yup.bool(),
    taskActionStartId: Yup.string(),
    taskActionStartDelay: Yup.number().when("taskActionStartId", {
      is: (taskActionStartId) => !!taskActionStartId,
      then: Yup.number().required(t("please input delay")),
    }),
    taskActionStartDelayUnit: Yup.string().when("taskActionStartId", {
      is: (taskActionStartId) => !!taskActionStartId,
      then: Yup.string().required(t("please input delay unit")),
    }),
    taskActionStopId: Yup.string(),
    taskActionStopDelay: Yup.number().when("taskActionStopId", {
      is: (taskActionStopId) => !!taskActionStopId,
      then: Yup.number().required(t("please input delay")),
    }),
    taskActionStopDelayUnit: Yup.string().when("taskActionStopId", {
      is: (taskActionStopId) => !!taskActionStopId,
      then: Yup.string().required(t("please input delay unit")),
    }),
    criticalityOptions: Yup.string().required(t("please choose criticality")),
    compositeRules: Yup.array()
      .of(
        Yup.object().shape({
          uniqueKey: Yup.string().required(t("unique key is required")),
          type: Yup.string().required(t("please select field type")),
        })
      )
      .uniqueProperty("uniqueKey", t("field key must be unique")),
  });

  const handleAddPrecondition = (type) => {
    if (!type) {
      return;
    }
    const apiType = PreconditionTypeMapping[type];

    if (apiType === PreconditionTypeApi.selection) {
      setPreconditions([
        ...preconditions,
        {
          type: apiType,
          isMultiselect: type === PreconditionType.selection_multiple,
          isDropdown: type === PreconditionType.selection_dropdown,
        },
      ]);
    } else {
      setPreconditions([
        ...preconditions,
        {
          type: apiType,
        },
      ]);
    }
  };

  const handleDelete = async () => {
    if (rule) {
      await dispatch(deleteRule(t, rule.id));
      await dispatch(fetchRules());
    }
  };

  const handleSubmitRule = async (values, formik) => {
    if (rule) {
      await dispatch(
        updateRule(t, rule.id, {
          alertsToEmit: [values.criticalityOptions],
          name: values.name,
          requirements: {
            anyMatch: values.anyMatch,
            preconditions:
              values.type === PreconditionType.composite
                ? createCompositePreconditionsArray(values.compositeRules)
                : updatePreconditions(preconditions, values),
          },
          taskActions: updateTaskActions(values),
          critical: values.critical,
          sendNotifications: values.sendNotifications,
        })
      );
      onClose && onClose();
      await dispatch(fetchRules());
    } else {
      const response = await dispatch(
        addRule(t, {
          alertsToEmit: [values.criticalityOptions],
          name: values.name,
          requirements: {
            anyMatch: values.anyMatch,
            preconditions:
              values.type === PreconditionType.composite
                ? createCompositePreconditionsArray(values.compositeRules)
                : updatePreconditions(preconditions, values),
          },
          taskActions: updateTaskActions(values),
          critical: values.critical,
          sendNotifications: values.sendNotifications,
        })
      );
      await dispatch(fetchRules());
      setPreconditions([]);
      formik.resetForm();
      onClose && onClose();
    }
  };

  const handleDeletePrecondition = (preconditionToRemove) => {
    setPreconditions(
      preconditions.map((precondition) => {
        if (precondition !== preconditionToRemove) {
          return precondition;
        }
        return null;
      })
    );
  };

  return (
    <Form
      id={formId}
      onSubmit={handleSubmitRule}
      initialValues={initialRule}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ values }) => {
        return (
          <>
            <div className={styles.start_row}>
              <FormControl>
                <FieldLabel>
                  {t("name")}
                  <span className={styles.red}> *</span>
                </FieldLabel>
                <FieldInput
                  name="name"
                  type="text"
                  disabled={readOnly}
                  placeholder={t("add rule name")}
                  size="m"
                />
                <FieldError name="name" />
              </FormControl>
              <FormControl className={styles.dropdown}>
                <FieldLabel>
                  {t("field type")}
                  <span className={styles.red}> *</span>
                </FieldLabel>
                <FieldSelect
                  name="type"
                  size="s"
                  disabled={readOnly}
                  onChange={() => setPreconditions([])}
                  options={[
                    {
                      value: PreconditionType.composite,
                      label: t("composite"),
                    },
                    { value: PreconditionType.text, label: t("description") },
                    { value: PreconditionType.boolean, label: t("boolean") },
                    //TODO ?
                    // { value: PreconditionType.date, label: "Date" },
                    {
                      value: PreconditionType.selection_single,
                      label: t("single choice"),
                    },
                    {
                      value: PreconditionType.selection_multiple,
                      label: t("multiple choice"),
                    },
                    {
                      value: PreconditionType.selection_dropdown,
                      label: t("dropdown"),
                    },
                    {
                      value: PreconditionType.numerical,
                      label: t("numerical"),
                    },
                  ]}
                  placeholder={t("select field type")}
                />
                <FieldError name="type" />
              </FormControl>
              {rule && !readOnly && (
                <div className={styles.trash} onClick={handleDelete}>
                  <div className={styles.icon_box}>
                    <Icon name="trash" className={styles.icon} />
                  </div>
                </div>
              )}
            </div>
            <div className={styles.condition_row}>
              <div className={styles.condition_txt}> {t("if")}</div>
              <FormControl className={styles.condition_dropdown}>
                <FieldSelect
                  name="anyMatch"
                  disabled={readOnly}
                  size="s"
                  options={[
                    { value: true, label: t("any") },
                    { value: false, label: t("all") },
                  ]}
                  placeholder={t("any")}
                />
              </FormControl>
              <div className={styles.condition_txt}>
                {t("of the following conditions")}:
              </div>
              {preconditions.filter((p) => p !== null).length === 0 &&
                values.type !== PreconditionType.composite && (
                  <div className={styles.btn_top}>
                    <Button
                      type="button"
                      variant={!values.type ? "disabled" : "orange"}
                      size="s"
                      onClick={() => handleAddPrecondition(values.type)}
                    >
                      + {t("add condition", "title")}
                    </Button>
                  </div>
                )}
            </div>
            {values.type === PreconditionType.composite && (
              <CompositePreconditionsForm values={values} />
            )}
            <PreconditionsForm
              readOnly={readOnly}
              showEditing={!!rule}
              preconditions={preconditions}
              onDeletePrecondition={handleDeletePrecondition}
            />

            {preconditions.filter((p) => p !== null).length > 0 &&
              !readOnly &&
              values.type !== PreconditionType.composite && (
                <Button
                  type="button"
                  variant="orange"
                  size="s"
                  onClick={() => handleAddPrecondition(values.type)}
                >
                  + {t("add condition", "title")}
                </Button>
              )}
            <>
              <div className={styles.perform_txt}>
                {t("perform the following actions")}:
              </div>
              <FieldArray
                name="tasksToStart"
                render={(arrayHelpers) => {
                  return (
                    <div>
                      <div className={styles.button}>
                        <Button
                          type="button"
                          variant="orange"
                          size="s"
                          onClick={() => {
                            arrayHelpers.push({
                              taskActionStartId: "",
                              taskActionStartDelay: undefined,
                              taskActionStartDelayUnit: "",
                            });
                          }}
                        >
                          + {t("add task to start")}
                        </Button>
                      </div>
                      {values.tasksToStart.map((task, index) => {
                        return (
                          <div key={index} className={styles.row}>
                            <FormControl className={styles.perform_dropdown}>
                              <FieldLabel>
                                {t("choose tasks to be started")}
                              </FieldLabel>
                              <FieldSelect
                                name={`tasksToStart[${index}].taskActionStartId`}
                                size="s"
                                disabled={readOnly}
                                options={taskPrototypesOptions}
                                placeholder={t("task")}
                              />
                              <FieldError name="taskActionStartId" />
                            </FormControl>
                            <FormControl className={styles.number_input}>
                              <FieldLabel className={styles.label_right}>
                                {t("start")}
                              </FieldLabel>
                              <FieldInput
                                name={`tasksToStart[${index}].taskActionStartDelay`}
                                type="number"
                                disabled={readOnly}
                                size="xxs"
                              />
                              <FieldError name="taskActionStartDelay" />
                            </FormControl>
                            <FormControl className={styles.dropdown}>
                              <FieldLabel></FieldLabel>
                              <FieldSelect
                                disabled={readOnly}
                                name={`tasksToStart[${index}].taskActionStartDelayUnit`}
                                size="s"
                                options={taskActionDelayUnitsOptions}
                                placeholder={t("select field type")}
                              />
                              <FieldError name="taskActionStartDelayUnit" />
                            </FormControl>
                            <div className={styles.buttonRemoveWrapper}>
                              <Button
                                type="button"
                                variant="orange"
                                size="s"
                                onClick={() => arrayHelpers.remove(index)}
                              >
                                {t("remove")}
                              </Button>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  );
                }}
              />
              <FieldArray
                name="tasksToStop"
                render={(arrayHelpers) => {
                  return (
                    <div>
                      <div className={styles.button}>
                        <Button
                          type="button"
                          variant="orange"
                          size="s"
                          onClick={() => {
                            arrayHelpers.push({
                              taskActionStopId: "",
                              taskActionStopDelay: 0,
                              taskActionStopDelayUnit: "DAYS",
                            });
                          }}
                        >
                          + {t("add task to stop")}
                        </Button>
                      </div>
                      {values.tasksToStop.map((task, index) => {
                        return (
                          <div className={styles.row} key={index}>
                            <FormControl className={styles.perform_dropdown}>
                              <FieldLabel>
                                {t("choose tasks to be stopped")}
                              </FieldLabel>
                              <FieldSelect
                                name={`tasksToStop[${index}].taskActionStopId`}
                                disabled={readOnly}
                                size="s"
                                options={taskPrototypesOptions}
                                placeholder={t("task")}
                              />
                              <FieldError name="taskActionStopId" />
                            </FormControl>
                            <FormControl className={styles.number_input}>
                              <FieldLabel className={styles.label_right}>
                                {t("stop")}
                              </FieldLabel>
                              <FieldInput
                                name={`tasksToStop[${index}].taskActionStopDelay`}
                                type="number"
                                disabled={readOnly}
                                size="xxs"
                              />
                              <FieldError name="taskActionStopDelay" />
                            </FormControl>
                            <FormControl className={styles.dropdown}>
                              <FieldLabel></FieldLabel>
                              <FieldSelect
                                name={`tasksToStop[${index}].taskActionStopDelayUnit`}
                                size="s"
                                disabled={readOnly}
                                options={taskActionDelayUnitsOptions}
                                placeholder={t("select field type")}
                              />
                              <FieldError name="taskActionStopDelayUnit" />
                            </FormControl>
                            <div className={styles.buttonRemoveWrapper}>
                              <Button
                                type="button"
                                variant="orange"
                                size="s"
                                onClick={() => arrayHelpers.remove(index)}
                              >
                                {t("remove")}
                              </Button>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  );
                }}
              />
            </>
            <div className={styles.spacer_checkbox}>
              <Checkbox
                disabled={readOnly}
                name="critical"
                label={t(
                  "rule was created in response to something being wrong"
                )}
              />
            </div>
            <div className={styles.info_spacer}>
              <Checkbox
                name="sendNotifications"
                label={t("inform users")}
                disabled={readOnly}
              />
            </div>
            <div>
              <FormControl className={styles.perform_dropdown}>
                <FieldLabel>{t("choose criticality")}</FieldLabel>
                <FieldSelect
                  name="criticalityOptions"
                  size="s"
                  disabled={readOnly}
                  options={criticalityOptions}
                  placeholder={t("select field type")}
                />
                <FieldError name="criticalityOptions" />
              </FormControl>
            </div>
            {rule && !readOnly && (
              <div className={styles.button_section}>
                {!noCancelButton && (
                  <Button
                    className={styles.btn_spacer}
                    onClick={onClose}
                    variant="ghost"
                    size="s"
                    data-testid="modal-cancel"
                  >
                    {t("cancel")}
                  </Button>
                )}
                <Button
                  type="submit"
                  variant="orange"
                  size="s"
                  data-testid="modal-create"
                >
                  {t("save")}
                </Button>
              </div>
            )}
          </>
        );
      }}
    </Form>
  );
};

export default RuleEditForm;
