export const createBuildingsOptions = (assets) => {
  const buildings = [];

  assets.subAssets.forEach((asset) =>
    buildings.push({ label: asset.name, value: asset.id })
  );

  return buildings;
};

export const createChosenBuildingsOptions = (buildings) => {
  return buildings.map((el) => ({ label: el.name, value: el.id }));
};

export const getTasksCount = (data) => {
  let count = 0;

  data.forEach((el) => (count = count + Number(el.count)));

  return count;
};

export const createSensorsOptions = (sensors) => {
  const sensorsOptions = [];

  sensors.forEach((el) => {
    sensorsOptions.push({ label: el.name, value: el.id });
  });
  return sensorsOptions;
};

export const createPropertiesOptions = (properties) => {
  const propertiesOptions = [];

  properties.forEach((el) => {
    propertiesOptions.push({ label: el.property, value: el.property });
  });
  return propertiesOptions;
};

export const createAssetPrototypesOptions = (data) => {
  const options = [];

  data.forEach((item) =>
    options.push({
      label: item.name,
      value: item.id,
      global: item.visibleOnAllCompanies,
    })
  );

  return options;
};

export const createTaskTemplatesOptions = (data) => {
  const options = [];

  data
    .filter((item) => !item.deprecated)
    .forEach((item) =>
      options.push({
        label: item.name,
        value: item.id,
        global: item.global,
      })
    );

  return options;
};
