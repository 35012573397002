import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { I18nContext } from "../../i18n/I18nContext";
import styles from "./RulesEditor.module.scss";
import {
  addRule,
  deleteRule,
  updateRule,
  fetchAssetPrototypes,
  fetchTasksPrototypesList,
} from "../../redux";
import RuleItem from "./RuleItem";

const RulesEditor = (props) => {
  const {
    procedures,
    prototype,
    readOnly,
    assetPrototype,
    showLocalLabels,
    toggleRulesEditorOpen,
    notificationGroups,
  } = props;
  const { t } = useContext(I18nContext);
  const dispatch = useDispatch();
  const isPrivileged = useSelector(
    (state) => state.auth.info?.companyPrivileged
  );
  const [rules, setRules] = useState(
    procedures ? procedures : prototype?.procedures
  );
  const [showNewRuleForm, setShowNewRuleForm] = useState(false);

  useEffect(() => {
    if (!!prototype) {
      setRules(prototype.procedures);
    }
  }, [prototype]);

  useEffect(() => {
    if (!!procedures) {
      setRules(procedures);
    }
  }, [procedures]);

  useEffect(() => {
    toggleRulesEditorOpen(showNewRuleForm);
  }, [showNewRuleForm]);

  const properties = [];

  if (prototype?.forms) {
    prototype.forms.forEach((form) =>
      form.properties.forEach((field) => {
        if (field.propertyType !== "date") {
          properties.push(field);
        }
      })
    );
  } else {
    prototype?.assetProperties?.properties.forEach((property) => {
      if (property.propertyType !== "date") {
        properties.push(property);
      }
    });
  }

  const createRule = async (ruleItem) => {
    const idObject = assetPrototype
      ? { assetPrototypeId: prototype.id }
      : { taskPrototypeId: prototype.id };
    const response = await dispatch(addRule(t, { ...ruleItem, ...idObject }));
    await dispatch(
      assetPrototype ? fetchAssetPrototypes() : fetchTasksPrototypesList()
    );
    setShowNewRuleForm(false);
  };

  const editRule = async (ruleId, ruleItem) => {
    const response = await dispatch(updateRule(t, ruleId, ruleItem));
    await dispatch(
      assetPrototype ? fetchAssetPrototypes() : fetchTasksPrototypesList()
    );
    setShowNewRuleForm(false);
  };

  const removeRule = async (ruleId) => {
    const response = await dispatch(
      deleteRule(
        t,
        ruleId,
        prototype.id,
        assetPrototype ? "assetPrototypeId" : "taskPrototypeId"
      )
    );
    await dispatch(
      assetPrototype ? fetchAssetPrototypes() : fetchTasksPrototypesList()
    );
  };
  return (
    <div className={styles.rules_editor}>
      {!!rules.length &&
        rules.map((procedure) => (
          <RuleItem
            key={procedure.id}
            rule={procedure}
            properties={properties}
            createRule={createRule}
            editRule={editRule}
            deleteRule={removeRule}
            readOnly={readOnly || (procedure.global && !isPrivileged)}
            disablingAvailable={!readOnly && procedure.global && !isPrivileged}
            showLocalLabels={showLocalLabels}
            formIsGlobal={prototype?.global}
            assetPrototype={assetPrototype}
            recurring={!!prototype?.recurrence}
            rules={rules}
            ruleNames={rules
              .map((rule) => rule.name)
              .filter((name) => name !== procedure.name)}
            toggleRulesEditorOpen={toggleRulesEditorOpen}
            notificationGroupIds={notificationGroups}
          />
        ))}

      {!!showNewRuleForm && (
        <RuleItem
          properties={properties}
          createRule={createRule}
          cancelMethod={() => setShowNewRuleForm(false)}
          readOnly={readOnly}
          assetPrototype={assetPrototype}
          recurring={!!prototype.recurrence}
          ruleNames={rules.map((rule) => rule.name)}
          notificationGroupIds={notificationGroups}
        />
      )}

      {!showNewRuleForm && !readOnly && (
        <div
          className={styles.add_rule_btn}
          onClick={() => setShowNewRuleForm(true)}
        >
          {`+ ${t("add new rule", "upper")}`}
        </div>
      )}
    </div>
  );
};

export default RulesEditor;
