import Actions from "../actions";

const initialState = {
  userItems: [],
  userError: null,
  rolesItems: [],
  rolesError: null,
  companiesItems: [],
  companiesError: null,
  usersWithAssetAccess: [],
  usersWithAssetAccessError: null,
  pending: false,
};

const accessReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.FETCH_USERS_SUCCESS:
      return {
        ...state,
        userItems: action.payload,
      };
    case Actions.FETCH_USERS_FAILURE:
      return {
        ...state,
        userError: action.payload,
      };

    case Actions.FETCH_REGISTERED_ROLES_SUCCESS:
      return {
        ...state,
        rolesItems: action.payload,
      };
    case Actions.FETCH_REGISTERED_ROLES_FAILURE:
      return {
        ...state,
        rolesError: action.payload,
      };
    case Actions.FETCH_REGISTERED_COMPANIES_SUCCESS:
      return {
        ...state,
        companiesItems: action.payload,
      };
    case Actions.FETCH_REGISTERED_COMPANIES_FAILURE:
      return {
        ...state,
        companiesError: action.payload,
      };
    case Actions.FETCH_USERS_WITH_ASSET_ACCESS_SUCCESS:
      return {
        ...state,
        usersWithAssetAccess: action.payload,
      };
    case Actions.FETCH_USERS_WITH_ASSET_ACCESS_FAILURE:
      return {
        ...state,
        usersWithAssetAccessError: action.payload,
      };
    case Actions.USER_ACTION_PENDING:
      return {
        ...state,
        pending: true,
      };
    case Actions.USER_ACTION_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    default:
      return state;
  }
};

export default accessReducer;
