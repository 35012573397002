import React, { useState } from "react";
import styles from "./GroupTasks.module.scss";
import GroupTasksItem from "./GroupTasksItem";
import DeleteGroupTasksModal from "./DeleteGroupTasksModal";
import GroupTasksModal from "./GroupTasksModal";

const GroupTasks = ({ items, filters }) => {
  const [openEditGroupTasksModal, setEditGroupTasksModalOpen] = useState(false);
  const [openDeleteGroupTasksModal, setDeleteGroupTasksModalOpen] =
    useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);

  const showGroupDeleteModal = (e, group) => {
    e.stopPropagation();
    setDeleteGroupTasksModalOpen(true);
    setSelectedGroup(group);
  };

  const showGroupEditModal = (e, group) => {
    e.stopPropagation();
    setEditGroupTasksModalOpen(true);
    setSelectedGroup(group);
  };

  return (
    <>
      <div className={styles.task_list_container}>
        {items.map((item) => (
          <GroupTasksItem
            key={item.id}
            taskPool={item}
            onEditClick={showGroupEditModal}
            onDeleteClick={showGroupDeleteModal}
          />
        ))}

        {!!openDeleteGroupTasksModal && (
          <DeleteGroupTasksModal
            key="delete-group"
            group={selectedGroup}
            isOpen={openDeleteGroupTasksModal}
            onClose={() => {
              setDeleteGroupTasksModalOpen(false);
              setSelectedGroup(null);
            }}
            filters={filters}
          />
        )}
        {!!openEditGroupTasksModal && (
          <GroupTasksModal
            key="edit-group"
            group={selectedGroup}
            isOpen={openEditGroupTasksModal}
            onClose={() => {
              setEditGroupTasksModalOpen(false);
              setSelectedGroup(null);
            }}
            groupFilters={filters}
          />
        )}
      </div>
    </>
  );
};

export default GroupTasks;
