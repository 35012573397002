import React, { useContext, useEffect, useRef, useState } from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { useDetectOutsideClick } from "../../hooks/useDetectOutsideClick";
import Button from "../Button";
import OneOfTaskModal from "./OneOfTaskModal";
import RecurringTaskModal from "./RecurringTaskModal";
import styles from "./MeatballTaskRecent.module.scss";
import { I18nContext } from "../../i18n/I18nContext";

const MeatballTaskRecent = (recurranceRequired) => {
  const { t } = useContext(I18nContext);
  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);
  const [openOneOfTaskModal, setOpenOneOfTaskModal] = useState(false);
  const [openRecurringTaskModal, setOpenRecurringTaskModal] = useState(false);

  const onClick = (event) => {
    event.stopPropagation();
    setIsActive(!isActive);
  };

  const options = [
    {
      label: t("one of task"),
      onClick: () => {
        setOpenOneOfTaskModal(true);
        setIsActive(!isActive);
      },
    },
    {
      label: t("recurring task"),
      onClick: () => {
        setOpenRecurringTaskModal(true);
        setIsActive(!isActive);
      },
    },
  ];

  const classOpenMeatball = cx(styles.menu, {
    [styles.active]: isActive,
    [styles.inactive]: !isActive,
  });

  return (
    <div>
      <Button variant="orange" size="s" onClick={onClick}>
        {`+ ${t("add new task")}`}
      </Button>
      <div className={styles.menu_trigger}></div>
      <div ref={dropdownRef} className={classOpenMeatball}>
        {options.map((item, idx) => (
          <div
            className={styles.item_container}
            key={idx}
            onClick={item.onClick}
          >
            <span className={styles.label_menu}>{t(item.label)}</span>
          </div>
        ))}
      </div>
      {!!openOneOfTaskModal && (
        <OneOfTaskModal
          isOpen={openOneOfTaskModal}
          onClose={() => setOpenOneOfTaskModal(false)}
        />
      )}
      {!!openRecurringTaskModal && (
        <RecurringTaskModal
          recurranceRequired={true}
          isOpen={openRecurringTaskModal}
          onClose={() => setOpenRecurringTaskModal(false)}
        />
      )}
      {/* <GroupTaskModal
        isOpen={openGroupTaskModal}
        onClose={() => setOpenGroupTaskModal(false)}
      /> */}
    </div>
  );
};

MeatballTaskRecent.propTypes = {
  recurranceRequired: PropTypes.bool,
};
export default MeatballTaskRecent;
