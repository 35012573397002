import React, { useContext } from "react";
import styles from "./LayoutFilter.module.scss";
import Select from "react-select";
import { I18nContext } from "../../i18n/I18nContext";

const LayoutFilter = ({
  type,
  placeholder,
  options,
  onSelect,
  value,
  multi,
  disabled,
}) => {
  const { t } = useContext(I18nContext);
  const focusColor = "#EE7203";
  const customStyles = {
    option: (provided, { data }) => {
      return {
        ...provided,
        width: "198px",
        maxWidth: "198px",
        display: "flex",
        alignItems: "center",
        paddingRight: "3px",
        color: "#393939",
        height: "auto",
        backgroundColor: data.global ? "#fff8f1" : "#ffffff",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        "&:hover": {
          backgroundColor: "#eaeaea",
          color: "#393939",
          cursor: "pointer",
        },
      };
    },
    menu: (provided) => {
      return {
        ...provided,
        boxSizing: "border-box",
        border: `1px solid ${focusColor}`,
      };
    },
    container: (provided, state) => {
      return {
        ...provided,
        width: "200px",
        color: "#393939",
        borderRadius: "6px",
        fontSize: "14px",
        lineHeight: "16px",
        "&:hover": {
          backgroundColor: "#EE7203",
        },
      };
    },
    control: (base, state, provided) => ({
      ...provided,
      paddingLeft: "8px",
      fontWeight: "400",
      fontSize: "14px",
      height: "auto",
      dropdownIndicator: "red",
      ...base,
      boxShadow: state.isFocused ? 0 : 0,
      borderColor: state.isFocused ? focusColor : base.borderColor,
      "&:hover": {
        borderColor: state.isFocused ? focusColor : base.borderColor,
        cursor: "pointer",
      },
    }),
    indicatorSeparator: (provided) => {
      return {
        ...provided,
        backgroundColor: "none",
      };
    },
    dropdownIndicator: (provided, state) => {
      return {
        ...provided,
        color: state.isFocused ? "#EE7203" : "#AAAAAA",
      };
    },
    placeholder: (provided) => {
      return {
        ...provided,
        marginLeft: "2px",
        color: "#AAAAAA",
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "16px",
      };
    },
  };

  return (
    <div className={styles.filterWrapper}>
      <Select
        value={options.find((x) => x.value === value)}
        isClearable
        styles={customStyles}
        isSearchable={true}
        isMulti={multi}
        options={options}
        onChange={(selected) => onSelect(selected?.value || null, type)}
        placeholder={placeholder || t("select")}
        noOptionsMessage={() => t("no options")}
        isDisabled={disabled}
      />
    </div>
  );
};

export default LayoutFilter;
