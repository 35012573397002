import SceneLayer from './SceneLayer';
class Scene {
    constructor() {
        this._layers = new Array();
    }
    setBackground(background) {
        this._background = background;
    }
    add(poi) {
        this._layers.push(new SceneLayer(this._layers.length, poi));
    }
    remove(element) {
        this._layers = this._layers.filter(x => x.element.id !== element.id);
    }
    clear() {
        this._background = null;
        this._layers = [];
    }
    get background() { return this._background; }
    get layers() { return this._layers; }
}
export default Scene;
