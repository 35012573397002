import SelectStartegy from './Strategies/SelectStrategy';
import ControllerModeType from '../ControllerModeType';
import PanningStrategy from './Strategies/PanningStrategy';
class MouseDownStrategyProvider {
    static get(mode, intersection) {
        if (mode === ControllerModeType.VIEWER || intersection.element === null)
            return new PanningStrategy();
        else
            return new SelectStartegy();
    }
}
export default MouseDownStrategyProvider;
