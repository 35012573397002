import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import { deleteAttachmentsType, fetchAttachmentsTypesList } from "../../redux";
import Button from "../Button";
import Modal from "../Modal";
import styles from "./DeleteAttachmentsTypeModal.module.scss";
import { I18nContext } from "../../i18n/I18nContext";

const DeleteAttachmentsTypeModal = ({ isOpen, onClose, type }) => {
  const { t } = useContext(I18nContext);
  const dispatch = useDispatch();

  const handleClear = () => {
    onClose();
  };
  const handleDeleteFolder = async () => {
    await dispatch(deleteAttachmentsType(t, type.id));
    dispatch(fetchAttachmentsTypesList());
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={`${t("delete type")}?`}
      form="delete-form-attachments-type"
      icon
      footer={
        <div className={styles.button_section}>
          <Button
            className={styles.btn_separator}
            onClick={handleClear}
            type="reset"
            form={"delete-form-attachments-type"}
            variant="ghost"
            size="s"
          >
            {t("cancel")}
          </Button>
          <Button
            type="submit"
            variant="orange"
            size="s"
            form={"delete-form-attachments-type"}
            onClick={handleDeleteFolder}
          >
            {t("delete")}
          </Button>
        </div>
      }
    >
      <div className={styles.delete_txt}>
        {t("are you sure you want to permanently delete type")}
        <div className={styles.spacing_txt}>{type?.name} ?</div>
      </div>
    </Modal>
  );
};

export default DeleteAttachmentsTypeModal;
