import "react-datepicker/dist/react-datepicker.css";
import React from "react";
import ReactDatePicker from "react-datepicker";
import PropTypes from "prop-types";
import { Field } from "formik";
import Icon from "../Icon";
import styles from "./DatePicker.module.scss";

const DatePicker = ({ name, disabled, withTime, noDefaultValue = false }) => {
  return (
    <Field name={name}>
      {({
        field,
        form: { touched, handleChange, handleBlur, setFieldTouched },
      }) => {
        const selected =
          noDefaultValue && !field.value
            ? null
            : field.value
            ? new Date(field.value)
            : Date.now();
        return (
          <div className={styles.container}>
            <label className={styles.picker_wrapper}>
              <ReactDatePicker
                wrapperClassName={styles.wrapper}
                showTimeSelect={withTime}
                selected={selected}
                readOnly={disabled}
                touched={touched[name]}
                onChange={(value) => {
                  if (!withTime && !!value) {
                    value.setHours(12);
                  }
                  const eventFormik = { target: { name, value } };
                  handleChange(eventFormik);
                  setFieldTouched(name, true, false);
                  // handleBlur({ target: { name } });
                }}
                onBlur={() => {
                  const eventFormik = { target: { name } };
                  handleBlur(eventFormik);
                }}
                dateFormat="dd-MM-yyyy"
              />
              <Icon className={styles.icon} name="calendar" />
            </label>
          </div>
        );
      }}
    </Field>
  );
};

DatePicker.propTypes = {
  name: PropTypes.string.isRequired,
};

export default DatePicker;
