import { useField } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Icon from "../Icon";
import CheckboxBase from "./CheckboxBase";
import styles from "./OptionsBuilder.module.scss";
import RadioBase from "./RadioBase";
import { I18nContext } from "../../i18n/I18nContext";

const OptionsBuilder = ({
  name,
  chosenName,
  multiselect,
  showSelection,
  boolean,
  property,
}) => {
  const { t } = useContext(I18nContext);
  const [nameOptionsCopy, setNameOptionsCopy] = useState([]);
  const [firstValue, setFirstValue] = useState("");
  const [, { value: nameOptions = [] }, { setValue: setOptionsValue }] =
    useField({
      name: name,
    });

  useEffect(() => {
    setOptionsValue(property ? property.options : []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setNameOptionsCopy(nameOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nameOptions]);

  const onChangeFirst = (event) => {
    setFirstValue(event.currentTarget.value);
  };

  const onBlurFirst = () => {
    if (firstValue && !nameOptions.includes(firstValue)) {
      setOptionsValue([...nameOptions, firstValue]);
      setNameOptionsCopy([...nameOptionsCopy, firstValue]);
      setFirstValue("");
    }
  };

  const handleChangeOption = (event, index) => {
    const newOptions = [...nameOptionsCopy];
    newOptions[index] = event.target.value;
    setNameOptionsCopy(newOptions);
  };

  const onBlurOption = (event, index) => {
    const newOptions = [...nameOptions];
    newOptions[index] = event.currentTarget.value;
    setOptionsValue([...newOptions]);
  };

  const handleDeleteOption = (optionToRemove) => {
    const newNameOptions = [...nameOptionsCopy].filter(
      (option) => option !== optionToRemove
    );
    setNameOptionsCopy(newNameOptions);
    const newOptions = [...nameOptions].filter(
      (option) => option !== optionToRemove
    );
    setOptionsValue(newOptions);
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(nameOptions);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setOptionsValue(items);

    const itemsCopy = Array.from(nameOptionsCopy);
    const [reorderedItemCopy] = itemsCopy.splice(result.source.index, 1);
    itemsCopy.splice(result.destination.index, 0, reorderedItemCopy);
    setNameOptionsCopy(itemsCopy);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="options">
        {(provided) => (
          <div
            className={styles.container}
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {nameOptions?.map((option, index) => (
              <Draggable
                key={index}
                draggableId={index.toString()}
                index={index}
              >
                {(provided) => (
                  <div
                    key={option}
                    className={styles.row}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    {!showSelection && (
                      <div className={styles.no_dropdown}>{index + 1}.</div>
                    )}
                    {showSelection && (
                      <>
                        {multiselect ? (
                          <CheckboxBase checkmarkClass={styles.checkmark}>
                            <input type="checkbox" disabled />
                          </CheckboxBase>
                        ) : (
                          <RadioBase>
                            <input
                              type="radio"
                              value={option}
                              name={chosenName}
                              disabled
                            />
                          </RadioBase>
                        )}
                      </>
                    )}

                    <input
                      className={styles.single_input}
                      value={nameOptionsCopy[index]}
                      onChange={(event) => handleChangeOption(event, index)}
                      onBlur={(event) => onBlurOption(event, index)}
                    />
                    <div onClick={() => handleDeleteOption(option)}>
                      <Icon className={styles.delete_icon} name="x-delete" />
                    </div>

                    <div className={styles.drag_handle}>
                      <div className={styles.drag_handle_bar} />
                      <div className={styles.drag_handle_bar} />
                      <div className={styles.drag_handle_bar} />
                    </div>
                  </div>
                )}
              </Draggable>
            ))}
            <div className={styles.row}>
              {!showSelection && (
                <div className={styles.no_dropdown}>
                  {(nameOptions?.length ?? 0) + 1}.
                </div>
              )}
              {showSelection && (
                <>
                  {multiselect && !boolean && (
                    <CheckboxBase checkmarkClass={styles.checkmark}>
                      <input type="checkbox" disabled />
                    </CheckboxBase>
                  )}
                  {multiselect && boolean && nameOptions.length === 0 && (
                    <CheckboxBase checkmarkClass={styles.checkmark}>
                      <input type="checkbox" disabled />
                    </CheckboxBase>
                  )}
                  {!multiselect && (
                    <RadioBase>
                      <input
                        className={styles.single_input}
                        type="radio"
                        disabled
                      />
                    </RadioBase>
                  )}
                </>
              )}
              {boolean && nameOptions.length === 0 && (
                <input
                  key="first-input"
                  data-testid="first-input"
                  className={styles.single_input}
                  value={firstValue}
                  onBlur={onBlurFirst}
                  onChange={onChangeFirst}
                />
              )}
              {!boolean && (
                <input
                  key="first-input"
                  data-testid="first-input"
                  className={styles.single_input}
                  value={firstValue}
                  onBlur={onBlurFirst}
                  onChange={onChangeFirst}
                />
              )}
            </div>

            {nameOptions?.includes(firstValue) && (
              <div className={styles.error}>{t("option already exists")}</div>
            )}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default OptionsBuilder;
