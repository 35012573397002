import React from "react";
import { Field } from "formik";
import PropTypes from "prop-types";
import CheckboxBase from "./CheckboxBase";

const Checkbox = ({ name, label, disabled, ...rest }) => {
  return (
    <CheckboxBase {...rest} label={label} disabled={disabled}>
      <Field type="checkbox" name={name} disabled={disabled} />
    </CheckboxBase>
  );
};

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

export default Checkbox;
