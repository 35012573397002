import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import styles from "./CheckboxGroup.module.scss";

const CheckboxBase = ({ label, children, checkmarkClass, disabled }) => {
  return (
    <div className={styles.container}>
      <label className={styles.label_single}>
        {children}
        <span
          className={cx(checkmarkClass, {
            [styles.checkmark2]: disabled,
            [styles.checkmark]: !disabled,
          })}
        />
        {label}
      </label>
    </div>
  );
};

CheckboxBase.propTypes = {
  label: PropTypes.string,
};

export default CheckboxBase;
