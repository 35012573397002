import axios from "../../redux/auth";
import Actions from "../../redux/actions";
import { createToast } from "../../redux";

export const deleteNotificationGroup = (id) => {
  return (dispatch) => {
    return axios
      .delete(`/notificationGroup/deleteById?id=${id}`)
      .then((response) => {
        dispatch(actionSuccess(response.data));
      })
      .catch((error) => {
        dispatch(actionFailure(error));
      });
  };
};

export const fetchNotificationGroupUsers = () => {
  return (dispatch) => {
    return axios
      .get(`notificationGroup/users`)
      .then((response) => {
        dispatch(fetchUsersListSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchUsersListFailure(error));
      });
  };
};
export const fetchNotificationGroups = () => {
  return (dispatch) => {
    return axios
      .get(`/notificationGroup/findAll`)
      .then((response) => {
        dispatch(fetchNotificationGroupsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchNotificationGroupsFailure(error));
      });
  };
};

const fetchNotificationGroupsSuccess = (data) => {
  return {
    type: Actions.FETCH_NOTIFICATION_GROUP_SUCCESS,
    payload: data,
  };
};

const fetchNotificationGroupsFailure = (error) => {
  return {
    type: Actions.FETCH_NOTIFICATION_GROUP_ERROR,
    payload: error,
  };
};

export const addNotificationGroup = (t, data) => {
  return (dispatch) => {
    return axios.post(`/notificationGroup/save`, data).then((response) => {
      dispatch(
        createToast({
          type: "success",
          title: t("successfully added notification group"),
        })
      );
      dispatch(actionSuccess());
    });
  };
};
export const editNotificationGroup = (t, data) => {
  return (dispatch) => {
    return axios.put(`/notificationGroup/edit`, data).then((response) => {
      dispatch(
        createToast({
          type: "success",
          title: t("successfully added notification group"),
        })
      );
      dispatch(actionSuccess());
    });
  };
};
const actionSuccess = () => {
  return {
    type: Actions.NOTIFICATION_GROUP_SUCCESS,
  };
};

const actionFailure = (error) => {
  return {
    type: Actions.NOTIFICATION_GROUP_ERROR,
    payload: error,
  };
};
const fetchUsersListSuccess = (data) => {
  return {
    type: Actions.FETCH_NOTIFICATION_GROUP_USERS_SUCCESS,
    payload: data,
  };
};
const fetchUsersListFailure = (error) => {
  return {
    type: Actions.FETCH_NOTIFICATION_GROUP_USERS_FAILURE,
    payload: error,
  };
};
