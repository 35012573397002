import React, { useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import styles from "./Sidebar.module.scss";
import Icon from "../Icon";
import { appUrls } from "../../urls";
import { I18nContext } from "../../i18n/I18nContext";
import { logout } from "../../redux";
import history from "../../history";

const Sidebar = () => {
  const { t } = useContext(I18nContext);
  const dispatch = useDispatch();
  const role = useSelector((state) => state.auth.info?.role);
  const handleLogout = async () => {
    await dispatch(logout());
    history.push(appUrls.LOGIN);
  };

  let topMenuItems = [];
  let bottomMenuItems = [];

  if (role == "CUSTOMER" || role == "MAINTAINER") {
    topMenuItems = sidebarItems.filter(
      (el) =>
        el.label !== "configuration" &&
        el.label !== "access" &&
        el.label !== "reports"
    );
    bottomMenuItems = sidebarItemsBottom.filter((el) => el.label !== "license");
  } else {
    topMenuItems = sidebarItems;
    bottomMenuItems = sidebarItemsBottom;
  }

  return (
    <>
      <div className={styles.sidebar}>
        <div>
          {topMenuItems.map((item, idx) => (
            <NavLink
              key={idx}
              to={item.to}
              activeClassName={styles.selected_link}
              strict
            >
              <span className={styles.icon_sidebar}> {item.icon} </span>
              <span className={styles.label_sidebar}>{t(item.label)}</span>
            </NavLink>
          ))}
        </div>
        <div>
          {bottomMenuItems.map((item, idx) => (
            <NavLink
              key={idx}
              to={item.to}
              activeClassName={styles.selected_link}
              strict
            >
              <span className={styles.icon_sidebar}> {item.icon} </span>
              <span className={styles.label_sidebar}>{t(item.label)}</span>
            </NavLink>
          ))}
          <div className={styles.icon_holder} onClick={handleLogout}>
            <span className={styles.icon_sidebar}>
              <Icon name="logout" className={styles.icon_sidebar} />
            </span>
            <span className={styles.label_sidebar}>{t("logout")}</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;

const sidebarItems = [
  {
    label: "dashboard",
    to: appUrls.DASHBOARD,
    icon: <Icon name="dashboard" className={styles.icon_sidebar} />,
  },
  {
    label: "assets",
    to: appUrls.ASSETS,
    icon: <Icon name="assets" className={styles.icon_sidebar} />,
  },
  {
    label: "tasks",
    to: appUrls.TASK,
    icon: <Icon name="tasks" className={styles.icon_sidebar} />,
  },
  {
    label: "configuration",
    to: appUrls.CONFIGURATION,
    icon: <Icon name="configuration" className={styles.icon_sidebar} />,
  },
  {
    label: "access",
    to: appUrls.ACCESS,
    icon: <Icon name="access" className={styles.icon_sidebar} />,
  },
  {
    label: "reports",
    to: appUrls.REPORTS,
    icon: <Icon name="reports" className={styles.icon_sidebar} />,
  },
];

const sidebarItemsBottom = [
  // {
  //   label: "license",
  //   to: appUrls.LICENSE,
  //   icon: <Icon name="license" className={styles.icon_sidebar} />,
  // },
  {
    label: "settings",
    to: "/settings/profile",
    icon: <Icon name="setting-sidebar" className={styles.icon_sidebar} />,
  },
  {
    label: "help",
    to: appUrls.HELP,
    icon: <Icon name="help" className={styles.icon_sidebar} />,
  },
];
