import Actions from "../actions";

const rights = {
  items: [],
  loading: false,
  error: null,

  taskTemplateListError: null,
  taskTemplateList: [],
  taskTemplateListLoading: false,

  assetTemplateListLoading: false,
  assetTemplateList: [],
  assetTemplateListError: null,
};

const rightsReducer = (state = rights, action) => {
  switch (action.type) {
    case Actions.FETCH_RIGHTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case Actions.FETCH_RIGHTS_ERROR: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case Actions.FETCH_RIGHTS_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload,
      };
    case Actions.FETCH_RIGHTS_TASK_TEMPLATE_LIST_REQUEST:
      return {
        ...state,
        taskTemplateListLoading: true,
      };
    case Actions.FETCH_RIGHTS_TASK_TEMPLATE_LIST_ERROR: {
      return {
        ...state,
        taskTemplateListError: action.payload,
      };
    }
    case Actions.FETCH_RIGHTS_TASK_TEMPLATE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        taskTemplateList: action.payload,
      };
    case Actions.FETCH_RIGHTS_ASSET_TEMPLATE_LIST_REQUEST:
      return {
        ...state,
        assetTemplateListLoading: true,
      };
    case Actions.FETCH_RIGHTS_ASSET_TEMPLATE_LIST_ERROR: {
      return {
        ...state,
        assetTemplateListError: action.payload,
      };
    }
    case Actions.FETCH_RIGHTS_ASSET_TEMPLATE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        assetTemplateList: action.payload,
      };
    default:
      return state;
  }
};

export default rightsReducer;
