import React, { createContext, useState } from "react";
import { useSelector } from "react-redux";
import locales from "./locales";

export const I18nContext = createContext();

const I18nContextProvider = ({ children }) => {
  const { profile } = useSelector((state) => state.profile);
  const [pending, setPending] = useState(false);
  const [currentLocale, setCurrentLocale] = useState(
    locales[profile?.languageCode || localStorage.getItem("tapperLanguage")] ??
      locales["en"]
  );
  const [language, setLanguage] = useState(
    profile?.languageCode || localStorage.getItem("tapperLanguage") || "en"
  );

  const changeLanguage = (locale) => {
    return new Promise((resolve) => {
      setPending(true);
      setTimeout(() => {
        localStorage.setItem("tapperLanguage", locale);
        setLanguage(locale);
        setCurrentLocale(locales[locale]); //allows us to load translations from outside in the future
        setPending(false);
        resolve();
      }, 100);
    });
  };

  const t = (key, textCase) => {
    let text = currentLocale[key] || key;
    switch (textCase) {
      case "lower":
        return text;
      case "upper":
        return text.toUpperCase();
      case "title":
        return toTitleCase(text);
      default:
        return toProperCase(text);
    }
  };

  const toProperCase = (txt) =>
    txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  const toTitleCase = (str) => str.replace(/\w\S*/g, toProperCase);

  return (
    <I18nContext.Provider value={{ pending, changeLanguage, t, language }}>
      {children}
    </I18nContext.Provider>
  );
};

export default I18nContextProvider;
