import React, { useCallback, useEffect, useRef, useState } from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { useOnClickOutside } from "./utils";
import styles from "./Tooltip.module.scss";

const Tooltip = ({
  position,
  text,
  color,
  children,
  size,
  title,
  onVisibilityChange,
  isOpen,
}) => {
  const ref = useRef();
  const [isVisible, setVisible] = useState(false);

  const isControlled = isOpen !== undefined;

  useOnClickOutside(ref, () => onVisibilityChange && onVisibilityChange(false));

  useEffect(() => {
    setVisible(isOpen);
  }, [isOpen]);

  const handleHover = useCallback(
    ({ target }) => {
      if (ref.current?.contains(target)) {
        setVisible(true);
        onVisibilityChange && onVisibilityChange(true);
      } else {
        setVisible(false);
        onVisibilityChange && onVisibilityChange(true);
      }
    },
    [setVisible, onVisibilityChange]
  );

  const hideTooltip = useCallback(() => {
    setVisible(false);
    onVisibilityChange && onVisibilityChange(false);
  }, [setVisible, onVisibilityChange]);

  useEffect(() => {
    if (isControlled) {
      return;
    }
    document.addEventListener("mouseover", handleHover);
    return () => {
      document.removeEventListener("mouseover", handleHover);
    };
  }, [handleHover, isControlled]);

  useEffect(() => {
    if (isControlled) {
      return;
    }
    document.addEventListener("scroll", hideTooltip, true);
    return () => {
      document.removeEventListener("scroll", hideTooltip, true);
    };
  }, [hideTooltip, isControlled]);

  return (
    <div className={styles.container} ref={ref}>
      {children}
      {isVisible && (
        <div
          className={cx(
            styles.tooltip_content,
            styles[position],
            styles[color],
            styles[size]
          )}
        >
          <span className={styles.arrow}></span>
          {text}
          {title}
        </div>
      )}
    </div>
  );
};
Tooltip.propTypes = {
  position: PropTypes.oneOf(["right"]),
  color: PropTypes.oneOf(["white"]),
  size: PropTypes.oneOf(["s", "m"]),
  title: PropTypes.string,
  text: PropTypes.object,
  onVisibilityChange: PropTypes.func,
  children: PropTypes.node,
  isOpen: PropTypes.bool,
};
Tooltip.defaultProps = {
  position: "top",
};

export default Tooltip;
