import Icon from "../Icon";
import { DatePicker, FieldLabel, FormControl } from "../Input";
import styles from "./PreconditionsForm.module.scss";

const DateCondition = ({
  precondition,
  readOnly,
  onDeletePrecondition,
  index,
  name,
}) => {
  return (
    <FormControl key={index}>
      <FieldLabel>{name ? "Date" : precondition.name}</FieldLabel>
      <div className={styles.date_section}>
        <DatePicker name={name ? name : precondition.name} />
        {onDeletePrecondition && !readOnly && (
          <div onClick={() => onDeletePrecondition(precondition)}>
            <Icon name="x-delete" />
          </div>
        )}
      </div>
    </FormControl>
  );
};

export default DateCondition;
