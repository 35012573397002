import React, { useContext, useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import {
  FieldLabel,
  Form,
  FormControl,
  FieldError,
  FieldInput,
  Checkbox,
  FieldTextArea,
  FieldSelect,
} from "../Input";
import Tooltip from "../Tooltip";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../Modal";
import {
  addAssetPrototype,
  addAssetPrototypeAttachment,
  editAssetPrototype,
  fetchAssetPrototypes,
  fetchIconsList,
  assetPrototypesEditErrorClear,
} from "../../redux";
import {
  getInitialState,
  PrototypeForm,
  updateAssetProperties,
} from "../PrototypeForm";
import PropertyType from "../../constants/PropertyType";
import FieldBuilder from "../FieldBuilder";
import {
  createNumericProperty,
  createOtherProperty,
  createSelectionProperty,
} from "../PrototypeForm/utils";
import styles from "./AssetPrototypeModal.module.scss";
import { I18nContext } from "../../i18n/I18nContext";
import InputTagForm from "../InputTag";
import Button from "../Button";
import EditAssetPropertyModal from "./EditAssetPropertyModal";
import RulesEditor from "../RulesEditor/RulesEditor";
import FormEditErrorModal from "./FormEditErrorModal";
import { fetchNotificationGroups } from "../NotificationGroups/notificationGroupActions";

const defaultInitialValues = {
  isTopLevel: false,
  prototypeNameUnique: "",
  grouping: false,
  visibleOnAllCompanies: false,
  prototypeDescUnique: "",
  files: [],
  assetProperties: {
    properties: [],
    procedures: [],
  },
  tags: [],
  iconId: "",
};

const createValidationScheme = (t, assetProperties, prototypes) => {
  // const propertiesSchema = assetProperties.properties.reduce(
  //   (current, property) => {
  //     if (property.flags.isMandatory) {
  //       current[property.id || property.name] = Yup.mixed().required(
  //         t("this field is required, please provide a value.")
  //       );
  //     }
  //     return current;
  //   },
  //   {}
  // );

  return Yup.object({
    prototypeNameUnique: Yup.mixed()
      .notOneOf(prototypes, t("name must be unique"))
      .required(t("please enter the name of the asset prototype")),
    grouping: Yup.bool(),
    prototypeDescUnique: Yup.string().required(
      t("please provide your asset description")
    ),
    iconId: Yup.string().required(t("please choose one option")),
    // ...propertiesSchema,
  });
};

const AssetPrototypeModal = ({
  onClose,
  isOpen,
  assetPrototype,
  preview,
  propertiesDisabled,
}) => {
  const { t } = useContext(I18nContext);
  const dispatch = useDispatch();
  const currentValues = useRef(defaultInitialValues);
  const currentReset = useRef(null);
  const [tags, setTags] = useState([]);
  const [assetProperties, setAssetProperties] = useState({
    properties: [],
    procedures: [],
  });
  const [initialValues, setInitialValues] = useState(defaultInitialValues);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editedProperty, setEditedProperty] = useState(null);
  const [editedPropertyProcedures, setEditedPropertyProcedures] =
    useState(null);
  const [editedPropertyIndex, setEditedPropertyIndex] = useState(null);
  const [isPropertyEdited, setIsPropertyEdited] = useState(false);
  const [showPropertyEditedWarning, setShowPropertyEditedWarning] =
    useState(false);
  const [formikContent, setFormikContent] = useState(null);
  const [displayedProperties, setDisplayedProperties] = useState([]);
  const [rulesEditorOpen, setRulesEditorOpen] = useState(false);
  const [showAssetTypeEditErrorModal, setShowAssetTypeEditErrorModal] =
    useState(false);
  const notificationGroups = useSelector(
    (state) => state.notificationGroups.items
  );
  const unitsOptions = useSelector((state) => state.units.items);
  const icons = useSelector((state) => state.icons.items);
  const isCompanyPrivileged = useSelector(
    (state) => state.auth.info?.companyPrivileged
  );

  const assetTypeEditError = useSelector(
    (state) => state.prototypes.assetTypeEditError
  );
  const assetTypeEditErrorStatus = useSelector(
    (state) => state.prototypes.assetTypeEditErrorStatus
  );

  const toggleRulesEditorOpen = (state) => {
    setRulesEditorOpen(state);
  };

  const iconOptions = icons
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((item) => ({
      value: item.id,
      label: (
        <div className={styles.optionIconWrapper}>
          <svg data-src={item.url} fill="#575756" width="24px" height="24px" />
          {item.name}
        </div>
      ),
    }));

  const prototypes = useSelector((state) =>
    assetPrototype
      ? state.prototypes.items
          .filter((item) => item.id !== assetPrototype.id && !item.deprecated)
          .map((p) => p.name)
      : state.prototypes.items
          .filter((item) => !item.deprecated)
          .map((p) => p.name)
  );

  useEffect(() => {
    dispatch(fetchIconsList());
    dispatch(fetchNotificationGroups());
  }, []);

  useEffect(() => {
    setInitialValues(getInitialState(assetProperties, currentValues.current));
  }, [assetProperties]);

  useEffect(() => {
    setInitialValues(getInitialState(assetProperties, currentValues.current));
  }, [assetProperties]);

  useEffect(() => {
    if (assetPrototype) {
      setAssetProperties(assetPrototype.assetProperties);
      setDisplayedProperties(
        assetPrototype.displayedProperties
          ? assetPrototype.displayedProperties.map((prop) => prop.id)
          : []
      );
      setTags(assetPrototype.tags);
      setInitialValues({
        prototypeNameUnique: assetPrototype.name,
        grouping: assetPrototype.grouping,
        isTopLevel: assetPrototype.topLevel || false,
        visibleOnAllCompanies: assetPrototype.visibleOnAllCompanies,
        prototypeDescUnique: assetPrototype.desc,
        iconId: assetPrototype.icon?.id,
        tags: assetPrototype.tags,
        files: assetPrototype.attachments,
        ...getInitialState(assetPrototype.assetProperties),
        id: assetPrototype.id,
      });
    }
  }, [assetPrototype]);

  useEffect(() => {
    dispatch(assetPrototypesEditErrorClear());
  }, []);

  useEffect(() => {
    if (assetTypeEditErrorStatus === 400) {
      setShowAssetTypeEditErrorModal(true);
    } else {
      dispatch(assetPrototypesEditErrorClear());
    }
  }, [assetTypeEditError]);

  const validationSchema = createValidationScheme(
    t,
    assetProperties,
    prototypes
  );

  const handleSubmit = async (
    values,
    formik,
    overridePropertyEditedWarning
  ) => {
    if (isPropertyEdited && !overridePropertyEditedWarning) {
      setShowPropertyEditedWarning(true);
      setFormikContent({ values, formik });
      return null;
    }

    const properties = updateAssetProperties(
      assetProperties.properties,
      values
    );

    if (!assetPrototype) {
      const { id } = await dispatch(
        addAssetPrototype(t, {
          name: values.prototypeNameUnique,
          grouping: values.grouping,
          isTopLevel: values.isTopLevel,
          visibleOnAllCompanies: values.visibleOnAllCompanies,
          desc: values.prototypeDescUnique,
          assetProperties: {
            properties,
            procedures: [],
          },
          displayedProperties: displayedProperties.map((prop) =>
            properties.find((x) => x.tempId === prop)
          ),
          tags,
          iconId: values.iconId,
        })
      );

      if (values.files.length) {
        await dispatch(addAssetPrototypeAttachment(t, id, values.files));
      }
    } else {
      const { id } = await dispatch(
        editAssetPrototype(t, {
          id: assetPrototype.id,
          name: values.prototypeNameUnique,
          desc: values.prototypeDescUnique,
          visibleOnAllCompanies: values.visibleOnAllCompanies,
          assetProperties: {
            properties,
            procedures: assetPrototype.assetProperties.procedures,
          },
          displayedProperties: displayedProperties.map((prop) =>
            properties.find((x) => x.id === prop || x.tempId === prop)
          ),
          tags,
          iconId: values.iconId,
          isTopLevel: values.isTopLevel,
        })
      );
      const newFiles = values.files.filter((file) => !file.key);
      if (newFiles.length) {
        await dispatch(addAssetPrototypeAttachment(t, id, newFiles));
      }
    }

    dispatch(fetchAssetPrototypes());

    setInitialValues(defaultInitialValues);
    setAssetProperties({
      properties: [],
      procedures: [],
    });
    formik.resetForm();
    handleClose();
  };

  const handleClose = () => {
    currentValues.current = defaultInitialValues;
    setInitialValues(defaultInitialValues);
    setAssetProperties({
      properties: [],
      procedures: [],
    });
    currentReset.current();
    setShowPropertyEditedWarning(false);
    onClose();
  };

  const handleSubmitProperty = (values, formik) => {
    const { property } = values;
    const { propertyType } = property;

    let newAssetProperties = structuredClone(assetProperties);
    const tempId = JSON.stringify(Math.random() * 100).replace(".", "");

    if (propertyType.startsWith(PropertyType.selection)) {
      if (property.options.length === 0) {
        return;
      }
      newAssetProperties.properties.push({
        ...createSelectionProperty(propertyType, property),
        tempId,
      });
    } else if (propertyType.startsWith(PropertyType.numeric)) {
      newAssetProperties.properties.push({
        ...createNumericProperty(propertyType, property, unitsOptions),
        tempId,
      });
    } else {
      newAssetProperties.properties.push({
        ...createOtherProperty(propertyType, property),
        tempId,
      });
    }

    setAssetProperties(newAssetProperties);

    formik.resetForm();
  };

  const handleEditProperty = (values, formik) => {
    const { property = {} } = values;
    const { propertyType } = property;

    let newAssetProperties = structuredClone(assetProperties);
    const newProperties = [...newAssetProperties.properties];
    const index = property.id
      ? newProperties.indexOf(
          newProperties.find((property) => property.id === editedProperty.id)
        )
      : newProperties.indexOf(
          newProperties.find(
            (property) => property.name === editedProperty.name
          )
        );

    if (propertyType.startsWith(PropertyType.selection)) {
      if (property.options.length === 0) {
        return;
      }

      newProperties[index] = {
        ...createSelectionProperty(propertyType, property),
        id: property.id,
        tempId: editedProperty.tempId,
      };

      setAssetProperties({
        ...assetProperties,
        properties: newProperties,
      });
    } else if (propertyType.startsWith(PropertyType.numeric)) {
      newProperties[index] = {
        ...createNumericProperty(propertyType, property, unitsOptions),
        id: property.id,
        tempId: editedProperty.tempId,
      };

      setAssetProperties({
        ...assetProperties,
        properties: newProperties,
      });
    } else {
      newProperties[index] = {
        ...createOtherProperty(propertyType, property),
        id: property.id,
        tempId: editedProperty.tempId,
      };

      setAssetProperties({
        ...assetProperties,
        properties: newProperties,
      });
    }
    setIsEditModalOpen(false);
    setEditedProperty(null);
    setEditedPropertyProcedures(null);
    setEditedPropertyIndex(null);
    formik.resetForm();
  };

  const handleDeleteProperty = (propertyToRemove) => {
    let newAssetProperties = structuredClone(assetProperties);
    const newProperties = [...newAssetProperties.properties];
    const index = propertyToRemove.id
      ? newProperties.indexOf(
          newProperties.find(
            (property) =>
              property.id === propertyToRemove.id && property.isActive
          )
        )
      : newProperties.indexOf(
          newProperties.find(
            (property) =>
              property.name === propertyToRemove.name && property.isActive
          )
        );
    !!propertyToRemove.id
      ? (newProperties[index].isActive = false)
      : newProperties.splice(index, 1);
    newAssetProperties.properties = newProperties;

    const displayedPropertyIndex = displayedProperties.indexOf(
      propertyToRemove.id || propertyToRemove.tempId
    );

    if (displayedPropertyIndex > -1) {
      const newDisplayedProperties = [...displayedProperties];
      newDisplayedProperties.splice(displayedPropertyIndex, 1);
      setDisplayedProperties(newDisplayedProperties);
    }

    setAssetProperties(newAssetProperties);
  };

  const propertyNames = assetProperties.properties
    .filter((property) => property.isActive)
    .map((property) => {
      return property.name;
    });

  // const handleDelete = async (file) => {
  //   if (assetPrototype && file.key) {
  //     await dispatch(
  //       deleteAssetPrototypeAttachment(t, assetPrototype.id, file.key)
  //     );
  //   }
  // };

  const selectDisplayedProperty = (position, property) => {
    const newDisplayedProperties = [...displayedProperties];
    const isPropertySelected = newDisplayedProperties.includes(property);

    if (position === 0) {
      if (isPropertySelected) {
        const index = newDisplayedProperties.indexOf(property);
        newDisplayedProperties.splice(index, 1);
      }
    } else if (
      position <= newDisplayedProperties.length &&
      !isPropertySelected
    ) {
      newDisplayedProperties[position - 1] = property;
    } else if (newDisplayedProperties.length < 3) {
      newDisplayedProperties.push(property);
    }
    setDisplayedProperties(newDisplayedProperties);
  };

  const changePropertiesOrder = (newProperties) => {
    let updatedAssetProperties = { ...assetProperties };
    const inactiveProperties = assetProperties.properties.filter(
      (prop) => !prop.isActive
    );
    const updatedProperties = [...newProperties, ...inactiveProperties];
    updatedAssetProperties.properties = updatedProperties;
    setAssetProperties(updatedAssetProperties);
  };
  return (
    <Modal
      className={styles.modal}
      isOpen={isOpen}
      onClose={handleClose}
      title={
        preview
          ? t("preview asset type")
          : assetPrototype
          ? t("edit asset template")
          : t("configure properties for new asset prototype")
      }
      form="add-prototype-form"
      divider={true}
      footer={
        <div className={styles.button_section}>
          {showPropertyEditedWarning && (
            <div className={styles.propertyEditedWarning}>
              <span>
                {t("you have made changes, but have not added the property")}
                {". "}
                {t("are you sure you want to proceed")}
                {"?"}
              </span>
              <Button
                className={styles.btn_separator}
                variant="orange"
                size="s"
                onClick={() => {
                  setShowPropertyEditedWarning(false);
                  setIsPropertyEdited(false);
                  handleSubmit(
                    formikContent.values,
                    formikContent.formik,
                    true
                  );
                }}
              >
                {t("confirm")}
              </Button>
            </div>
          )}
          <Button
            onClick={(e) => {
              if (showPropertyEditedWarning) {
                e.preventDefault();
                setShowPropertyEditedWarning(false);
              } else {
                handleClose();
              }
            }}
            type="reset"
            form={"add-prototype-form"}
            variant="ghost"
            size="s"
            data-testid="modal-cancel"
          >
            {t(!preview ? "cancel" : "close")}
          </Button>
          {!assetPrototype && !showPropertyEditedWarning && (
            <Button
              className={styles.btn_separator}
              type="submit"
              variant="orange"
              size="s"
              form={"add-prototype-form"}
            >
              {t("add")}
            </Button>
          )}

          {assetPrototype && !showPropertyEditedWarning && !preview && (
            <Button
              className={styles.btn_separator}
              type="submit"
              variant={rulesEditorOpen ? "disabled" : "orange"}
              size="s"
              form={"add-prototype-form"}
              disabled={rulesEditorOpen}
            >
              {t("save changes")}
            </Button>
          )}
        </div>
      }
    >
      <Form
        id="add-prototype-form"
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ values, resetForm, errors }) => {
          currentValues.current = values;
          currentReset.current = resetForm;

          return (
            <>
              <FormControl className={styles.margin_top}>
                <FieldLabel>
                  {t("name")}
                  <span className={styles.red}> *</span>
                </FieldLabel>
                <FieldInput
                  name="prototypeNameUnique"
                  type="text"
                  placeholder={t("name")}
                  size="l"
                  disabled={preview}
                />
                <FieldError name="prototypeNameUnique" />
              </FormControl>
              <FormControl className={styles.margin_bottom}>
                <FieldLabel>
                  {t("description")}
                  <span className={styles.red}> *</span>
                </FieldLabel>
                <FieldTextArea name="prototypeDescUnique" disabled={preview} />
                <FieldError name="prototypeDescUnique" />
              </FormControl>
              <FieldLabel>{t("tags")}</FieldLabel>
              <InputTagForm name="tags" onChange={setTags} disabled={preview} />
              <div className={styles.group_checkbox}>
                <Tooltip
                  color="white"
                  position="right"
                  size="s"
                  text={
                    <div>
                      {t(
                        "if checked, asset can contain other assets as sub-assets"
                      )}
                    </div>
                  }
                >
                  <Checkbox
                    checkmarkClass={styles.checkbox}
                    disabled={!!assetPrototype || preview}
                    name="grouping"
                    label={t("group prototype")}
                  />
                </Tooltip>
              </div>
              {values.grouping && (
                <div className={styles.group_checkbox}>
                  <Tooltip
                    color="white"
                    position="right"
                    size="s"
                    text={<div>{t("if checked, asset will be top level")}</div>}
                  >
                    <Checkbox
                      checkmarkClass={styles.checkbox}
                      disabled={preview}
                      name="isTopLevel"
                      label={t("top level")}
                    />
                  </Tooltip>
                </div>
              )}
              {isCompanyPrivileged && (
                <div className={styles.group_checkbox}>
                  <Tooltip
                    color="white"
                    position="right"
                    size="s"
                    text={
                      <div>
                        {t(
                          "if checked, this type will be available for sub-customers"
                        )}
                      </div>
                    }
                  >
                    <Checkbox
                      checkmarkClass={styles.checkbox}
                      name="visibleOnAllCompanies"
                      label={t("visible for sub-customers")}
                    />
                  </Tooltip>
                </div>
              )}
              <FormControl className={styles.dropdown}>
                <FieldLabel>
                  {t("icon")}
                  <span className={styles.red}>*</span>
                </FieldLabel>
                <FieldSelect
                  name="iconId"
                  size="s"
                  options={iconOptions}
                  disabled={preview}
                />
                <FieldError name="iconId" />
              </FormControl>
              <PrototypeForm
                isCreating={true}
                assetPrototype={assetPrototype}
                assetProperties={assetProperties.properties.filter(
                  (property) => property.isActive
                )}
                onDeleteProperty={handleDeleteProperty}
                onEditProperty={(property, procedureIds, index) => {
                  setIsEditModalOpen(true);
                  setEditedProperty(property);
                  setEditedPropertyProcedures(procedureIds);
                  setEditedPropertyIndex(index);
                }}
                allowDeletingProperties={true}
                editable={!preview}
                disabled={preview || propertiesDisabled}
                showDisplayedPropertiesSelector={true}
                displayedProperties={displayedProperties}
                selectDisplayedProperty={selectDisplayedProperty}
                noDefaultDateValue={true}
                allowDisplayedPropertiesEditing={true}
                changeOrder={changePropertiesOrder}
              />
            </>
          );
        }}
      </Form>
      <hr className={styles.separator} />

      {!preview && (
        <FieldBuilder
          onSubmit={handleSubmitProperty}
          hideTags={true}
          propertyNames={propertyNames}
          assetView
          setIsPropertyEdited={setIsPropertyEdited}
          setShowPropertyEditedWarning={setShowPropertyEditedWarning}
        />
      )}

      {!!assetPrototype && (
        <RulesEditor
          prototype={assetPrototype}
          assetPrototype
          toggleRulesEditorOpen={toggleRulesEditorOpen}
          notificationGroups={notificationGroups}
        />
      )}

      {!!isEditModalOpen && (
        <EditAssetPropertyModal
          onSubmit={handleEditProperty}
          isOpen={isEditModalOpen}
          onClose={() => {
            setIsEditModalOpen(false);
            setEditedProperty(null);
            setEditedPropertyProcedures(null);
            setEditedPropertyIndex(null);
          }}
          property={editedProperty}
          propertyNames={propertyNames}
          procedureIds={editedPropertyProcedures}
          index={editedPropertyIndex}
        />
      )}

      {!!assetPrototype && !!assetTypeEditError && (
        <FormEditErrorModal
          onClose={() => {
            dispatch(assetPrototypesEditErrorClear());
            setShowAssetTypeEditErrorModal(false);
          }}
          isOpen={showAssetTypeEditErrorModal}
          form={assetPrototype}
          errors={assetTypeEditError}
        />
      )}
    </Modal>
  );
};

export default AssetPrototypeModal;
