export const getRecentTasks = (state) => state.tasks.todoItems;
export const getReadyTasks = (state) => state.tasks.doneItems;
export const getBatchTasks = (state) => state.tasks.batchTasks;
export const getAssetTasks = (state) => state.tasks.assetTasks;
export const getRecurrenceTasks = (state) => state.tasks.recurrenceTasks;
export const getGroupTasks = (state) => state.tasks.groupTasks;

export const getAllTasks = (state) => {
  const { todoItems, doneItems } = state.tasks;
  return [...todoItems, ...doneItems];
};
