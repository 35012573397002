import Actions from "../actions";
import axios from "../auth";
import history from "../../history";
import { appUrls } from "../../urls";
import { createToast } from "../toast/toastActions";

const fetchCompaniesSuccess = (companies) => {
  return {
    type: Actions.FETCH_COMPANIES_SUCCESS,
    payload: companies,
  };
};

const selectCompanySuccess = (data) => {
  return {
    type: Actions.SELECT_COMPANIES_SUCCESS,
    payload: data,
  };
};

const selectCompanyFailure = (error) => {
  return {
    type: Actions.SELECT_COMPANIES_FAILURE,
    payload: error,
  };
};

const fetchCompaniesFailure = (error) => {
  return {
    type: Actions.FETCH_COMPANIES_FAILURE,
    payload: error,
  };
};

export const fetchCompanies = () => {
  return (dispatch) => {
    return axios
      .get(`/chooseCompany`)
      .then((response) => {
        dispatch(fetchCompaniesSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchCompaniesFailure(error));
      });
  };
};

export const selectCompany = (t, companyId, companyName) => {
  return (dispatch) => {
    const searchParams = history.location.search ?? "";
    return axios
      .post(`/submitCompany`, { companyId, companyName })
      .then(({ data }) => {
        dispatch(selectCompanySuccess(data));
        history.push(appUrls.REGION + searchParams);
      })
      .catch((error) => {
        dispatch(selectCompanyFailure(error));
        if (error) {
          dispatch(
            createToast({
              type: "error",
              title: t("this user's access to selected customer is disabled"),
            })
          );
        }
        history.push(appUrls.LOGIN + searchParams);
      });
  };
};
