import Element from '../../CanvasObjects/Abstract/Element';
import Vector from '../../Utils/Vector2';
class RenderableElement extends Element {
    constructor() {
        super(...arguments);
        this._position = new Vector(0, 0);
    }
    get position() { return this._position; }
    set position(position) { this._position = position; }
    get size() { return this._size; }
    set size(size) { this._size = size; }
    get ratio() { return this._ratio; }
    set ratio(ratio) { this._ratio = ratio; }
    get originalSize() { return this._originalSize; }
    set originalSize(originalSize) { this._originalSize = originalSize; }
    resizeToFitCanvas(context) {
        let widthRatio = this.size.width / context.canvas.width;
        let heightRatio = this.size.height / context.canvas.height;
        if (widthRatio > 1 || heightRatio > 1) {
            let scale = Math.max(widthRatio, heightRatio);
            this.size = {
                width: Math.floor(this.originalSize.width / scale),
                height: Math.floor(this.originalSize.height / scale)
            };
        }
    }
}
export default RenderableElement;
