export const PreconditionTypeApi = {
  composite: "composite_precondition",
  boolean: "boolean_precondition",
  date: "date_precondition",
  numerical: "numerical_precondition",
  selection: "selection_precondition",
  text: "text_precondition",
};

export const PreconditionType = {
  composite: "composite_precondition",
  boolean: "boolean_precondition",
  date: "date_precondition",
  numerical: "numerical_precondition",
  selection_single: "selection_precondition-single",
  selection_dropdown: "selection_precondition-dropdown",
  selection_multiple: "selection_precondition-multiple",
  text: "text_precondition",
};

export const PreconditionTypeMapping = {
  [PreconditionType.composite]: PreconditionTypeApi.composite,
  [PreconditionType.boolean]: PreconditionTypeApi.boolean,
  [PreconditionType.text]: PreconditionTypeApi.text,
  [PreconditionType.numerical]: PreconditionTypeApi.numerical,
  [PreconditionType.selection_single]: PreconditionTypeApi.selection,
  [PreconditionType.selection_dropdown]: PreconditionTypeApi.selection,
  [PreconditionType.selection_multiple]: PreconditionTypeApi.selection,
};
