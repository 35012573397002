import React, { useContext } from "react";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import styles from "./DeleteLayoutModal.module.scss";
import { I18nContext } from "../../i18n/I18nContext";

const DeleteLayoutModal = ({ isOpen, onClose, asset, action }) => {
  const { t } = useContext(I18nContext);

  const handleClear = () => {
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t(`delete asset plan`)}
      icon
      footer={
        <div className={styles.button_section}>
          <Button
            className={styles.btn_separator}
            onClick={handleClear}
            type="reset"
            variant="ghost"
            size="s"
          >
            {t("cancel")}
          </Button>
          <Button type="submit" variant="orange" size="s" onClick={action}>
            {t("delete")}
          </Button>
        </div>
      }
    >
      <div className={styles.delete_txt}>
        {t("are you sure you want to permanently delete the asset plan")}?
      </div>
    </Modal>
  );
};

export default DeleteLayoutModal;
