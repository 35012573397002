import React, { useCallback, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import cx from "classnames";
import {
  fetchAssetPrototypesUsedWithinCompany,
  fetchAssets,
  fetchFilteredAssets,
} from "../../redux";
import Modal from "../Modal";
import {
  FieldInput,
  FieldLabel,
  FieldSelect,
  Form,
  FormControl,
} from "../Input";
import CheckboxTreeTask from "../Tasks/CheckboxTreeTask";
import styles from "./RightsBuildingFilterModal.module.scss";
import Button from "../Button";
import { I18nContext } from "../../i18n/I18nContext";

/**
 * Remove duplicated from api
 */
const selectUniqueAssets = (state) => {
  const assets = state.assets.searchAssets;
  const uniqueItems = [];
  for (let item of assets) {
    if (!uniqueItems.find((i) => i.id === item.id)) {
      uniqueItems.push(item);
    }
  }
  return uniqueItems;
};

const prototypeToFields = (prototype) => {
  return prototype.assetProperties.properties.map((property) => {
    return {
      ...property,
      id: `${prototype.id}_${property.name}`,
    };
  });
};

const filterWithParents = (filtered, all, current = []) => {
  const parentIds = filtered.filter((f) => f.parentId).map((f) => f.parentId);
  const parents = all.filter((s) => parentIds.includes(s.id));

  for (const parent of parents) {
    if (!current.find((c) => c.id === parent.id)) {
      current.push(parent);
    }
  }

  if (parents.length) {
    return filterWithParents(parents, all, current);
  } else {
    return current;
  }
};

const createAssetsTree = (asset, allAssets, checked = []) => {
  const childrenIds = allAssets.filter((a) => a.parentId === asset.id);

  return childrenIds
    .map((asset) => {
      if (asset) {
        return {
          value: asset.id,
          label: asset.name,
          className: cx({
            "rtc-selected-asset": checked.includes(asset.id),
          }),
          children: createAssetsTree(asset, allAssets, checked),
        };
      }
      return null;
    })
    .filter((asset) => asset !== null);
};

const createTree = (assets, topAssets, checked) => {
  const top = topAssets;

  return top.map((asset) => {
    return {
      value: asset.id,
      label: asset.name,
      className: cx({
        "rtc-selected-asset": checked.includes(asset.id),
      }),
      children: createAssetsTree(asset, assets, checked),
    };
  });
};

const defaultState = {
  prototypeId: "",
  propertyId: "",
  assetName: "",
};

const RightsBuildingFilterModal = ({ isOpen, onClose, onCheck, checked }) => {
  const { t } = useContext(I18nContext);
  const dispatch = useDispatch();
  const [properties, setProperties] = useState([]);
  const [tree, setTree] = useState([]);
  const [initialValues, setInitialValues] = useState(defaultState);
  const [initiallyChecked, setInitiallyChecked] = useState([]);

  const prototypes = useSelector((state) => state.prototypes.withinCompany);
  const assetNameSearchOptions = useSelector(selectUniqueAssets);

  const handleSubmit = (values) => {
    onClose();
  };

  useEffect(() => {
    dispatch(fetchFilteredAssets({}));
  }, [dispatch]);

  useEffect(() => {
    setInitiallyChecked(checked);
  }, [isOpen]);

  useEffect(() => {
    const { prototypeId, assetName } = initialValues;

    const filtered = assetNameSearchOptions
      .filter((asset) => {
        if (prototypeId.length) {
          return asset.prototypeId === prototypeId;
        }
        return true;
      })
      .filter((asset) => {
        if (assetName.length) {
          return asset.name.toLowerCase().includes(assetName.toLowerCase());
        }
        return true;
      });

    const filteredWithParents = filterWithParents(
      filtered,
      assetNameSearchOptions,
      filtered
    );

    const tree = createTree(
      filteredWithParents,
      filteredWithParents.filter((asset) => asset.parentId === undefined),
      checked
    );
    setTree(tree);
  }, [initialValues, dispatch, onCheck, assetNameSearchOptions, checked]);

  const resetProperties = useCallback(
    () => setProperties(prototypes.map(prototypeToFields).flat()),
    [prototypes]
  );

  const handleClear = () => {
    setInitialValues(defaultState);
    onCheck([]);
    resetProperties();
    onClose();
  };

  const handleSelectAll = (e) => {
    e.preventDefault();
    const ids = tree.map((item) => item.value);
    onCheck(ids);
  };

  const handleDeselectAll = (e) => {
    e.preventDefault();
    onCheck([]);
  };

  useEffect(() => {
    resetProperties();
  }, [prototypes, resetProperties]);

  useEffect(() => {
    const tree = createTree(
      assetNameSearchOptions
        .filter((asset) => asset.isGrouping)
        ?.sort((a, b) => a.name.localeCompare(b.name)),
      assetNameSearchOptions
        .filter((asset) => asset.parentId === undefined)
        ?.sort((a, b) => a.name.localeCompare(b.name)),
      checked
    );
    setTree(tree);
  }, [assetNameSearchOptions, checked]);

  useEffect(() => {
    dispatch(fetchAssets());
    dispatch(fetchAssetPrototypesUsedWithinCompany());
  }, [dispatch]);

  return (
    <Modal
      className={styles.modal}
      isOpen={isOpen}
      onClose={onClose}
      title={t("grouping assets access")}
      key="GroupFiltersModalFilters"
      form="groupFilters-task-form"
      footer={
        <div className={styles.button_section}>
          <div>
            <Button
              onClick={() => {
                onCheck(initiallyChecked);
                onClose();
              }}
              variant="ghost"
              size="s"
            >
              {t("cancel")}
            </Button>
          </div>
          <div className={styles.buttons_group}>
            <Button
              className={styles.btn_separator}
              type="submit"
              variant="orange"
              size="s"
              form={"groupFilters-task-form"}
              data-testid="modal-create"
            >
              {t("apply selection")}
            </Button>
          </div>
        </div>
      }
    >
      <Form
        initialValues={initialValues}
        enableReinitialize
        id="groupFilters-task-form"
        onSubmit={handleSubmit}
      >
        <div className={styles.row}>
          <FormControl>
            <FieldLabel>{t("asset prototype")}</FieldLabel>
            <div className={styles.width_dropdown}>
              <FieldSelect
                size="s"
                name="prototypeId"
                options={prototypes.map((proto) => ({
                  label: proto.name,
                  value: proto.id,
                  global: proto.visibleOnAllCompanies,
                }))}
                onChange={(prototypeId) => {
                  const selected = prototypes.find(
                    (proto) => proto.id === prototypeId
                  );
                  setProperties(selected ? prototypeToFields(selected) : []);
                  setInitialValues({
                    ...initialValues,
                    prototypeId: selected ? selected.id : "",
                    propertyId: "",
                  });
                }}
                placeholder={t("choose prototype")}
                isClearable={true}
              />
            </div>
          </FormControl>
          <FormControl>
            <FieldLabel>{t("properties")}</FieldLabel>
            <div className={styles.width_dropdown}>
              <FieldSelect
                size="s"
                name="propertyId"
                options={properties.map((proto) => ({
                  label: proto.name,
                  value: proto.id,
                }))}
                onChange={(id) => {
                  const [prototypeId] = id ? id.split("_") : "";
                  const selected = prototypes.find(
                    (proto) => proto.id === prototypeId
                  );
                  setProperties(selected ? prototypeToFields(selected) : []);
                  setInitialValues({
                    ...initialValues,
                    propertyId: id,
                    prototypeId: prototypeId ? prototypeId : "",
                  });
                }}
                placeholder={t("choose name")}
                isClearable={true}
              />
            </div>
          </FormControl>
        </div>
        <FormControl className={styles.input_search}>
          <FieldInput
            name="assetName"
            icon
            onChange={(assetName) => {
              setInitialValues({
                ...initialValues,
                assetName,
              });
            }}
            type="text"
            placeholder={t("search asset by name")}
            size="l"
          />
        </FormControl>
        <hr className={styles.divider}></hr>

        <div className={styles.select_all_buttons}>
          <Button onClick={handleSelectAll} variant="ghost" size="s">
            {t("select all")}
          </Button>
          <Button
            className={styles.btn_separator}
            onClick={handleDeselectAll}
            variant="ghost"
            size="s"
          >
            {t("deselect all")}
          </Button>
        </div>

        <CheckboxTreeTask tree={tree} onCheck={onCheck} checked={checked} />
      </Form>
    </Modal>
  );
};

export default RightsBuildingFilterModal;
