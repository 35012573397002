import React, { useContext } from "react";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import styles from "./DeleteAttachmentsModal.module.scss";
import { I18nContext } from "../../i18n/I18nContext";

const DeleteSingleAttachmentModal = ({
  isOpen,
  onClose,
  onDelete,
  attachment,
}) => {
  const { t } = useContext(I18nContext);

  const handleClear = () => {
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t("delete file")}
      icon
      footer={
        <div className={styles.button_section}>
          <Button
            className={styles.btn_separator}
            onClick={handleClear}
            type="reset"
            variant="ghost"
            size="s"
          >
            {t("cancel")}
          </Button>
          <Button type="submit" variant="orange" size="s" onClick={onDelete}>
            {t("delete")}
          </Button>
        </div>
      }
    >
      <div className={styles.delete_txt}>
        {t("are you sure you want to permanently delete this attachment")}
        {attachment && (
          <div className={styles.spacing_txt}>{attachment.name} ?</div>
        )}
      </div>
    </Modal>
  );
};

export default DeleteSingleAttachmentModal;
