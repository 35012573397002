import Actions from "../actions";

const maintainers = {
  maintainerOrganizationsListError: null,
  maintainerOrganizationsList: [],
  maintainerOrganizationsListLoading: false,
  maintainerOrganizationsRightsListError: null,
  maintainerOrganizationsRightsList: [],
  maintainerOrganizationsRightsListLoading: false,
};

const maintainersReducer = (state = maintainers, action) => {
  switch (action.type) {
    case Actions.FETCH_MAINTAINER_ORGANIZATIONS_REQUEST:
      return {
        ...state,
        maintainerOrganizationsListLoading: true,
      };
    case Actions.FETCH_MAINTAINER_ORGANIZATIONS_ERROR: {
      return {
        ...state,
        maintainerOrganizationsListError: action.payload,
      };
    }
    case Actions.FETCH_MAINTAINER_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        maintainerOrganizationsList: action.payload,
      };

    case Actions.FETCH_MAINTAINER_ORGANIZATIONS_RIGHTS_REQUEST:
      return {
        ...state,
        maintainerOrganizationsRightsListLoading: true,
      };
    case Actions.FETCH_MAINTAINER_ORGANIZATIONS_RIGHTS_ERROR: {
      return {
        ...state,
        maintainerOrganizationsRightsListError: action.payload,
      };
    }
    case Actions.FETCH_MAINTAINER_ORGANIZATIONS_RIGHTS_SUCCESS:
      return {
        ...state,
        maintainerOrganizationsRightsList: action.payload,
      };
    default:
      return state;
  }
};

export default maintainersReducer;
