import React, { useContext, useEffect, useState } from "react";
import { FilterDropdown } from "../../components/Dropdown";
import PropTypes from "prop-types";
import { filterItems } from "./utils";
import styles from "./TaskFilteredDropdown.module.scss";
import { useSelector } from "react-redux";
import { I18nContext } from "../../i18n/I18nContext";
import ReactDatePicker from "react-datepicker";
import "./TaskFilteredDropdown.module.scss";
import { createAssetDisplayedPropertiesString } from "../../helpers/helpers";
import Button from "../Button";

const getUniqueItemsByProperties = (items, propName) => {
  return items.filter(
    (item, index, array) =>
      index ===
      array.findIndex((foundItem) => foundItem[propName] === item[propName])
  );
};

const TaskFilteredDropdown = ({
  onItemsFiltered,
  items,
  assetDropdown,
  openTasks,
  closedTasks,
  sort,
  toggleSort,
  defaultFrom,
  defaultTo,
  defaultTaskTypes,
  defaultState,
  nearlyMissedOnly,
}) => {
  const { t } = useContext(I18nContext);
  const [assetFilter, setAssetFilter] = useState(null);
  const [fromDateFilter, setFromDateFilter] = useState(
    defaultFrom ? new Date(defaultFrom) : null
  );
  const [toDateFilter, setToDateFilter] = useState(
    defaultTo ? new Date(defaultTo) : null
  );
  const [templateFilter, setTemplateFilter] = useState(
    defaultTaskTypes ? defaultTaskTypes : []
  );
  const [stateFilter, setStateFilter] = useState(
    defaultState ? defaultState : []
  );
  const [nearlyMissedFilter, setNearlyMissedFilter] = useState(
    !!nearlyMissedOnly
  );

  const assetNames = useSelector((state) => state.assets.items ?? []);
  const templatePrototypes = useSelector(
    (state) => state.taskPrototypes.items ?? []
  );

  useEffect(() => {
    if (items) {
      onItemsFiltered(
        filterItems(
          items,
          fromDateFilter,
          toDateFilter,
          stateFilter,
          templateFilter,
          assetFilter,
          closedTasks,
          nearlyMissedFilter
        ),
        {
          fromDateFilter,
          toDateFilter,
          stateFilter,
          templateFilter,
          assetFilter,
          nearlyMissedFilter,
        }
      );
    }
  }, [
    items,
    fromDateFilter,
    toDateFilter,
    stateFilter,
    templateFilter,
    onItemsFiltered,
    assetFilter,
    nearlyMissedFilter,
  ]);

  const stateOptions = openTasks
    ? [
        { value: "TODO", label: t("one-off tasks due") },
        { value: "OVERDUE", label: t("one-off tasks overdue") },
        { value: "RECURRING", label: t("recurring tasks") },
        { value: "SUSPENDED", label: t("suspended tasks") },
      ]
    : [
        { value: "COMPLETED", label: t("ready") },
        { value: "DONE_TOO_LATE", label: t("done too late") },
        { value: "MISSED", label: t("missed") },
        { value: "CANCELLED", label: t("cancelled") },
        { value: "CANT_DO", label: t("cant do") },
      ];

  const templateOptions =
    items
      ?.sort((a, b) => a.name.localeCompare(b.name))
      ?.map(({ prototypeId }) => {
        return {
          value: prototypeId,
          label: templatePrototypes.find(
            (template) => template.id === prototypeId
          )?.name,
          global: templatePrototypes.find(
            (template) => template.id === prototypeId
          )?.global,
        };
      }) ?? [];

  const nameOptions =
    items
      ?.filter((item) => item.assetId)
      ?.sort((a, b) => a.name.localeCompare(b.name))
      ?.map(({ assetId, assetName }) => ({
        value: assetId,
        label: assetName,
        asset: assetNames.find((x) => x.id === assetId),
      })) ?? [];

  const customAssetOptionLabel = (option) => {
    const isPath = !!option.asset?.path && !!option.asset?.path.length;
    const isProperties =
      !!option.asset?.displayedProperties &&
      !!option.asset?.displayedProperties.length;
    return (
      <div className={styles.assetOptionLabel}>
        <div className={styles.assetOptionName}>
          {option.label}
          {isProperties
            ? createAssetDisplayedPropertiesString(option.asset, t)
            : ""}
        </div>
        <div className={styles.assetOptionPath}>
          {isPath ? option.asset.path.join(" -> ") : ""}
        </div>
      </div>
    );
  };

  return (
    <div className={styles.row}>
      {sort && toggleSort && (
        <Button onClick={toggleSort} variant="orange" size="s">
          {sort === "date"
            ? t("sort by path")
            : openTasks
            ? t("sort by due date")
            : t("sort by finish date")}
        </Button>
      )}
      {assetDropdown && (
        <FilterDropdown
          value={assetFilter}
          options={getUniqueItemsByProperties(nameOptions, "value")}
          onSelect={(value) => setAssetFilter(value)}
          data-testid={`select-name`}
          placeholder={t("asset")}
          customLabel={customAssetOptionLabel}
        />
      )}
      <div className={styles.date_wrapper}>
        <div className={styles.date_label}>{t("period from")}:</div>
        <ReactDatePicker
          wrapperClassName={styles.wrapper}
          selected={fromDateFilter}
          onChange={(value) => setFromDateFilter(value)}
          placholder={t("period from")}
          dateFormat="dd-MM-yyyy"
        />
        <div className={styles.date_label}>{t("period to", "lower")}:</div>
        <ReactDatePicker
          wrapperClassName={styles.wrapper}
          selected={toDateFilter}
          onChange={(value) => setToDateFilter(value)}
          placholder={t("to")}
          dateFormat="dd-MM-yyyy"
        />
      </div>
      <FilterDropdown
        value={stateFilter}
        options={getUniqueItemsByProperties(stateOptions, "value")}
        onSelect={(value) => setStateFilter(value)}
        placeholder={t("task state")}
        isMultiple={true}
      />
      <FilterDropdown
        value={templateFilter}
        options={getUniqueItemsByProperties(templateOptions, "value")}
        onSelect={(value) => setTemplateFilter(value)}
        placeholder={t("task template")}
        isMultiple={true}
      />
      {openTasks && (
        <div className={styles.nearly_missed_filter}>
          <label
            className={styles.input_label_checkbox}
            htmlFor={"nearlyMissedFilter"}
          >
            {t("show only nearly missed tasks")}
          </label>
          <input
            id="nearlyMissedFilter"
            name="nearlyMissedFilter"
            type="checkbox"
            className={styles.checkbox}
            onChange={(e) => setNearlyMissedFilter(!nearlyMissedFilter)}
            checked={nearlyMissedFilter}
          />
        </div>
      )}
    </div>
  );
};

TaskFilteredDropdown.propTypes = {
  assetDropdown: PropTypes.bool,
  items: PropTypes.array,
};

export default TaskFilteredDropdown;
