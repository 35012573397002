import React, { useContext, useState, useEffect } from "react";
import styles from "./AttachmentsExplorerModal.module.scss";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../components/Modal";
import { I18nContext } from "../../i18n/I18nContext";
import ActivityIndicator from "../ActivityIndicator";
import {
  fetchAttachmentsList,
  fetchAttachmentsFoldersList,
  fetchAttachmentsTypesList,
  deleteSingleAttachment,
  downloadAttachmentsInZip,
  addAttachment,
  deleteMultipleSelectedAttachments,
  fetchAssetDetails,
} from "../../redux";
import Select from "react-select";
import Icon from "../Icon";
import ReactDatePicker from "react-datepicker";
import { formatDate } from "../../helpers/helpers";
import DeleteSingleAttachmentModal from "../AssetDetails/DeleteSingleAttachmentModal";
import axios from "../../redux/auth";
import Button from "../Button";
import AttachmentModal from "./AttachmentModal";
import DeleteAttachmentsModal from "../AssetDetails/DeleteAttachmentsModal";
import CheckIcon from "@mui/icons-material/Check";

const focusColor = "#EE7203";

const AttachmentsExplorerModal = ({ onClose, isOpen, assetId, assetName }) => {
  const { t } = useContext(I18nContext);
  const dispatch = useDispatch();
  const { pending, items, folders, types, pendingDelete } = useSelector(
    (state) => state.attachments
  );
  const [filters, setFilters] = useState({
    folder: null,
    type: null,
    date: null,
  });
  const [selectedAttachments, setSelectedAttachments] = useState([]);
  const [openAddAttachmentModal, setOpenAddAttachmentModal] = useState(false);
  const [openEditAttachmentModal, setOpenEditAttachmentModal] = useState(false);
  const [openDeleteAttachmentsModal, setOpenDeleteAttachmentsModal] =
    useState(false);
  const [openDeleteSingleAttachmentModal, setOpenDeleteSingleAttachmentModal] =
    useState(false);
  const [currentFile, setCurrentFile] = useState(null);
  const [showUploadComponent, setShowUploadComponent] = useState(null);
  const [dragActive, setDragActive] = useState(false);

  useEffect(() => {
    if (!!isOpen) {
      dispatch(fetchAttachmentsFoldersList());
      dispatch(fetchAttachmentsTypesList());
      dispatch(fetchAttachmentsList(assetId));
    }
  }, [isOpen]);

  const foldersOptions = folders.map((folder) => ({
    value: folder.id,
    label: folder.name,
  }));

  const typesOptions = types.map((type) => ({
    value: type.id,
    label: type.name,
  }));

  const handleClose = async () => {
    setFilters({
      folder: null,
      type: null,
      date: null,
    });
    setCurrentFile(null);
    setSelectedAttachments([]);
    await dispatch(fetchAssetDetails(assetId));
    onClose();
  };

  const customStyles = {
    option: (styles, { data }) => {
      return {
        ...styles,
        display: "flex",
        alignItems: "center",
        color: "#393939",
        height: "auto",
        backgroundColor: data.global ? "#fff8f1" : "#ffffff",
        "&:hover": {
          backgroundColor: "#eaeaea",
          color: "#393939",
          cursor: "pointer",
        },
      };
    },
    menu: (provided) => {
      return {
        ...provided,
        border: `1px solid ${focusColor}`,
        borderRadius: "6px",
      };
    },
    container: (provided, state) => {
      return {
        ...provided,
        width: "230px",
        color: "#393939",
        borderRadius: "6px",
        marginRight: "12px",
        fontSize: "14px",
        lineHeight: "16px",
      };
    },
    control: (base, state, provided) => ({
      ...provided,
      paddingLeft: "8px",
      fontWeight: "400",
      fontSize: "16px",
      height: "36px",
      dropdownIndicator: "red",
      ...base,
      boxShadow: state.isFocused ? 0 : 0,
      borderColor: state.isFocused ? focusColor : base.borderColor,
      "&:hover": {
        borderColor: state.isFocused ? focusColor : base.borderColor,
        cursor: "pointer",
      },
      borderRadius: "6px",
    }),
    indicatorSeparator: (provided) => {
      return {
        ...provided,
        backgroundColor: "none",
      };
    },
    dropdownIndicator: (base, state) => {
      return {
        ...base,
        color: state.isFocused ? "#EE7203" : "#AAAAAA",
      };
    },
    placeholder: (provided) => {
      return {
        ...provided,
        marginLeft: "2px",
        color: "#AAAAAA",
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "16px",
      };
    },
  };

  const applyFilter = (type, value) => {
    const newFilters = { ...filters };
    newFilters[type] = value;
    setFilters(newFilters);
  };
  const filterAssets = (assets) => {
    return !!filters.folder || !!filters.type || filters.date
      ? assets.filter((asset) => filterAttachments(asset.attachments).length)
      : [...assets];
  };

  const filterAttachments = (attachments) => {
    const filteredByFolder = !!filters.folder
      ? attachments.filter(
          (att) => att.attachmentFolder?.id === filters.folder.value
        )
      : [...attachments];

    const filteredByType = !!filters.type
      ? filteredByFolder.filter((att) => att.type?.id === filters.type.value)
      : [...filteredByFolder];

    return !!filters.date
      ? filteredByType.filter((att) => att.date <= formatDate(filters.date))
      : [...filteredByType];
  };

  const selectAttachment = (attachmentId) => {
    const newSelectedAttachments = [...selectedAttachments];

    if (newSelectedAttachments.includes(attachmentId)) {
      const index = newSelectedAttachments.indexOf(attachmentId);
      newSelectedAttachments.splice(index, 1);
    } else {
      newSelectedAttachments.push(attachmentId);
    }

    setSelectedAttachments(newSelectedAttachments);
  };

  const selectMultipleAttachments = (attachmentIds) => {
    const newSelectedAttachments = [...selectedAttachments];

    if (checkIfAllAreSelected(attachmentIds)) {
      attachmentIds.forEach((attachmentId) => {
        const index = newSelectedAttachments.indexOf(attachmentId);
        newSelectedAttachments.splice(index, 1);
      });
    } else {
      attachmentIds.forEach((attachmentId) => {
        if (!newSelectedAttachments.includes(attachmentId)) {
          newSelectedAttachments.push(attachmentId);
        }
      });
    }

    setSelectedAttachments(newSelectedAttachments);
  };

  const checkIfAllAreSelected = (attachmentIds) => {
    let unselectedCount = 0;
    attachmentIds.forEach((attachmentId) => {
      if (!selectedAttachments.includes(attachmentId)) {
        unselectedCount++;
      }
    });
    return unselectedCount === 0;
  };

  const selectMultipleAttachmentsForAllAssets = (idsLists) => {
    const newSelectedAttachments = [...selectedAttachments];

    if (checkIfAllAreSelectedForAllAssets(idsLists)) {
      idsLists.forEach((idsList) => {
        idsList.forEach((attachmentId) => {
          const index = newSelectedAttachments.indexOf(attachmentId);
          newSelectedAttachments.splice(index, 1);
        });
      });
    } else {
      idsLists.forEach((idsList) => {
        idsList.forEach((attachmentId) => {
          if (!newSelectedAttachments.includes(attachmentId)) {
            newSelectedAttachments.push(attachmentId);
          }
        });
      });
    }

    setSelectedAttachments(newSelectedAttachments);
  };

  const checkIfAllAreSelectedForAllAssets = (idsLists) => {
    let unselectedCount = 0;
    idsLists.forEach((idsList) => {
      idsList.forEach((attachmentId) => {
        if (!selectedAttachments.includes(attachmentId)) {
          unselectedCount++;
        }
      });
    });
    return unselectedCount === 0;
  };

  const openAttachment = (attachment) => {
    axios
      .get(`/files/getBytes?key=${attachment.key}&name=${attachment.name}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const url = URL.createObjectURL(
          new Blob([response.data], {
            type:
              attachment.attachmentFileType === "DOCUMENT" &&
              attachment.key.split(".").pop() !== "gif"
                ? "application/pdf"
                : `image/${attachment.key.split(".").pop()}`,
          })
        );
        window.open(url);
      })
      .catch((error) => console.log(error));
  };

  const downloadAttachment = (attachment) => {
    axios
      .get(`/files/getBytes?key=${attachment.key}&name=${attachment.name}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.download = attachment.name + "." + attachment.key.split(".").pop();
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => console.log(error));
  };

  const handleDelete = async (attachment) => {
    await dispatch(
      deleteSingleAttachment(t, currentFile.assetId, attachment.key)
    );
    await dispatch(fetchAttachmentsList(assetId));
    handleCloseDeleteSingleFileModal();
  };

  const deleteSelectedAttachments = async () => {
    await dispatch(deleteMultipleSelectedAttachments(t, selectedAttachments));
    await dispatch(fetchAttachmentsList(assetId));
    handleCloseDeleteAttachmentsModal();
  };

  const handleOpenDeleteSingleFileModal = (attachment) => {
    setCurrentFile(attachment);
    setOpenDeleteSingleAttachmentModal(true);
  };

  const handleCloseDeleteSingleFileModal = () => {
    setCurrentFile(null);
    setOpenDeleteSingleAttachmentModal(false);
  };

  const handleDownloadSelectedFilesClick = async () => {
    if (selectedAttachments.length) {
      const url = await dispatch(
        downloadAttachmentsInZip(t, selectedAttachments)
      );
      const link = document.createElement("a");
      link.href = url;
      link.download = `attachments.zip`;
      document.body.appendChild(link);
      link.click();
    }
  };

  const handleOpenAddAttachmentModal = () => {
    setOpenAddAttachmentModal(true);
  };

  const handleCloseAddAttachmentModal = () => {
    setOpenAddAttachmentModal(false);
  };

  const handleOpenDeleteAttachmentsModal = () => {
    setOpenDeleteAttachmentsModal(true);
  };

  const handleCloseDeleteAttachmentsModal = () => {
    setOpenDeleteAttachmentsModal(false);
  };

  const handleOpenEditAttachmentModal = (attachment) => {
    setCurrentFile(attachment);
    setOpenEditAttachmentModal(true);
  };

  const handleCloseEditAttachmentModal = () => {
    setCurrentFile(null);
    setOpenEditAttachmentModal(false);
  };

  const toggleShowUploadComponentClick = (assetIndex) => {
    if (assetIndex !== showUploadComponent) {
      setShowUploadComponent(assetIndex);
    } else {
      setShowUploadComponent(null);
    }
  };

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length) {
      for (const [key, value] of Object.entries(e.dataTransfer.files)) {
        const fileInfo = {
          folderId: filters.folder?.value ?? "",
          typeId: filters.type?.value ?? "",
          name: value.name,
          date: new Date(),
          isSignificant: false,
        };
        await dispatch(
          addAttachment(
            t,
            showUploadComponent ? showUploadComponent : assetId,
            value,
            fileInfo
          )
        );
      }
      setDragActive(false);
      setShowUploadComponent(false);
      await dispatch(fetchAttachmentsList(assetId));
    }
  };

  const handleFileAdd = async (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files.length) {
      for (let i = 0; i < e.target.files; i++) {
        const fileInfo = {
          folderId: filters.folder?.value ?? "",
          typeId: filters.type?.value ?? "",
          name: e.target.files[i].name,
          date: new Date(),
        };
        await dispatch(
          addAttachment(
            t,
            showUploadComponent ? showUploadComponent : assetId,
            e.target.files[i],
            fileInfo
          )
        );
      }
    }
    dispatch(fetchAttachmentsList(assetId));
  };

  return (
    <Modal
      className={styles.modal}
      isOpen={isOpen}
      onClose={handleClose}
      title={t("attachments explorer", "title") + " - " + assetName}
      divider
      previewMode
    >
      {pending && (
        <div className={styles.isLoading}>
          <ActivityIndicator />
        </div>
      )}
      {!pending && (
        <div className={styles.explorer}>
          <div className={styles.actionsBar}>
            <div className={styles.filters}>
              <Select
                value={filters.folder}
                isClearable={true}
                styles={customStyles}
                isSearchable={false}
                isMulti={false}
                defaultValue={null}
                options={foldersOptions}
                onChange={(value) => applyFilter("folder", value)}
                placeholder={t("attachment folder")}
                noOptionsMessage={() => t("no options")}
              />
              <Select
                value={filters.type}
                isClearable={true}
                styles={customStyles}
                isSearchable={false}
                isMulti={false}
                defaultValue={null}
                options={typesOptions}
                onChange={(value) => applyFilter("type", value)}
                placeholder={t("attachment type")}
                noOptionsMessage={() => t("no options")}
              />
              <div className={styles.datePickerContainer}>
                <ReactDatePicker
                  wrapperClassName={styles.datePickerWrapper}
                  showTimeSelect={false}
                  selected={filters.date}
                  onChange={(value) => applyFilter("date", value)}
                  placholder={t("date")}
                  dateFormat="dd-MM-yyyy"
                />
                <Icon name="calendar" className={styles.datePickerIcon} />
              </div>
            </div>
            <Button
              onClick={handleOpenAddAttachmentModal}
              variant="orange"
              size="s"
              margin="right"
            >
              {t("add attachment")}
            </Button>
            <Button
              onClick={handleDownloadSelectedFilesClick}
              variant={selectedAttachments.length ? "orange" : "disabled"}
              size="s"
              margin="right"
            >
              {t("download selected files")}
            </Button>
            <Button
              onClick={handleOpenDeleteAttachmentsModal}
              variant={selectedAttachments.length ? "orange" : "disabled"}
              size="s"
            >
              {t("delete selected files")}
            </Button>
          </div>
          {!showUploadComponent && (
            <div className={styles.parentAssetUpload}>
              <form
                className={styles.uploadInputContainer}
                onDragEnter={handleDrag}
                onSubmit={(e) => e.preventDefault()}
              >
                <input
                  type="file"
                  id="file-upload"
                  multiple={true}
                  onChange={handleFileAdd}
                />
                <label
                  className={
                    !dragActive ? styles.uploadText : styles.uploadTextActive
                  }
                  htmlFor="file-upload"
                >
                  {t("drag and drop files to upload")}
                </label>
                {dragActive && (
                  <div
                    className={styles.dragFile}
                    onDragEnter={handleDrag}
                    onDragLeave={handleDrag}
                    onDragOver={handleDrag}
                    onDrop={handleDrop}
                  ></div>
                )}
              </form>
            </div>
          )}
          {items.length > 1 && (
            <div className={styles.selectAllWrapper}>
              <div className={styles.attachmentsCheckbox}>
                <div
                  className={
                    checkIfAllAreSelectedForAllAssets(
                      items.map((item) =>
                        item.attachments.map((attachment) => attachment.id)
                      )
                    )
                      ? styles.checkboxChecked
                      : styles.checkbox
                  }
                  onClick={() =>
                    selectMultipleAttachmentsForAllAssets(
                      items.map((item) =>
                        item.attachments.map((attachment) => attachment.id)
                      )
                    )
                  }
                >
                  {checkIfAllAreSelectedForAllAssets(
                    items.map((item) =>
                      item.attachments.map((attachment) => attachment.id)
                    )
                  ) ? (
                    <span>&#x2713;</span>
                  ) : null}
                </div>
              </div>
              <div className={styles.checkboxLabel}>
                {checkIfAllAreSelectedForAllAssets(
                  items.map((item) =>
                    item.attachments.map((attachment) => attachment.id)
                  )
                )
                  ? t("deselect all")
                  : t("select all")}
              </div>
            </div>
          )}
          <div className={styles.list}>
            {filterAssets(items)
              .sort((a, b) => a.path.join().localeCompare(b.path.join()))
              .map((item, index) => (
                <div className={styles.listItem} key={index}>
                  <div className={styles.row}>
                    <div className={styles.name}>{item.name}</div>
                    <div className={styles.path}>{item.path.join(" -> ")}</div>

                    <div
                      className={styles.uploadButton}
                      onClick={() =>
                        toggleShowUploadComponentClick(item.assetId)
                      }
                    >
                      {showUploadComponent === item.assetId ? (
                        <Icon name="close" className={styles.iconClose} />
                      ) : (
                        <Icon name="upload" className={styles.iconUpload} />
                      )}
                    </div>
                  </div>
                  {showUploadComponent === item.assetId && (
                    <form
                      className={styles.uploadInputContainer}
                      onDragEnter={handleDrag}
                      onSubmit={(e) => e.preventDefault()}
                    >
                      <input
                        type="file"
                        id="file-upload"
                        multiple={true}
                        onChange={handleFileAdd}
                      />
                      <label
                        className={
                          !dragActive
                            ? styles.uploadText
                            : styles.uploadTextActive
                        }
                        htmlFor="file-upload"
                      >
                        {t("drag and drop files to upload")}
                      </label>
                      {dragActive && (
                        <div
                          className={styles.dragFile}
                          onDragEnter={handleDrag}
                          onDragLeave={handleDrag}
                          onDragOver={handleDrag}
                          onDrop={handleDrop}
                        ></div>
                      )}
                    </form>
                  )}
                  <div className={styles.row}>
                    <div className={styles.attachments}>
                      <div className={styles.attachmentsHeader}>
                        <div className={styles.attachmentsHeaderCheckbox}>
                          <div
                            className={
                              checkIfAllAreSelected(
                                item.attachments.map(
                                  (attachment) => attachment.id
                                )
                              )
                                ? styles.checkboxChecked
                                : styles.checkbox
                            }
                            onClick={() =>
                              selectMultipleAttachments(
                                item.attachments.map(
                                  (attachment) => attachment.id
                                )
                              )
                            }
                          >
                            {checkIfAllAreSelected(
                              item.attachments.map(
                                (attachment) => attachment.id
                              )
                            ) ? (
                              <span>&#x2713;</span>
                            ) : null}
                          </div>
                        </div>
                        <div className={styles.attachmentsHeaderItem}>
                          {t("attachment name")}
                        </div>
                        <div className={styles.attachmentsHeaderItem}>
                          {t("attachment folder")}
                        </div>
                        <div className={styles.attachmentsHeaderItem}>
                          {t("attachment type")}
                        </div>
                        <div className={styles.attachmentsHeaderItem}>
                          {t("attachment date")}
                        </div>
                        <div className={styles.attachmentsHeaderItem}>
                          {t("significant")}
                        </div>
                      </div>
                      {filterAttachments(item.attachments).map(
                        (attachment, index) => (
                          <div className={styles.attachmentItem} key={index}>
                            <div className={styles.attachmentItemCheckbox}>
                              <div
                                className={
                                  selectedAttachments.includes(attachment.id)
                                    ? styles.checkboxChecked
                                    : styles.checkbox
                                }
                                onClick={() => selectAttachment(attachment.id)}
                              >
                                {selectedAttachments.includes(attachment.id) ? (
                                  <span>&#x2713;</span>
                                ) : null}
                              </div>
                            </div>
                            <div className={styles.attachmentItemField}>
                              {attachment?.name}
                            </div>
                            <div className={styles.attachmentItemField}>
                              {attachment?.attachmentFolder?.name}
                            </div>
                            <div className={styles.attachmentItemField}>
                              {attachment?.type?.name}
                            </div>
                            <div className={styles.attachmentItemField}>
                              {attachment?.date}
                            </div>
                            <div className={styles.attachmentItemField}>
                              {attachment?.isSignificant ? <CheckIcon /> : ""}
                            </div>
                            <div className={styles.attachmentItemActionsField}>
                              <div
                                className={styles.actionButton}
                                onClick={() => openAttachment(attachment)}
                              >
                                {(attachment?.attachmentFileType === "IMAGE" ||
                                  (attachment?.attachmentFileType ===
                                    "DOCUMENT" &&
                                    attachment.key.split(".").pop() ===
                                      "pdf") ||
                                  (attachment?.attachmentFileType ===
                                    "DOCUMENT" &&
                                    attachment.key.split(".").pop() ===
                                      "gif")) && (
                                  <Icon
                                    name="eye-grey"
                                    className={styles.icon}
                                  />
                                )}
                              </div>
                              <div
                                className={styles.actionButton}
                                onClick={() => downloadAttachment(attachment)}
                              >
                                <Icon
                                  name="download-grey"
                                  className={styles.icon}
                                />
                              </div>
                              <div
                                className={styles.actionButton}
                                onClick={() =>
                                  handleOpenEditAttachmentModal(attachment)
                                }
                              >
                                <Icon name="edit" className={styles.icon} />
                              </div>
                              <div
                                className={styles.actionButton}
                                onClick={() =>
                                  handleOpenDeleteSingleFileModal(attachment)
                                }
                              >
                                <Icon name="trash" className={styles.icon} />
                              </div>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}
      <AttachmentModal
        isOpen={openAddAttachmentModal}
        onClose={handleCloseAddAttachmentModal}
        assetId={assetId}
      />
      <AttachmentModal
        isOpen={openEditAttachmentModal}
        onClose={handleCloseEditAttachmentModal}
        attachment={currentFile}
        assetId={assetId}
      />
      <DeleteSingleAttachmentModal
        isOpen={openDeleteSingleAttachmentModal}
        onClose={handleCloseDeleteSingleFileModal}
        onDelete={() => handleDelete(currentFile)}
        attachment={currentFile}
      />
      <DeleteAttachmentsModal
        isOpen={openDeleteAttachmentsModal}
        onClose={handleCloseDeleteAttachmentsModal}
        onDeleteAll={deleteSelectedAttachments}
        pending={pendingDelete || pending}
        selected
      />
    </Modal>
  );
};

export default AttachmentsExplorerModal;
