import axios from "../auth";

export const getSensorsByIds = (sensorIdArray) => {
  return axios.get(`/sensors/getByIds`, sensorIdArray);
};

export const createSensor = (sensorObject) => {
  return axios.post(`/sensors/create`, sensorObject);
};

export const editSensor = (sensorId, sensorObject) => {
  return axios.put(`/sensors/${sensorId}`, sensorObject);
};

export const getSensorToEdit = (sensorId) => {
  return axios.get(`/sensors/getById?sensorId=${sensorId}`);
};

export const detachSensor = (sensorId) => {
  return axios.delete(`/sensors/${sensorId}`);
};

export const getSensorsMeasurements = (assetId) => {
  return axios.get(`/measurements/getAverages?assetId=${assetId}`);
};

export const getAllDataSensorMeasurements = (sensorId) => {
  return axios.get(`/measurements/getAll?sensorId=${sensorId}`);
};

export const getVolatilityData = (sensorId) => {
  return axios.get(`/volatilityList?sensorId=${sensorId}`);
};
