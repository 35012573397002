import React, { useContext } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import MeatballMenu from "./MeatballMenu";
import Icon from "../Icon";
import styles from "./CompanyRowForm.module.scss";
import { I18nContext } from "../../i18n/I18nContext";

const CompanyRowForm = ({
  company,
  onEdit,
  meatballOpened,
  meatballOpenedObserver,
}) => {
  const { t } = useContext(I18nContext);
  const role = useSelector((state) => state.auth.info.role);

  const meatballMenuItems = [
    {
      label: t("edit company"),
      show: true,
      icon: <Icon name="editPencil-orange" className={styles.icon_menu} />,
      onClick: () => {
        onEdit();
      },
    },
    // {
    //   label: t("delete company"),
    //   show: true,
    //   icon: <Icon name="trash-orange" className={styles.icon_menu} />,
    //   onClick: () => null,
    // },
  ];

  return (
    <>
      <div className={styles.box_container}>
        <div className={styles.company_info}>
          <span className={styles.no_img}>{company.name[0].toUpperCase()}</span>
          <span className={styles.txt_name}>{company.name}</span>
        </div>
        <div className={styles.container}>
          {role === "SUPER_ADMIN" && (
            <span className={styles.meatball}>
              <MeatballMenu
                items={meatballMenuItems}
                id={company.id}
                meatballOpened={meatballOpened}
                meatballOpenedObserver={meatballOpenedObserver}
              />
            </span>
          )}
        </div>
      </div>
    </>
  );
};

CompanyRowForm.propTypes = {
  showMaintainerSection: PropTypes.bool,
};

export default CompanyRowForm;
