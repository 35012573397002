import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import cx from "classnames";
import PropTypes from "prop-types";

import styles from "./Toast.module.scss";
import { deleteToast } from "../../redux";

const toastMessage = (toast) => {
  switch (toast.type) {
    case "success":
      return {
        ...toast,
        backgroundColor: "#37833b",
      };
    case "error":
      return {
        ...toast,
        backgroundColor: "#d2042d",
      };
    case "warning":
      return {
        ...toast,
        backgroundColor: "#ee7203",
      };

    default:
      return toast;
  }
};

const Toast = ({ position, autoDelete = true, dismissTime = 7000 }) => {
  const dispatch = useDispatch();
  const toastList = useSelector((state) =>
    state.toast.map((toast) => toastMessage(toast))
  );

  useEffect(() => {
    const interval = setInterval(() => {
      if (autoDelete && toastList.length) {
        dispatch(deleteToast(toastList[0].id));
      }
    }, dismissTime);

    return () => {
      clearInterval(interval);
    };
  }, [toastList, autoDelete, dismissTime, dispatch]);

  return (
    <div className={cx(styles["notification-container"], styles[position])}>
      {toastList.map((toast, i) => (
        <div
          key={i}
          className={cx(styles.notification, styles.toast, styles[position])}
          style={{
            backgroundColor: toast.backgroundColor,
            color: toast.color,
          }}
        >
          <button
            className={styles.close}
            onClick={() => dispatch(deleteToast(toast.id))}
          >
            <div>x</div>
          </button>
          <div className={styles["notification-body"]}>
            <div className={styles["notification-title"]}>
              {toast.title + " "}
            </div>
            <div className={styles["notification-message"]}>
              {toast.description}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

Toast.propTypes = {
  position: PropTypes.string,
  autoDelete: PropTypes.bool,
  dismissTime: PropTypes.number,
};

Toast.defaultProps = {
  position: "top-right",
};

export default Toast;
