import React from "react";
import Dashboard from "../../layouts/Dashboard";
import HelpContent from "../../components/Help/HelpContent";

const Help = () => (
  <Dashboard>
    <HelpContent />
  </Dashboard>
);

export default Help;
