import styles from "./PieChartLegendItem.module.scss";

const PieChartLegendItem = ({ item, tasksCount, texts, colors, statusOfOpen }) => {
  const percentage = Math.round((item.count / tasksCount) * 100);

  return (
    <div className={styles.legendWrapper}>
      <div className={styles.line}>
        <div
          className={styles.square}
          style={{backgroundColor: colors[statusOfOpen && item.isRecurring ? "RECURRING" : item.state]}}
        />
        <span className={styles.status}>
          {texts[statusOfOpen && item.isRecurring ? "RECURRING" : item.state]}
        </span>
        <span className={styles.amountPercentage}>- {percentage}%</span>
        <span className={styles.amount}>({item.count})</span>
      </div>
    </div>
  );
};

export default PieChartLegendItem;
