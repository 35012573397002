import React, { useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";

import Icon from "../../components/Icon";
import Entry from "../../layouts/Entry";

import { useSelector, useDispatch } from "react-redux";
import styles from "./RequestEmailChange.module.scss";
import { requestEmailChangeConfirmation } from "../../redux";
import { I18nContext } from "../../i18n/I18nContext";

const useToken = () => {
  const { pathname } = useLocation();
  const token = pathname.split("/").pop();

  return token;
};

const RequestEmailChange = () => {
  const { t, language, changeLanguage } = useContext(I18nContext);
  const dispatch = useDispatch();
  const token = useToken();

  const hasError = useSelector(
    (state) => state.auth.showRequestEmailChangeError
  );

  const searchParams = new URLSearchParams(document.location.search);
  const languageCode = searchParams.get("languageCode");

  useEffect(() => {
    if (!!languageCode && language !== languageCode) {
      changeLanguage(languageCode);
    }
  }, []);

  useEffect(() => {
    submitEmailChangeRequest();
  }, []);

  const submitEmailChangeRequest = async () => {
    dispatch(requestEmailChangeConfirmation(token));
  };

  return (
    <Entry>
      <div className={styles.container}>
        {!hasError ? (
          <span className={styles.icon_circle}>
            <Icon className={styles.icon} name="envelope-orange" />
          </span>
        ) : (
          <span className={styles.icon_circleRed}>
            <Icon className={styles.icon} name="warning" />
          </span>
        )}
        {!hasError ? (
          <div className={styles.info}>
            <div className={styles.header}>
              {t("email address change requested")}
            </div>
            <div className={styles.txt_container_no_margin_bottom}>
              {t(
                "you will now receive an email with a link to validate this change"
              )}
              .
            </div>
            <div className={styles.txt_container_no_margin_bottom}>
              {t("this link is valid for 15 minutes")}.
            </div>
            <div className={styles.txt_container}>
              {t(
                "if you don't click this link within 15 minutes, the change of your email address won't be saved"
              )}
              .
            </div>
          </div>
        ) : (
          <div className={styles.error}>
            <div className={styles.header}>
              {t("email change request error")}
            </div>
            <div className={styles.txt_container_no_margin_bottom}>
              {t(
                "there has been an error sending email change activation request"
              )}
              .
            </div>
            <div className={styles.txt_container}>
              {t("please try again or contact an administrator")}.
            </div>
          </div>
        )}
      </div>
    </Entry>
  );
};

export default RequestEmailChange;
