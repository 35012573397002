import MoveableElement from "../../CanvasObjects/Abstract/MoveableElement";
import Vector from "../../Utils/Vector2";
import CanvasElementTypes from "../../CanvasObjects/types";
class Poi extends MoveableElement {
  constructor(_image, config, _colors, scale) {
    super(CanvasElementTypes.Poi);
    this._image = _image;
    this._colors = _colors;
    this._id = config.id;
    this._name = config.name;
    this._tasksCount = config.tasksCount;
    this.originalSize = { width: _image.width, height: _image.height };
    this.size = { width: 40 / scale, height: 40 / scale };
    this.ratio = this.size.width / this.size.height;
    this.position = new Vector(config.x, config.y);
    this.scale = scale;
  }
  get image() {
    return this._image;
  }
  draw(context, zoom) {
    const { position, scale } = this;
    context.save();
    context.translate(position.x, -position.y);
    // context.scale(1 / zoom, 1 / zoom);
    this.size = { width: 40 / scale / zoom, height: 40 / scale / zoom };
    this.drawCircle(context);
    this.drawIcon(context);
    this.drawName(context, zoom);
    this.drawTasksCount(context, zoom);
    context.restore();
  }
  drawCircle(context) {
    const { size, scale } = this;
    context.save();
    context.fillStyle = this._colors[1];
    context.strokeStyle = this._colors[0];
    context.beginPath();
    context.arc(0, 0, size.width / 2, 0, 2 * Math.PI);
    context.fill();
    context.lineWidth = 1 / scale;
    context.stroke();
    context.restore();
  }
  drawIcon(context) {
    const {
      size,
      originalSize: { width, height },
      image,
    } = this;
    context.save();
    context.drawImage(
      image,
      0,
      0,
      width,
      height,
      -size.width * 0.28,
      -size.height * 0.28,
      size.width * 0.56,
      size.height * 0.56
    );
    context.restore();
  }
  drawName(context, zoom) {
    const { size, scale } = this;
    context.save();
    context.font = `${15 / scale / (zoom * 0.8)}px Arial`;
    context.textBaseline = "middle";
    context.textAlign = "center";

    // const width = context.measureText(this._name).width;
    const textMeasure = context.measureText(this._name);
    const width = textMeasure.width;
    const height = textMeasure.fontBoundingBoxAscent + textMeasure.fontBoundingBoxDescent;

    context.fillStyle = "#ee7203";
    context.strokeStyle = "#ee7203";
    context.fillRect(-width / 2 - (10 / zoom), size.height / 1.45, width + (20 / zoom), height + (10 / zoom));

    context.fillStyle = "#ffffff";
    context.strokeStyle = "#ffffff";
    context.fillText(this._name, 0, size.height);
    context.restore();
  }
  drawTasksCount(context, zoom) {
    const { size, scale } = this;
    context.save();
    context.fillStyle = "#ee7203";
    context.strokeStyle = "#ee7203";
    context.beginPath();
    context.arc(
      size.width / 2,
      -size.height / 2,
      size.width / 3,
      0,
      2 * Math.PI
    );
    context.stroke();
    context.fill();
    // context.font = `bold ${12}px Arial`;
    context.font = `bold ${13 / scale / (zoom * 0.95)}px Arial`;
    context.textBaseline = "middle";
    context.textAlign = "center";
    context.fillStyle = "white";
    context.fillText(
      this._tasksCount.toString(),
      size.width / 2,
      -size.height / 2
    );
    context.restore();
  }
}
export default Poi;
