import React, { useState, useEffect, useContext } from "react";
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

import styles from "./SensorChartItem.module.scss";
import Icon from "../../Icon";
import { I18nContext } from "../../../i18n/I18nContext";

const SensorChartItem = ({ data, property, unit, sensorId, sensorName }) => {
  const { t } = useContext(I18nContext);
  const [isSaved, setIsSaved] = useState(false);

  const CustomTooltip = ({ active, payload, label, unit }) => {
    if (active && payload && payload.length) {
      return (
        <div className={styles.customTooltip}>
          <p className={styles.label}>{`${t("alert level")}: ${
            payload[0].payload.alertLevel
          }`}</p>
          <p>{payload[0].payload.procedureName}</p>
          <p>{`${payload[1].name}: ${payload[1].value} ${unit ? unit : ""}`}</p>
        </div>
      );
    }

    return null;
  };

  const handleSaveSensor = () => {
    const sensorsSaved = localStorage.getItem("savedSensors");
    if (!sensorsSaved) {
      const saved = [];
      saved.push({ sensorId, property });
      localStorage.setItem("savedSensors", JSON.stringify(saved));
      return;
    }
    const savedSensors = JSON.parse(sensorsSaved);
    savedSensors.push({ sensorId, property });
    localStorage.setItem("savedSensors", JSON.stringify(savedSensors));
    setIsSaved(true);
  };

  const handleRemoveFromStorage = () => {
    const savedSensors = localStorage.getItem("savedSensors");
    const parsedSensors = JSON.parse(savedSensors);

    const newSensors = parsedSensors.filter(
      (el) => el.sensorId !== sensorId && el.property !== property
    );

    localStorage.setItem("savedSensors", JSON.stringify(newSensors));
    setIsSaved(false);
  };

  useEffect(() => {
    const savedSensors = localStorage.getItem("savedSensors");
    const parsedSensors = JSON.parse(savedSensors);

    if (!parsedSensors) {
      setIsSaved(false);
      return;
    }

    const element = parsedSensors.find(
      (el) => el.sensorId === sensorId && el.property === property
    );

    if (element) {
      setIsSaved(true);
    } else {
      setIsSaved(false);
    }
  }, []);

  const parsedData = data.map((el) => {
    const [month, day] = [
      new Date(el.date).getMonth(),
      new Date(el.date).getDate(),
    ];
    const [hour, minutes] = [
      new Date(el.date).getHours(),
      new Date(el.date).getMinutes(),
    ];
    const parsedDate = `${day}/${month} ${hour}:${minutes}`;

    return {
      ...el,
      date: parsedDate,
    };
  });

  return (
    <div className={styles.sensorWrapper}>
      <p className={styles.sensorName}>
        {t("name")}: <span className={styles.text}>{sensorName} </span>
        {t("property")}: <span className={styles.text}>{property}</span>
      </p>
      <ResponsiveContainer width="90%" height="90%">
        <ScatterChart
          width={500}
          height={300}
          margin={{
            top: 20,
            right: 20,
            bottom: 30,
            left: 20,
          }}
        >
          <CartesianGrid />
          <XAxis type="category" dataKey="date" name="date" />
          <YAxis type="number" dataKey="value" name={property} />
          <Tooltip
            content={<CustomTooltip unit={unit} />}
            property={property}
          />
          <Legend
            margin={{
              top: 20,
            }}
          />
          <Scatter name={"Alerts"} data={parsedData} fill={`#ee7203`} line />
        </ScatterChart>
      </ResponsiveContainer>
      <div
        className={styles.padlock}
        onClick={!isSaved ? handleSaveSensor : handleRemoveFromStorage}
      >
        {isSaved ? (
          <Icon name="padlock-black" className={styles.icon} />
        ) : (
          <Icon name="padlock-grey" className={styles.icon} />
        )}
      </div>
    </div>
  );
};

export default SensorChartItem;
