import React, { useContext, useRef, useState } from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { useDetectOutsideClick } from "../../hooks/useDetectOutsideClick";
import Icon from "../Icon";
import { I18nContext } from "../../i18n/I18nContext";
import styles from "./Meatball.module.scss";
import DeleteAttachmentsModal from "./DeleteAttachmentsModal";

const MeatballFiles = ({ onClickDeleteAll, onDownloadAll, onFileUpload }) => {
  const { t } = useContext(I18nContext);
  const dropdownRef = useRef(null);
  const [openDeleteAllAttachmentsModal, setOpenDeleteAllAttachmentsModal] =
    useState(false);
  const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);

  const onClick = (event) => {
    event.stopPropagation();
    setIsActive(!isActive);
  };

  const handleDeleteAll = () => {
    onClickDeleteAll();
    setOpenDeleteAllAttachmentsModal(false);
  };

  const classOpenMeatball = cx(styles.menu, {
    [styles.active]: isActive,
    [styles.inactive]: !isActive,
  });

  const options = [
    {
      label: t("add file"),
      icon: <Icon name="add" className={styles.icon} />,
      onClick: onFileUpload,
    },
    {
      label: t("download all"),
      icon: <Icon name="download" className={styles.icon} />,
      onClick: onDownloadAll,
    },
    {
      label: t("delete all"),
      icon: <Icon name="trash" className={styles.icon_menu} />,
      onClick: () => setOpenDeleteAllAttachmentsModal(true),
    },
  ];

  return (
    <div className={styles.menu_container} onClick={onClick}>
      <div className={styles.menu_trigger}>
        <Icon name="meatball-menu" className={styles.icon} />
      </div>
      <div ref={dropdownRef} className={classOpenMeatball}>
        {options.map((item, idx) => (
          <div
            className={styles.item_container}
            key={idx}
            onClick={item.onClick}
          >
            <span> {item.icon} </span>
            <span className={styles.label_menu}>{item.label}</span>
          </div>
        ))}
      </div>
      <DeleteAttachmentsModal
        isOpen={openDeleteAllAttachmentsModal}
        onDeleteAll={handleDeleteAll}
        onClose={() => setOpenDeleteAllAttachmentsModal(false)}
      />
    </div>
  );
};

MeatballFiles.propTypes = {
  onClickDeleteAll: PropTypes.func,
  onDownloadAll: PropTypes.func,
};

export default MeatballFiles;
