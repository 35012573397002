import React, { useContext, useEffect } from "react";
import * as Yup from "yup";
import {
  FieldError,
  FieldInput,
  FieldLabel,
  Form,
  FormControl,
} from "../Input";
import { useDispatch, useSelector } from "react-redux";
import { fetchProfileInfo } from "../../redux";
import { I18nContext } from "../../i18n/I18nContext";
import Button from "../Button";
import { editProfileInfo, requestEmailChange } from "../../redux";
import styles from "./EditPasswordProfile.module.scss";

const SettingsProfile = () => {
  const { t } = useContext(I18nContext);
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profile.profile);

  useEffect(() => {
    dispatch(fetchProfileInfo());
  }, [dispatch]);

  const initialValues = {
    department: profile.department,
    email: profile.email,
    location: profile.location,
    position: profile.position,
    username: profile.username,
    company: profile.companyName,
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email(t("wrong e-mail format"))
      .required(t("e-mail needs to be provided")),
  });

  const handleSubmit = async (values) => {
    await dispatch(
      editProfileInfo(t, {
        department: values.department,
        location: values.location,
        position: values.position,
        username: values.username,
      })
    );
  };

  const handleEmailChangeSubmit = async (values) => {
    await dispatch(requestEmailChange(t, values.email));
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>{t("profile")}</div>
      <span className={styles.subtitle}>
        {t("manage your personal data.")}
        <span className={styles.title1}>{t("user data")}</span>
      </span>
      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
      >
        <FormControl>
          <FieldLabel>{t("name and surname")}</FieldLabel>
          <FieldInput
            placeholder={t("your name and surname")}
            name="username"
            size="l"
            type="text"
          />
        </FormControl>
        <FormControl>
          <FieldLabel>{t("position title")}</FieldLabel>
          <FieldInput
            placeholder={t("your position")}
            name="position"
            size="l"
            type="text"
          />
        </FormControl>
        <FormControl>
          <FieldLabel>{t("company")}</FieldLabel>
          <FieldInput
            placeholder={t("your company")}
            name="company"
            disabled
            size="l"
            type="text"
          />
        </FormControl>
        <FormControl>
          <FieldLabel>{t("department")}</FieldLabel>
          <FieldInput
            placeholder={t("your department")}
            name="department"
            size="l"
            type="text"
          />
        </FormControl>
        <FormControl>
          <FieldLabel>{t("location")}</FieldLabel>
          <FieldInput
            placeholder={t("your location")}
            name="location"
            size="l"
            type="text"
          />
        </FormControl>
        <Button
          className={styles.btn_separator}
          type="submit"
          variant="orange"
          size="s"
        >
          {t("save changes", "title")}
        </Button>
      </Form>
      <Form
        onSubmit={handleEmailChangeSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
      >
        <hr className={styles.separator}></hr>
        <div className={styles.column}>
          <div className={styles.title1}>{t("e-mail")}</div>
          <div className={styles.current}>{t("current e-mail")}</div>
          <span className={styles.txt_container1}>
            {t("your current email is")}:
            <span className={styles.txt_orange}>{profile.email}</span>
          </span>
        </div>
        <FormControl>
          <FieldLabel>{t("new e-mail address")} </FieldLabel>
          <FieldInput
            name="email"
            placeholder={t("enter a new email address")}
            type="email"
            size="l"
          />
          <FieldError name="email" />
        </FormControl>
        <Button
          className={styles.btn_separator}
          type="submit"
          variant="orange"
          size="s"
        >
          {t("request email change", "title")}
        </Button>
      </Form>
    </div>
  );
};

export default SettingsProfile;
