import PropTypes from "prop-types";
import styles from "./AccessTitles.module.scss";
import { I18nContext } from "../../i18n/I18nContext";
import { useContext } from "react";

const AccessTitles = ({ content }) => {
  const { t } = useContext(I18nContext);
  return (
    <>
      {content === "users" && (
        <div className={styles.title_row}>
          <span className={styles.title_name}>{t("user")}</span>
          <span className={styles.title_email}>{t("e-mail")}</span>
          <span className={styles.title_role}>{t("role")}</span>
          <span className={styles.title_added}>{t("added")}</span>
          <span className={styles.title_action}>{t("actions")}</span>
        </div>
      )}

      {content === "maintainerOrgs" && (
        <div className={styles.title_row1}>
          <span className={styles.title_name_maintainer}>
            {t("organization")}
          </span>
          <span className={styles.title_email_maintainer}>{t("")}</span>
          <span className={styles.title_role_maintainer}>
            {t("maintainers")}
          </span>
          <span className={styles.title_added_maintainer}>{t("added")}</span>
          <span className={styles.title_action_maintainer}>{t("actions")}</span>
        </div>
      )}

      {content === "companies" && (
        <div className={styles.title_row}>
          <span className={styles.title_name}>{t("company")}</span>
          {/*<span className={styles.title_action}>{t("actions")}</span>*/}
        </div>
      )}

      {content === "license" && (
        <div className={styles.title_row1}>
          <div className={styles.title_name1}>{t("organization")}</div>
          <div className={styles.title_action}>{t("users")}</div>
        </div>
      )}
    </>
  );
};

AccessTitles.propTypes = {
  content: PropTypes.string,
};

export default AccessTitles;
