import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import MeatballMenu from "../Access/MeatballMenu";
import Icon from "../Icon";
import { I18nContext } from "../../i18n/I18nContext";
import styles from "./AttachmentsContent.module.scss";
import AddAttachmentsFolderModal from "./AddAttachmentsFolderModal";
import DeleteAttachmentsFolderModal from "./DeleteAttachmentsFolderModal";
import AddAttachmentsTypeModal from "./AddAttachmentsTypeModal";
import DeleteAttachmentsTypeModal from "./DeleteAttachmentsTypeModal";

const AttachmentsContent = ({ folders, types, canEdit }) => {
  const { t } = useContext(I18nContext);
  const [openEditAttachmentsFolderModal, setEditAttachmentsFolderModalOpen] =
    useState(false);
  const [
    openDeleteAttachmentsFolderModal,
    setDeleteAttachmentsFolderModalOpen,
  ] = useState(false);
  const [selectedAttachmentsFolder, setSelectedAttachmentsFolder] =
    useState(null);
  const [openEditAttachmentsTypeModal, setEditAttachmentsTypeModalOpen] =
    useState(false);
  const [openDeleteAttachmentsTypeModal, setDeleteAttachmentsTypeModalOpen] =
    useState(false);
  const [selectedAttachmentsType, setSelectedAttachmentsType] = useState(null);
  const [meatballOpened, setMeatballOpened] = useState(null);
  const isPrivileged = useSelector(
    (state) => state.auth.info?.companyPrivileged
  );

  const meatBallsItemsFolders = (folder) => [
    {
      label: t("edit"),
      show:
        !folder.deprecated &&
        (isPrivileged || (!isPrivileged && !folder.isGlobal)),
      onClick: () => {
        setEditAttachmentsFolderModalOpen(true);
        setSelectedAttachmentsFolder(folder);
      },
      icon: <Icon name="editPencil-orange" />,
    },
    {
      label: t("delete"),
      show:
        !folder.deprecated &&
        (isPrivileged || (!isPrivileged && !folder.isGlobal)),
      onClick: () => {
        setDeleteAttachmentsFolderModalOpen(true);
        setSelectedAttachmentsFolder(folder);
      },
      icon: <Icon name="trash-orange" />,
    },
  ];

  const meatBallsItemsTypes = (type) => [
    {
      label: t("edit"),
      show:
        !type.deprecated && (isPrivileged || (!isPrivileged && !type.isGlobal)),
      onClick: () => {
        setEditAttachmentsTypeModalOpen(true);
        setSelectedAttachmentsType(type);
      },
      icon: <Icon name="editPencil-orange" />,
    },
    {
      label: t("delete"),
      show:
        !type.deprecated && (isPrivileged || (!isPrivileged && !type.isGlobal)),
      onClick: () => {
        setDeleteAttachmentsTypeModalOpen(true);
        setSelectedAttachmentsType(type);
      },
      icon: <Icon name="trash-orange" />,
    },
  ];

  return (
    <div className={styles.attachments_container}>
      <div className={styles.attachments_column}>
        {folders?.map((item) => {
          return (
            <div
              key={item.id}
              className={item.isGlobal ? styles.row_global : styles.row}
            >
              <div className={styles.title}>{item.name}</div>
              <div className={styles.actions}>
                {(!item.isGlobal || isPrivileged) && (
                  <div className={styles.meatball}>
                    <MeatballMenu
                      items={meatBallsItemsFolders(item)}
                      disabled={
                        item.deprecated ||
                        (!isPrivileged && item.global) ||
                        !canEdit
                      }
                      id={item.id}
                      meatballOpened={meatballOpened}
                      meatballOpenedObserver={(state) =>
                        setMeatballOpened(state)
                      }
                    />
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
      <div className={styles.attachments_column}>
        {types?.map((item) => {
          return (
            <div
              key={item.id}
              className={item.isGlobal ? styles.row_global : styles.row}
            >
              <div className={styles.title}>{item.name}</div>
              <div className={styles.actions}>
                <div className={styles.meatball}>
                  {(!item.isGlobal || isPrivileged) && (
                    <MeatballMenu
                      items={meatBallsItemsTypes(item)}
                      disabled={
                        item.deprecated ||
                        (!isPrivileged && item.global) ||
                        !canEdit
                      }
                      id={item.id}
                      meatballOpened={meatballOpened}
                      meatballOpenedObserver={(state) =>
                        setMeatballOpened(state)
                      }
                    />
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <AddAttachmentsFolderModal
        key="add-attachments-folder"
        folderEdit={openEditAttachmentsFolderModal}
        isOpen={openEditAttachmentsFolderModal}
        folder={selectedAttachmentsFolder}
        onClose={() => {
          setEditAttachmentsFolderModalOpen(false);
          setSelectedAttachmentsFolder(null);
        }}
        folderNames={folders.map((folder) => folder.name)}
      />
      <DeleteAttachmentsFolderModal
        key="delete-attachments-folder"
        folder={selectedAttachmentsFolder}
        isOpen={openDeleteAttachmentsFolderModal}
        onClose={() => {
          setDeleteAttachmentsFolderModalOpen(false);
          setSelectedAttachmentsFolder(null);
        }}
      />
      <AddAttachmentsTypeModal
        key="add-attachments-type"
        typeEdit={openEditAttachmentsTypeModal}
        isOpen={openEditAttachmentsTypeModal}
        type={selectedAttachmentsType}
        onClose={() => {
          setEditAttachmentsTypeModalOpen(false);
          setSelectedAttachmentsType(null);
        }}
        typeNames={types.map((type) => type.name)}
      />
      <DeleteAttachmentsTypeModal
        key="delete-attachments-type"
        type={selectedAttachmentsType}
        isOpen={openDeleteAttachmentsTypeModal}
        onClose={() => {
          setDeleteAttachmentsTypeModalOpen(false);
          setSelectedAttachmentsType(null);
        }}
      />
    </div>
  );
};

AttachmentsContent.propTypes = {
  folders: PropTypes.array,
  types: PropTypes.array,
};

export default AttachmentsContent;
