import React from "react";
import Dashboard from "../../layouts/Dashboard";
import Access from "../../components/Access";

const AccessV = () => (
  <Dashboard>
    <Access />
  </Dashboard>
);

export default AccessV;
