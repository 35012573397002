import Actions from "../actions";

const initialValues = {
  todoItems: [],
  doneItems: [],
  batchTasks: [],
  assetTasks: [],
  loading: false,
  loadingRecent: false,
  loadingReady: false,
  recurrenceLoading: false,
  recurrenceTasks: [],
  error: null,
  groupTasks: [],
};

const tasksReducer = (state = initialValues, action) => {
  switch (action.type) {
    case Actions.TASKS_LOADING_START:
      return {
        ...state,
        loading: true,
      };
    case Actions.TASKS_LOADING_STOP:
      return {
        ...state,
        loading: false,
      };
    case Actions.TASKS_RECENT_LOADING_START:
      return {
        ...state,
        loadingRecent: true,
      };
    case Actions.TASKS_RECENT_LOADING_STOP:
      return {
        ...state,
        loadingRecent: false,
      };
    case Actions.TASKS_READY_LOADING_START:
      return {
        ...state,
        loadingReady: true,
      };
    case Actions.TASKS_READY_LOADING_STOP:
      return {
        ...state,
        loadingReady: false,
      };
    case Actions.FETCH_TASKS_REQUEST:
      if (action.state === "TODO") {
        return {
          ...state,
          loading: true,
          loadingRecent: true,
        };
      } else {
        return {
          ...state,
          loading: true,
          loadingReady: true,
        };
      }
    case Actions.FETCH_TASKS_ERROR: {
      return {
        error: action.payload,
      };
    }
    case Actions.FETCH_TASKS_SUCCESS:
      if (action.state === "TODO") {
        return {
          ...state,
          loading: false,
          loadingRecent: false,
          todoItems: action.tasks,
        };
      } else {
        return {
          ...state,
          loading: false,
          loadingReady: false,
          doneItems: action.tasks,
        };
      }

    case Actions.FETCH_BATCHTASKS_REQUEST:
      return {
        ...state,
        batchTasks: [],
        loading: true,
      };
    case Actions.FETCH_BATCHTASKS_ERROR: {
      return {
        ...state,
        batchTasks: [],
        error: action.payload,
      };
    }
    case Actions.FETCH_BATCHTASKS_SUCCESS:
      return {
        ...state,
        loading: false,
        batchTasks: action.tasks,
      };
    case Actions.CLEAR_BATCHTASKS_REQUEST:
      return {
        ...state,
        loading: false,
        batchTasks: [],
      };
    case Actions.FETCH_ASSET_TASKS_SUCCESS:
      return {
        ...state,
        assetTasks: action.tasks,
      };
    case Actions.FETCH_ASSET_TASKS_REQUEST:
      return {
        ...state,
        assetTasks: [],
      };
    case Actions.FETCH_ASSET_TASKS_ERROR:
      return {
        ...state,
        assetTasks: [],
        assetError: action.error,
      };
    case Actions.FETCH_RECURRENCE_TASKS_SUCCESS:
      return {
        ...state,
        recurrenceLoading: false,
        recurrenceTasks: action.tasks,
      };
    case Actions.FETCH_RECURRENCE_TASKS_REQUEST:
      return {
        ...state,
        recurrenceLoading: true,
        recurrenceTasks: [],
      };
    case Actions.FETCH_RECURRENCE_TASKS_ERROR:
      return {
        ...state,
        recurrenceLoading: false,
        recurrenceTasks: [],
        recurrenceTasksError: action.error,
      };
    case Actions.FETCH_GROUP_TASKS_SUCCESS:
      return {
        ...state,
        loading: false,
        groupTasks: action.groupTasks,
      };
    default:
      return state;
  }
};

export default tasksReducer;
