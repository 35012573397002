import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { editSelectedRights } from "../../redux";
import {
  addMaintainerOrganization,
  getMaintainersOrganizationList,
} from "../../redux/maintainers/maintainersActions";
import {
  FieldError,
  FieldInput,
  FieldLabel,
  FieldSelect,
  Form,
  FormControl,
} from "../Input";
import Modal from "../Modal";
import styles from "./OrganizationFormModal.module.scss";
import { I18nContext } from "../../i18n/I18nContext";

const OrganizationFormModal = ({ onClose, isOpen, org }) => {
  const { t } = useContext(I18nContext);
  const dispatch = useDispatch();
  const initialValues = {
    name: org?.name ?? "",
    rightIds: org?.rights.map((o) => o.id) ?? [],
  };

  const rightOptions = useSelector((state) =>
    state.rights.items.map((r) => ({ value: r.id, label: r.category }))
  );

  const maintainerOrgs = useSelector(
    (state) => state.maintainers.maintainerOrganizationsList
  );

  const validationSchema = org
    ? Yup.object({
        rightIds: Yup.array()
          .required(t("please choose one option"))
          .min(1, t("Minimum one value")),
      })
    : Yup.object({
        rightIds: Yup.array()
          .required(t("please choose one option"))
          .min(1, t("Minimum one value")),
        name: Yup.string()
          .required(t("please choose one option"))
          .notOneOf(
            maintainerOrgs.map((m) => m.name),
            t("email must be unique")
          ),
      });

  const handleSubmit = async (values, formik) => {
    if (org) {
      await dispatch(
        editSelectedRights(t, {
          maintainerOrgId: org.id,
          rightIds: values.rightIds,
        })
      );
    } else {
      await dispatch(
        addMaintainerOrganization(t, {
          name: values.name,
          rightIds: values.rightIds,
        })
      );
    }

    dispatch(getMaintainersOrganizationList());
    formik.resetForm();
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={org ? t("edit organization") : t("add new organization")}
      form="organization-form"
      editMode={!!org}
    >
      <Form
        id="organization-form"
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ errors }) => {
          return (
            <>
              <FormControl className={styles.dropdown}>
                <FieldLabel>
                  {t("name")} <span className={styles.red}>*</span>
                </FieldLabel>
                <FieldInput
                  name="name"
                  size="l"
                  placeholder={t("organization name")}
                  disabled={org}
                />
                <FieldError name="name" />
              </FormControl>

              <FormControl className={styles.dropdown}>
                <FieldLabel>
                  {t("organization rights")}{" "}
                  <span className={styles.red}>*</span>
                </FieldLabel>
                <FieldSelect
                  name="rightIds"
                  isMultiple={true}
                  size="s"
                  options={rightOptions}
                  placeholder={t("access rights")}
                />
                <FieldError name="rightIds" />
              </FormControl>
            </>
          );
        }}
      </Form>
    </Modal>
  );
};

export default OrganizationFormModal;
