import React, { useContext, useState } from "react";
import * as Yup from "yup";
import PropTypes from "prop-types";
import { FieldError, FieldSelect, FormControl, Form, Checkbox } from "../Input";
import { Field } from "formik";
import MeatballMenu from "./MeatballMenu";
import UserFormModal from "./UserFormModal";
import DeleteUserModal from "./DeleteUserModal";
import SendEmailModal from "./SendEmailModal";
import Icon from "../Icon";
import styles from "./UserRowForm.module.scss";
import { resendInvitation } from "../../redux";
import { useSelector, useDispatch } from "react-redux";
import { I18nContext } from "../../i18n/I18nContext";
import DeleteMaintainerModal from "./DeleteMaintainerModal";

const UserRowForm = ({
  user,
  org,
  showMaintainerSection,
  showRight,
  showSendEmail,
  showEditRights = true,
  meatballOpened,
  meatballOpenedObserver,
  checkSelectUser,
  checked,
  sendInvitation,
  refreshMaintainersList,
}) => {
  const { t } = useContext(I18nContext);
  const [openAddUsersModal, setOpenAddUsersModal] = useState(false);
  const [openSendEmailModal, setOpenSendEmailModal] = useState(false);
  const [openDeleteUserModal, setOpenDeleteUserModal] = useState(false);
  const [openDeleteMaintainerModal, setOpenDeleteMaintainerModal] =
    useState(false);
  const [userEdited, setUserEditing] = useState(user);
  const [, setSelectedRights] = useState([]);
  const dispatch = useDispatch();

  const rights = useSelector((state) => state.rights.items);
  const rolesOptions = useSelector((state) =>
    state.access.rolesItems.map((r) => ({
      value: r.id,
      label: t(r.name.toLowerCase()),
      role: r.role,
    }))
  );

  const initialValues = {
    roleId: rolesOptions.find((o) => o.role === user.access.role)?.value,
    right: user.access.rights?.map((r) => r.id),
  };

  const validationSchema = Yup.object({
    roleId: Yup.string()
      .oneOf(rolesOptions.map((item) => item.value))
      .required(t("please choose one option")),
    right: Yup.array()
      .required(t("please choose one option"))
      .of(Yup.string().oneOf(rights.map((item) => item.value))),
  });

  const handleSubmit = (values) => {
    console.log(values);
  };

  let meatballMenuItems;
  if (showSendEmail) {
    meatballMenuItems = [
      {
        label: t("send e-mail"),
        show: true,
        icon: <Icon name="envelope-orange" className={styles.icon_menu} />,
        onClick: () => {
          setUserEditing(user);
          setOpenSendEmailModal(true);
        },
      },
      {
        label: t("edit user"),
        show: true,
        onClick: () => {
          setUserEditing(user);
          setOpenAddUsersModal(true);
        },
        icon: <Icon name="editPencil-orange" className={styles.icon_menu} />,
      },
      {
        label: t("delete user"),
        show: true,
        icon: <Icon name="trash-orange" className={styles.icon_menu} />,
        onClick: () => {
          setUserEditing(user);
          setOpenDeleteUserModal(true);
        },
      },
      {
        label: t(
          user.isInvitationDelayed ? "send invitation" : "resend invitation"
        ),
        show: !user.isActive,
        icon: <Icon name="envelope-orange" className={styles.icon_menu} />,
        onClick: async () => {
          if (user.isInvitationDelayed) {
            sendInvitation(user.id);
          } else {
            await dispatch(resendInvitation(t, user));
          }
        },
      },
    ];
  } else {
    meatballMenuItems = [
      {
        label: t("edit"),
        show: true,
        onClick: () => {
          setUserEditing(user);
          setOpenAddUsersModal(true);
        },
        icon: <Icon name="editPencil-orange" className={styles.icon_menu} />,
      },
      {
        label: t("delete"),
        show: true,
        onClick: () => {
          setUserEditing(user);
          setOpenDeleteMaintainerModal(true);
        },

        icon: <Icon name="trash-orange" className={styles.icon_menu} />,
      },
      {
        label: t(
          user.isInvitationDelayed ? "send invitation" : "resend invitation"
        ),
        show: !user.isActive,
        icon: <Icon name="envelope-orange" className={styles.icon_menu} />,
        onClick: async () => {
          if (user.isInvitationDelayed) {
            sendInvitation(user.id);
          } else {
            await dispatch(resendInvitation(t, user));
          }
        },
      },
    ];
  }

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
      >
        <div className={styles.box_container}>
          <div className={styles.user_info}>
            <div className={styles.checkbox_container}>
              {user.isInvitationDelayed && (
                <input
                  id={user.id}
                  name={user.id}
                  type="checkbox"
                  className={styles.checkbox}
                  onChange={(e) => checkSelectUser(user.id, e.target.checked)}
                  checked={checked}
                />
              )}
            </div>
            {user.image && (
              <img className={styles.img} src={user.image} alt="avatar" />
            )}
            {!user.image && (
              <div className={styles.no_img}>
                {user.username
                  .split(" ")
                  .map((word) => word[0]?.toUpperCase())
                  .join("")}
              </div>
            )}
            <div className={styles.txt_name}>
              {user.username}
              {!user.isInvitationDelayed && !user.isActive && (
                <div className={styles.notification_white}>
                  {t("invitation pending")}
                </div>
              )}
              {user.isInvitationDelayed && !user.isActive && (
                <div className={styles.notification_orange}>
                  {t("awaiting invitation")}
                </div>
              )}
            </div>
            {/*{user.notification === "Expired" && (*/}
            {/*  <span className={styles.notification_red}>{t("expired")}</span>*/}
            {/*)}*/}
            {/*{user.notification === "Pending" && (*/}
            {/*  <span className={styles.notification_white}>{t("pending")}</span>*/}
            {/*)}*/}
            {/*{!user.notification && (*/}
            {/*  <span className={styles.no_notifcation}></span>*/}
            {/*)}*/}
          </div>
          <span className={styles.txt_email}>{user.email}</span>

          {!showMaintainerSection && (
            <FormControl className={styles.dropdown}>
              <FieldSelect
                disabled
                name="roleId"
                size="s"
                options={rolesOptions}
                placeholder={t("role")}
              />
              <FieldError name="roleId" />
            </FormControl>
          )}
          {showRight && (
            <FormControl className={styles.dropdown}>
              <FieldSelect
                name="right"
                disabled
                isMultiple={true}
                size="s"
                options={rights.map((r) => ({
                  value: r.id,
                  label: r.category,
                }))}
                onChange={(rights) => {
                  setSelectedRights(rights);
                }}
                placeholder={t("access rights")}
              />
              <FieldError name="right" />
            </FormControl>
          )}

          <span className={styles.date}>
            {new Date(user.access.date).toLocaleDateString(undefined, {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            })}
          </span>
          <span className={styles.meatball}>
            <MeatballMenu
              items={meatballMenuItems}
              id={"user" + user.id}
              meatballOpened={meatballOpened}
              meatballOpenedObserver={meatballOpenedObserver}
            />
          </span>
        </div>
      </Form>
      {!!openAddUsersModal && (
        <UserFormModal
          org={org}
          user={userEdited}
          isOpen={openAddUsersModal}
          onClose={() => {
            setOpenAddUsersModal(false);
            setUserEditing(null);
          }}
        />
      )}
      {!!openSendEmailModal && (
        <SendEmailModal
          user={userEdited}
          isOpen={openSendEmailModal}
          onClose={() => {
            setOpenSendEmailModal(false);
            setUserEditing(null);
          }}
        />
      )}
      {!!openDeleteUserModal && (
        <DeleteUserModal
          key="delete-user"
          user={userEdited}
          isOpen={openDeleteUserModal}
          onClose={() => {
            setOpenDeleteUserModal(false);
            setUserEditing(null);
          }}
        />
      )}
      {!!openDeleteMaintainerModal && (
        <DeleteMaintainerModal
          key="delete-maintainer"
          user={userEdited}
          orgId={org?.id}
          isOpen={openDeleteMaintainerModal}
          onClose={() => {
            setOpenDeleteMaintainerModal(false);
            setUserEditing(null);
            setTimeout(() => {
              refreshMaintainersList();
            }, 1000);
          }}
        />
      )}
    </>
  );
};

UserRowForm.propTypes = {
  showMaintainerSection: PropTypes.bool,
  showRight: PropTypes.bool,
  showSendEmail: PropTypes.bool,
};

export default UserRowForm;
