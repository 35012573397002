import React, { useContext, useState } from "react";
import { I18nContext } from "../../i18n/I18nContext";
import styles from "./GroupTasksAssetsList.module.scss";
import Icon from "../Icon";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import useDeepCompareEffect from "use-deep-compare-effect";

const GroupTasksAssetsList = ({
  assets,
  filters,
  applyFilters,
  selectAssets,
  selectedAssets,
  sort,
  applySorting,
  unfilteredAssets,
}) => {
  const { t } = useContext(I18nContext);
  const [showFilterOptions, setShowFilterOptions] = useState(null);
  const [textFilterInput, setTextFilterInput] = useState("");
  const [numericFilterInputFrom, setNumericFilterInputFrom] = useState(null);
  const [numericFilterInputTo, setNumericFilterInputTo] = useState(null);
  const [dateFilterInputFrom, setDateFilterInputFrom] = useState("");
  const [dateFilterInputTo, setDateFilterInputTo] = useState("");

  useDeepCompareEffect(() => {
    if (filters?.length) {
      removeFilteredOutAssetsFromSelection();
    }
  }, [filters]);

  const getAssetPropertyValue = (property) => {
    if (property.propertyType === "boolean") {
      return property.state ? t("TRUE") : t("FALSE");
    }
    if (property.propertyType === "numeric" && !!property.value) {
      return property.value + " " + property.unit?.symbol;
    }
    if (
      property.propertyType === "selection" &&
      property.chosenOptions.filter((option) => !!option).length
    ) {
      return property.chosenOptions.join(", ");
    }
    if (property.propertyType === "text" && !!property.contents) {
      return property.contents;
    }
    if (property.propertyType === "date" && !!property.selectedDate) {
      return property.selectedDate;
    }
    return "-";
  };

  const renderFilterOptions = (property, assets) => {
    if (property.propertyType === "boolean") {
      const usedOptions = assets.map(
        (asset) => asset.properties.find((x) => x.id === property.id).state
      );
      // .sort((a, b) => a.localeCompare(b));
      const uniqueUsedOptions = [...new Set(usedOptions)];

      return (
        <div className={styles.filterOptionsList}>
          {uniqueUsedOptions.includes(true) && (
            <div
              className={
                filters.find((x) => x.id === property.id)?.state === true
                  ? styles.filterOptionSingleSelected
                  : styles.filterOptionSingle
              }
              onClick={() => selectFilter(property, true)}
            >
              {t("TRUE")}
            </div>
          )}
          {uniqueUsedOptions.includes(false) && (
            <div
              className={
                filters.find((x) => x.id === property.id)?.state === false
                  ? styles.filterOptionSingleSelected
                  : styles.filterOptionSingle
              }
              onClick={() => selectFilter(property, false)}
            >
              {t("FALSE")}
            </div>
          )}
        </div>
      );
    }

    if (property.propertyType === "selection" && property.options.length) {
      let usedOptions = [];
      assets.forEach((asset) => {
        asset.properties
          .find((x) => x.id === property.id)
          .chosenOptions.forEach((option) => {
            if (!usedOptions.includes(option) && !!option) {
              usedOptions.push(option);
            }
          });
      });
      const uniqueUsedOptions = [...new Set(usedOptions)].sort((a, b) =>
        a.localeCompare(b)
      );

      return (
        <div className={styles.filterOptionsList}>
          <div
            className={styles.filterOptionMulti}
            onClick={() =>
              invertFilterOptionsSelection(property, uniqueUsedOptions)
            }
          >
            <div className={styles.invertButton}>
              <Icon name="invert" />
            </div>
            <div className={styles.invertButtonLabel}>
              {t("invert selection")}
            </div>
          </div>
          {uniqueUsedOptions.map((option) => (
            <div
              key={option}
              className={styles.filterOptionMulti}
              onClick={() => selectFilter(property, option)}
            >
              <div
                className={
                  filters
                    .find((x) => x.id === property.id)
                    ?.options.includes(option)
                    ? styles.assetCheckboxChecked
                    : styles.assetCheckbox
                }
              >
                {filters
                  .find((x) => x.id === property.id)
                  ?.options.includes(option) ? (
                  <span>&#x2713;</span>
                ) : null}
              </div>
              {option}
            </div>
          ))}
        </div>
      );
    }

    if (property.propertyType === "text") {
      return (
        <div className={styles.filterOptionsList}>
          <input
            className={styles.filterInput}
            type="text"
            onChange={(e) => {
              e.preventDefault();
              setTextFilterInput(e.target.value);
            }}
            value={textFilterInput}
          />
          <div
            className={styles.applyFilterButton}
            onClick={() =>
              textFilterInput ? selectFilter(property, textFilterInput) : null
            }
          >
            {t("apply")}
          </div>
        </div>
      );
    }

    if (property.propertyType === "numeric") {
      return (
        <div className={styles.filterOptionsList}>
          <div className={styles.filterInputsWrapper}>
            <div className={styles.filterInputWrapper}>
              <div className={styles.filterInputLabel}>{t("from")}</div>
              <input
                className={styles.filterInput}
                type="number"
                onChange={(e) => {
                  e.preventDefault();
                  setNumericFilterInputFrom(e.target.value);
                }}
                value={numericFilterInputFrom}
              />
            </div>
            <div className={styles.filterInputWrapper}>
              <div className={styles.filterInputLabel}>{t("to")}</div>
              <input
                className={styles.filterInput}
                type="number"
                onChange={(e) => {
                  e.preventDefault();
                  setNumericFilterInputTo(e.target.value);
                }}
                value={numericFilterInputTo}
              />
            </div>
          </div>
          <div
            className={styles.applyFilterButton}
            onClick={() =>
              numericFilterInputFrom && numericFilterInputTo
                ? selectFilter(
                    property,
                    numericFilterInputFrom,
                    numericFilterInputTo
                  )
                : null
            }
          >
            {t("apply")}
          </div>
        </div>
      );
    }

    if (property.propertyType === "date") {
      return (
        <div className={styles.filterOptionsListDate}>
          <div className={styles.filterInputsWrapper}>
            <div className={styles.filterInputWrapper}>
              <div className={styles.filterInputLabel}>{t("from")}</div>
              <label className={styles.picker_wrapper}>
                <ReactDatePicker
                  wrapperClassName={styles.filterInputDate}
                  selected={dateFilterInputFrom}
                  onChange={(value) => {
                    setDateFilterInputFrom(value);
                  }}
                  dateFormat="dd-MM-yyyy"
                />
                <Icon className={styles.icon} name="calendar" />
              </label>
            </div>
            <div className={styles.filterInputWrapper}>
              <div className={styles.filterInputLabel}>{t("to")}</div>
              <label className={styles.picker_wrapper}>
                <ReactDatePicker
                  wrapperClassName={styles.filterInputDate}
                  selected={dateFilterInputTo}
                  onChange={(value) => {
                    setDateFilterInputTo(value);
                  }}
                  dateFormat="dd-MM-yyyy"
                />
                <Icon className={styles.icon} name="calendar" />
              </label>
            </div>
          </div>
          <div
            className={styles.applyFilterButton}
            onClick={() =>
              dateFilterInputFrom && dateFilterInputTo
                ? selectFilter(property, dateFilterInputFrom, dateFilterInputTo)
                : null
            }
          >
            {t("apply")}
          </div>
        </div>
      );
    }

    if (property.propertyType === "date") {
      return property.selectedDate;
    }

    return null;
  };

  const convertDateToShortDate = (date) => {
    let newDate = new Date(date);
    const mm = newDate.getMonth() + 1;
    const dd = newDate.getDate();
    const yyyy = newDate.getFullYear();

    return (
      yyyy + "-" + (mm > 9 ? "" : "0") + mm + "-" + (dd > 9 ? "" : "0") + dd
    );
  };

  const toggleShowFilterOptions = (property) => {
    if (showFilterOptions === property.id) {
      setShowFilterOptions(null);
    } else {
      const currentFilter = filters.find((x) => x.id === property.id);

      if (!!currentFilter && property.propertyType === "text") {
        setTextFilterInput(currentFilter.contents);
      } else {
        setTextFilterInput("");
      }

      if (!!currentFilter && property.propertyType === "numeric") {
        setNumericFilterInputFrom(currentFilter.valueFrom);
        setNumericFilterInputTo(currentFilter.valueTo);
      } else {
        setNumericFilterInputFrom(null);
        setNumericFilterInputTo(null);
      }

      if (!!currentFilter && property.propertyType === "date") {
        setDateFilterInputFrom(new Date(currentFilter.selectedDateFrom));
        setDateFilterInputTo(new Date(currentFilter.selectedDateTo));
      } else {
        setDateFilterInputFrom("");
        setDateFilterInputTo("");
      }

      setShowFilterOptions(property.id);
    }
  };

  const selectFilter = (property, filterOption, filterOption2) => {
    const newFilters = [...filters];
    const currentPropertyFilter = newFilters.find((x) => x.id === property.id);
    const index = newFilters.indexOf(currentPropertyFilter);

    if (property.propertyType === "boolean") {
      if (currentPropertyFilter) {
        newFilters.splice(index, 1);
      }
      newFilters.push({ id: property.id, state: filterOption });
    }

    if (property.propertyType === "selection") {
      if (!currentPropertyFilter) {
        newFilters.push({ id: property.id, options: [filterOption] });
      } else {
        if (currentPropertyFilter.options.includes(filterOption)) {
          if (currentPropertyFilter.options.length > 1) {
            const optionIndex = newFilters[index].options.indexOf(filterOption);
            newFilters[index].options.splice(optionIndex, 1);
          } else {
            newFilters.splice(index, 1);
          }
        } else {
          newFilters[index].options.push(filterOption);
        }
      }
    }

    if (property.propertyType === "text") {
      if (currentPropertyFilter) {
        newFilters.splice(index, 1);
      }
      newFilters.push({ id: property.id, contents: filterOption });
    }

    if (property.propertyType === "numeric") {
      if (currentPropertyFilter) {
        newFilters.splice(index, 1);
      }
      newFilters.push({
        id: property.id,
        valueFrom: filterOption,
        valueTo: filterOption2,
      });
    }

    if (property.propertyType === "date") {
      if (currentPropertyFilter) {
        newFilters.splice(index, 1);
      }
      newFilters.push({
        id: property.id,
        selectedDateFrom: convertDateToShortDate(filterOption),
        selectedDateTo: convertDateToShortDate(filterOption2),
      });
    }

    applyFilters(newFilters);
    if (property.propertyType !== "selection") {
      setShowFilterOptions(null);
    }
  };

  const invertFilterOptionsSelection = (property, options) => {
    const newFilters = [...filters];
    const currentPropertyFilter = newFilters.find((x) => x.id === property.id);
    const index = newFilters.indexOf(currentPropertyFilter);

    let newFilterSelectedOptions = currentPropertyFilter
      ? [...currentPropertyFilter.options]
      : [];

    options.forEach((option) => {
      if (newFilterSelectedOptions.includes(option)) {
        const index = newFilterSelectedOptions.indexOf(option);
        newFilterSelectedOptions.splice(index, 1);
      } else {
        newFilterSelectedOptions.push(option);
      }
    });

    if (currentPropertyFilter) {
      newFilters.splice(index, 1);
    }

    if (newFilterSelectedOptions.length) {
      newFilters.push({ id: property.id, options: newFilterSelectedOptions });
    }

    applyFilters(newFilters);
    if (property.propertyType !== "selection") {
      setShowFilterOptions(null);
    }
  };

  const clearFilter = (propertyId) => {
    const newFilters = [...filters];
    const currentPropertyFilter = newFilters.find((x) => x.id === propertyId);
    const index = newFilters.indexOf(currentPropertyFilter);
    newFilters.splice(index, 1);
    applyFilters(newFilters);
    setShowFilterOptions(null);
  };

  const getPropertyFilterText = (property) => {
    const type = property.propertyType;
    const propertyFilter = filters.find((x) => x.id === property.id);

    if (type === "boolean") {
      return propertyFilter.state ? t("TRUE") : t("FALSE");
    } else if (type === "text") {
      return propertyFilter.contents;
    } else if (type === "selection") {
      return propertyFilter.options.join(", ");
    } else if (type === "date") {
      return (
        convertDateToShortDate(propertyFilter.selectedDateFrom) +
        " - " +
        convertDateToShortDate(propertyFilter.selectedDateTo)
      );
    } else if (type === "numeric") {
      return propertyFilter.valueFrom + " - " + propertyFilter.valueTo;
    } else {
      return null;
    }
  };

  const activeSortStyling = {
    background: "#EE7203",
    color: "#FFFFFF",
    borderColor: "#EE7203",
  };

  const selectAllAssets = () => {
    selectAssets(assets.map((asset) => asset.id));
  };

  const deselectAllAssets = () => {
    selectAssets([]);
  };

  const selectAsset = (assetId) => {
    const newSelectedAssets = [...selectedAssets];

    if (newSelectedAssets.includes(assetId)) {
      const index = newSelectedAssets.indexOf(assetId);
      newSelectedAssets.splice(index, 1);
    } else {
      newSelectedAssets.push(assetId);
    }

    selectAssets(newSelectedAssets);
  };

  const invertAssetsSelection = () => {
    const newSelectedAssets = assets
      .filter((asset) => !selectedAssets.includes(asset.id))
      .map((asset) => asset.id);
    selectAssets(newSelectedAssets);
  };

  const removeFilteredOutAssetsFromSelection = () => {
    const newSelectedAssets = selectedAssets.filter((assetId) =>
      assets.map((asset) => asset.id).includes(assetId)
    );
    selectAssets(newSelectedAssets);
  };

  return (
    <div className={styles.container}>
      <div className={styles.assetsList}>
        <div className={styles.assetsListHeader}>
          <div className={styles.headerCellName}>
            <div className={styles.headerCellLabel}>
              {t("asset id", "lower")}
            </div>
            <div className={styles.selectOptions}>
              <div
                className={styles.selectOption}
                onClick={
                  selectedAssets.length === 0 ||
                  selectedAssets.length !== assets.length
                    ? selectAllAssets
                    : deselectAllAssets
                }
              >
                <div
                  className={
                    selectedAssets.length !== 0 &&
                    selectedAssets.length === assets.length
                      ? styles.assetCheckboxChecked
                      : styles.assetCheckbox
                  }
                >
                  {selectedAssets.length !== 0 &&
                  selectedAssets.length === assets.length ? (
                    <span>&#x2713;</span>
                  ) : null}
                </div>
                <div className={styles.selectOptionLabel}>
                  {selectedAssets.length === 0 ||
                  selectedAssets.length !== assets.length
                    ? t("select all")
                    : t("deselect all")}
                </div>
              </div>
              <div className={styles.selectOption}>
                <div
                  className={styles.invertButton}
                  onClick={invertAssetsSelection}
                >
                  <Icon name="invert" />
                </div>
                <div className={styles.invertButtonLabel}>
                  {t("invert selection")}
                </div>
              </div>
            </div>
            <div className={styles.sortButtons}>
              <div
                className={styles.sortButton}
                style={
                  sort.sortBy === "name" && sort.sortVal === "ASC"
                    ? activeSortStyling
                    : {}
                }
                onClick={() =>
                  applySorting({ sortBy: "name", sortVal: "ASC", type: null })
                }
              >
                &#x25B2;
              </div>
              <div
                className={styles.sortButton}
                style={
                  sort.sortBy === "name" && sort.sortVal === "DESC"
                    ? activeSortStyling
                    : {}
                }
                onClick={() =>
                  applySorting({ sortBy: "name", sortVal: "DESC", type: null })
                }
              >
                &#x25BC;
              </div>
            </div>
          </div>
          {!!unfilteredAssets.length &&
            unfilteredAssets[0].properties.map((property) => (
              <div key={property.id} className={styles.headerCell}>
                <div className={styles.headerCellLabel}>{property.name}</div>
                <div
                  className={styles.selectFilter}
                  onClick={() => toggleShowFilterOptions(property)}
                >
                  <div
                    className={
                      filters.find((x) => x.id === property.id)
                        ? styles.selectFilterTextSelected
                        : styles.selectFilterText
                    }
                  >
                    {filters.find((x) => x.id === property.id)
                      ? getPropertyFilterText(property)
                      : t("filter by value")}
                  </div>
                  <div className={styles.selectFilterIcon}>
                    <Icon name="chevron-down" />
                  </div>
                </div>
                {filters.find((x) => x.id === property.id) && (
                  <div
                    className={styles.clearFilterIcon}
                    onClick={() => clearFilter(property.id)}
                  >
                    <Icon name="x-delete" />
                  </div>
                )}
                {showFilterOptions === property.id &&
                  renderFilterOptions(property, unfilteredAssets)}
                <div className={styles.sortButtons}>
                  <div
                    className={styles.sortButton}
                    style={
                      sort.sortBy === property.id && sort.sortVal === "ASC"
                        ? activeSortStyling
                        : {}
                    }
                    onClick={() =>
                      applySorting({
                        sortBy: property.id,
                        sortVal: "ASC",
                        type: property.propertyType,
                      })
                    }
                  >
                    &#x25B2;
                  </div>
                  <div
                    className={styles.sortButton}
                    style={
                      sort.sortBy === property.id && sort.sortVal === "DESC"
                        ? activeSortStyling
                        : {}
                    }
                    onClick={() =>
                      applySorting({
                        sortBy: property.id,
                        sortVal: "DESC",
                        type: property.propertyType,
                      })
                    }
                  >
                    &#x25BC;
                  </div>
                </div>
              </div>
            ))}
        </div>
        <div className={styles.assetItems}>
          {!!assets.length &&
            assets.map((asset) => (
              <div key={asset.id} className={styles.assetItem}>
                <div className={styles.assetItemNameCell}>
                  <div
                    className={
                      selectedAssets.includes(asset.id)
                        ? styles.assetCheckboxChecked
                        : styles.assetCheckbox
                    }
                    onClick={() => selectAsset(asset.id)}
                  >
                    {selectedAssets.includes(asset.id) ? (
                      <span>&#x2713;</span>
                    ) : null}
                  </div>
                  <div className={styles.assetItemLabel}>
                    <div className={styles.assetItemName}>{asset.name}</div>
                    <div className={styles.assetItemPath}>
                      {asset.path.join(" -> ")}
                    </div>
                  </div>
                </div>
                {asset.properties.map((property) => (
                  <div key={property.id} className={styles.assetItemCell}>
                    <div className={styles.propertyLabel}>
                      {getAssetPropertyValue(property)}
                    </div>
                  </div>
                ))}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default GroupTasksAssetsList;
