import Matrix3 from '../Utils/Matrix3';
import Vector2 from '../Utils/Vector2';
import { clamp } from '../Utils/Math';
class Camera {
    constructor() {
        this._projection = Matrix3.ones();
        this._renderScale = Matrix3.ones();
        this._localToGlobal = Matrix3.ones();
        this._zoomScalar = 1;
        this._zoom = Matrix3.ones();
        this._zoomInv = Matrix3.ones();
        this._translate = Matrix3.ones();
        this._translateInv = Matrix3.ones();
        this._render = Matrix3.ones();
    }
    setRenderMatrix(canvasSize, renderSize) {
        this._canvasSize = canvasSize;
        this._projection = new Matrix3([[1, 0, canvasSize.x / 2], [0, -1, canvasSize.y / 2], [0, 0, 1]]);
        this._renderScale = new Matrix3([[renderSize.x / canvasSize.x, 0, 0], [0, renderSize.y / canvasSize.y, 0], [0, 0, 1]]);
        this._renderScaleScalar = renderSize.x / canvasSize.x;
        this._localToGlobal = this._renderScale.multiplyByMatrix(this._projection).getInverseMatrix();
        this.update();
    }
    move(vector) {
        this.updateTranslateMatrix(vector);
    }
    setZoom(zoom) {
        this._zoomScalar = clamp(zoom, 1, 3);
        this.updateZoomMatrix();
        this.keepInsideCanvas();
    }
    zoomIn() {
        this._zoomScalar = clamp(this._zoomScalar * 1.1, 1, 6);
        this.updateZoomMatrix();
        this.keepInsideCanvas();
    }
    zoomOut() {
        this._zoomScalar = clamp(this._zoomScalar / 1.1, 1, 6);
        this.updateZoomMatrix();
        this.keepInsideCanvas();
    }
    getImageCoordinates(screenCoordinates) {
        return screenCoordinates.clone().transformWithMatrix(this._localToGlobal).transformWithMatrix(this._zoomInv).transformWithMatrix(this._translateInv);
    }
    update() {
        this._render = this._projection.multiplyByMatrix(this._zoom).multiplyByMatrix(this._translate);
        this.updateViewport();
    }
    updateZoomMatrix() {
        this._zoom = new Matrix3([[this._zoomScalar, 0, 0], [0, this._zoomScalar, 0], [0, 0, 1]]);
        this._zoomInv = this._zoom.getInverseMatrix();
        this.update();
    }
    updateTranslateMatrix(vector) {
        this._translate = this._translate.translate(vector);
        this._translateInv = this._translate.getInverseMatrix();
        this.update();
    }
    updateViewport() {
        const width = this._canvasSize.x / this._zoomScalar;
        const height = this._canvasSize.y / this._zoomScalar;
        this._viewport = {
            top: -this._translate.values[1][2] + height / 2,
            bottom: -this._translate.values[1][2] - height / 2,
            right: -this._translate.values[0][2] + width / 2,
            left: -this._translate.values[0][2] - width / 2,
        };
    }
    getCameraCorrection() {
        const { top, bottom, left, right } = this._viewport;
        const correction = new Vector2();
        if (top > this._canvasSize.y / 2)
            correction.y = top - this._canvasSize.y / 2;
        if (bottom < -this._canvasSize.y / 2)
            correction.y = bottom + this._canvasSize.y / 2;
        if (left < -this._canvasSize.x / 2)
            correction.x = left + this._canvasSize.x / 2;
        if (right > this._canvasSize.x / 2)
            correction.x = right - this._canvasSize.x / 2;
        return correction;
    }
    keepInsideCanvas() {
        const correction = this.getCameraCorrection();
        if (!correction.isEmptyVector()) {
            this.updateTranslateMatrix(correction);
        }
    }
    get projection() { return this._projection; }
    get renderScale() { return this._renderScale; }
    get localToGlobal() { return this._localToGlobal; }
    get render() { return this._render; }
    get translate() { return this._translate; }
    get translateInv() { return this._translateInv; }
    get zoom() { return this._zoom; }
    get zoomInv() { return this._zoomInv; }
    get zoomScalar() { return this._zoomScalar; }
    get renderScaleScalar() { return this._renderScaleScalar; }
}
export default Camera;
