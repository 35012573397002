import React from "react";
import { PreconditionTypeApi } from "../../constants/PreconditionType";
import BooleanCondition from "./BooleanCondition";
import TextCondition from "./TextCondition";
import SelectionCondition from "./SelectionCondition";
import NumericalCondition from "./NumericalCondition";
import DateCondition from "./DateCondition";

const PreconditionsForm = ({
  readOnly,
  preconditions,
  onDeletePrecondition,
  showEditing,
}) => {
  return (
    <>
      {preconditions.map((precondition, index) => {
        if (!precondition) {
          return null;
        }

        if (precondition.type === PreconditionTypeApi.boolean) {
          return (
            <BooleanCondition
              readOnly={readOnly}
              key={index}
              precondition={precondition}
              onDeletePrecondition={onDeletePrecondition}
              index={index}
            />
          );
        }

        if (precondition.type === PreconditionTypeApi.text) {
          return (
            <TextCondition
              key={index}
              readOnly={readOnly}
              precondition={precondition}
              onDeletePrecondition={onDeletePrecondition}
              index={index}
            />
          );
        }
        if (precondition.type === PreconditionTypeApi.selection) {
          return (
            <SelectionCondition
              key={index}
              readOnly={readOnly}
              precondition={precondition}
              onDeletePrecondition={onDeletePrecondition}
              index={index}
              showEditing={showEditing}
            />
          );
        }
        if (precondition.type === PreconditionTypeApi.date) {
          return (
            <DateCondition
              key={index}
              readOnly={readOnly}
              precondition={precondition}
              onDeletePrecondition={onDeletePrecondition}
              index={index}
            />
          );
        }
        if (precondition.type === PreconditionTypeApi.numerical) {
          return (
            <NumericalCondition
              key={index}
              readOnly={readOnly}
              precondition={precondition}
              onDeletePrecondition={onDeletePrecondition}
              index={index}
            />
          );
        }
        return null;
      })}
    </>
  );
};

export default PreconditionsForm;
