import React from "react";
import { Field } from "formik";
import PropTypes from "prop-types";

import styles from "./RadioField.module.scss";
import RadioBase from "./RadioBase";

const RadioField = ({
  options,
  name,
  disabled: groupDisabled,
  taskPreview,
}) => {
  return (
    <div
      className={!taskPreview ? styles.container : styles.container_vertical}
      data-testid={`radio-container-${name}`}
    >
      {options.map(({ value, label, disabled: optionDisabled }) => {
        const disabled = optionDisabled || groupDisabled;
        return (
          <RadioBase label={label} key={value} disabled={disabled}>
            <Field
              type="radio"
              id={value}
              name={name}
              value={value}
              disabled={disabled}
              data-testid={`radio-${name}-${value}`}
            />
          </RadioBase>
        );
      })}
    </div>
  );
};

RadioField.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    })
  ),
};

export default RadioField;
