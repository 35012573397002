import { useField } from "formik";
import React, { useContext, useEffect } from "react";
import TagsInput from "react-tagsinput";
import Icon from "../Icon";
import styles from "./InputTag.module.scss";
import { I18nContext } from "../../i18n/I18nContext";

const InputTagForm = ({ name, onDelete, onChange, disabled }) => {
  const { t } = useContext(I18nContext);
  const [, { value = [] }, { setValue }] = useField({
    name,
  });

  const handleRemoveTags = () => {
    setValue([]);
    onDelete && onDelete();
  };

  useEffect(() => {
    onChange && onChange(value);
  }, [value, onChange]);

  return (
    <div className={styles.row}>
      <TagsInput
        disabled={disabled}
        className={disabled ? [styles.wrapper2] : [styles.wrapper]}
        name="tags"
        value={value}
        tagProps={{
          className: styles["tagsinput_tag"],
          classNameRemove: styles["tagsinput_remove"],
        }}
        inputProps={{
          className: styles["tagsinput_input"],
          placeholder: t("add a tag"),
        }}
        focusedClassName={styles["tagsinput--focused"]}
        onChange={(tags) => {
          setValue(tags);
        }}
      />
      {!disabled && (
        <div onClick={handleRemoveTags}>
          <Icon className={styles.delete_icon} name="x-delete" />
        </div>
      )}
    </div>
  );
};

export default InputTagForm;
