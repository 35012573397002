import React, { useState, useContext } from "react";
import axios from "../../redux/auth";

import PieChartLegendItem from "./subComponents/PieChartLegendItem";
import PieChartCompletedTasksLegend from "./subComponents/PieChartCompletedTasksLegend";
import PieChart from "./subComponents/PieChartView";
import { I18nContext } from "../../i18n/I18nContext";
import { getTasksCount } from "./utils";

import styles from "./PieChartsPartContent.module.scss";
import ActivityIndicator from "../ActivityIndicator";
import useDeepCompareEffect from "use-deep-compare-effect";
import { formatDate } from "../../helpers/helpers";

const PieChartsPartContent = ({ taskPrototypes=[], allChosen=[], filterStartDate, filterEndDate }) => {
  const { t } = useContext(I18nContext);
  const [timelyExecutionOfTasksData, setTimelyExecutionOfTasksData] = useState(
    []
  );
  const [statusOfCompletedTasksData, setStatusOfCompletedTasksData] = useState(
    []
  );
  const [statusOfOpenTasksData, setStatusOfOpenTasksData] = useState([]);
  const [loadingTimelyExecution, setLoadingTimelyExecution] = useState(false);
  const [loadingCompleted, setLoadingCompleted] = useState(false);
  const [loadingOpen, setLoadingOpen] = useState(false);

  useDeepCompareEffect(() => {
    fetchTasksData();
  }, [filterStartDate, filterEndDate, allChosen, taskPrototypes]);

  useDeepCompareEffect(() => {
    fetchOpenTasksData();
  }, [allChosen, taskPrototypes]);

  const fetchTasksData = async () => {
    setLoadingTimelyExecution(true);
    setLoadingCompleted(true);
    const buildingIds = allChosen.map((el) => el.id);
    const urlTimelyExecutionOfTasks = `/dashboard/timelyExecutionOfTasks`;
    const urlStatusOfCompletedTasks = `/dashboard/statusOfCompletedTasks`;
    try {
      const timelyExecutionOfTasksResponse = await axios.post(
        urlTimelyExecutionOfTasks,
        {
          fromDate: filterStartDate,
          toDate: filterEndDate,
          topLevelIds: buildingIds,
          taskPrototypeIds: taskPrototypes,
        }
      );
      const timelyExecutionOfTasksDataArr = timelyExecutionOfTasksResponse.data;

      setTimelyExecutionOfTasksData(timelyExecutionOfTasksDataArr);
      setLoadingTimelyExecution(false);

      const statusOfCompletedTasksResponse = await axios.post(
        urlStatusOfCompletedTasks,
        {
          fromDate: filterStartDate,
          toDate: filterEndDate,
          topLevelIds: buildingIds,
          taskPrototypeIds: taskPrototypes,
        }
      );
      const statusOfCompletedTasksDataArr = statusOfCompletedTasksResponse.data;

      setStatusOfCompletedTasksData(statusOfCompletedTasksDataArr);
      setLoadingCompleted(false);
    } catch (err) {
      console.log(err);
    }
  };

  const STATUS_OPEN_COLORS = {
    TODO: "#64b90c",
    OVERDUE: "#ee7203",
    RECURRING: "#0a49a7",
  };

  const STATUS_OPEN_TEXT = {
    TODO: t("one-off tasks due"),
    OVERDUE: t("one-off tasks overdue"),
    RECURRING: t("recurring tasks")
  };

  const STATUS_CLOSED_COLORS = {
    COMPLETED: "#64b90c",
    MISSED: "#d2042d",
    DONE_TOO_LATE: "#ee7203",
    CANCELLED: "#0a49a7"
  };

  const STATUS_CLOSED_TEXT = {
    COMPLETED: t("on time closed tasks"),
    MISSED: t("missed tasks"),
    DONE_TOO_LATE: t("too late closed tasks"),
    CANCELLED: t("cancelled tasks")
  };

  const RESULT_CLOSED_COLORS = {
    tasksWithDiscrepancies: "#ee7203",
    tasksWithoutDiscrepancies: "#64b90c",
    cannotBeDone: "#d2042d",
  };

  const RESULT_CLOSED_TEXT = {
    tasksWithDiscrepancies: t("completed tasks with nonconformities"),
    tasksWithoutDiscrepancies: t("completed tasks without nonconformities"),
    cannotBeDone: t("tasks couldn't be completed"),
  };

  const fetchOpenTasksData = async () => {
    setLoadingOpen(true);
    const buildingIds = allChosen.map((el) => el.id);
    const urlStatusOfOpenTasks = `/dashboard/statusOfOpenTasks`;
    try {
      const statusOfOpenTasksResponse = await axios.post(urlStatusOfOpenTasks, {
        rootIds: buildingIds,
        prototypeIds: taskPrototypes,
      });
      const statusOfOpenTasksDataArr = statusOfOpenTasksResponse.data;

      setStatusOfOpenTasksData(statusOfOpenTasksDataArr);
      setLoadingOpen(false);
    } catch (err) {
      console.log(err);
    }
  };

  const getDataForStatusOfCompletedTasks = (data) =>
    Object.values(data).map((item, index) => ({
      ...item,
      status: Object.keys(data)[index],
    }));

  const checkIfStatusOfCompletedTasksDataAvailable = (data) => {
    const isCount = Object.values(data).filter((item) => item.count > 0);
    return isCount.length > 0;
  };

  const goToTaskPage = (state) => {
    window.location.replace(
      `task?type=${state}&from=${formatDate(filterStartDate)}&to=${formatDate(filterEndDate)}${taskPrototypes.length ? "&tasktypes=" + taskPrototypes.join(",") : ""}`
    );
  };

  return (
    <div className={styles.pieChartsWrapper}>
      <div className={styles.container} onClick={() => goToTaskPage('open')}>
        <div className={styles.contained_cell}>
          <div className={styles.containedHeader}>
            <span>{t("status of open tasks")}</span>
          </div>
          <div className={styles.pieChartContainer}>
            {!!loadingOpen && (
              <div className={styles.pieChart}>
                <ActivityIndicator />
              </div>
            )}
            {!!statusOfOpenTasksData.filter(x => x?.count !== 0).length <= 0 && !loadingOpen && (
              <span className={styles.tasksInfo}>
                {t("no open tasks found")}
              </span>
            )}
            {!!statusOfOpenTasksData.filter(x => x?.count !== 0).length && !loadingOpen && (
              <div className={styles.chartBox}>
                <div>
                  {statusOfOpenTasksData &&
                    statusOfOpenTasksData.map((el, index) => (
                      <PieChartLegendItem
                        key={index}
                        item={el}
                        tasksCount={getTasksCount(statusOfOpenTasksData)}
                        texts={STATUS_OPEN_TEXT}
                        colors={STATUS_OPEN_COLORS}
                        statusOfOpen
                      />
                    ))}
                </div>
                <div className={styles.pieChart}>
                  <PieChart
                    data={statusOfOpenTasksData ? statusOfOpenTasksData : []}
                    texts={STATUS_OPEN_TEXT}
                    colors={STATUS_OPEN_COLORS}
                    statusOfOpen
                  />
                </div>
                <div className={styles.base}>
                  {statusOfOpenTasksData
                    ?.reduce((accumulator, object) => {
                      return accumulator + object.count;
                    }, 0)
                  }
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={styles.container} onClick={() => goToTaskPage('closed')}>
        <div className={styles.contained_cell}>
          <div className={styles.containedHeader}>
            <span>{t("status of completed tasks")}</span>
          </div>
          <div className={styles.pieChartContainer}>
            {!!loadingTimelyExecution && (
              <div className={styles.pieChart}>
                <ActivityIndicator />
              </div>
            )}
            {timelyExecutionOfTasksData?.length <= 0 &&
              !loadingTimelyExecution && (
                <span className={styles.tasksInfo}>
                  {t("no tasks history for chosen period")}
                </span>
              )}
            {!!timelyExecutionOfTasksData.length && !loadingTimelyExecution && (
              <div className={styles.chartBox}>
                <div>
                  {timelyExecutionOfTasksData &&
                    timelyExecutionOfTasksData.map((el, index) => (
                      <PieChartLegendItem
                        key={index}
                        item={el}
                        tasksCount={getTasksCount(timelyExecutionOfTasksData)}
                        texts={STATUS_CLOSED_TEXT}
                        colors={STATUS_CLOSED_COLORS}
                      />
                    ))}
                </div>
                <div className={styles.pieChart}>
                  <PieChart
                    data={
                      timelyExecutionOfTasksData
                        ? timelyExecutionOfTasksData
                        : []
                    }
                    texts={STATUS_CLOSED_TEXT}
                    colors={STATUS_CLOSED_COLORS}
                  />
                </div>
                <div className={styles.base}>
                  {timelyExecutionOfTasksData
                    ?.reduce((accumulator, object) => {
                      return accumulator + object.count;
                    }, 0)
                  }
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={styles.container} onClick={() => goToTaskPage('result')}>
        <div className={styles.contained_cell}>
          <div className={styles.containedHeader}>
            <span>{t("result of closed tasks")}</span>
          </div>
          <div className={styles.pieChartContainer}>
            {!!loadingCompleted && (
              <div className={styles.pieChart}>
                <ActivityIndicator />
              </div>
            )}
            {!checkIfStatusOfCompletedTasksDataAvailable(
                statusOfCompletedTasksData
              ) &&
              !loadingCompleted && (
                <span className={styles.tasksInfo}>
                  {t("no tasks history for chosen period")}
                </span>
              )}
            {checkIfStatusOfCompletedTasksDataAvailable(
                statusOfCompletedTasksData
              ) &&
              !loadingCompleted && (
                <div className={styles.chartBox}>
                  <PieChartCompletedTasksLegend
                    completedTasksData={statusOfCompletedTasksData}
                    texts={RESULT_CLOSED_TEXT}
                    colors={RESULT_CLOSED_COLORS}
                  />
                  <div className={styles.pieChart}>
                    <PieChart
                      statusOfCompleted
                      data={
                        checkIfStatusOfCompletedTasksDataAvailable(
                          statusOfCompletedTasksData
                        )
                          ? getDataForStatusOfCompletedTasks(
                            statusOfCompletedTasksData
                          )
                          : []
                      }
                      texts={RESULT_CLOSED_TEXT}
                      colors={RESULT_CLOSED_COLORS}
                    />
                  </div>
                  <div className={styles.base}>
                    {getDataForStatusOfCompletedTasks(statusOfCompletedTasksData)
                      ?.reduce((accumulator, object) => {
                        return accumulator + object.count;
                      }, 0)
                    }
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PieChartsPartContent;
