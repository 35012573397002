import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteGroupTask,
  fetchAssetDetails,
  fetchGroupTasks,
  fetchRecurrenceTasksList,
  fetchTasksList,
} from "../../redux";
import Button from "../Button";
import Modal from "../Modal";
import styles from "./DeleteGroupTasksModal.module.scss";
import { I18nContext } from "../../i18n/I18nContext";
import { TaskState } from "../../constants/Tasks";

const DeleteGroupTasksModal = ({
  isOpen,
  onClose,
  group,
  recurrence,
  filters,
  fetchRecurrenceTasksForCurrentAsset,
  assetDetailsId,
}) => {
  const { t } = useContext(I18nContext);
  const dispatch = useDispatch();
  const allChosen = useSelector((state) => state.buildings.choosenBuildings);

  const handleClear = () => {
    onClose();
  };
  const handleDeleteGroup = async () => {
    await dispatch(deleteGroupTask(t, group.id));

    dispatch(
      fetchTasksList(
        TaskState.TODO,
        allChosen.map((el) => el.id)
      )
    );
    dispatch(
      fetchGroupTasks(
        allChosen.map((el) => el.id),
        recurrence ? [] : filters.taskTypeFilter,
        recurrence ? [] : filters.statusFilter,
        recurrence ? null : filters.sort
      )
    );
    fetchRecurrenceTasksForCurrentAsset
      ? fetchRecurrenceTasksForCurrentAsset()
      : dispatch(
          fetchRecurrenceTasksList(
            allChosen.map((el) => el.id),
            !recurrence ? [] : filters.taskTypeFilter,
            !recurrence ? [] : filters.statusFilter,
            !recurrence ? null : filters.sort
          )
        );

    if (assetDetailsId) {
      await dispatch(fetchAssetDetails(assetDetailsId));
    }

    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={`${
        !recurrence ? t("delete group") : t("delete recurrence schedule")
      }?`}
      form="delete-form-group"
      icon
      footer={
        <div className={styles.button_section}>
          <Button
            className={styles.btn_separator}
            onClick={handleClear}
            type="reset"
            form={"delete-form-group"}
            variant="ghost"
            size="s"
          >
            {t("cancel")}
          </Button>
          <Button
            type="submit"
            variant="orange"
            size="s"
            form={"delete-form-group"}
            onClick={handleDeleteGroup}
          >
            {t("delete")}
          </Button>
        </div>
      }
    >
      <div className={styles.delete_txt}>
        {!recurrence
          ? t("are you sure you want to permanently delete task group") + "?"
          : t(
              "are you sure you want to permanently delete recurrence schedule"
            ) + "?"}
      </div>
      <div className={styles.message}>
        {t(
          "creating tasks for multiple assets can take some time, so the tasks might not be visible in the system straight away"
        )}
        .
      </div>
    </Modal>
  );
};

export default DeleteGroupTasksModal;
