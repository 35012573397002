import Actions from "../actions";

const toastReducer = (state = [], action) => {
  switch (action.type) {
    case Actions.CREATE_TOAST:
      return [...state, action.payload];
    case Actions.DELETE_TOAST:
      return state.filter(({ id }) => id !== action.id);
    default:
      return state;
  }
};

export default toastReducer;
