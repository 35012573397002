import Actions from "../actions";

const initialState = {
  items: [],
  top: [],
  notTop: [],
  withinCompany: [],
  withinParent: [],
  error: null,
  assetTypeEditError: "",
  assetTypeEditErrorStatus: null,
};

const prototypesReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.FETCH_PROTOTYPES_SUCCESS:
      return {
        ...state,
        items: action.payload,
      };
    case Actions.FETCH_PROTOTYPES_USED_WITHIN_COMPANY_SUCCESS:
      return {
        ...state,
        withinCompany: action.payload,
      };
    case Actions.FETCH_PROTOTYPES_USED_WITHIN_PARENT_ASSET_SUCCESS:
      return {
        ...state,
        withinParent: action.payload,
      };
    case Actions.FETCH_TOP_PROTOTYPES_SUCCESS:
      return {
        ...state,
        top: action.payload,
      };
    case Actions.FETCH_NOT_TOP_PROTOTYPES_SUCCESS:
      return {
        ...state,
        notTop: action.payload,
      };
    case Actions.FETCH_PROTOTYPES_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case Actions.ASSET_PROTOTYPES_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        assetTypeEditError: action.error,
        assetTypeEditErrorStatus: action.status,
      };
    case Actions.ASSET_PROTOTYPES_EDIT_ERROR_CLEAR:
      return {
        ...state,
        assetTypeEditError: "",
        assetTypeEditErrorStatus: null,
      };
    default:
      return state;
  }
};

export default prototypesReducer;
