import React, {
  useMemo,
  useCallback,
  useEffect,
  useRef,
  useLayoutEffect,
} from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import styles from "./AccordionItem.module.scss";
import { AccordionItemContext, useAccordion } from "./AccordionContext";

const AccordionItem = ({
  children,
  id,
  openClass,
  className,
  parentState,
  updateAsset,
  assetState,
  assetAlertLevel,
  assetIconColor,
  parentsIds,
}) => {
  const { toggle, open } = useAccordion();

  const isOpen = open.includes(id);
  const toggleItem = useCallback(() => {
    toggle(id);
  }, [toggle, id]);

  const firstUpdateParentState = useRef(true);
  const firstUpdateAssetState = useRef(true);

  useLayoutEffect(() => {
    if (firstUpdateParentState.current) {
      firstUpdateParentState.current = false;
      return;
    }
    if (!!parentState && !!updateAsset) {
      updateAsset();
    }
  }, [parentState]);

  useLayoutEffect(() => {
    if (firstUpdateAssetState.current) {
      firstUpdateAssetState.current = false;
      return;
    }
    if (
      !!assetState &&
      !!assetAlertLevel &&
      !!assetIconColor &&
      !!updateAsset
    ) {
      updateAsset(id);
      parentsIds.forEach((parentId) => {
        updateAsset(parentId);
      });
    }
  }, [assetState, assetAlertLevel, assetIconColor]);

  const value = useMemo(
    () => ({
      id,
      isOpen,
      toggleItem,
    }),
    [isOpen, toggleItem, id]
  );

  const classNames = cx(styles.container, className, {
    [styles.open]: isOpen,
    [styles.closed]: !isOpen,
    [openClass]: isOpen,
  });

  return (
    <AccordionItemContext.Provider value={value}>
      <div className={classNames} data-testid={`accordion-item-${id}`}>
        {children}
      </div>
    </AccordionItemContext.Provider>
  );
};

AccordionItem.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  openClass: PropTypes.string,
  className: PropTypes.string,
  tapable: PropTypes.bool,
};

export default AccordionItem;
