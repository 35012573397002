import Actions from "../actions";
import axios from "../auth";
import { createToast } from "../toast/toastActions";
import { formatDate } from "../../helpers/helpers";

const fetchAttachmentsStart = () => {
  return {
    type: Actions.FETCH_ATTACHMENTS_START,
  };
};

const fetchAttachmentsSuccess = (attachments) => {
  return {
    type: Actions.FETCH_ATTACHMENTS_SUCCESS,
    payload: attachments,
  };
};

const fetchAttachmentsFailure = (error) => {
  return {
    type: Actions.FETCH_ATTACHMENTS_FAILURE,
    payload: error,
  };
};

const deleteAttachmentsStart = () => {
  return {
    type: Actions.ATTACHMENTS_DELETE_START,
  };
};

const deleteAttachmentsStop = () => {
  return {
    type: Actions.ATTACHMENTS_DELETE_STOP,
  };
};

const fetchAttachmentsFoldersSuccess = (folders) => {
  return {
    type: Actions.FETCH_ATTACHMENTS_FOLDERS_SUCCESS,
    payload: folders,
  };
};

const fetchAttachmentsTypesSuccess = (types) => {
  return {
    type: Actions.FETCH_ATTACHMENTS_TYPES_SUCCESS,
    payload: types,
  };
};

export const fetchAttachmentsList = (parentId) => {
  return (dispatch) => {
    dispatch(fetchAttachmentsStart());
    return axios
      .get(`/attachments/explorer/${parentId}`)
      .then((response) => {
        dispatch(fetchAttachmentsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchAttachmentsFailure(error));
      });
  };
};

export const addAttachment = (t, assetId, attachment, info) => {
  info.date = formatDate(info.date);
  const formData = new FormData();
  formData.append("file", attachment, attachment.name);
  return (dispatch) => {
    return axios
      .post(
        `/assets/${assetId}/attachment?name=${
          !!info.name ? info.name : attachment.name
        }${info.folderId ? "&folderId=" + info.folderId : ""}${
          info.typeId ? "&typeId=" + info.typeId : ""
        }&date=${info.date}&isSignificant=${info.isSignificant}`,
        formData
      )
      .then((response) => {
        dispatch(
          createToast({
            type: "success",
            title: t("successfully added new attachment"),
          })
        );
      });
  };
};

export const editAttachment = (t, attachment) => {
  return (dispatch) => {
    return axios
      .put(
        `/attachments/${attachment.id}?folderId=${attachment.folderId}&typeId=${attachment.typeId}&name=${attachment.name}&date=${attachment.date}&isSignificant=${attachment.isSignificant}`,
        attachment
      )
      .then((response) => {
        dispatch(
          createToast({
            type: "success",
            title: t("successfully edited attachment"),
          })
        );
      });
  };
};

export const deleteMultipleSelectedAttachments = (t, attachmentIds) => {
  return (dispatch) => {
    dispatch(deleteAttachmentsStart());
    return axios
      .post(`/attachments/delete`, { ids: attachmentIds })
      .then((response) => {
        dispatch(deleteAttachmentsStop());
        dispatch(
          createToast({
            type: "success",
            title: t("successfully deleted attachments"),
          })
        );
      });
  };
};

export const fetchAttachmentsFoldersList = () => {
  return (dispatch) => {
    return axios
      .get(`/attachments/folders`)
      .then((response) => {
        dispatch(fetchAttachmentsFoldersSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchAttachmentsFailure(error));
      });
  };
};

export const addAttachmentsFolder = (t, folder) => {
  return (dispatch) => {
    return axios.post(`/attachments/folder`, folder).then((response) => {
      dispatch(
        createToast({
          type: "success",
          title: t("successfully added new attachments folder"),
        })
      );
    });
  };
};

export const editAttachmentsFolder = (t, folder) => {
  return (dispatch) => {
    return axios.put(`/attachments/folder`, folder).then((response) => {
      dispatch(
        createToast({
          type: "success",
          title: t("successfully updated attachments folder"),
        })
      );
    });
  };
};

export const deleteAttachmentsFolder = (t, id) => {
  return (dispatch) => {
    return axios.delete(`/attachments/folder/${id}`).then((response) => {
      dispatch(
        createToast({
          type: "success",
          title: t("successfully deleted attachments folder"),
        })
      );
    });
  };
};

export const fetchAttachmentsTypesList = () => {
  return (dispatch) => {
    return axios
      .get(`/attachments/types`)
      .then((response) => {
        dispatch(fetchAttachmentsTypesSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchAttachmentsFailure(error));
      });
  };
};

export const addAttachmentsType = (t, type) => {
  return (dispatch) => {
    return axios.post(`/attachments/type`, type).then((response) => {
      dispatch(
        createToast({
          type: "success",
          title: t("successfully added new attachments type"),
        })
      );
    });
  };
};

export const editAttachmentsType = (t, type) => {
  return (dispatch) => {
    return axios.put(`/attachments/type`, type).then((response) => {
      dispatch(
        createToast({
          type: "success",
          title: t("successfully updated attachments type"),
        })
      );
    });
  };
};

export const deleteAttachmentsType = (t, id) => {
  return (dispatch) => {
    return axios.delete(`/attachments/type/${id}`).then((response) => {
      dispatch(
        createToast({
          type: "success",
          title: t("successfully deleted attachments type"),
        })
      );
    });
  };
};

export const downloadAttachmentsInZip = (t, ids) => {
  return (dispatch) => {
    return axios.post(`/attachments/zip`, { ids }).then((response) => {
      dispatch(
        createToast({
          type: "success",
          title: t("successfully generated attachments zip file"),
        })
      );
      return response.data;
    });
  };
};
