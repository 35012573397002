import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import {
  FieldLabel,
  Form,
  FormControl,
  FieldError,
  FieldInput,
  FieldTextArea,
} from "../Input";
import Button from "../Button";
import Modal from "../Modal";
import styles from "./SendEmailModal.module.scss";
import { sendEmailToUser } from "../../redux";
import { I18nContext } from "../../i18n/I18nContext";
import { createToast } from "../../redux";

const SendEmailModal = ({ isOpen, onClose, user }) => {
  const { t } = useContext(I18nContext);
  const dispatch = useDispatch();
  const initialValues = {
    email: user?.email,
    context: "",
    subject: "",
  };

  const handleClear = () => {
    onClose();
  };
  const handleSubmit = async (values, { resetForm }) => {
    const response = await dispatch(
      sendEmailToUser({
        email: values.email,
        context: values.context,
        subject: values.subject,
      })
    );

    if (response.status === 200) {
      dispatch(
        createToast({
          type: "success",
          title: t("an email has been sent"),
        })
      );
    }
    resetForm();
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      className={styles.modal}
      title={t("send e-mail to the user")}
      form="send-form"
      footer={
        <div className={styles.button_section}>
          <Button
            className={styles.btn_separator}
            onClick={handleClear}
            type="reset"
            form={"send-form"}
            variant="ghost"
            size="s"
          >
            {t("cancel")}
          </Button>
          <Button type="submit" variant="orange" size="s" form={"send-form"}>
            {t("send")}
          </Button>
        </div>
      }
    >
      <Form
        id="send-form"
        onSubmit={handleSubmit}
        initialValues={initialValues}
        enableReinitialize
      >
        {() => {
          return (
            <div className={styles.delete_txt}>
              {t("send e-mail to")}:
              <div className={styles.spacing_txt}>{user?.email} </div>
              <FormControl>
                <FieldLabel>
                  {t("input e-mail subject")}
                  <span className={styles.red}> *</span>
                </FieldLabel>
                <FieldInput name="subject" />
                <FieldError name="subject" />
              </FormControl>
              <FormControl>
                <FieldLabel>
                  {t("type your message")}
                  <span className={styles.red}> *</span>
                </FieldLabel>
                <FieldTextArea name="context" />
                <FieldError name="context" />
              </FormControl>
            </div>
          );
        }}
      </Form>
    </Modal>
  );
};

export default SendEmailModal;
