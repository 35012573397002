import React, { useContext, useEffect, useState } from "react";
import * as Yup from "yup";
import {
  FieldLabel,
  Form,
  FormControl,
  FieldError,
  FieldInput,
  Checkbox,
} from "../Input";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchTopAssetPrototypes,
  fetchAssets,
  getPropertiesByProto,
  searchBuildings,
} from "../../redux";
import styles from "./BuildingsForm.module.scss";
import { I18nContext } from "../../i18n/I18nContext";
import SortableSelect from "../Input/SortableSelect";
import Button from "../Button";

function arrayMove(array, from, to) {
  array = array.slice();
  array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
  return array;
}

const defaultInitialValues = {
  name: "",
};

const parseProperty = (el, values) => {
  if (el.propertyType === "text") {
    return {
      name: el.name,
      contents: values[`prop-${el.name}`],
    };
  }
  if (el.propertyType === "numeric") {
    return {
      name: el.name,
      value: values[`prop-${el.name}`],
    };
  }
  if (el.propertyType === "boolean") {
    return {
      name: el.name,
      state: values[`prop-${el.name}`],
    };
  }
};

const BuildingsForm = ({
  onClose,
  formId = "choose-buildings-form",
  setBuildings,
}) => {
  const { t } = useContext(I18nContext);
  const dispatch = useDispatch();
  const [selectedPrototypes, setSelectedPrototypes] = useState([]);
  const [chosenPrototypes, setChosenPrototypes] = useState([]);
  const [selectedProperties, setSelectedProperties] = useState([]);
  const [chosenProperties, setChosenProperties] = useState([]);
  const [initialValues, setInitialValues] = useState(defaultInitialValues);
  const [properties, setProperties] = useState([]);

  const topPrototypes = useSelector((state) => state.prototypes.top);

  // function for sortable select
  const onChangePrototypes = (selectedOptions) => {
    const ids = selectedOptions.map((el) => el.value);
    const filteredPrototypes = topPrototypes.filter((el) =>
      ids.includes(el.id)
    );
    setChosenPrototypes(filteredPrototypes);
    setSelectedPrototypes(selectedOptions);
  };

  const onChangeProperties = (selectedOptions) => {
    const ids = selectedOptions.map((el) => el.value);
    const filteredProperties = properties.filter((el) => ids.includes(el.name));
    setChosenProperties(filteredProperties);
    setSelectedProperties(selectedOptions);
  };
  // function for sortable select
  const onSortEndPrototypes = ({ oldIndex, newIndex }) => {
    const newValue = arrayMove(selectedPrototypes, oldIndex, newIndex);
    setSelectedPrototypes(newValue);
  };

  const onSortEndProperties = ({ oldIndex, newIndex }) => {
    const newValue = arrayMove(selectedProperties, oldIndex, newIndex);
    setSelectedProperties(newValue);
  };

  const handleSubmit = async (values, formik) => {
    const updatedProperties = chosenProperties.map((el) =>
      parseProperty(el, values)
    );

    const objectToSend = {
      name: values?.name || "",
      properties: updatedProperties,
      prototypeIds: chosenPrototypes.map((el) => el.id),
    };

    const buildings = await searchBuildings(objectToSend);

    setBuildings(buildings);

    // formik.resetForm();
  };

  const getProperties = async () => {
    try {
      const properties = await getPropertiesByProto(
        chosenPrototypes.map((el) => el.id)
      );
      setProperties(properties.sort((a, b) => a.name.localeCompare(b.name)));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    // dispatch(fetchAssets());
    dispatch(fetchTopAssetPrototypes());
    getProperties();
    handleSubmit();
  }, [dispatch]);

  useEffect(() => {
    getProperties();
  }, [chosenPrototypes]);

  return (
    <>
      <Form
        id={formId}
        onSubmit={handleSubmit}
        initialValues={initialValues}
        // validationSchema={validationSchema}
        enableReinitialize
      >
        {({ values, errors }) => {
          return (
            <>
              <div className={styles.row}>
                <FormControl classNameCustom={styles.field}>
                  <FieldLabel>{t("name")}</FieldLabel>
                  <FieldInput
                    name="name"
                    type="text"
                    placeholder={t("building name")}
                    size="l"
                  />
                  <FieldError name="name" />
                </FormControl>
                <FormControl className={styles.dropdown_config}>
                  <FieldLabel>{t("choose prototypes")}</FieldLabel>
                  <SortableSelect
                    options={topPrototypes
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map((el) => ({
                        value: el.id,
                        label: el.name,
                        global: el.visibleOnAllCompanies,
                      }))}
                    selected={selectedPrototypes}
                    onChange={onChangePrototypes}
                    onSortEnd={onSortEndPrototypes}
                  />
                  <FieldError name="prototypeIds" />
                </FormControl>
                <FormControl className={styles.dropdown_config}>
                  <FieldLabel>{t("choose properties")}</FieldLabel>
                  <SortableSelect
                    options={properties.map((el) => ({
                      value: el.name,
                      label: el.name,
                    }))}
                    selected={selectedProperties}
                    onChange={onChangeProperties}
                    onSortEnd={onSortEndProperties}
                  />
                  <FieldError name="prototypeIds" />
                </FormControl>
              </div>
              <div className={styles.row}>
                {chosenProperties.map((el) => {
                  if (el.propertyType === "text") {
                    return (
                      <FormControl classNameCustom={styles.field}>
                        <FieldLabel>{t(el.name)}</FieldLabel>
                        <FieldInput
                          name={`prop-${el.name}`}
                          type="text"
                          placeholder={t(el.name)}
                          size="l"
                        />
                        <FieldError name={el.name} />
                      </FormControl>
                    );
                  }
                  if (el.propertyType === "numeric") {
                    return (
                      <FormControl classNameCustom={styles.field}>
                        <FieldLabel>
                          {t(el.name)}
                          <span className={styles.red}> *</span>
                        </FieldLabel>
                        <FieldInput
                          name={`prop-${el.name}`}
                          type="number"
                          placeholder={t(el.name)}
                          size="l"
                        />
                        <FieldError name={el.name} />
                      </FormControl>
                    );
                  }
                  if (el.propertyType === "boolean") {
                    return (
                      <div className={styles.width}>
                        <Checkbox name={`prop-${el.name}`} label={el.name} />
                      </div>
                    );
                  }
                })}
              </div>
              <div className={styles.searchButton}>
                <Button
                  className={styles.btn_separator}
                  type="submit"
                  variant="orange"
                  size="s"
                  form={"choose-buildings-form"}
                >
                  {t("search")}
                </Button>
              </div>
            </>
          );
        }}
      </Form>
    </>
  );
};

export default BuildingsForm;
