import React from "react";
import styles from "./Loader.module.scss";
import ActivityIndicator from "../ActivityIndicator";

const Loader = () => {
  return (
    <div className={styles.loader_wrapper}>
      <ActivityIndicator />
    </div>
  );
};

export default Loader;
