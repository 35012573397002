import React, { useState, useEffect, useContext } from "react";

import Modal from "../Modal";
import { I18nContext } from "../../i18n/I18nContext";
import Button from "../Button";
import styles from "./BuildingsModal.module.scss";
import BuildingsForm from "./BuildingsForm";
import BuildingRow from "./BuildingRow";
import { useDispatch, useSelector } from "react-redux";
import {
  setChoosenBuildings,
  clearChoosenBuildings,
  fetchChosenAssets,
} from "../../redux";

const formId = "choose-buildings-form";

const BuildingsModal = ({ onClose, isOpen }) => {
  const { t } = useContext(I18nContext);
  const [buildings, setBuildings] = useState([]);
  const [selectedBuildings, setSelectedBuildings] = useState([]);
  const dispatch = useDispatch();

  const buildingsFromRedux = useSelector(
    (state) => state.buildings.choosenBuildings
  );

  const handleSetBuildings = (buildings) => {
    setBuildings(buildings);
  };

  const handleClearChoosen = () => {
    onClose();
    dispatch(clearChoosenBuildings());
  };

  const handleToggleSelectBuilding = (building) => {
    if (selectedBuildings.find((el) => el.id === building.id)) {
      setSelectedBuildings((prev) =>
        prev.filter((el) => el.id !== building.id)
      );
    } else {
      setSelectedBuildings((prev) => [
        ...prev,
        { id: building.id, name: building.name },
      ]);
    }
  };

  const handleSetAll = () => {
    setSelectedBuildings(buildings);
  };

  const handleSubmit = () => {
    dispatch(
      setChoosenBuildings(
        selectedBuildings.sort((a, b) => a.name.localeCompare(b.name))
      )
    );
    setSelectedBuildings([]);
    onClose();
  };

  useEffect(() => {
    setSelectedBuildings(buildingsFromRedux);
  }, [buildingsFromRedux]);

  return (
    <Modal
      className={styles.modal}
      noPadding
      isOpen={isOpen}
      onClose={onClose}
      title={t("choose the buildings")}
      form={formId}
      divider={false}
      footer={
        <div className={styles.btn_section}>
          {!!buildingsFromRedux.length && (
            <Button
              onClick={handleClearChoosen}
              type="submit"
              variant="ghost"
              size="s"
            >
              {t("clear chosen buildings")}
            </Button>
          )}
          <Button
            className={styles.btn_separator}
            onClick={onClose}
            variant="ghost"
            size="s"
          >
            {t("cancel")}
          </Button>
          <Button
            className={styles.btn_separator}
            onClick={handleSubmit}
            type="submit"
            variant="orange"
            size="s"
          >
            {t("choose")}
          </Button>
        </div>
      }
    >
      <BuildingsForm setBuildings={handleSetBuildings} />
      <div className={styles.row}>
        {!!buildings.length && (
          <div className={styles.searchResults}>
            <span className={styles.selectAll}>{t("choose buildings")}:</span>
            <div className={styles.buildingList}>
              {buildings.map((el) => (
                <BuildingRow
                  name={el.name}
                  key={el.id}
                  selected={selectedBuildings.find((item) => item.id === el.id)}
                  onSelect={() => handleToggleSelectBuilding(el)}
                />
              ))}
            </div>
          </div>
        )}
        {!!selectedBuildings.length && (
          <div>
            <span className={styles.selectAll}>{t("selected buildings")}:</span>
            <div className={styles.buildingList}>
              {selectedBuildings.map((el) => (
                <BuildingRow
                  selectedItem
                  name={el.name}
                  key={el.id}
                  selected={selectedBuildings.find((item) => item.id === el.id)}
                  onSelect={() => handleToggleSelectBuilding(el)}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default BuildingsModal;
