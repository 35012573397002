import React, { useContext, useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import {
  FieldLabel,
  Form,
  FormControl,
  FieldError,
  FieldInput,
  FieldSelect,
} from "../../components/Input";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../components/Modal";
import { I18nContext } from "../../i18n/I18nContext";
import styles from "./AddSensorModal.module.scss";
import {
  getProceduresByPropertyType,
  createSensor,
  getSensorToEdit,
  editSensor,
} from "../../redux";
import Icon from "../Icon";
import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
} from "../Accordion";
import RuleEditForm from "../Configuration/RuleEditForm";
import AddRulesModal from "../Configuration/AddRulesModal";
import { fetchUnitsList } from "../../redux/units/unitActions";
import { createProperSensorObject, parseProperties } from "./utils";

const basicValues = {
  sensorNameUnique: "",
  sensorId: "",
};

const initialValuesProperty = {
  name: "",
  procedureIds: [],
  unitId: "",
};

const SensorModal = ({ assetId, sensor, isOpen, onClose }) => {
  const { t } = useContext(I18nContext);
  const dispatch = useDispatch();
  const currentValues = useRef(basicValues);
  const [showRule, setShowRule] = useState(false);
  const [initialValues, setInitialValues] = useState(basicValues);
  const [initialState, setInitialState] = useState(initialValuesProperty);
  const [openAddRulesModal, setOpenAddRulesModal] = useState(false);
  const [procedureIds, setSelectedProcedures] = useState([]);
  const [properties, setProperties] = useState([]);
  const [proceduresByType, setProceduresByType] = useState([]);

  const allProcedures = useSelector((state) => state.rules.items ?? []);

  const units = useSelector((state) => state.units.items);

  const unitsOptions = useSelector((state) =>
    state.units.items.map((u) => ({ label: u.name, value: u.id }))
  );

  const validationSchema = Yup.object({
    sensorId: Yup.string().required(t("please enter the id of the sensor")),
    sensorNameUnique: Yup.string().required(
      t("please enter the name of the sensor")
    ),
  });

  const propertySchema = Yup.object({
    name: Yup.string().required(t("please enter the name of the property")),
    unitId: Yup.string()
      .oneOf(unitsOptions.map((item) => item.value))
      .required("please choose the unit of the property"),
  });

  useEffect(() => {
    dispatch(fetchUnitsList());
  }, []);

  useEffect(() => {
    handleFetchProcedures();
  }, [allProcedures]);

  useEffect(() => {
    if (!sensor) {
      currentValues.current = basicValues;
      setInitialValues(basicValues);
      return;
    }

    handleFetchSensor(sensor);
  }, [sensor]);

  const handleClose = () => {
    setProperties([]);
    setShowRule(false);
    onClose();
  };

  const handleFetchSensor = async (sensorId) => {
    try {
      const response = await getSensorToEdit(sensorId);
      currentValues.current = {
        ...currentValues.current,
        sensorId: response.data.id,
        sensorNameUnique: response.data.name,
      };
      setInitialValues(currentValues.current);
      setProperties(parseProperties(response.data.properties, units));
    } catch (err) {
      console.log(err);
    }
  };

  const handleAddRules = () => {
    setShowRule(true);
    if (proceduresByType.length === 0) {
      handleFetchProcedures();
    }
  };

  const handleFetchProcedures = async () => {
    const { data } = await getProceduresByPropertyType("numeric");
    setProceduresByType(data);
  };

  const handleSubmit = async (values, { resetForm }) => {
    const { sensorId, sensorNameUnique } = values;
    if (sensor) {
      try {
        const response = await editSensor(
          sensor,
          createProperSensorObject(
            assetId,
            sensorId,
            sensorNameUnique,
            properties,
            units
          )
        );

        handleClose();
        return;
      } catch (err) {
        console.log(err);
      }
    }

    try {
      const response = await createSensor(
        createProperSensorObject(
          assetId,
          sensorId,
          sensorNameUnique,
          properties,
          units
        )
      );

      handleClose();
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmitProperty = (values, { resetForm }) => {
    setProperties((prev) => [...prev, values]);
    setShowRule(false);
    setSelectedProcedures([]);
    resetForm();
  };

  const handleDeleteProperty = (index) => {
    setProperties((prev) => {
      const prevCopy = [...prev];
      prevCopy.splice(index, 1);
      return prevCopy;
    });
  };

  const handleRuleCreate = (id) => {
    setInitialState({
      ...initialState,
      procedureIds: [...initialState.procedureIds, id],
    });
  };

  return (
    <Modal
      className={styles.modal}
      isOpen={isOpen}
      onClose={handleClose}
      title={sensor ? t("edit sensor", "title") : t("add sensor", "title")}
      form="add-sensor-form"
      editMode={sensor}
    >
      <Form
        id="add-sensor-form"
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
      >
        {({ values, setFieldValue, errors }) => {
          currentValues.current = values;

          return (
            <>
              <FormControl>
                <FieldLabel>
                  {t("sensor id")}
                  <span className={styles.red}> *</span>
                </FieldLabel>
                <FieldInput
                  name="sensorId"
                  type="text"
                  placeholder={t("add sensor id")}
                  size="m"
                />
                <FieldError name="sensorId" />
              </FormControl>
              <FormControl>
                <FieldLabel>
                  {t("name")}
                  <span className={styles.red}> *</span>
                </FieldLabel>
                <FieldInput
                  name="sensorNameUnique"
                  type="text"
                  placeholder={t("add sensor name")}
                  size="m"
                />
                <FieldError name="sensorNameUnique" />
              </FormControl>
            </>
          );
        }}
      </Form>
      {properties &&
        properties.map((property, index) => {
          return (
            <div className={styles.propertyContainer}>
              <span>
                {t("name")}: {property.name}
              </span>
              <span className={styles.unit}>
                {t("unit")}:{" "}
                {property.unitId &&
                  units.find((el) => el.id === property.unitId).name}
              </span>
              <Accordion single>
                {property.procedureIds
                  .map((ruleId) =>
                    proceduresByType.find((rule) => rule.id === ruleId)
                  )
                  .map((rule) => (
                    <AccordionItem key={rule.id} id={rule.id}>
                      <AccordionHeader
                        text={rule.name}
                        hasItem={true}
                        className={styles.accordion_header}
                      />
                      <AccordionPanel>
                        <div className={styles.panel}>
                          <RuleEditForm rule={rule} readOnly={true} />
                        </div>
                      </AccordionPanel>
                    </AccordionItem>
                  ))}
              </Accordion>
              <div
                className={styles.removePropertyBtn}
                onClick={() => handleDeleteProperty(index)}
              >
                <Icon className={styles.delete_icon} name="x-delete" />
              </div>
            </div>
          );
        })}
      <Form
        id="add-proptotype-property"
        onSubmit={handleSubmitProperty}
        initialValues={initialState}
        validationSchema={propertySchema}
        enableReinitialize
      >
        {({ values, setFieldValue, errors }) => {
          return (
            <div className={styles.mainWrapper}>
              <div className={styles.start_row}>
                <FormControl>
                  <FieldLabel>
                    {t("property name")}
                    <span className={styles.red}> *</span>
                  </FieldLabel>
                  <FieldInput
                    name="name"
                    type="text"
                    placeholder={t("property name")}
                    size="m"
                  />
                  <FieldError name="name" />
                </FormControl>
                <FormControl className={styles.units_container}>
                  <FieldLabel>{t("units")}</FieldLabel>
                  <FieldSelect
                    placeholder="Units"
                    size="s"
                    name="unitId"
                    options={unitsOptions}
                  />
                  <FieldError name="unitId" />
                </FormControl>
                <button className={styles.add_btn} type="submit">
                  {`+ ${t("add property")}`}
                </button>
              </div>
              <div className={styles.column}>
                <div className={styles.add_section}>
                  {!showRule && (
                    <span className={styles.cursor} onClick={handleAddRules}>
                      {`+ ${t("add rules")}`}
                    </span>
                  )}
                </div>
              </div>
              {showRule && (
                <div className={styles.column}>
                  <div className={styles.title}>{t("field rules")}</div>
                  <div className={styles.row_section}>
                    <FormControl className={styles.rule_dropdown}>
                      <FieldLabel>
                        {t("rule")}
                        <span className={styles.red}> *</span>
                      </FieldLabel>
                      <FieldSelect
                        size="s"
                        name="procedureIds"
                        isMultiple={true}
                        onChange={(procedureIds) => {
                          setSelectedProcedures(procedureIds);
                        }}
                        options={proceduresByType.map((rule) => ({
                          label: rule.name,
                          value: rule.id,
                        }))}
                        placeholder={t("select existing rules")}
                      />
                      <FieldError name="procedureIds" />
                    </FormControl>
                    <div className={styles.txt}>or</div>
                    <div
                      className={styles.txt1}
                      onClick={() => setOpenAddRulesModal(true)}
                    >
                      {t("create new rule")}
                    </div>
                    <div
                      className={styles.delete_rules}
                      onClick={() => {
                        setShowRule(false);
                        setFieldValue("procedureIds", []);
                        setSelectedProcedures([]);
                      }}
                    >
                      <Icon className={styles.delete_icon} name="x-delete" />
                    </div>
                  </div>
                </div>
              )}
            </div>
          );
        }}
      </Form>
      <Accordion single>
        {procedureIds
          .map((ruleId) => proceduresByType.find((rule) => rule.id === ruleId))
          .map((rule) => (
            <AccordionItem key={rule.id} id={rule.id}>
              <AccordionHeader
                text={rule.name}
                hasItem={true}
                className={styles.accordion_header}
              />
              <AccordionPanel>
                <div className={styles.panel}>
                  <RuleEditForm rule={rule} readOnly={true} />
                </div>
              </AccordionPanel>
            </AccordionItem>
          ))}
      </Accordion>

      <AddRulesModal
        isOpen={openAddRulesModal}
        onCreate={handleRuleCreate}
        onClose={() => setOpenAddRulesModal(false)}
      />
    </Modal>
  );
};

export default SensorModal;
