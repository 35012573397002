import React from "react";
import styles from "./RuleCondition.module.scss";
import BooleanCondition from "./BooleanCondition";
import TextCondition from "./TextCondition";
import DateCondition from "./DateCondition";
import SelectionCondition from "./SelectionCondition";
import NumericCondition from "./NumericCondition";

const RuleCondition = (props) => {
  const {
    condition,
    selectedProperty,
    readOnly,
    selectStyles,
    selectCondition,
  } = props;

  return (
    <div className={styles.condition}>
      {!!selectedProperty && selectedProperty.propertyType === "boolean" && (
        <BooleanCondition
          condition={condition}
          readOnly={readOnly}
          selectStyles={selectStyles}
          selectCondition={(condition) => selectCondition(condition)}
        />
      )}
      {!!selectedProperty && selectedProperty.propertyType === "text" && (
        <TextCondition
          condition={condition}
          readOnly={readOnly}
          selectCondition={(condition) => selectCondition(condition)}
        />
      )}
      {!!selectedProperty && selectedProperty.propertyType === "selection" && (
        <SelectionCondition
          condition={condition}
          readOnly={readOnly}
          selectStyles={selectStyles}
          selectCondition={(condition) => selectCondition(condition)}
          isMultiple={selectedProperty.multiselect}
          isDropdown={selectedProperty.dropdown}
          selectionOptions={selectedProperty.options}
        />
      )}
      {!!selectedProperty && selectedProperty.propertyType === "date" && (
        <DateCondition
          condition={condition}
          readOnly={readOnly}
          selectCondition={(condition) => selectCondition(condition)}
        />
      )}
      {!!selectedProperty && selectedProperty.propertyType === "numeric" && (
        <NumericCondition
          condition={condition}
          readOnly={readOnly}
          selectStyles={selectStyles}
          selectCondition={(condition) => selectCondition(condition)}
        />
      )}
    </div>
  );
};

export default RuleCondition;
