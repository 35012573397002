import { createSelector } from "reselect";

const createAssetsTree = (asset, allAssets) => {
  if (asset.subAssetsCount === 0 && !allAssets[asset.id]) {
    return asset;
  }
  const subAssets = allAssets[asset.id] ?? [];
  return {
    ...asset,
    subAssetsCount:
      asset.subAssetsCount > subAssets.length
        ? asset.subAssetsCount
        : subAssets.length,
    childrenIds: subAssets.map((asset) => asset.id),
    subAssets: subAssets.map((asset) => createAssetsTree(asset, allAssets)),
  };
};

export const getAllAssetsState = (state) => state.assets.allAssets;
export const getTopAssetsState = (state) => state.assets.topAssets;

export const getAllPossibleAssets = createSelector(
  getAllAssetsState,
  getTopAssetsState,
  (allAssets, topAssets) =>
    [...Object.values(allAssets), ...Object.values(topAssets)].flat()
);

export const getAllAssets = createSelector(
  getAllAssetsState,
  getTopAssetsState,
  (allAssets, topAssets) => {
    const subAssets = Object.values(topAssets).map((asset) =>
      createAssetsTree(asset, allAssets)
    );
    return {
      id: "root",
      subAssets,
      subAssetsCount: subAssets.length,
    };
  }
);

export const getBuildingsOptions = createSelector(
  getTopAssetsState,
  (topAssets) => {
    return Object.values(topAssets).map((asset) => ({
      value: asset.id,
      label: asset.name,
    }));
  }
);
