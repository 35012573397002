import Actions from "../actions";

const initialValues = {
  loading: false,
  error: null,
};

const reportsReducer = (state = initialValues, action) => {
  switch (action.type) {
    case Actions.FETCH_REPORT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case Actions.FETCH_REPORT_FINISHED:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default reportsReducer;
