import React, { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { cancelDeletingAccount } from "../../redux";
import Entry from "../../layouts/Entry";
import styles from "./CancelDeletingView.module.scss";
import { I18nContext } from "../../i18n/I18nContext";
import Button from "../../components/Button";
import { appUrls } from "../../urls";

const CancelDeletingView = () => {
  const { t, language, changeLanguage } = useContext(I18nContext);
  const history = useHistory();
  const user = useSelector((state) => state.auth.info);

  const searchParams = new URLSearchParams(document.location.search);
  const languageCode = searchParams.get("languageCode");

  useEffect(() => {
    if (!!languageCode && language !== languageCode) {
      changeLanguage(languageCode);
    }
  }, []);

  const handleSubmit = async () => {
    const response = await cancelDeletingAccount(user.email);

    if (response.status === 200) {
      history.push(appUrls.COMPANY);
    }
  };
  return (
    <Entry>
      <div className={styles.container}>
        <span className={styles.header}>{t("cancel deleting account")}.</span>
        <span className={styles.txt_container}>
          {t(
            "in order to cancel deleting your account please click the button below"
          )}
          .
        </span>
        <Button
          onClick={handleSubmit}
          className={styles.spacing}
          type="submit"
          variant="orange"
          size="m"
        >
          {t("cancel deleting account")}
        </Button>
      </div>
    </Entry>
  );
};

export default CancelDeletingView;
