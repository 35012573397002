import React from "react";
import SidebarSettings from "../../components/Settings/SidebarSettings";
import Sidebar from "../../components/Sidebar";
import styles from "./SettingsLayout.module.scss";

const SettingsLayout = ({ children }) => {
  return (
    <div className={styles.container}>
      <Sidebar />
      <SidebarSettings />
      <div className={styles.main}>{children}</div>
    </div>
  );
};

export default SettingsLayout;
