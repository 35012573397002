export const Role = {
  ADMIN: "ADMIN",
  CUSTOMER: "CUSTOMER",
  CUSTOMER_ADMIN: "CUSTOMER_ADMIN",
  MAINTAINER: "MAINTAINER",
  SUPER_ADMIN: "SUPER_ADMIN",
};

export const RoleOptions = (t) => [
  { value: Role.ADMIN, label: t("admin", "title") },
  { value: Role.CUSTOMER, label: t("customer", "title") },
  { value: Role.MAINTAINER, label: t("maintainer", "title") },
  { value: Role.SUPER_ADMIN, label: t("super admin", "title") },
  { value: Role.CUSTOMER_ADMIN, label: t("customer admin", "title") },
];

export const RightOptions = (t) => [
  { value: "fullAccess", label: t("full access") },
  { value: "water", label: t("water") },
  { value: "construction", label: t("construction") },
  { value: "electricity", label: t("electricity") },
];
