import Vector2 from "../Utils/Vector2";
class Renderer {
    constructor(container) {
        this._size = new Vector2();
        this._container = container;
        this._canvas = document.createElement('canvas');
        this._context = this._canvas.getContext('2d');
        this.setSize(new Vector2(800, 800));
        this._container.appendChild(this._canvas);
    }
    setSize(size) {
        this._size = size;
        this.canvas.width = size.x;
        this.canvas.height = size.y;
    }
    setRenderSize(size) {
        this.canvas.style.width = `${size.x}px`;
        this.canvas.style.height = `${size.y}px`;
    }
    render(scene, camera) {
        this.clear();
        this._context.save();
        let r = camera.render.values;
        this._context.setTransform(r[0][0], r[1][0], r[0][1], -r[1][1], r[0][2], r[1][2]);
        if (scene.background)
            scene.background.draw(this._context);
        if (scene.layers.length)
            scene.layers.forEach(({ element }) => element.draw(this._context, camera.zoomScalar));
        this._context.restore();
    }
    reset() {
        this.setSize(new Vector2(800, 800));
    }
    clear() {
        this._context.save();
        this._context.fillStyle = 'white';
        this._context.fillRect(0, 0, this.size.x, this.size.y);
        this._context.restore();
    }
    get size() { return this._size; }
    get containerSize() { return new Vector2(this._container.clientWidth, this._container.clientHeight); }
    get canvas() { return this._canvas; }
}
export default Renderer;
