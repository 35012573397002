import Actions from "../actions";
import axios from "../auth";
import { createToast } from "../toast/toastActions";
import { refreshToken, refreshSuccess } from "../auth/authActions";
import { downloadFile } from "../../helpers/helpers";

const fetchRegisteredCompaniesSuccess = (companies) => {
  return {
    type: Actions.FETCH_REGISTERED_COMPANIES_SUCCESS,
    payload: companies,
  };
};

const fetchRegisteredCompaniesFailure = (companies) => {
  return {
    type: Actions.FETCH_REGISTERED_COMPANIES_FAILURE,
    payload: companies,
  };
};

const fetchRegisteredRolesSuccess = (roles) => {
  return {
    type: Actions.FETCH_REGISTERED_ROLES_SUCCESS,
    payload: roles,
  };
};

const fetchRegisteredRolesFailure = (roles) => {
  return {
    type: Actions.FETCH_REGISTERED_ROLES_FAILURE,
    payload: roles,
  };
};

// rights & companies & roles

export const fetchRegisteredCompanies = () => {
  return (dispatch) => {
    return axios
      .get(`/company/`)
      .then((response) => {
        dispatch(fetchRegisteredCompaniesSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchRegisteredCompaniesFailure(error));
      });
  };
};

export const fetchRegisteredRoles = () => {
  return (dispatch) => {
    return axios
      .get(`/registration/roles`)
      .then((response) => {
        dispatch(fetchRegisteredRolesSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchRegisteredRolesFailure(error));
      });
  };
};

const fetchUsersListSuccess = (users) => {
  return {
    type: Actions.FETCH_USERS_SUCCESS,
    payload: users,
  };
};

const fetchUsersListFailure = (error) => {
  return {
    type: Actions.FETCH_USERS_FAILURE,
    payload: error,
  };
};

const editUsersRightsSuccess = (users) => {
  return {
    type: Actions.EDIT_USERS_RIGHTS_SUCCESS,
    payload: users,
  };
};

const editUsersRightsFailure = (error) => {
  return {
    type: Actions.EDIT_USERS_RIGHTS_FAILURE,
    payload: error,
  };
};

export const fetchRegisteredUsersList = () => {
  return (dispatch) => {
    return axios
      .get(`/users/`)
      .then((response) => {
        dispatch(fetchUsersListSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchUsersListFailure(error));
      });
  };
};

const userActionPending = () => {
  return {
    type: Actions.USER_ACTION_PENDING,
  };
};

const userActionSuccess = () => {
  return {
    type: Actions.USER_ACTION_SUCCESS,
  };
};

export const addUser = (t, user) => {
  return (dispatch) => {
    dispatch(userActionPending());
    return axios.post(`/registration/`, user).then((response) => {
      dispatch(
        createToast({
          type: "success",
          title: t("successfully added user"),
        })
      );
      if (response?.data?.isUserAlreadyInDifferentCompany) {
        dispatch(
          createToast({
            type: "success",
            title: t("this user already has an account within other customer"),
          })
        );
      }
      dispatch(userActionSuccess());
    });
  };
};

export const checkIfUserExists = (email) => {
  return axios
    .get(`/users/exists?email=${email}`)
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const exportUsersTemplate = (t) => {
  return (dispatch) => {
    return axios
      .post(`/registration/export`)
      .then(({ data }) => {
        downloadFile(data);
      })
      .catch((error) => {
        dispatch(
          createToast({
            type: "error",
            title: t("exporting users template failed"),
          })
        );
      });
  };
};

export const importUsers = (t, values) => {
  const formData = new FormData();
  values.files.forEach((file) => {
    formData.append("file", file, file.name);
  });

  return async (dispatch) => {
    const { data } = await refreshToken();
    await dispatch(refreshSuccess(data));
    return axios
      .post(`/registration/import`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        dispatch(fetchRegisteredUsersList());
        dispatch(
          createToast({
            type: "success",
            title: t("successfully send file"),
          })
        );
        if (response?.data?.file) {
          downloadFile(response.data.file);
        }
        return response;
      });
  };
};

export const resendInvitation = (t, user) => {
  return (dispatch) => {
    return axios
      .post(`/users/resendInvitation/${user.id}`)
      .then((response) => {
        dispatch(
          createToast({
            type: "success",
            title: t("invitation has been sent"),
          })
        );
      })
      .catch((error) => {
        dispatch(
          createToast({
            type: "error",
            title: t("something went wrong"),
          })
        );
      });
  };
};

export const sendMultipleInvitations = (t, userIds) => {
  return (dispatch) => {
    return axios
      .post(`/users/sendInvitation`, { ids: userIds })
      .then((response) => {
        dispatch(
          createToast({
            type: "success",
            title: t("invitations have been sent to selected users"),
          })
        );
      })
      .catch((error) => {
        dispatch(
          createToast({
            type: "error",
            title: t("something went wrong"),
          })
        );
      });
  };
};

export const editSelectedRights = (t, user) => {
  return (dispatch) => {
    return axios
      .put(`/right-edits/edit`, user)
      .then((response) => {
        dispatch(editUsersRightsSuccess(response.data));
        dispatch(
          createToast({
            type: "success",
            title: t("successfully edited user rights"),
          })
        );
      })
      .catch((error) => {
        dispatch(editUsersRightsFailure(error));
      });
  };
};

export const editUser = (t, user) => {
  return (dispatch) => {
    dispatch(userActionPending());
    return axios
      .put(`/users/edit`, user)
      .then((response) => {
        dispatch(editUsersRightsSuccess(response.data));
        dispatch(
          createToast({
            type: "success",
            title: t("successfully edited user"),
          })
        );
        dispatch(userActionSuccess());
      })
      .catch((error) => {
        dispatch(editUsersRightsFailure(error));
      });
  };
};

export const deleteUser = (t, id) => {
  return (dispatch) => {
    return axios.delete(`/users/delete/${id}`).then((response) => {
      dispatch(
        createToast({
          type: "success",
          title: t("successfully deleted user"),
        })
      );
    });
  };
};

//senedEmail
export const sendEmailToUser = (user) => {
  return () => {
    return axios.post(`/users/sendEmailToUser`, user);
  };
};

export const addCompany = (t, company) => {
  return (dispatch) => {
    return axios.post(`/company/create`, company).then((response) => {
      dispatch(
        createToast({
          type: "success",
          title: t("successfully created new company"),
        })
      );
    });
  };
};

export const editCompany = (t, company) => {
  return (dispatch) => {
    return axios
      .put(`/company`, company)
      .then((response) => {
        dispatch(
          createToast({
            type: "success",
            title: t("successfully edited company"),
          })
        );
        return response;
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 409) {
            dispatch(
              createToast({
                type: "error",
                title: t(
                  "there is a difference between license amount and buildings amount"
                ),
              })
            );
          }
        }
      });
  };
};

const fetchUsersWithAssetAccessSuccess = (users) => {
  return {
    type: Actions.FETCH_USERS_WITH_ASSET_ACCESS_SUCCESS,
    payload: users,
  };
};

const fetchUsersWithAssetAccessFailure = (error) => {
  return {
    type: Actions.FETCH_USERS_WITH_ASSET_ACCESS_FAILURE,
    payload: error,
  };
};

export const fetchUsersWithAssetAccess = (assetId) => {
  return (dispatch) => {
    return axios
      .get(`/users/getUsersByAssetIdAccess?assetId=${assetId}`)
      .then((response) => {
        dispatch(fetchUsersWithAssetAccessSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchUsersWithAssetAccessFailure(error));
      });
  };
};

export const saveAssetAccess = (t, assetAccess) => {
  return (dispatch) => {
    return axios.post(`/users/assignAccess/`, assetAccess).then((response) => {
      dispatch(
        createToast({
          type: "success",
          title: t("successfully saved asset access"),
        })
      );
    });
  };
};
