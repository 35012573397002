import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import Button from "../Button";
import Modal from "../Modal";
import * as Yup from "yup";
import {
  Checkbox,
  FieldError,
  FieldInput,
  FieldLabel,
  FieldSelect,
  Form,
  FormControl,
} from "../Input";
import { Role } from "../../constants/Access";
import styles from "./UserFormModal.module.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  addMaintainer,
  getMaintainerOrganizationsRights,
  getMaintainersOrganizationList,
} from "../../redux/maintainers/maintainersActions";
import { I18nContext } from "../../i18n/I18nContext";
import RightsBuildingFilterModal from "./RightsBuildingFilterModal";
import { checkIfUserExists } from "../../redux";
import Icon from "../Icon";

const defaultState = {
  username: "",
  email: "",
  maintainerOrgId: null,
  rightIds: [],
  delay: false,
};

const MaintainerFormModal = ({ onClose, isOpen, user, org }) => {
  const { t } = useContext(I18nContext);
  const [initialValuesForm, setInitialValues] = useState(defaultState);
  const [openFiltersModal, setOpenFiltersModal] = useState(false);
  const [assetIds, setAssetIds] = useState([]);
  const [userExists, setUserExists] = useState(false);
  const dispatch = useDispatch();
  const userRole = window.sessionStorage.getItem("role");
  const formRef = useRef();
  const maintainerRoleId = useSelector((state) => state.access.rolesItems).find(
    (role) => role.role === Role.MAINTAINER
  ).id;
  const rightOptions = useSelector((state) =>
    state.maintainers.maintainerOrganizationsRightsList.map((item) => ({
      value: item.id,
      label: item.name,
    }))
  );

  const organizationOptions = useSelector((state) =>
    state.maintainers.maintainerOrganizationsList.map((item) => ({
      value: item.id,
      label: item.name,
    }))
  );

  const userEmails = useSelector((state) =>
    state.access.userItems.map((u) => u.email)
  );

  const orgEmails = org?.users.map((user) => user.email) ?? [];

  const selectUniqueAssets = (state) => {
    const assets = state.assets.searchAssets;
    const uniqueItems = [];
    for (let item of assets) {
      if (!uniqueItems.find((i) => i.id === item.id)) {
        uniqueItems.push(item);
      }
    }
    return uniqueItems;
  };
  const assetNameSearchOptions = useSelector(selectUniqueAssets);

  const handleCheck = useCallback(
    (checked) => {
      setAssetIds(checked);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setAssetIds]
  );

  const deselectGroupingAsset = (id) => {
    let newAssetIds = [...assetIds];
    const index = newAssetIds.indexOf(id);
    newAssetIds.splice(index, 1);
    setAssetIds(newAssetIds);
  };

  const validationSchema = Yup.object({
    username: Yup.string().required(t("missing user name")),
    email: Yup.mixed()
      .notOneOf([...userEmails, ...orgEmails], t("email must be unique"))
      .required(t("missing email")),
    rightIds: Yup.array(),
  });

  useEffect(() => {
    if (user) {
      setInitialValues({
        username: user.username,
        email: user.email,
        rightIds: user.rightIds,
        maintainerOrgId: user.access.company.id,
      });
      setAssetIds(user.access.assets?.map((assetId) => assetId));
    }
  }, [user]);

  const handleClear = () => {
    setInitialValues(defaultState);
    setAssetIds([]);
    onClose();
  };

  useEffect(() => {
    if (org) {
      dispatch(getMaintainerOrganizationsRights(org.id));
    }
  }, [dispatch, org]);

  const handleSubmit = async (values, { resetForm }) => {
    await dispatch(
      addMaintainer(t, {
        maintainerOrgId: values.maintainerOrgId ?? org.id,
        username: values.username,
        email: values.email,
        roleId: maintainerRoleId,
        rightIds: values.rightIds,
        assets: assetIds,
        mailDelayed: !!userExists ? false : values.delay,
      })
    );
    dispatch(getMaintainersOrganizationList());
    resetForm();
    onClose();
  };

  const handleCheckIfUserExists = async (email) => {
    const userExists = await checkIfUserExists(email);
    setUserExists(userExists);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t("add maintainer")}
      form="user-form"
      footer={
        <div className={styles.button_section}>
          <Button
            onClick={handleClear}
            type="reset"
            form={"user-form"}
            variant="ghost"
            size="s"
          >
            {t("cancel")}
          </Button>
          <Button
            className={styles.btn_separator}
            type="submit"
            variant="orange"
            size="s"
            form={"user-form"}
          >
            {t("add")}
          </Button>
        </div>
      }
    >
      <Form
        id="user-form"
        onSubmit={handleSubmit}
        initialValues={initialValuesForm}
        validationSchema={validationSchema}
        enableReinitialize
        refForm={formRef}
      >
        {() => {
          return (
            <>
              <FormControl>
                <FieldLabel>
                  {t("name")}
                  <span className={styles.red}>*</span>
                </FieldLabel>
                <FieldInput size="l" name="username" type="text" />
                <FieldError name="username" />
              </FormControl>
              <FormControl>
                <FieldLabel>
                  {t("e-mail")}
                  <span className={styles.red}>*</span>
                </FieldLabel>
                <FieldInput
                  size="l"
                  name="email"
                  type="email"
                  onBlur={(value) => handleCheckIfUserExists(value)}
                />
                <FieldError name="email" />
              </FormControl>
              {userRole === Role.SUPER_ADMIN && (
                <>
                  <FormControl className={styles.dropdown}>
                    <FieldLabel>
                      {t("maintainer organization name")}
                      <span className={styles.red}>*</span>
                    </FieldLabel>
                    <FieldSelect
                      name="maintainerOrgId"
                      size="s"
                      options={organizationOptions}
                      placeholder={t("maintainer organization name")}
                    />
                    <FieldError name="maintainerOrgId" />
                  </FormControl>
                </>
              )}
              <FormControl className={styles.dropdown}>
                <FieldLabel>
                  {t("access")}
                  <span className={styles.red}>*</span>
                </FieldLabel>
                <FieldSelect
                  name="rightIds"
                  isMultiple={true}
                  size="s"
                  options={rightOptions}
                  placeholder={t("access rights")}
                />
                <FieldError name="rightIds" />
              </FormControl>

              <div className={styles.grouping_assets_access_wrapper}>
                <div className={styles.grouping_assets_access_label}>
                  {t("grouping assets access")}
                  <span className={styles.red}>*</span>
                </div>
                <div className={styles.grouping_assets_access_box}>
                  <div className={styles.grouping_assets_access_input}>
                    {!assetIds.length ? (
                      <div
                        className={
                          styles.grouping_assets_access_input_placeholder
                        }
                        onClick={() => setOpenFiltersModal(true)}
                      >
                        {t("choose grouping assets to give rights")}
                      </div>
                    ) : (
                      <div
                        className={styles.grouping_assets_access_input_values}
                      >
                        {assetIds.map((assetId) => (
                          <div
                            className={
                              styles.grouping_assets_access_input_value
                            }
                            key={assetId}
                          >
                            <div
                              className={
                                styles.grouping_assets_access_input_value_label
                              }
                            >
                              {
                                assetNameSearchOptions.find(
                                  (x) => x.id === assetId
                                ).name
                              }
                            </div>
                            <div
                              className={
                                styles.grouping_assets_access_input_value_icon
                              }
                              onClick={() => deselectGroupingAsset(assetId)}
                            >
                              <Icon name="x-delete-black" />
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  <div
                    className={styles.grouping_assets_access_icon}
                    onClick={() => setOpenFiltersModal(true)}
                  >
                    <Icon name="editPencil-orange" />
                  </div>
                </div>
              </div>

              {!user && !userExists && (
                <>
                  <Checkbox
                    disabled={false}
                    name="delay"
                    label={t("delay invitation")}
                  />
                </>
              )}
            </>
          );
        }}
      </Form>
      {!!openFiltersModal && (
        <RightsBuildingFilterModal
          isOpen={openFiltersModal}
          onCheck={handleCheck}
          checked={assetIds}
          onClose={() => setOpenFiltersModal(false)}
        />
      )}
    </Modal>
  );
};

export default MaintainerFormModal;
