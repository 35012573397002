import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import { deleteAssetLayout, fetchAssets } from "../../redux/";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import styles from "./DeleteLayoutModal.module.scss";
import { I18nContext } from "../../i18n/I18nContext";

const DeleteLayoutModal = ({ isOpen, onClose, asset, setLayoutImage }) => {
  const { t } = useContext(I18nContext);
  const dispatch = useDispatch();

  const handleClear = () => {
    onClose();
  };
  const handleDeleteLayout = async () => {
    const res = await dispatch(deleteAssetLayout(t, asset.id));
    if (res.status === 200) {
      setLayoutImage("");
      await dispatch(fetchAssets(asset.parentId));
    }
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={`${t("delete layout")}?`}
      form="delete-form-layout"
      icon
      footer={
        <div className={styles.button_section}>
          <Button
            className={styles.btn_separator}
            onClick={handleClear}
            type="reset"
            form={"delete-form-layout"}
            variant="ghost"
            size="s"
          >
            {t("cancel")}
          </Button>
          <Button
            type="submit"
            variant="orange"
            size="s"
            form={"delete-form-layout"}
            onClick={handleDeleteLayout}
          >
            {t("delete")}
          </Button>
        </div>
      }
    >
      <div className={styles.delete_txt}>
        {t("are you sure you want to permanently delete layout from asset")}
        <div className={styles.spacing_txt}>{asset?.name} ?</div>
      </div>
    </Modal>
  );
};

export default DeleteLayoutModal;
