import React, { useContext, useEffect, useState } from "react";
import * as Yup from "yup";
import {
  FieldLabel,
  Form,
  FormControl,
  FieldError,
  DatePicker,
  FieldSelect,
} from "../Input";
import Modal from "../Modal";
import TaskPrototypeForm from "../Configuration/TaskPrototypeForm";
import { I18nContext } from "../../i18n/I18nContext";
import { useDispatch, useSelector } from "react-redux";
import {
  addTaskFromPrototype,
  fetchAssetDetails,
  fetchAssetsNameList,
  fetchTasksList,
} from "../../redux";
import { TaskState } from "../../constants/Tasks";
import styles from "./TasksModal.module.scss";
import { differenceInCalendarDays } from "date-fns";
import { createAssetDisplayedPropertiesString } from "../../helpers/helpers";
import useDeepCompareEffect from "use-deep-compare-effect";
import ActivityIndicator from "../ActivityIndicator";

const basicValues = {
  dueDate: new Date(),
  templateId: "",
  assetId: "",
};

const OneOfTaskModal = ({ onClose, isOpen, currentDetails }) => {
  const { t } = useContext(I18nContext);
  const dispatch = useDispatch();
  const [selected, setSelectedTemplate] = useState(null);
  const [edited, setEditedTemplate] = useState(null);
  const [initialValues, setInitialValues] = useState(basicValues);
  const [taskPrototypeErrors, setTaskPrototypeErrors] = useState(null);
  const [templatedNotSelected, setTemplateNotSelected] = useState(false);
  const [desc, setDesc] = useState("");

  const taskPrototypes = useSelector((state) => state.taskPrototypes.items);
  const assetNames = useSelector((state) => state.assets.items ?? []);
  const loadingAssetNames = useSelector(
    (state) => state.assets.loadingAssetNames
  );
  const allChosen = useSelector((state) => state.buildings.choosenBuildings);

  useDeepCompareEffect(() => {
    if (!currentDetails) {
      if (allChosen.length > 0) {
        dispatch(fetchAssetsNameList(allChosen.map((el) => el.id)));
      } else {
        dispatch(fetchAssetsNameList());
      }
    }
  }, [allChosen]);

  useEffect(() => {
    if (currentDetails) {
      setInitialValues({ ...basicValues, assetId: currentDetails.id });
    }
  }, [currentDetails, isOpen]);

  const validationSchema = Yup.object({
    templateId: Yup.string().required(t("please select one of the options")),
    assetId: Yup.string().required(t("please select one of the options")),
    dueDate: Yup.date()
      .required(t("due date is required"))
      .test(
        "dueDate",
        t("due date cannot be in the past"),
        (value) => differenceInCalendarDays(value, new Date()) >= 0
      ),
  });

  const clearForm = (formik) => {
    setSelectedTemplate(null);
    setEditedTemplate(null);

    setTemplateNotSelected(false);
    setInitialValues(basicValues);
    formik?.resetForm();
  };

  const handleSubmit = async (values, formik) => {
    if (Object.keys(taskPrototypeErrors).length > 0) {
      return;
    }

    const template = edited ?? selected;
    const {
      forms,
      formsIds,
      createdAt,
      sections,
      attachments,
      ...restOfTemplate
    } = template;

    const assetId = currentDetails?.id ? currentDetails.id : values.assetId;

    await dispatch(
      addTaskFromPrototype(t, template.id, {
        ...restOfTemplate,
        dueDate: values.dueDate,
        assetId: values.assetId,
        desc: desc ? desc : null,
        tags: [],
      })
    );
    await dispatch(
      fetchTasksList(
        TaskState.TODO,
        allChosen.map((el) => el.id)
      )
    );

    await dispatch(fetchAssetDetails(assetId));
    clearForm(formik);
    setDesc("");
    onClose();
  };

  const handleClose = () => {
    clearForm();
    onClose();
  };

  const customAssetOptionLabel = (option) => {
    const isPath = !!option.asset?.path && option.asset?.path.length > 1;
    const isProperties =
      !!option.asset?.displayedProperties &&
      !!option.asset?.displayedProperties.length;
    return (
      <div className={styles.assetOptionLabel}>
        <span className={styles.assetOptionPath}>
          {isPath ? option.asset.path.slice(0, -1).join(" / ") + " / " : ""}
        </span>
        <span className={styles.assetOptionName}>
          {option.label}
          {isProperties
            ? createAssetDisplayedPropertiesString(option.asset, t)
            : ""}
        </span>
      </div>
    );
  };

  return (
    <Modal
      className={styles.modal}
      isOpen={isOpen}
      onClose={handleClose}
      lengthInfo
      divider
      title={t("one of task")}
      form="oneOf-task-form"
      pending={!!loadingAssetNames}
    >
      {!!loadingAssetNames && <ActivityIndicator withWrapper />}
      {!loadingAssetNames && (
        <Form
          id="oneOf-task-form"
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize={true}
        >
          {(props) => {
            if (templatedNotSelected && !props.touched.templateId) {
              props.setFieldTouched("templateId");
            }
            return (
              <>
                <FormControl>
                  <FieldLabel>
                    {t("assign task to asset")}
                    <span className={styles.red}> *</span>
                  </FieldLabel>
                  <div>
                    <FieldSelect
                      size="s"
                      isMultiple={false}
                      name="assetId"
                      disabled={currentDetails?.id}
                      singleValue={currentDetails?.id}
                      options={
                        !currentDetails
                          ? assetNames
                              .map((asset) => ({
                                label: asset.name,
                                value: asset.id,
                                asset: asset,
                              }))
                              .sort((a, b) =>
                                a.asset.path
                                  .join()
                                  .localeCompare(b.asset.path.join())
                              )
                          : [
                              {
                                label: currentDetails.name,
                                value: currentDetails.id,
                                asset: currentDetails,
                              },
                            ]
                      }
                      placeholder={t("assign task to asset")}
                      customLabel={customAssetOptionLabel}
                    />
                  </div>
                  <FieldError name="assetId" />
                </FormControl>
                <div className={styles.fullRow}>
                  <FormControl>
                    <FieldLabel>
                      {t("task template")}
                      <span className={styles.red}> *</span>
                    </FieldLabel>
                    <div className={styles.width}>
                      <FieldSelect
                        size="s"
                        name="templateId"
                        options={taskPrototypes
                          .filter(
                            (taskProto) =>
                              !taskProto.recurrence && !taskProto.deprecated
                          )
                          .sort((a, b) => a.name.localeCompare(b.name))
                          .map((proto) => ({
                            label: proto.name,
                            value: proto.id,
                            global: proto.global,
                          }))}
                        onChange={(templateId) => {
                          setTemplateNotSelected(false);
                          setSelectedTemplate(
                            taskPrototypes.find(
                              (template) => template.id === templateId
                            )
                          );
                        }}
                        placeholder={t("task")}
                      />
                    </div>
                    <FieldError name="templateId" />
                  </FormControl>
                  <FormControl>
                    <FieldLabel>
                      {t("due date")} <span className={styles.red}>*</span>
                    </FieldLabel>
                    <DatePicker name="dueDate" withTime={false} />
                    <FieldError name="dueDate" />
                  </FormControl>
                </div>
              </>
            );
          }}
        </Form>
      )}
      {selected && (
        <TaskPrototypeForm
          noTaskTypeName={true}
          readOnly={true}
          allowEditingTags={false}
          recurranceRequired={false}
          taskPrototype={selected}
          onErrors={setTaskPrototypeErrors}
          onEdit={setEditedTemplate}
          disabled
          noRulesEditor={true}
          addTask={true}
        />
      )}
    </Modal>
  );
};
export default OneOfTaskModal;
