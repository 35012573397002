var __awaiter =
  (this && this.__awaiter) ||
  function (thisArg, _arguments, P, generator) {
    function adopt(value) {
      return value instanceof P
        ? value
        : new P(function (resolve) {
            resolve(value);
          });
    }
    return new (P || (P = Promise))(function (resolve, reject) {
      function fulfilled(value) {
        try {
          step(generator.next(value));
        } catch (e) {
          reject(e);
        }
      }
      function rejected(value) {
        try {
          step(generator["throw"](value));
        } catch (e) {
          reject(e);
        }
      }
      function step(result) {
        result.done
          ? resolve(result.value)
          : adopt(result.value).then(fulfilled, rejected);
      }
      step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
  };
import Scene from "./Core/Scene/Scene";
import Renderer from "./Core/Renderer";
import ImageLoader from "./Utils/ImageLoader";
import Camera from "./Core/Camera";
import Controller from "./Core/Controller/Controller";
import Vector2 from "./Utils/Vector2";
import Background from "./CanvasObjects/Objects/Background";
import Poi from "./CanvasObjects/Objects/Poi";
class CanvasFacade {
  constructor(_viewerConfig) {
    this._viewerConfig = _viewerConfig;
    this.scene = new Scene();
    this.camera = new Camera();
  }
  mount(container) {
    this.container = container;
    this.renderer = new Renderer(container);
    this.controller = new Controller(this.scene, this.renderer, this.camera);
    this.controller.init();
    this.renderer.render(this.scene, this.camera);
  }
  unmount() {
    this.container.removeChild(this.renderer.canvas);
    this.controller.stop();
  }
  setMode(mode) {
    this.controller.mode = mode;
    return this.controller.mode;
  }
  loadLayout(layout) {
    var _a;
    return __awaiter(this, void 0, void 0, function* () {
      if (this.scene.layers.length) this.clear();
      yield this.loadBackground(layout.background);
      (_a = layout.pois) === null || _a === void 0
        ? void 0
        : _a.reduce(
            (previousPromise, poi) =>
              __awaiter(this, void 0, void 0, function* () {
                yield previousPromise;
                yield this.addPoi(poi);
              }),
            Promise.resolve()
          );
    });
  }
  remove() {
    if (this.controller.selectedElement) {
      this.scene.remove(this.controller.selectedElement);
      this.renderer.render(this.scene, this.camera);
    }
  }
  setZoom(zoom) {
    this.camera.setZoom(zoom);
    this.renderer.render(this.scene, this.camera);
    return this.camera.zoomScalar;
  }
  zoomIn() {
    this.camera.zoomIn();
    this.renderer.render(this.scene, this.camera);
    return this.camera.zoomScalar;
  }
  zoomOut() {
    this.camera.zoomOut();
    this.renderer.render(this.scene, this.camera);
    return this.camera.zoomScalar;
  }
  clear() {
    this.scene.clear();
    this.renderer.reset();
    this.controller.selectedElement = null;
    this.controller.handleResize();
    this.renderer.render(this.scene, this.camera);
  }
  getPois() {
    return this.scene.layers.reduce((acc, layer) => {
      acc[layer.element.id] = {
        x: layer.element.position.x,
        y: layer.element.position.y,
      };
      return acc;
    }, {});
  }
  loadBackground(source) {
    return __awaiter(this, void 0, void 0, function* () {
      const image = yield ImageLoader.loadFromUrl(source);
      const imageSize = new Vector2(image.width, image.height);
      this.renderer.setSize(imageSize);
      this.controller.handleResize();
      this.scene.setBackground(new Background(image));
      this.renderer.render(this.scene, this.camera);
    });
  }
  addPoi(config) {
    return __awaiter(this, void 0, void 0, function* () {
      const colors = this._viewerConfig.colors[config.color];
      const image = yield ImageLoader.loadSVG(
        this._viewerConfig.icons[config.icon] ? this._viewerConfig.icons[config.icon] : config.icon,
        colors[0]
      );
      this.scene.add(new Poi(image, config, colors, this.camera._renderScaleScalar));
      this.renderer.render(this.scene, this.camera);
    });
  }
}
export default CanvasFacade;
