import Actions from "../actions";
import axios from "../auth";
import { createToast } from "../toast/toastActions";

const fetchRulesSuccess = (rules) => {
  return {
    type: Actions.FETCH_RULES_SUCCESS,
    rules,
  };
};

const fetchRulesError = (error) => {
  return {
    type: Actions.FETCH_RULES_ERROR,
    error,
  };
};

const fetchRulesRequest = () => {
  return {
    type: Actions.FETCH_RULES_REQUEST,
  };
};

export const fetchRules = () => {
  return (dispatch) => {
    dispatch(fetchRulesRequest());

    return axios.post("/procedures").then(({ data }) => {
      dispatch(fetchRulesSuccess(data));
    });
  };
};

const fetchTaskTypesWithRulesSuccess = (types) => {
  return {
    type: Actions.FETCH_TASK_TYPES_WITH_RULES_SUCCESS,
    types,
  };
};

const fetchTaskTypesWithRulesRequest = () => {
  return {
    type: Actions.FETCH_TASK_TYPES_WITH_RULES_REQUEST,
  };
};

const fetchTaskTypesWithRulesError = (error) => {
  return {
    type: Actions.FETCH_TASK_TYPES_WITH_RULES_ERROR,
    error,
  };
};

export const fetchTaskTypesWithRulesForSelectedCompany = (
  companyId,
  taskPrototypeIds
) => {
  return (dispatch) => {
    dispatch(fetchTaskTypesWithRulesRequest());

    return axios
      .post("/taskPrototypes/procedures", { companyId, taskPrototypeIds })
      .then(({ data }) => {
        dispatch(fetchTaskTypesWithRulesSuccess(data));
      });
  };
};

export const addRule = (t, rule) => {
  return (dispatch) => {
    return axios.post("/procedures", rule).then(() => {
      dispatch(
        createToast({
          type: "success",
          title: t("successfully added rule"),
        })
      );
    });
  };
};

export const updateRule = (t, ruleId, rule) => {
  return (dispatch) => {
    return axios.put(`/procedures/${ruleId}`, rule).then(() => {
      dispatch(
        createToast({
          type: "success",
          title: t("successfully edited rule"),
        })
      );
    });
  };
};

export const deleteRule = (t, ruleId, objectId, objectType) => {
  return (dispatch) => {
    return axios
      .delete(`/procedures/${ruleId}?${objectType}=${objectId}`)
      .then(() => {
        dispatch(
          createToast({
            type: "success",
            title: t("successfully deleted rule"),
          })
        );
      });
  };
};

export const getProceduresByPropertyType = (propertyType) => {
  return axios.get(`/procedures/type?type=${propertyType.toUpperCase()}`);
};
