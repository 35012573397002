import { Route, Switch } from "react-router-dom";

import { appUrls } from "./urls";
import ValidateLogin from "./views/Login/ValidateLogin";
import InstallApp from "./views/Login/InstallApp";
import ForgotPassword from "./views/Login/ForgotPassword";
import ResetPassword from "./views/Login/ResetPassword";
import RequestActivation from "./views/Login/RequestActivation";
import ConfirmAccount from "./views/Login/ConfirmAccount";
import Login from "./views/Login/Login";
import Toast from "./components/Toast";

import React, { useContext } from "react";
import { I18nContext } from "./i18n/I18nContext";
import Loader from "./components/Loader";
import PrivateRoute from "./PrivateRoute";
import MainApp from "./MainApp";
import CancelDeletingView from "./views/Login/CancelDeletingView";
import RequestEmailChange from "./views/Login/RequestEmailChange";
import ConfirmEmailChange from "./views/Login/ConfirmEmailChange";

const App = () => {
  const { pending } = useContext(I18nContext);

  if (pending) return <Loader />;
  return (
    <>
      <Toast />
      <Switch>
        <Route exact path={appUrls.LOGIN} component={Login} />
        <Route exact path={appUrls.VALIDATE_LOGIN} component={ValidateLogin} />
        <Route exact path={appUrls.INSTALL_APP} component={InstallApp} />
        <Route
          exact
          path={appUrls.FORGOT_PASSWORD}
          component={ForgotPassword}
        />
        <Route path={appUrls.RESET_PASSWORD} component={ResetPassword} />
        <Route
          path={appUrls.REQUEST_ACTIVATION}
          component={RequestActivation}
        />
        <Route path={appUrls.CONFIRM_ACCOUNT} component={ConfirmAccount} />
        <Route
          path={appUrls.REQUEST_EMAIL_CHANGE}
          component={RequestEmailChange}
        />
        <Route
          path={appUrls.CONFIRM_EMAIL_CHANGE}
          component={ConfirmEmailChange}
        />
        <Route
          exact
          path={appUrls.CANCELDELETING}
          component={CancelDeletingView}
        />

        <PrivateRoute path={"/"} component={MainApp} />
      </Switch>
    </>
  );
};

export default App;
