import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import styles from "./FormControl.module.scss";

const FormControl = ({ children, classNameCustom, taskPreview, ...rest }) => {
  return (
    <div
      className={
        taskPreview
          ? cx(styles.input_container_no_fixed_height, classNameCustom)
          : cx(styles.input_container, classNameCustom)
      }
      {...rest}
    >
      {children}
    </div>
  );
};

FormControl.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FormControl;
