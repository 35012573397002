import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import cx from "classnames";
import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
} from "../Accordion";
import Button from "../Button";
import Icon from "../Icon";
import MeatballMenu from "./MeatballMenu";
import AccessFilteredSearch from "./AccessFilteredSearch";
import CategoryFormModal from "./CategoryFormModal";
import AccessRightTable from "./AccessRightTable";
import DeleteFormModal from "./DeleteFormModal";
import styles from "./RightContent.module.scss";
import { I18nContext } from "../../i18n/I18nContext";
import { Role } from "../../constants/Access";

const RightContent = ({ items = [], privilegedCompany }) => {
  const { t } = useContext(I18nContext);
  const role = useSelector((state) => state.auth.info?.role);
  const [openAddCategoryModal, setOpenAddCategoryModal] = useState(false);
  const [openEditItemModal, setEditItemModalOpen] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [searchString, setSearchString] = useState(null);
  const [filteredItems, setFilteredItems] = useState(items);

  const [selectedRight, setSelectedRight] = useState(null);
  const [meatballOpened, setMeatballOpened] = useState(null);

  const rights = useSelector((state) => state.rights.items);
  const assetPrototypes = useSelector((state) => state.prototypes.items);
  const taskTemplates = useSelector((state) => state.taskPrototypes.items);

  useEffect(() => {
    let filtered = items;
    if (searchString) {
      filtered = filtered.filter(
        (item) =>
          item.assetTemplates.find((assetPrototypeId) =>
            assetPrototypes
              .find((p) => p.id === assetPrototypeId)
              ?.name?.toLowerCase()
              .includes(searchString.toLowerCase())
          ) ||
          item.taskTemplates.find((taskTemplateId) =>
            taskTemplates
              .find((t) => t.id === taskTemplateId)
              ?.name.toLowerCase()
              .includes(searchString.toLowerCase())
          )
      );
    }
    setFilteredItems(filtered);
  }, [searchString, items, rights]);

  const meatBallsItems = (category) => [
    {
      label: t("edit"),
      show: true,
      onClick: () => {
        setEditItemModalOpen(true);
        setSelectedRight(category);
      },
      icon: <Icon name="editPencil-orange" className={styles.icon_menu} />,
    },
    {
      label: t("delete"),
      show: true,
      onClick: () => {
        setOpenDeleteModal(true);
        setSelectedRight(category);
      },
      //TODO onClick: handleDelete, TODO: add when delete endpoint is ready
      icon: <Icon name="trash-orange" className={styles.icon_menu} />,
    },
  ];

  return (
    <>
      <div className={styles.column}>
        <div className={styles.filters_section}>
          <div>
            <AccessFilteredSearch
              placeholder
              onSearchChange={setSearchString}
            />
          </div>
          <div className={styles.row}>
            <div>
              <Button
                variant="orange"
                size="s"
                onClick={() => setOpenAddCategoryModal(true)}
              >
                + {t("add new category")}
              </Button>
            </div>
          </div>
        </div>

        <Accordion>
          {filteredItems.map((right) => {
            return (
              <AccordionItem
                key={right.id}
                id={right.id}
                className={cx(styles.container_item, {
                  [styles.visible_for_sub_companies]: right.isGlobal,
                })}
              >
                <AccordionHeader
                  text={right.category}
                  className={styles.accordion_header}
                  hasItem={true}
                  rightIcon={
                    !right.isGlobal || privilegedCompany ? (
                      <MeatballMenu
                        items={meatBallsItems(right)}
                        id={right.id}
                        meatballOpened={meatballOpened}
                        meatballOpenedObserver={(state) =>
                          setMeatballOpened(state)
                        }
                        disabled={role === Role.CUSTOMER}
                      />
                    ) : null
                  }
                ></AccordionHeader>
                <AccordionPanel className={styles.accordion_panel}>
                  <AccessRightTable
                    right={right}
                    canDelete={!right.isGlobal || privilegedCompany}
                  />
                </AccordionPanel>
              </AccordionItem>
            );
          })}
        </Accordion>
      </div>
      {openEditItemModal && (
        <CategoryFormModal
          key="edit-items"
          right={selectedRight}
          isOpen={openEditItemModal}
          onClose={() => {
            setEditItemModalOpen(false);
            setSelectedRight(null);
          }}
        />
      )}
      {openAddCategoryModal && (
        <CategoryFormModal
          key="add-category"
          isOpen={openAddCategoryModal}
          onClose={() => {
            setSelectedRight(null);
            setOpenAddCategoryModal(false);
          }}
        />
      )}
      {openDeleteModal && (
        <DeleteFormModal
          key="delete-category"
          categories={selectedRight}
          isOpen={openDeleteModal}
          onClose={() => {
            setOpenDeleteModal(false);
            setSelectedRight(null);
          }}
        />
      )}
    </>
  );
};

export default RightContent;
