import Actions from "../actions";

const rules = {
  items: [],
  taskTypesWitheRulesForCompany: [],
  loading: false,
  loadingTypes: false,
  error: null,
};

const rulesReducer = (state = rules, action) => {
  switch (action.type) {
    case Actions.FETCH_RULES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case Actions.FETCH_RULES_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.rules,
      };
    case Actions.FETCH_RULES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case Actions.FETCH_TASK_TYPES_WITH_RULES_REQUEST:
      return {
        ...state,
        loadingTypes: true,
      };
    case Actions.FETCH_TASK_TYPES_WITH_RULES_SUCCESS:
      return {
        ...state,
        loadingTypes: false,
        taskTypesWitheRulesForCompany: action.types,
      };
    case Actions.FETCH_TASK_TYPES_WITH_RULES_ERROR:
      return {
        ...state,
        loadingTypes: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default rulesReducer;
