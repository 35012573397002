import React, { useContext, useEffect, useState } from "react";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { fetchRegions, selectRegion, getRegionsName } from "../../redux";
import {
  FieldError,
  FieldLabel,
  FieldSelect,
  Form,
} from "../../components/Input";
import Button from "../../components/Button";
import Entry from "../../layouts/Entry";
import styles from "./CompanySelect.module.scss";
import { I18nContext } from "../../i18n/I18nContext";

const defaultValues = {
  regionId: "",
};

const RegionSelect = () => {
  const { t } = useContext(I18nContext);
  const dispatch = useDispatch();
  const [initialValues] = useState(defaultValues);
  const regions = useSelector(getRegionsName);

  useEffect(() => {
    dispatch(fetchRegions());
  }, [dispatch]);

  useEffect(() => {
    if (regions.length === 1) {
      handleSubmit({ regionId: regions[0].value });
    }
  }, [regions]);

  const validationSchema = Yup.object({
    regionId: Yup.mixed()
      .required(t("please choose one option"))
      .oneOf(regions.map((item) => item.value)),
  });

  const handleSubmit = (values) => {
    dispatch(selectRegion(values.regionId));
  };

  if (!regions || regions.length < 2) return null;
  return (
    <Entry>
      <div className={styles.container}>
        <span className={styles.header}>{t("region")}</span>
        <span className={styles.txt_container}>
          {t("please choose region you want to work with")}
        </span>
        <Form
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          <FieldLabel>
            {t("region")}
            <span className={styles.red}>*</span>
          </FieldLabel>
          <FieldSelect
            name="regionId"
            options={regions}
            placeholder={t("choose region")}
          />
          <FieldError name="regionId" />
          <Button
            className={styles.spacing}
            type="submit"
            variant="orange"
            size="l"
          >
            {t("next")}
          </Button>
        </Form>
      </div>
    </Entry>
  );
};
export default RegionSelect;
