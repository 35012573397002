import Actions from "../actions";
import axios from "../auth";
import { createToast } from "../toast/toastActions";

const fetchMaintainerOrganizationsSuccess = (payload) => {
  return {
    type: Actions.FETCH_MAINTAINER_ORGANIZATIONS_SUCCESS,
    payload,
  };
};

const fetchMaintainerOrganizationsRequest = () => {
  return {
    type: Actions.FETCH_MAINTAINER_ORGANIZATIONS_REQUEST,
  };
};

const fetchMaintainerOrganizationsError = () => {
  return {
    type: Actions.FETCH_MAINTAINER_ORGANIZATIONS_ERROR,
  };
};

export const addMaintainer = (t, maint) => {
  return (dispatch) => {
    return axios.post(`/maintainers/`, maint).then((response) => {
      dispatch(
        createToast({
          type: "success",
          title: t("successfully added maintainer"),
        })
      );
    });
  };
};

export const deleteMaintainer = (t, id, organizationId) => {
  return (dispatch) => {
    return axios
      .delete(`/maintainers/${id}/${organizationId}`)
      .then((response) => {
        dispatch(
          createToast({
            type: "success",
            title: t("successfully deleted maintainer"),
          })
        );
      });
  };
};

export const deleteOrganizationMaintainer = (t, id) => {
  return (dispatch) => {
    return axios.delete(`/maintainerOrganizations/${id}`).then((response) => {
      dispatch(
        createToast({
          type: "success",
          title: t("successfully deleted maintainer organization"),
        })
      );
    });
  };
};

export const getMaintainersOrganizationList = () => {
  return (dispatch) => {
    dispatch(fetchMaintainerOrganizationsRequest());
    return axios
      .get(`/maintainerOrganizations/`)
      .then((response) => {
        dispatch(fetchMaintainerOrganizationsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchMaintainerOrganizationsError(error));
      });
  };
};

export const addMaintainerOrganization = (t, maintainerOrg) => {
  return (dispatch) => {
    return axios
      .post(`/maintainerOrganizations/add`, maintainerOrg)
      .then((response) => {
        dispatch(
          createToast({
            type: "success",
            title: t("successfully created new maintainer organization"),
          })
        );
      });
  };
};

export const deleteMaintainerOrganization = (t, id) => {
  return () => {
    return axios.delete(`/maintainerOrganizations/${id}`);
  };
};

const fetchMaintainerOrganizationsRightsSuccess = (payload) => {
  return {
    type: Actions.FETCH_MAINTAINER_ORGANIZATIONS_RIGHTS_SUCCESS,
    payload,
  };
};

const fetchMaintainerOrganizationsRightsRequest = () => {
  return {
    type: Actions.FETCH_MAINTAINER_ORGANIZATIONS_RIGHTS_REQUEST,
  };
};

const fetchMaintainerOrganizationsRightsError = () => {
  return {
    type: Actions.FETCH_MAINTAINER_ORGANIZATIONS_RIGHTS_ERROR,
  };
};

export const getMaintainerOrganizationsRights = (organizationId) => {
  return (dispatch) => {
    dispatch(fetchMaintainerOrganizationsRightsRequest());
    return axios
      .get(
        `/maintainerOrganizations/getOrganizationRights?organizationId=${organizationId}`
      )
      .then((response) => {
        dispatch(fetchMaintainerOrganizationsRightsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchMaintainerOrganizationsRightsError(error));
      });
  };
};
