import React, { useContext, useState, useEffect } from "react";
import { I18nContext } from "../../i18n/I18nContext";
import styles from "./PreconditionsEditor.module.scss";
import ConditionCreator from "./ConditionCreator";

const PreconditionsEditor = (props) => {
  const {
    properties,
    requirementIndex,
    requirement,
    updateRequirement,
    removeRequirement,
    readOnly,
  } = props;
  const { t } = useContext(I18nContext);

  const [showNewRuleForm, setShowNewRuleForm] = useState(
    requirement && !requirement.preconditions.length
  );
  const [preconditions, setPreconditions] = useState(
    requirement ? requirement.preconditions : []
  );

  useEffect(() => {
    setPreconditions(requirement.preconditions);
  }, [requirement]);

  const preparePrecondition = (property, condition) => {
    if (condition.type === "boolean_precondition") {
      return {
        type: "boolean_precondition",
        propertyId: property.value.id,
        sectionId: property.value?.sectionId,
        expectedValue: condition.value.value,
      };
    }
    if (condition.type === "numerical_precondition") {
      return {
        type: "numerical_precondition",
        propertyId: property.value.id,
        sectionId: property.value?.sectionId,
        comparisons: [
          {
            comparedValue: condition.value,
            operation: condition.operator.value,
          },
        ],
      };
    }
    if (condition.type === "selection_precondition") {
      return {
        type: "selection_precondition",
        propertyId: property.value.id,
        sectionId: property.value?.sectionId,
        triggeringSelections: condition.isMultiselect
          ? condition.value.map((el) => el.value)
          : [condition.value.value],
      };
    }
    if (condition.type === "text_precondition") {
      return {
        type: "text_precondition",
        propertyId: property.value.id,
        sectionId: property.value?.sectionId,
        triggerWords: condition.value,
      };
    }
    if (condition.type === "date_precondition") {
      return {
        type: "date_precondition",
        propertyId: property.value.id,
        sectionId: property.value?.sectionId,
        expectedValue: condition.value,
      };
    }
  };

  const createCondition = (property, condition, index) => {
    if (typeof index !== "undefined") {
      const newPreconditions = [...preconditions];
      newPreconditions[index] = preparePrecondition(property, condition, index);
      setPreconditions(newPreconditions);
      updateRequirement(newPreconditions, requirementIndex);
    } else {
      const newPreconditions = [
        ...preconditions,
        preparePrecondition(property, condition, index),
      ];
      setPreconditions(newPreconditions);
      updateRequirement(newPreconditions, requirementIndex);
      setShowNewRuleForm(false);
    }
  };

  const removeCondition = (index) => {
    const newPreconditions = [...preconditions];
    newPreconditions.splice(index, 1);
    setPreconditions(newPreconditions);
    if (!newPreconditions.length) {
      removeRequirement(requirementIndex);
    } else {
      updateRequirement(newPreconditions, requirementIndex);
    }
  };

  return (
    <div className={styles.preconditions_editor}>
      {!!preconditions.length &&
        preconditions.map((precondition, index) => (
          <div key={index}>
            <div className={styles.precondition_form}>
              <ConditionCreator
                index={index}
                precondition={precondition}
                properties={properties}
                createCondition={createCondition}
                removeCondition={removeCondition}
                readOnly={readOnly}
              />
            </div>
            {index !== preconditions.length - 1 && (
              <div className={styles.and_separator}>{t("and", "upper")}</div>
            )}
          </div>
        ))}

      {!!showNewRuleForm && (
        <div className={styles.precondition_form_new}>
          <ConditionCreator
            properties={properties}
            createCondition={createCondition}
            readOnly={readOnly}
          />
        </div>
      )}

      {!showNewRuleForm && !readOnly && (
        <div
          className={styles.add_precondition_button}
          onClick={() => setShowNewRuleForm(true)}
        >
          {!!preconditions.length
            ? `+ ${t("and", "upper")}`
            : `+ ${t("add new condition")}`}
        </div>
      )}
    </div>
  );
};

export default PreconditionsEditor;
