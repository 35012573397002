import { Formik, Form, useFormikContext } from "formik";
import React, { useEffect } from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import styles from "./Form.module.scss";

const FormikForm = ({
  children,
  onSubmit,
  initialValues,
  validationSchema,
  enableReinitialize,
  id,
  classNames,
  refForm,
  setIsPropertyEdited,
  disabled,
}) => {
  const FormikObserver = () => {
    const { values } = useFormikContext();

    useEffect(() => {
      let valuesChanged = false;

      Object.values(initialValues).forEach((value, index) => {
        if (value !== Object.values(values)[index]) {
          valuesChanged = true;
        }
      });

      if (valuesChanged && setIsPropertyEdited) {
        setIsPropertyEdited(true);
      } else {
        if (setIsPropertyEdited) {
          setIsPropertyEdited(false);
        }
      }
    }, [values]);

    return null;
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize={enableReinitialize}
      innerRef={refForm}
    >
      {(props) => {
        if (typeof children === "function") {
          return (
            <Form
              className={cx(styles.form, classNames)}
              id={id}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                }
              }}
              disabled={disabled}
            >
              {children(props)}
              <FormikObserver />
            </Form>
          );
        } else {
          return (
            <Form className={styles.form} id={id} disabled={disabled}>
              {children}
            </Form>
          );
        }
      }}
    </Formik>
  );
};

Form.propTypes = {
  onSubmit: PropTypes.func,
  initialValues: PropTypes.node,
  validationSchema: PropTypes.object,
  id: PropTypes.string,
  enableReinitialize: PropTypes.bool,
  classNames: PropTypes.string,
};

export default FormikForm;
