import React, { useContext, useEffect, useState } from "react";
import cx from "classnames";
import AccessTitles from "../Access/AccessTitles";
import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
} from "../Accordion";
import Button from "../Button";
import Icon from "../Icon";
import SectionHeader from "../SectionHeader";
import LicenseAdminModal from "./LicenseAdminModal";
import LicenseCustomerModal from "./LicenseCustomerModal";
import { I18nContext } from "../../i18n/I18nContext";
import styles from "./License.module.scss";
import TableForm from "./TableForm";
import { useDispatch, useSelector } from "react-redux";
// import { fetchLicenseList } from "../../redux";
import { fetchLicenses } from "../../redux";
// import DeleteLicenseModal from "./DeleteLicenseModal";
import MeatballMenu from "../../components/Access/MeatballMenu";

const License = () => {
  const { t } = useContext(I18nContext);
  const dispatch = useDispatch();

  const [openEditLicenseModal, setEditLicenseModal] = useState(false);
  const [openDeleteLicenseModal, setDeleteLicenseModal] = useState(false);
  const [licenseSelected, setSelectedLicense] = useState(null);

  // const [openAddLicenseModal, setOpenAddLicenseModal] = useState(false);
  const [openAddNewLicenseModal, setOpenAddNewLicenseModal] = useState(false);

  const licenses = useSelector((state) => state.license.items);
  const role = useSelector((state) => state.auth.info.role);

  const meatBallsItems = (lic) => [
    {
      label: t("edit"),
      show: true,
      onClick: () => {
        setEditLicenseModal(true);
        setSelectedLicense(lic);
      },
      icon: <Icon name="editPencil-orange" />,
    },
    {
      label: t("delete"),
      show: true,
      onClick: () => {
        setDeleteLicenseModal(true);
        setSelectedLicense(lic);
      },
      icon: <Icon name="trash-orange" />,
    },
  ];

  useEffect(() => {
    dispatch(fetchLicenses());
  }, [dispatch]);

  const items = [1, 2];

  const dateCompare = (date) => {
    return new Date().getTime() > new Date(date).getTime();
  };

  return (
    <>
      <div className={styles.header_part}>
        <SectionHeader>
          <div className={styles.title1}>{t("license")}</div>
        </SectionHeader>
        {/* <div>
          <Button
            variant="orange"
            size="s"
            onClick={() => setOpenAddNewLicenseModal(true)}
          >
            + {t("add another license")}
          </Button>
        </div> */}
      </div>

      <div className={styles.task_container}>
        {licenses &&
          licenses.length > 0 &&
          licenses.map((item, index) => {
            return (
              <>
                <div key={index} className={styles.row}>
                  <div className={styles.title}>
                    <span className={styles.data}>{item.user.userName}</span>{" "}
                    {t(`changed the license amount from`, "lower")}:{" "}
                    <span className={styles.data}>{item.licenseAmount}</span>{" "}
                    {t("to", "lower")}:{" "}
                    <span className={styles.data}>{item.newLicenseAmount}</span>{" "}
                    {t("in", "lower")}:{" "}
                    <span className={styles.data}>
                      {item.companyName ? item.companyName : t("company")}
                    </span>
                  </div>

                  <div>
                    <div className={styles.title}>
                      {`${t("new start date")}:`}{" "}
                      <span className={styles.data}>
                        {item.newLicenseStartDate}
                      </span>{" "}
                      {`(${t("before change", "lower")}: ${
                        item.licenseStartDate
                      })`}
                    </div>
                  </div>
                  <div>
                    <div className={styles.title}>
                      {`${t("new expire date")}:`}{" "}
                      <span className={styles.data}>
                        {item.newLicenseExpireDate}
                      </span>{" "}
                      {`(${t("before change", "lower")}: ${
                        item.licenseExpireDate
                      })`}
                    </div>
                  </div>
                </div>
              </>
            );
          })}
      </div>

      {/* <Accordion>
        {license.map((lic) => {
          return (
            <AccordionItem
              key={lic.id}
              id={lic.name}
              className={styles.accordion_item}
            >
              <AccordionHeader
                text={lic.name}
                isNotActive={dateCompare(lic.expiresAt)}
                message={dateCompare(lic.expiresAt) ? t("expired") : null}
                className={styles.accordion_header}
                rightIcon={
                  <div className={styles.license_icon}>
                    {<MeatballMenu items={meatBallsItems(lic)} />}
                  </div>
                }
              ></AccordionHeader>
              <AccordionPanel className={styles.accordion_panel}>
                <TableForm license={lic} />
              </AccordionPanel>
            </AccordionItem>
          );
        })}
      </Accordion>
      {role !== "SUPER_ADMIN" && (
        <LicenseCustomerModal
          key="license-customer"
          isOpen={openAddNewLicenseModal}
          onClose={() => {
            setOpenAddNewLicenseModal(false);
          }}
        />
      )}
      {role === "SUPER_ADMIN" && (
        <LicenseAdminModal
          key="license-admin"
          isOpen={openAddNewLicenseModal}
          onClose={() => {
            setOpenAddNewLicenseModal(false);
          }}
        />
      )}
      <LicenseAdminModal
        key="edit-license"
        licenseEdit={openEditLicenseModal}
        isOpen={openEditLicenseModal}
        license={licenseSelected}
        onClose={() => {
          setEditLicenseModal(false);
          setSelectedLicense(null);
        }}
      />
      <DeleteLicenseModal
        key="delete-license"
        license={licenseSelected}
        isOpen={openDeleteLicenseModal}
        onClose={() => {
          setDeleteLicenseModal(false);
          setSelectedLicense(null);
        }}
      /> */}
    </>
  );
};

export default License;
