import Actions from "../actions";

const initialState = {
  choosenBuildings: [],
};

const buildingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.SET_BUILDINGS:
      return { ...state, choosenBuildings: action.payload };
    case Actions.CLEAR_BUILDINGS: {
      return { ...state, choosenBuildings: [] };
    }
    default:
      return state;
  }
};

export default buildingsReducer;
