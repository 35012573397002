import RenderableElement from './RenderableElement';
class MoveableElement extends RenderableElement {
    constructor() {
        super(...arguments);
        this._isClickable = true;
    }
    get isClickable() { return this._isClickable; }
    set isClickable(isClickable) { this._isClickable = isClickable; }
    select(mousePosition) {
        this._offset = mousePosition.clone().sub(this.position);
    }
    move(mousePosition) {
        this.position = mousePosition.clone().sub(this._offset);
    }
}
export default MoveableElement;
