import React, { useContext } from "react";
import Button from "../Button";
import Modal from "../Modal";
import styles from "./FormEditErrorModal.module.scss";
import { I18nContext } from "../../i18n/I18nContext";

const FormEditErrorModal = ({ isOpen, onClose, form, errors }) => {
  const { t } = useContext(I18nContext);

  const handleClear = () => {
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={`${t("form modification error")}?`}
      icon
      footer={
        <div className={styles.button_section}>
          <Button
            className={styles.btn_separator}
            onClick={handleClear}
            type="reset"
            variant="ghost"
            size="s"
          >
            {t("ok")}
          </Button>
        </div>
      }
    >
      <div className={styles.delete_txt}>
        {t(
          "the changes to the form could not be applied as the form properties are being used in following rules"
        ) + ":"}
        <ul className={styles.spacing_txt}>
          {!!errors?.length &&
            errors.map((error) => (
              <li className={styles.spacing_txt}>
                {error?.companyName
                  ? error?.companyName +
                    " -> " +
                    error?.name +
                    ", " +
                    t("rule") +
                    ": " +
                    error?.procedureName
                  : error?.name +
                    ", " +
                    t("rule") +
                    ": " +
                    error?.procedureName}
              </li>
            ))}
        </ul>
      </div>
    </Modal>
  );
};

export default FormEditErrorModal;
