import React from "react";
import Dashboard from "../../layouts/Dashboard";
import LicenseC from "../../components/License";

const License = () => (
  <Dashboard>
    <LicenseC />
  </Dashboard>
);

export default License;
