import React, {useContext} from "react";
import styles from "./PropertyDeleteConfirmation.module.scss";
import {I18nContext} from "../../i18n/I18nContext";

const PropertyDeleteConfirmation = ({property, onConfirm, onClose}) => {
  const { t } = useContext(I18nContext);

  return (
    <div className={styles.property_delete_confirmation}>
      <div className={styles.confirmation_text}>{t("are you sure you want to remove this property")}?</div>
      <div
        className={styles.confirmation_button}
        onClick={() => {
          onConfirm(property);
          onClose;
        }}
      >
        {t("yes")}
      </div>
      <div className={styles.confirmation_button} onClick={onClose}>{t("no")}</div>
    </div>
  )
};

export default PropertyDeleteConfirmation;
