import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteAsset, fetchAssets } from "../../redux";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import styles from "./DeleteAssetModal.module.scss";
import { I18nContext } from "../../i18n/I18nContext";
import ActivityIndicator from "../../components/ActivityIndicator";

const DeleteAssetModal = ({ isOpen, onClose, asset }) => {
  const { t } = useContext(I18nContext);
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.assets.loading);

  const handleClear = () => {
    onClose();
  };
  const handleDeleteAsset = async () => {
    await dispatch(deleteAsset(t, asset.id));
    await dispatch(fetchAssets(asset.parentId));
    await dispatch(fetchAssets());
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t(`delete asset`)}
      icon
      footer={
        <div className={styles.button_section}>
          <Button
            className={styles.btn_separator}
            onClick={handleClear}
            type="reset"
            variant="ghost"
            size="s"
          >
            {!!loading ? t("close") : t("cancel")}
          </Button>
          <Button
            type="submit"
            variant={loading ? "disabled" : "orange"}
            size="s"
            onClick={handleDeleteAsset}
            disabled={loading}
          >
            {t("delete")}
          </Button>
        </div>
      }
    >
      <div className={styles.delete_txt}>
        {t("are you sure you want to permanently delete the asset")}
        <div className={styles.spacing_txt}>{asset?.name} ?</div>
      </div>
    </Modal>
  );
};

export default DeleteAssetModal;
