import React, { useContext } from "react";
import * as Yup from "yup";
import Modal from "../Modal";
import {
  FieldError,
  FieldInput,
  FieldLabel,
  Form,
  FormControl,
} from "../Input";
import Button from "../Button";
import styles from "./AddAttachmentsTypeModal.module.scss";
import { useDispatch } from "react-redux";
import {
  addAttachmentsType,
  editAttachmentsType,
  fetchAttachmentsTypesList,
} from "../../redux";
import { I18nContext } from "../../i18n/I18nContext";

const AddAttachmentsTypeModal = ({
  onClose,
  isOpen,
  typeEdit,
  type,
  typeNames,
}) => {
  const { t } = useContext(I18nContext);
  const dispatch = useDispatch();

  const initialValues = {
    name: type?.name ?? "",
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .required(t("missing type name"))
      .notOneOf(typeNames, t("name must be unique")),
  });

  const clearForm = (formik) => {
    formik?.resetForm();
  };

  const handleClose = () => {
    clearForm();
    onClose();
  };
  const handleSubmit = async (values, formik) => {
    if (type) {
      await dispatch(
        editAttachmentsType(t, {
          id: type.id,
          name: values.name,
        })
      );
    } else {
      await dispatch(
        addAttachmentsType(t, {
          name: values.name,
        })
      );
    }
    dispatch(fetchAttachmentsTypesList());
    clearForm(formik);
    onClose();
  };

  return (
    <Modal
      className={styles.modal}
      isOpen={isOpen}
      onClose={onClose}
      classNameTitle={styles.title}
      title={typeEdit ? t("edit type") : t("add new type")}
      form="typeId"
      divider={false}
      footer={
        <div className={styles.button_section}>
          <Button
            onClick={handleClose}
            type="reset"
            form={"typeId"}
            variant="ghost"
            size="s"
          >
            {t("cancel")}
          </Button>
          {!typeEdit && (
            <Button
              className={styles.btn_separator}
              type="submit"
              variant="orange"
              size="s"
              form={"typeId"}
            >
              {t("add")}
            </Button>
          )}

          {typeEdit && (
            <Button
              className={styles.btn_separator}
              type="submit"
              variant="orange"
              size="s"
              form={"typeId"}
            >
              {t("save changes")}
            </Button>
          )}
        </div>
      }
    >
      <Form
        id="typeId"
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
      >
        <FormControl className={styles.dropdown}>
          <FieldLabel>
            {t("name")}
            <span className={styles.red}>*</span>
          </FieldLabel>
          <FieldInput name="name" size="l" placeholder={t("type name")} />
          <FieldError name="name" />
        </FormControl>
      </Form>
    </Modal>
  );
};

export default AddAttachmentsTypeModal;
