import React, { useContext } from "react";
import * as Yup from "yup";
import Modal from "../Modal";
import {
  DropzoneInput,
  FieldError,
  FieldInput,
  FieldLabel,
  Form,
  FormControl,
} from "../Input";
import Button from "../Button";
import styles from "./AddIconModal.module.scss";
import { useDispatch } from "react-redux";
import { addIcon, editIcon, fetchIconsList } from "../../redux";
import { I18nContext } from "../../i18n/I18nContext";

const AddIconModal = ({ onClose, isOpen, iconEdit, icon }) => {
  const { t } = useContext(I18nContext);
  const dispatch = useDispatch();
  const maxAttachmentsSize = 209715200; //200MB

  const initialValues = {
    name: icon?.name ?? "",
    file: [],
  };

  const validationSchema = Yup.object({
    name: Yup.string().required(t("missing icon name")),
  });

  const clearForm = (formik) => {
    formik?.resetForm();
  };

  const handleClose = () => {
    clearForm();
    onClose();
  };
  const handleSubmit = async (values, formik) => {
    if (icon) {
      await dispatch(
        editIcon(t, {
          id: icon.id,
          name: values.name,
          file: values.file[0],
        })
      );
    } else {
      await dispatch(
        addIcon(t, {
          name: values.name,
          file: values.file[0],
        })
      );
    }
    dispatch(fetchIconsList());
    clearForm(formik);
    onClose();
  };

  return (
    <Modal
      className={styles.modal}
      isOpen={isOpen}
      onClose={onClose}
      classNameTitle={styles.title}
      title={iconEdit ? t("edit icon") : t("add new icon")}
      form="iconId"
      divider={false}
      footer={
        <div className={styles.button_section}>
          <Button
            onClick={handleClose}
            type="reset"
            form={"iconId"}
            variant="ghost"
            size="s"
          >
            {t("cancel")}
          </Button>

          {!iconEdit && (
            <Button
              className={styles.btn_separator}
              type="submit"
              variant="orange"
              size="s"
              form={"iconId"}
            >
              {t("add")}
            </Button>
          )}

          {iconEdit && (
            <Button
              className={styles.btn_separator}
              type="submit"
              variant="orange"
              size="s"
              form={"iconId"}
            >
              {t("save changes")}
            </Button>
          )}
        </div>
      }
    >
      <Form
        id="iconId"
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
      >
        <FormControl className={styles.dropdown}>
          <FieldLabel>
            {t("name")}
            <span className={styles.red}>*</span>
          </FieldLabel>
          <FieldInput name="name" size="l" placeholder={t("icon name")} />
          <FieldError name="name" />
        </FormControl>
        <FieldLabel>{t("icon svg file")}</FieldLabel>
        <DropzoneInput
          maxSize={maxAttachmentsSize}
          name="file"
          text={t("drag and drop an image to upload, or")}
          fileType="image/svg"
        />
      </Form>
    </Modal>
  );
};

export default AddIconModal;
