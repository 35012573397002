import React from "react";
import Dashboard from "../../layouts/Dashboard";
import Configuration from "../../components/Configuration";

const ConfigurationV = () => {
  return (
    <Dashboard>
      <Configuration />
    </Dashboard>
  );
};

export default ConfigurationV;
