import React, { useState, useEffect, useContext } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

import Charts from "./Charts";
import Button from "../Button";

import styles from "./SensorChartsView.module.scss";
import { I18nContext } from "../../i18n/I18nContext";

import { getAllDataSensorMeasurements, getVolatilityData } from "../../redux";
import {
  parseSensorChartData,
  parseVolatilityData,
  getProperDate,
} from "./utils";
import ActivityIndicator from "../ActivityIndicator";
import { set } from "date-fns";

const CHARTCOLORS = ["#01B6CB", "#64b90c", "#d2042d", "#2264d1", "#f9dc76"];

const CustomTooltip = ({ active, payload, label, t }) => {
  if (active && payload && payload.length) {
    return (
      <div className={styles.customTooltip}>
        <p className={styles.label}>{`${t(
          "first measurement"
        )}: ${getProperDate(payload[0].payload.first)}`}</p>
        <p className={styles.label}>{`${t(
          "second measurement"
        )}: ${getProperDate(payload[0].payload.second)}`}</p>
        <p>{`${payload[0].name} ${t("difference", "lower")}: ${
          payload[0].value
        }`}</p>
      </div>
    );
  }

  return null;
};

const SensorChartsView = ({ sensor, onOpenEdit, onDetach }) => {
  const { t } = useContext(I18nContext);
  const [isAllDataView, setIsAllDataView] = useState(false);
  const [isVolatilityView, setIsVolatilityView] = useState(false);
  const [allMeasurementsData, setAllMeasurementsData] = useState(null);
  const [volatilityData, setVolatilityData] = useState(null);
  const [allMeasurementsDataTypes, setAllMeasurementsDataTypes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleFetchAllSensorData = async () => {
    setIsLoading(true);
    try {
      const { data } = await getAllDataSensorMeasurements(sensor.sensorId);
      setAllMeasurementsData(parseSensorChartData(data.measurements));
      setAllMeasurementsDataTypes(Object.keys(data.propertyUnits));
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const handleFetchVolatilityData = async () => {
    setIsLoading(true);
    try {
      const { data } = await getVolatilityData(sensor.sensorId);
      setVolatilityData(parseVolatilityData(data.volatilityList));
      setAllMeasurementsDataTypes(Object.keys(data.propertyUnits));
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (isAllDataView && !allMeasurementsData) {
      // fetch all data for sensor when there is no data yet
      handleFetchAllSensorData();
    }
  }, [isAllDataView]);

  useEffect(() => {
    if (isVolatilityView && !volatilityData) {
      handleFetchVolatilityData();
    }
  }, [isVolatilityView]);

  const handleShowAverage = () => {
    setIsAllDataView(false);
    setIsVolatilityView(false);
  };

  const handleShowAllData = () => {
    setIsAllDataView(true);
    setIsVolatilityView(false);
  };

  const handleShowVolatilityView = () => {
    setIsVolatilityView(true);
    setIsAllDataView(false);
  };

  return (
    <div className={styles.chartsWrapper} key={sensor.sensorId}>
      {!isAllDataView && !isVolatilityView && !isLoading && (
        <Charts
          data={sensor.measurements}
          name={sensor.sensorName}
          propertyUnits={sensor.propertyUnits}
        />
      )}
      {isAllDataView && !isLoading && (
        <ResponsiveContainer width="100%" height={300}>
          <LineChart
            width={500}
            height={260}
            data={allMeasurementsData}
            margin={{
              top: 20,
              right: 30,
              left: 10,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="label" type="category" />
            <YAxis />
            <Tooltip />
            <Legend
              iconType="circle"
              iconSize={10}
              wrapperStyle={{ top: 300, right: 200 }}
            />
            {allMeasurementsDataTypes.map((el, index) => {
              return (
                <Line
                  type="monotone"
                  dataKey={el}
                  stroke={CHARTCOLORS[index]}
                  activeDot={{ r: 8 }}
                />
              );
            })}
          </LineChart>
        </ResponsiveContainer>
      )}
      {isVolatilityView && volatilityData && !isLoading && (
        <ResponsiveContainer width="100%" height={300}>
          <LineChart
            width={500}
            height={260}
            data={volatilityData}
            margin={{
              top: 20,
              right: 30,
              left: 10,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="label" type="category" />
            <YAxis />
            <Tooltip content={<CustomTooltip t={t} />} />
            <Legend
              iconType="circle"
              iconSize={10}
              wrapperStyle={{ top: 300, right: 200 }}
            />
            {allMeasurementsDataTypes.map((el, index) => {
              return (
                <Line
                  key={index}
                  type="monotone"
                  dataKey={el}
                  stroke={CHARTCOLORS[index]}
                  activeDot={{ r: 8 }}
                  dot={false}
                />
              );
            })}
          </LineChart>
        </ResponsiveContainer>
      )}
      {isLoading && (
        <div className={styles.activityWrapper}>
          <ActivityIndicator />
        </div>
      )}
      <div className={styles.sensorButtonWrapper}>
        <div className={styles.space}>
          <Button
            variant="orange"
            size="s"
            onClick={
              isVolatilityView ? handleShowAverage : handleShowVolatilityView
            }
          >
            {!isVolatilityView
              ? t("show volatilities")
              : t("show average data")}
          </Button>
        </div>
        <div className={styles.space}>
          <Button
            variant="orange"
            size="s"
            onClick={isAllDataView ? handleShowAverage : handleShowAllData}
          >
            {!isAllDataView ? t("show all data") : t("show average data")}
          </Button>
        </div>
        <div className={styles.space}>
          <Button variant="orange" size="s" onClick={onOpenEdit}>
            {t("edit")}
          </Button>
        </div>
        <Button variant="orange" size="s" onClick={onDetach}>
          {t("detach")}
        </Button>
      </div>
    </div>
  );
};

export default SensorChartsView;
