import {
  PreconditionType,
  PreconditionTypeApi,
} from "../../constants/PreconditionType";

export const getInitialPreconditionsValues = (preconditions) => {
  return preconditions.reduce((current, precondition, index) => {
    switch (precondition.type) {
      case PreconditionTypeApi.numerical: {
        current[`${index}_operation`] = precondition.comparisons?.[0].operation;
        current[`${index}_comparedValue`] =
          precondition.comparisons?.[0].comparedValue;
        break;
      }
      case PreconditionTypeApi.boolean: {
        current[`${index}_expectedValue`] = precondition.expectedValue;
        break;
      }
      case PreconditionTypeApi.text: {
        current[`${index}_triggerWords`] = precondition.triggerWords.join(",");
        break;
      }
      case PreconditionTypeApi.selection: {
        current[`${index}_triggeringSelections`] =
          precondition.isDropdown || precondition.isMultiselect
            ? precondition.triggeringSelections.map((selection) => ({
                value: selection,
                label: selection,
              }))
            : precondition.triggeringSelections[0];
        break;
      }
      default:
    }
    return current;
  }, {});
};

export const createCompositePreconditionsArray = (values) => {
  const precontitionsObject = {};
  const uniqueKeys = values.map((el) => el.uniqueKey);
  const compositeArray = values.map((el, index) => {
    if (el.type === PreconditionType.boolean) {
      return { type: el.type, expectedValue: el.expectedValue };
    }

    if (el.type === PreconditionType.numerical) {
      const { comparedValue, operation } = el.comparisons[0];
      return {
        type: el.type,
        comparisons: [
          {
            comparedValue: comparedValue,
            operation: operation,
          },
        ],
      };
    }

    if (el.type === PreconditionType.text) {
      return { type: el.type, triggerWords: el.triggerWords?.split(",") };
    }

    if (el.type.startsWith(PreconditionTypeApi.selection)) {
      return {
        type: PreconditionTypeApi.selection,
        triggeringSelections:
          PreconditionType.selection_dropdown ||
          PreconditionType.selection_multiple
            ? el.triggeringSelections?.split(",")
            : [el.triggeringSelections],
        isMultiselect: el.type === PreconditionType.selection_multiple,
        isDropdown: el.type === PreconditionType.selection_dropdown,
      };
    }
  });

  compositeArray.forEach((el, index) => {
    precontitionsObject[uniqueKeys[index]] = el;
  });

  const compositeObj = {
    type: "composite_precondition",
    applicable: true,
    preconditions: precontitionsObject,
  };

  return [compositeObj];
};

export const getProperCompositeValuesArray = (values) => {
  const valuesArray = [];

  for (const [key, value] of Object.entries(values)) {
    let type = "";
    let triggeringString = "";

    if (value.isMultiselect) {
      type = PreconditionType.selection_multiple;
      triggeringString = value.triggeringSelections.join(",");
    }

    if (value.isDropdown) {
      type = PreconditionType.selection_dropdown;
      triggeringString = value.triggeringSelections.join(",");
    }

    if (
      value.type === PreconditionTypeApi.selection &&
      !value.isMultiselect &&
      !value.isDropdown
    ) {
      type = PreconditionType.selection_single;
      triggeringString = value.triggeringSelections.join(",");
    }

    if (value.type === PreconditionTypeApi.selection) {
      valuesArray.push({
        ...value,
        uniqueKey: key,
        type: type ? type : value.type,
        triggeringSelections: triggeringString,
      });
    } else if (value.type === PreconditionTypeApi.text) {
      valuesArray.push({
        ...value,
        uniqueKey: key,
        type: type ? type : value.type,
        triggerWords: value.triggerWords.join(","),
      });
    } else {
      valuesArray.push({
        ...value,
        uniqueKey: key,
        type: type ? type : value.type,
      });
    }
  }

  return valuesArray;
};

export const updatePreconditions = (preconditions, values) => {
  if (!preconditions.length) {
    throw new Error("No preconditions to update");
  }
  return preconditions

    .map((precondition, index) => {
      if (!precondition) {
        return null;
      }

      switch (precondition.type) {
        case PreconditionTypeApi.numerical: {
          return {
            ...precondition,
            comparisons: [
              {
                comparedValue: values[`${index}_comparedValue`],
                operation: values[`${index}_operation`],
              },
            ],
          };
        }
        case PreconditionTypeApi.boolean: {
          return {
            ...precondition,
            expectedValue: values[`${index}_expectedValue`],
          };
        }
        case PreconditionTypeApi.text: {
          return {
            ...precondition,
            triggerWords: values[`${index}_triggerWords`]?.split(","),
          };
        }
        case PreconditionTypeApi.selection: {
          return {
            ...precondition,
            triggeringSelections:
              precondition.isDropdown || precondition.isMultiselect
                ? values[`${index}_triggeringSelections`]?.split(",")
                : [values[`${index}_triggeringSelections`]],
          };
        }
        default:
          return precondition;
      }
    })
    .filter((precondition) => precondition !== null);
};

export const updateTaskActions = (values) => {
  const taskActions = [];

  if (values.tasksToStart.length > 0) {
    values.tasksToStart.forEach((task) => {
      taskActions.push({
        taskTemplateId: task.taskActionStartId,
        delay: task.taskActionStartDelay,
        delayUnit: task.taskActionStartDelayUnit,
        type: "CREATE",
      });
    });
  }

  if (values.tasksToStop.length > 0) {
    values.tasksToStop.forEach((task) => {
      taskActions.push({
        taskTemplateId: task.taskActionStopId,
        delay: task.taskActionStopDelay,
        delayUnit: task.taskActionStopDelayUnit,
        type: "DELETE",
      });
    });
  }

  return taskActions;
};

export const getInitialTaskActionsValues = (taskActions) => {
  const taskActionStart = taskActions.filter((x) => x.type === "CREATE");
  const taskActionStop = taskActions.filter((x) => x.type === "DELETE");

  const tasksToStart = taskActionStart.map((task) => ({
    taskActionStartId: task.taskTemplateId,
    taskActionStartDelay: task.delay,
    taskActionStartDelayUnit: task.delayUnit,
  }));

  const tasksToStop = taskActionStop.map((task) => ({
    taskActionStopId: task.taskTemplateId,
    taskActionStopDelay: task.delay,
    taskActionStopDelayUnit: task.delayUnit,
  }));

  const taskActionsValues = {
    tasksToStart,
    tasksToStop,
  };

  return taskActionsValues;
};
