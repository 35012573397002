import React, { useState, useMemo, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import styles from "./Accordion.module.scss";
import { AccordionContext } from "./AccordionContext";

const Accordion = ({ children, onChange, className, single, open }) => {
  const [currentOpen, setOpen] = useState([]);

  useEffect(() => {
    if (open) {
      setOpen(open);
    }
  }, [open]);

  const toggle = useCallback(
    (key) => {
      if (currentOpen.includes(key)) {
        setOpen(currentOpen.filter((k) => k !== key));
      } else {
        if (single) {
          setOpen([key]);
        } else {
          setOpen([...currentOpen, key]);
        }
      }
    },
    [setOpen, currentOpen, single]
  );

  useEffect(() => {
    if (onChange) {
      onChange(currentOpen);
    }
  }, [currentOpen, onChange]);

  const value = useMemo(() => {
    return {
      open: currentOpen,
      toggle,
    };
  }, [toggle, currentOpen]);

  const classNames = cx(styles.container, className);

  return (
    <AccordionContext.Provider value={value}>
      <div className={classNames} data-testid="accordion">
        {children}
      </div>
    </AccordionContext.Provider>
  );
};

Accordion.propTypes = {
  onNext: PropTypes.func,
  className: PropTypes.string,
  open: PropTypes.array,
};

export default Accordion;
