import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import {
  deleteAttachmentsFolder,
  fetchAttachmentsFoldersList,
} from "../../redux";
import Button from "../Button";
import Modal from "../Modal";
import styles from "./DeleteAttachmentsFolderModal.module.scss";
import { I18nContext } from "../../i18n/I18nContext";

const DeleteAttachmentsFolderModal = ({ isOpen, onClose, folder }) => {
  const { t } = useContext(I18nContext);
  const dispatch = useDispatch();

  const handleClear = () => {
    onClose();
  };
  const handleDeleteFolder = async () => {
    await dispatch(deleteAttachmentsFolder(t, folder.id));
    dispatch(fetchAttachmentsFoldersList());
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={`${t("delete folder")}?`}
      form="delete-form-attachments-folder"
      icon
      footer={
        <div className={styles.button_section}>
          <Button
            className={styles.btn_separator}
            onClick={handleClear}
            type="reset"
            form={"delete-form-attachments-folder"}
            variant="ghost"
            size="s"
          >
            {t("cancel")}
          </Button>
          <Button
            type="submit"
            variant="orange"
            size="s"
            form={"delete-form-attachments-folder"}
            onClick={handleDeleteFolder}
          >
            {t("delete")}
          </Button>
        </div>
      }
    >
      <div className={styles.delete_txt}>
        {t("are you sure you want to permanently delete folder")}
        <div className={styles.spacing_txt}>{folder?.name} ?</div>
      </div>
    </Modal>
  );
};

export default DeleteAttachmentsFolderModal;
