import ControllerModeType from '../ControllerModeType';
import MoveStrategy from './Strategies/MoveStrategy';
import PanningStrategy from './Strategies/PanningStrategy';
class MouseMoveStrategyProvider {
    static get(mode, controller) {
        if (controller.selectedElement !== null && mode === ControllerModeType.EDITOR)
            return new MoveStrategy();
        else
            return new PanningStrategy();
    }
}
export default MouseMoveStrategyProvider;
