import React from "react";
import { ErrorMessage } from "formik";
import PropTypes from "prop-types";
import styles from "./FieldError.module.scss";

const FieldError = ({ name }) => {
  return (
    <ErrorMessage name={name}>
      {(msg) => <div className={styles.error}>{msg}</div>}
    </ErrorMessage>
  );
};

FieldError.propTypes = {
  name: PropTypes.string,
};

export default FieldError;
