import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../Button";
import AccessFilteredSearch from "./AccessFilteredSearch";
import UserRowForm from "./UserRowForm";
import OrganizationFormModal from "./OrganizationFormModal";
import styles from "./MaintainerContent.module.scss";
import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
} from "../Accordion";
import OrganizationRowForm from "./OrganizationRowForm";
import AccessTitles from "./AccessTitles";
import MeatballMenu from "./MeatballMenu";
import Icon from "../Icon";
import MaintainerFormModal from "./MaintainerFormModal";
import { I18nContext } from "../../i18n/I18nContext";
import DeleteOrgModal from "./DeleteOrgModal";
import { fetchRegisteredRoles, sendMultipleInvitations } from "../../redux";
import { getMaintainersOrganizationList } from "../../redux/maintainers/maintainersActions";
import { Role } from "../../constants/Access";

const MaintainerContent = ({ items = [] }) => {
  const { t } = useContext(I18nContext);
  const role = useSelector((state) => state.auth.info?.role);
  const dispatch = useDispatch();
  const [openAddMaintainerModal, setOpenAddMaintainerModal] = useState(false);
  const [openDeleteMaintainerOrgModal, setOpenDeleteMaintainerOrgModal] =
    useState(false);
  const [searchString, setSearchString] = useState(null);
  const [openOrgModal, setOpenOrganizationModal] = useState(false);
  const [orgSelected, setSelectedOrg] = useState(null);
  const [filteredItems, setFilteredItems] = useState(items);
  const [meatballOpened, setMeatballOpened] = useState(null);
  const roles = useSelector((state) => state.access.rolesItems);
  const [checkSelectedUsers, setCheckSelectedUsers] = useState([]);

  useEffect(() => {
    dispatch(fetchRegisteredRoles());
  }, [dispatch]);

  useEffect(() => {
    let filtered = items;
    if (searchString) {
      filtered = filtered.filter((item) =>
        item.users.find(
          (v) =>
            v.username.toLowerCase().includes(searchString.toLowerCase()) ||
            v.email.toLowerCase().includes(searchString.toLowerCase())
        )
      );
    }
    setFilteredItems(filtered);
  }, [searchString, items]);

  const meatBallsItems = (org) => [
    {
      label: t("add maintainer"),
      show: true,
      onClick: () => {
        setOpenAddMaintainerModal(true);
        setSelectedOrg(org);
      },
      icon: <Icon name="maintainer-orange" />,
    },
    {
      label: t("edit"),
      show: true,
      onClick: () => {
        setSelectedOrg(org);
        setOpenOrganizationModal(true);
      },
      icon: <Icon name="editPencil-orange" />,
    },
    {
      label: t("delete"),
      show: true,
      onClick: () => {
        setSelectedOrg(org);
        setOpenDeleteMaintainerOrgModal(true);
      },

      icon: <Icon name="trash-orange" />,
    },
  ];

  const checkSelectUser = (userId, isChecked) => {
    const newCheckSelectedUsers = [...checkSelectedUsers];
    if (isChecked) {
      newCheckSelectedUsers.push(userId);
    } else {
      const index = newCheckSelectedUsers.indexOf(userId);
      newCheckSelectedUsers.splice(index, 1);
    }
    setCheckSelectedUsers(newCheckSelectedUsers);
  };

  const sendInvitations = async (userId) => {
    await dispatch(
      sendMultipleInvitations(t, userId ? [userId] : checkSelectedUsers)
    );
    dispatch(getMaintainersOrganizationList());
    setCheckSelectedUsers([]);
  };

  const refreshMaintainersList = () => {
    dispatch(getMaintainersOrganizationList());
  };

  return (
    <>
      <div className={styles.column}>
        <div className={styles.filters_section}>
          <div>
            <AccessFilteredSearch onSearchChange={setSearchString} />
          </div>
          <div className={styles.row}>
            {role !== Role.CUSTOMER && (
              <div className={styles.buttonLeft}>
                <Button
                  variant="orange"
                  size="s"
                  onClick={() => setOpenOrganizationModal(true)}
                >
                  + {t("add new organization")}
                </Button>
              </div>
            )}
            <div>
              <Button
                variant={!checkSelectedUsers.length ? "disabled" : "orange"}
                size="s"
                onClick={() => sendInvitations()}
                disabled={!checkSelectedUsers.length}
              >
                {t("send invitations")}
              </Button>
            </div>
          </div>
        </div>
        <AccessTitles content="maintainerOrgs" />
        <Accordion>
          {filteredItems.map((org) => {
            return (
              <AccordionItem
                key={org.id}
                id={org.name}
                className={styles.container_item}
              >
                <AccordionHeader
                  text={org.name}
                  className={styles.accordion_header}
                  hasItem={true}
                  rightIcon={
                    <MeatballMenu
                      id={org.id}
                      items={meatBallsItems(org)}
                      meatballOpened={meatballOpened}
                      meatballOpenedObserver={(state) =>
                        setMeatballOpened(state)
                      }
                      disabled={role === Role.CUSTOMER}
                    />
                  }
                >
                  <OrganizationRowForm org={org} />
                </AccordionHeader>
                <AccordionPanel className={styles.accordion_panel}>
                  {org.users &&
                    org.users.map((user) => (
                      <UserRowForm
                        org={org}
                        key={user.id}
                        user={user}
                        showRight={false}
                        showSendEmail={false}
                        meatballOpened={meatballOpened}
                        meatballOpenedObserver={(state) =>
                          setMeatballOpened(state)
                        }
                        checkSelectUser={checkSelectUser}
                        checked={checkSelectedUsers.includes(user.id)}
                        sendInvitation={sendInvitations}
                        refreshMaintainersList={refreshMaintainersList}
                      />
                    ))}
                </AccordionPanel>
              </AccordionItem>
            );
          })}
        </Accordion>
      </div>
      {roles.length !== 0 && !!openAddMaintainerModal && (
        <MaintainerFormModal
          key="add-maintainer"
          org={orgSelected}
          isOpen={openAddMaintainerModal}
          onClose={() => {
            setOpenAddMaintainerModal(false);
            setSelectedOrg(null);
            refreshMaintainersList();
          }}
        />
      )}
      {!!openOrgModal && (
        <OrganizationFormModal
          key="org-modal"
          isOpen={openOrgModal}
          org={orgSelected}
          onClose={() => {
            setOpenOrganizationModal(false);
            setSelectedOrg(null);
            refreshMaintainersList();
          }}
        />
      )}
      {!!openDeleteMaintainerOrgModal && (
        <DeleteOrgModal
          key="delete-maintainer"
          org={orgSelected}
          isOpen={openDeleteMaintainerOrgModal}
          onClose={() => {
            setOpenDeleteMaintainerOrgModal(false);
            refreshMaintainersList();
          }}
        />
      )}
    </>
  );
};

MaintainerContent.propTypes = {};

export default MaintainerContent;
