import React, { useContext, useState } from "react";
import * as Yup from "yup";
import {
  FieldLabel,
  Form,
  FormControl,
  FieldError,
  FieldTextArea,
  FieldSelect,
} from "../Input";
import { useDispatch, useSelector } from "react-redux";
import styles from "./TaskStateChangeForm.module.scss";
import { I18nContext } from "../../i18n/I18nContext";

import { fetchTasksList, updateState } from "../../redux";
import { TaskState } from "../../constants/Tasks";

const defaultInitialValues = {
  description: "",
  taskEvent: "",
};

const validationSchema = (t) =>
  Yup.object({
    description: Yup.string()
      .max(140, t("the reason must be at most 140 characters"))
      .required(t("please provide the reason")),
  });

const TaskStateChangeForm = ({ formId, itemId, onClose, getAssetDetails }) => {
  const { t } = useContext(I18nContext);
  const dispatch = useDispatch();
  const allChoosen = useSelector((state) => state.buildings.choosenBuildings);

  // const handleChangeState = async () => {
  //   await dispatch(updateState(t, taskEdited.id, taskEvent, reason));
  // };
  const options = [
    {
      value: "CANCEL",
      label: t("cancel"),
    },
    {
      value: "COMPLETE",
      label: t("complete"),
    },
    {
      value: "REOPEN",
      label: t("reopen"),
    },
    {
      value: "TIMEOUT",
      label: t("miss"),
    },
    {
      value: "UNDOABLE",
      label: t("cant do"),
    },
    {
      value: "COMPLETE_TOO_LATE",
      label: t("done too late"),
    },
    {
      value: "OVERDUE",
      label: t("overdue"),
    },
  ];
  const handleSubmit = async (values, formik) => {
    const { description, taskEvent } = values;

    try {
      const response = await dispatch(
        updateState(t, itemId, taskEvent, description)
      );

      dispatch(
        fetchTasksList(
          TaskState.TODO,
          allChoosen.map((el) => el.id)
        )
      );
      dispatch(
        fetchTasksList(
          TaskState.DONE,
          allChoosen.map((el) => el.id)
        )
      );
      if (!!getAssetDetails) {
        getAssetDetails();
      }

      formik.resetForm();
      onClose();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Form
        id={formId}
        onSubmit={handleSubmit}
        initialValues={defaultInitialValues}
        validationSchema={validationSchema(t)}
        enableReinitialize
      >
        {({ values, errors }) => {
          return (
            <>
              <FormControl>
                <FieldLabel>
                  {t("state change to")}
                  <span className={styles.red}> *</span>
                </FieldLabel>
                <FieldSelect
                  name="taskEvent"
                  options={options}
                  singleValue={true}
                ></FieldSelect>
                <FieldLabel>
                  {t("description")}
                  <span className={styles.red}> *</span>
                </FieldLabel>
                <FieldTextArea
                  name="description"
                  placeholder={t("please provide the reason")}
                />
                <FieldError name="description" />
              </FormControl>
            </>
          );
        }}
      </Form>
    </>
  );
};

export default TaskStateChangeForm;
