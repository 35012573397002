import ControllerModeType from '../ControllerModeType';
import PanningStrategy from '../MouseUpStrategies/Strategies/PanningStrategy';
class MouseUpStrategyProvider {
    static get(mode) {
        switch (mode) {
            case ControllerModeType.EDITOR:
            case ControllerModeType.VIEWER:
                return new PanningStrategy();
        }
    }
}
export default MouseUpStrategyProvider;
