import Actions from "../actions";
import axios from "../auth";
import { createToast } from "../toast/toastActions";

const fetchPrototypesSuccess = (prototypes) => {
  return {
    type: Actions.FETCH_PROTOTYPES_SUCCESS,
    payload: prototypes,
  };
};
const fetchPrototypesUsedWithinCompanySuccess = (prototypes) => {
  return {
    type: Actions.FETCH_PROTOTYPES_USED_WITHIN_COMPANY_SUCCESS,
    payload: prototypes,
  };
};

const fetchPrototypesUsedWithinParentAssetSuccess = (prototypes) => {
  return {
    type: Actions.FETCH_PROTOTYPES_USED_WITHIN_PARENT_ASSET_SUCCESS,
    payload: prototypes,
  };
};

const fetchTopPrototypesSuccess = (prototypes) => {
  return {
    type: Actions.FETCH_TOP_PROTOTYPES_SUCCESS,
    payload: prototypes,
  };
};

const fetchNotTopPrototypesSuccess = (prototypes) => {
  return {
    type: Actions.FETCH_NOT_TOP_PROTOTYPES_SUCCESS,
    payload: prototypes,
  };
};

const fetchPrototypesFailure = (error) => {
  return {
    type: Actions.FETCH_PROTOTYPES_FAILURE,
    payload: error,
  };
};

export const editAssetPrototypeSuccess = (prototype) => {
  return {
    type: Actions.EDIT_ASSET_PROTOTYPE_SUCCESS,
    payload: {
      prototype,
    },
  };
};

export const editAssetPrototype = (t, prototype) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.put(`/assetPrototypes`, prototype);
      dispatch(editAssetPrototypeSuccess(prototype, data));
      if (data) {
        dispatch(
          createToast({
            type: "success",
            title: t("successfully edited asset prototype"),
          })
        );
      }
      return data;
    } catch (err) {
      console.log(err);
      dispatch(
        assetPrototypesRequestFailure(err.response.status, err.response.data)
      );
    }
  };
};

const assetPrototypesRequestFailure = (status, error) => {
  return {
    type: Actions.ASSET_PROTOTYPES_REQUEST_FAILURE,
    status,
    error,
  };
};

export const assetPrototypesEditErrorClear = () => {
  return {
    type: Actions.ASSET_PROTOTYPES_EDIT_ERROR_CLEAR,
  };
};

export const addAssetPrototype = (t, prototype) => {
  return async (dispatch) => {
    const { data } = await axios.post(`/assetPrototypes`, prototype);
    if (data) {
      dispatch(
        createToast({
          type: "success",
          title: t("successfully added asset prototype"),
        })
      );
    }
    return data;
  };
};

export const fetchAssetPrototypes = () => {
  return (dispatch) => {
    return axios
      .get(`/assetPrototypes`)
      .then((response) => {
        dispatch(fetchPrototypesSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchPrototypesFailure(error));
      });
  };
};

export const fetchAssetPrototypesUsedWithinCompany = () => {
  return (dispatch) => {
    return axios
      .get(`/assetPrototypes/used`)
      .then((response) => {
        dispatch(fetchPrototypesUsedWithinCompanySuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchPrototypesFailure(error));
      });
  };
};

export const fetchAssetPrototypesUsedWithinParentAsset = (parentId) => {
  return (dispatch) => {
    return axios
      .get(`/assetPrototypes/summaries?assetParentId=${parentId}`)
      .then((response) => {
        dispatch(fetchPrototypesUsedWithinParentAssetSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchPrototypesFailure(error));
      });
  };
};

export const fetchTopAssetPrototypes = () => {
  return (dispatch) => {
    return axios
      .get(`/assetPrototypes/topLevel`)
      .then((response) => {
        dispatch(fetchTopPrototypesSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchPrototypesFailure(error));
      });
  };
};

export const fetchNotTopAssetPrototypes = () => {
  return (dispatch) => {
    return axios
      .get(`/assetPrototypes/notTopLevel`)
      .then((response) => {
        dispatch(fetchNotTopPrototypesSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchPrototypesFailure(error));
      });
  };
};

export const addAssetPrototypeAttachment = (t, id, files) => {
  var formData = new FormData();
  files.forEach((file) => {
    formData.append("files", file, file.name);
  });

  return (dispatch) => {
    return axios
      .post(`/assetPrototypes/${id}/attachments`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(() => {
        dispatch(
          createToast({
            type: "success",
            title: t("successfully added attachment"),
          })
        );
      });
  };
};

export const deleteAssetPrototypeAttachment = (t, id, key) => {
  return (dispatch) => {
    return axios
      .delete(`/assetPrototypes/${id}/attachment?key=${key}`)
      .then(() => {
        dispatch(
          createToast({
            type: "success",
            title: t(`successfully deleted attachment`),
          })
        );
      });
  };
};

export const deleteAssetPrototype = (t, id) => {
  return (dispatch) => {
    return axios.delete(`/assetPrototypes/${id}`).then(() => {
      dispatch(
        createToast({
          type: "success",
          title: t("successfully deleted asset prototype"),
        })
      );
    });
  };
};
