import Actions from "../actions";
import axios from "../auth";

const fetchLicenseSuccess = (items) => {
  return {
    type: Actions.FETCH_LICENSE_SUCCESS,
    items,
  };
};

const fetchLicenseRequest = () => {
  return {
    type: Actions.FETCH_LICENSE_REQUEST,
  };
};

// const fetchLicenseListSuccess = (items) => {
//   return {
//     type: Actions.FETCH_LICENSE_LIST_SUCCESS,
//     items,
//   };
// };

// const fetchLicenseListRequest = () => {
//   return {
//     type: Actions.FETCH_LICENSE_LIST_REQUEST,
//   };
// };

export const fetchLicenses = () => {
  return async (dispatch) => {
    dispatch(fetchLicenseRequest());
    try {
      const response = await axios.get("/licenseHistory");
      const { data } = response;
      dispatch(fetchLicenseSuccess(data));
    } catch (err) {
      console.log(err);
    }
  };
};

// export const fetchLicenseList = () => {
//   return async (dispatch) => {
//     dispatch(fetchLicenseRequest());
//     try {
//       const response = await axios.get("/licenses/all");
//       const { data } = response;
//       dispatch(fetchLicenseSuccess(data));
//     } catch (err) {
//       console.log(err);
//     }
//   };
// };

// export const fetchAllLicenses = () => {
//   return async (dispatch) => {
//     dispatch(fetchLicenseListRequest());
//     try {
//       const response = await axios.get("/licenses/allAvailable");
//       const { data } = response;
//       dispatch(fetchLicenseListSuccess(data));
//     } catch (err) {
//       console.log(err);
//     }
//   };
// };

// export const addLicense = (licence) => {
//   return () => {
//     return axios.post(`/licenses/create`, licence);
//   };
// };

// export const updateLicense = (license) => {
//   return () => {
//     return axios.put(`/licenses/edit`, license);
//   };
// };

// export const deleteLicense = (license) => {
//   return () => {
//     return axios.delete(`/licenses/${license}`);
//   };
// };
