import React from "react";
import cx from "classnames";
import styles from "./AccordionPanel.module.scss";
import { useAccordionItem } from "./AccordionContext";

const AccordionPanel = ({ children, className }) => {
  const { isOpen } = useAccordionItem();

  const classNames = cx(styles.wrapper, className);
  if (!isOpen) {
    return null;
  }
  return <div className={classNames}>{children}</div>;
};

export default AccordionPanel;
