import * as Yup from "yup";

export const createPropertySchema = (t, properties) => {
  let schema = {};

  const booleanProperties = properties.filter(
    (property) =>
      property.propertyType === "boolean" && !!property.flags.isMandatory
  );
  booleanProperties.forEach((property) => {
    schema = {
      ...schema,
      [property.id]: Yup.boolean().oneOf([true], t("required field")),
    };
  });

  const textProperties = properties.filter(
    (property) =>
      property.propertyType === "text" && !!property.flags.isMandatory
  );
  textProperties.forEach((property) => {
    schema = {
      ...schema,
      [property.id]: Yup.string().required(t("required field")),
    };
  });

  const singleSelectionProperties = properties.filter(
    (property) =>
      property.propertyType === "selection" &&
      !property.multiselect &&
      !!property.flags.isMandatory
  );
  singleSelectionProperties.forEach((property) => {
    schema = {
      ...schema,
      [property.id]: Yup.string().required(t("required field")),
    };
  });

  const multiSelectionProperties = properties.filter(
    (property) =>
      property.propertyType === "selection" &&
      !!property.multiselect &&
      !!property.flags.isMandatory
  );
  multiSelectionProperties.forEach((property) => {
    schema = {
      ...schema,
      [property.id]: Yup.array().min(1, t("required field")),
    };
  });

  const dateProperties = properties.filter(
    (property) =>
      property.propertyType === "date" && !!property.flags.isMandatory
  );
  dateProperties.forEach((property) => {
    schema = {
      ...schema,
      [property.id]: Yup.string().required(t("required field")),
    };
  });

  const numericProperties = properties.filter(
    (property) =>
      property.propertyType === "numeric" && !!property.flags.isMandatory
  );
  numericProperties.forEach((property) => {
    schema = {
      ...schema,
      [property.id]: Yup.number()
        .required(t("required field"))
        .min(
          property.unit.range.lowerBound,
          `${t("value cannot be out of unit range")} (${
            property.unit.range.lowerBound
          }-${property.unit.range.upperBound})`
        )
        .max(
          property.unit.range.upperBound,
          `${t("value cannot be out of unit range")} (${
            property.unit.range.lowerBound
          }-${property.unit.range.upperBound})`
        ),
    };
  });

  const rangeProperties = properties.filter(
    (property) =>
      property.propertyType === "numeric" &&
      !property.flags.isMandatory &&
      !!property.unit
  );
  rangeProperties.forEach((property) => {
    schema = {
      ...schema,
      [property.id]: Yup.number()
        .min(
          property.unit.range.lowerBound,
          `${t("value cannot be out of unit range")} (${
            property.unit.range.lowerBound
          }-${property.unit.range.upperBound})`
        )
        .max(
          property.unit.range.upperBound,
          `${t("value cannot be out of unit range")} (${
            property.unit.range.lowerBound
          }-${property.unit.range.upperBound})`
        ),
    };
  });

  return schema;
};
