import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import MeatballMenu from "../Access/MeatballMenu";
import Icon from "../Icon";
import AddIconModal from "./AddIconModal";
import DeleteIconModal from "./DeleteIconModal";
import { I18nContext } from "../../i18n/I18nContext";
import styles from "./IconsContent.module.scss";

const IconsContent = ({ items, canEdit }) => {
  const { t } = useContext(I18nContext);
  const [openEditIconModal, setOpenEditIconModal] = useState(false);
  const [openDeleteIconModal, setOpenDeleteIconModal] = useState(false);
  const [selectedIcon, setSelectedIcon] = useState(null);
  const [meatballOpened, setMeatballOpened] = useState(null);
  const isPrivileged = useSelector(
    (state) => state.auth.info?.companyPrivileged
  );

  const meatBallsItems = (icon) => [
    {
      label: t("edit"),
      show:
        !icon.deprecated && (isPrivileged || (!isPrivileged && !icon.global)),
      onClick: () => {
        setOpenEditIconModal(true);
        setSelectedIcon(icon);
      },
      icon: <Icon name="editPencil-orange" />,
    },
    {
      label: t("delete"),
      show:
        !icon.deprecated && (isPrivileged || (!isPrivileged && !icon.global)),
      onClick: () => {
        setOpenDeleteIconModal(true);
        setSelectedIcon(icon);
      },
      icon: <Icon name="trash-orange" />,
    },
  ];

  return (
    <div className={styles.task_container}>
      {items?.map((item) => {
        return (
          <div key={item.id} className={styles.row}>
            <div className={styles.title}>{item.name}</div>
            <div className={styles.iconCell}>
              <svg
                data-src={item.url}
                fill="#575756"
                width="36px"
                height="36px"
              />
            </div>
            <div className={styles.date}>
              <div className={styles.create}>
                {new Date(item.updatedAt).toLocaleDateString(undefined, {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                })}
              </div>
              <div className={styles.meatball}>
                <MeatballMenu
                  items={meatBallsItems(item)}
                  disabled={
                    item.deprecated ||
                    (!isPrivileged && item.global) ||
                    !canEdit
                  }
                  id={item.id}
                  meatballOpened={meatballOpened}
                  meatballOpenedObserver={(state) => setMeatballOpened(state)}
                />
              </div>
            </div>
          </div>
        );
      })}
      <AddIconModal
        key="edit-icon"
        iconEdit={openEditIconModal}
        isOpen={openEditIconModal}
        icon={selectedIcon}
        onClose={() => {
          setOpenEditIconModal(false);
          setSelectedIcon(null);
        }}
      />
      <DeleteIconModal
        key="delete-icon"
        icon={selectedIcon}
        isOpen={openDeleteIconModal}
        onClose={() => {
          setOpenDeleteIconModal(false);
          setSelectedIcon(null);
        }}
      />
    </div>
  );
};

IconsContent.propTypes = {
  items: PropTypes.array,
};

export default IconsContent;
