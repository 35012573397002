import React, { useContext } from "react";
import Button from "../Button";
import Modal from "../Modal";
import styles from "./DisableRuleModal.module.scss";
import { I18nContext } from "../../i18n/I18nContext";

const DisableRuleModal = ({ enable, isOpen, onClose, rule, onConfirm }) => {
  const { t } = useContext(I18nContext);

  const handleClear = () => {
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={`${t(!!enable ? "enable rule" : "disable rule")}?`}
      icon
      footer={
        <div className={styles.button_section}>
          <Button
            className={styles.btn_separator}
            onClick={handleClear}
            type="reset"
            variant="ghost"
            size="s"
          >
            {t("cancel")}
          </Button>
          <Button type="submit" variant="orange" size="s" onClick={onConfirm}>
            {t("confirm")}
          </Button>
        </div>
      }
    >
      <div className={styles.delete_txt}>
        {!!enable
          ? t("are you sure you want to enable rule")
          : t("are you sure you want to disable rule")}
        <div className={styles.spacing_txt}>{rule?.name} ?</div>
      </div>
    </Modal>
  );
};

export default DisableRuleModal;
