import React, { useContext } from "react";
import * as Yup from "yup";
import {
  FieldLabel,
  Form,
  FormControl,
  FieldError,
  FieldTextArea,
} from "../Input";
import { useDispatch, useSelector } from "react-redux";
import styles from "./TaskPrototypeForm.module.scss";
import { I18nContext } from "../../i18n/I18nContext";

import { undoneTask, fetchTasksList } from "../../redux";
import { TaskState } from "../../constants/Tasks";

const defaultInitialValues = {
  description: "",
};

const validationSchema = (t) =>
  Yup.object({
    description: Yup.string()
      .max(140, t("the reason must be at most 140 characters"))
      .required(t("please provide the reason")),
  });

const UndoneTaskForm = ({ formId, itemId, onClose, getAssetDetails }) => {
  const { t } = useContext(I18nContext);
  const dispatch = useDispatch();
  const allChoosen = useSelector((state) => state.buildings.choosenBuildings);

  const handleSubmit = async (values, formik) => {
    const { description } = values;

    try {
      const response = await dispatch(undoneTask(t, itemId, description));

      dispatch(
        fetchTasksList(
          TaskState.TODO,
          allChoosen.map((el) => el.id)
        )
      );
      dispatch(
        fetchTasksList(
          TaskState.DONE,
          allChoosen.map((el) => el.id)
        )
      );
      if (!!getAssetDetails) {
        getAssetDetails();
      }

      formik.resetForm();
      onClose();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Form
        id={formId}
        onSubmit={handleSubmit}
        initialValues={defaultInitialValues}
        validationSchema={validationSchema(t)}
        enableReinitialize
      >
        {({ values, errors }) => {
          return (
            <>
              <FormControl>
                <FieldLabel>
                  {t("description")}
                  <span className={styles.red}> *</span>
                </FieldLabel>
                <FieldTextArea
                  name="description"
                  placeholder={t("please provide the reason")}
                />
                <FieldError name="description" />
              </FormControl>
            </>
          );
        }}
      </Form>
    </>
  );
};

export default UndoneTaskForm;
