import Icon from "../../Icon";
import styles from "./ArticleButton.module.scss";
import { I18nContext } from "../../../i18n/I18nContext";
import React, { useContext } from "react";

const ArticleButton = ({ title, iconName, onClick, estimatedReadTime }) => {
  const { t } = useContext(I18nContext);
  return (
    <div className={styles.wrapper} onClick={onClick}>
      <div className={styles.contentWrapper}>
        <div className={styles.iconContainer}>
          <Icon name={iconName} className={styles.icon} />
        </div>
        <div className={styles.column}>
          <span>{title}</span>
          <span className={styles.estimatedText}>
            {t("about")} {estimatedReadTime} min
          </span>
        </div>
      </div>
      <Icon name="chevron-right" className={styles.arrow} />
    </div>
  );
};

export default ArticleButton;
