import Actions from "../actions";

const assets = {
  topAssets: {},
  allAssets: {},
  searchAssets: [],
  loading: false,
  loadingAssetNames: false,
  items: [],
  details: null,
  selectedLayout: null,
  assetsForGroupTask: [],
  unfilteredAssetsForGroupTask: [],
  stateChangeInProgress: false,
};

const assetsReducer = (state = assets, action) => {
  switch (action.type) {
    case Actions.ASSETS_LOADING_START:
      return {
        ...state,
        loading: true,
      };
    case Actions.ASSETS_LOADING_STOP:
      return {
        ...state,
        loading: false,
      };
    case Actions.FEATCH_ASSET_DETAILS_SUCCESS:
      return {
        ...state,
        details: action.asset,
      };
    case Actions.FETCH_ALL_ASSETS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case Actions.FETCH_ASSETS_SUCCESS:
      return {
        ...state,
        loading: false,
        allAssets: {
          ...state.allAssets,
          [action.id]: action.assets,
        },
      };
    case Actions.FETCH_ASSETS_SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        searchAssets: action.assets,
      };
    case Actions.CLEAR_SEARCH: {
      return {
        ...state,
        searchAssets: [],
      };
    }
    case Actions.ADD_ASSET_SUCCESS:
      return {
        ...state,
        loading: false,
        allAssets: {
          ...state.allAssets,
          [action.id]: [...(state.allAssets[action.id] ?? []), action.asset],
        },
      };
    case Actions.FETCH_TOP_ASSETS_SUCCESS:
      return {
        ...state,
        loading: false,
        topAssets: {
          ...action.assets.reduce((current, asset) => {
            current[asset.id] = asset;
            return current;
          }, {}),
        },
      };
    case Actions.ASSETS_NAMES_LOADING_START:
      return {
        ...state,
        loadingAssetNames: true,
      };
    case Actions.FETCH_ASSETS_NAMES_SUCCESS:
      return {
        ...state,
        loadingAssetNames: false,
        items: action.payload,
      };
    case Actions.FETCH_ASSETS_NAMES_FAILURE:
      return {
        ...state,
        loadingAssetNames: false,
        error: action.payload,
      };
    case Actions.EDIT_ASSET_SUCCESS:
      const { assetId, asset } = action.payload;
      const update = {
        ...state,
      };

      if (update.topAssets[assetId]) {
        update.topAssets[assetId] = asset;
        update.topAssets = { ...update.topAssets };
      }

      if (update.allAssets[asset.parentId]) {
        const assetIndex = update.allAssets[asset.parentId].findIndex(
          (asset) => asset.id === assetId
        );
        update.allAssets[asset.parentId][assetIndex] = asset;
        update.allAssets = {
          ...update.allAssets,
          [asset.parentId]: [...update.allAssets[asset.parentId]],
        };
      }

      const indexSearch = update.searchAssets.findIndex(
        (asset) => asset.asset.id === assetId
      );
      if (indexSearch !== -1) {
        update.searchAssets[indexSearch].asset = asset;
        update.searchAssets = [...update.searchAssets];
      }

      const indexItem = update.items.findIndex((asset) => asset.id === assetId);
      if (indexItem !== -1) {
        update.items[indexItem] = { id: asset.id, name: asset.name };
        update.items = [...update.items];
      }
      return update;
    case Actions.SELECT_LAYOUT:
      return {
        ...state,
        selectedLayout: action.layout,
      };
    case Actions.FETCH_ASSETS_FOR_GROUP_TASK_SUCCESS:
      return {
        ...state,
        loading: false,
        assetsForGroupTask: action.assets,
      };
    case Actions.FETCH_UNFILTERED_ASSETS_FOR_GROUP_TASK_SUCCESS:
      return {
        ...state,
        unfilteredAssetsForGroupTask: action.assets,
      };
    case Actions.STATE_CHANGE_START:
      return {
        ...state,
        stateChangeInProgress: true,
      };
    case Actions.STATE_CHANGE_STOP:
      return {
        ...state,
        stateChangeInProgress: false,
      };
    default:
      return state;
  }
};

export default assetsReducer;
