import { FieldInput, Form, FormControl } from "../Input";
import styles from "./HelpContent.module.scss";
import ArticleButton from "./subComponents/ArticleButton";
import HelpMenuItem from "./subComponents/HelpMenuItem";
import { I18nContext } from "../../i18n/I18nContext";
import Button from "../../components/Button";
import { useContext } from "react";

const HelpContent = () => {
  const { t } = useContext(I18nContext);

  const initialValues = {
    helpQuery: "",
  };

  return (
    <div>
      <Form enableReinitialize id="search-help" initialValues={initialValues}>
        <FormControl className={styles.input_search}>
          <FieldInput
            name="helpQuery"
            icon
            type="text"
            placeholder={"Search help"}
            size="l"
          />
        </FormControl>
      </Form>
      <HelpMenuItem
        title={"Popular help resources"}
        iconName="help-resources"
      />
      <HelpMenuItem title={"Read the help articles"} iconName="help-articles" />
      <HelpMenuItem title={"Share your opinion"} iconName="help-opinion" />
      <HelpMenuItem title={"Keyboard shortcuts"} iconName="keyboard-orange" />
      <div className={styles.divider}></div>
      <div className={styles.column}>
        <span>{t("whats new in T@pper")}</span>
        <span className={styles.whatsNew}>
          {t("learn what's new in the latest release of T@pper")}
        </span>
      </div>
      <ArticleButton
        title={"Learn how to check tasks"}
        estimatedReadTime={3}
        iconName={"help-white-star"}
      />
      <ArticleButton
        title={"Define rules and alerts"}
        estimatedReadTime={2}
        iconName={"help-alerts"}
      />
      <ArticleButton
        title={"Prepare reports"}
        estimatedReadTime={2}
        iconName={"help-reports"}
      />
      <ArticleButton
        title={"Measure performance with reports"}
        estimatedReadTime={3}
        iconName={"help-measure"}
      />
      <div className={styles.divider}></div>
      <div className={styles.adminGuideWrapper}>
        <span className={styles.adminHeader}>{t("admin guide")}</span>
        <span>{t("learn T@pper best practices and how")}</span>
        <span>{t("to provide excellent customer service", "lower")}.</span>
        <div className={styles.buttonWrapper}>
          <Button onClick={() => null} variant="orange" size="s">
            {"Read admin's guide"}
          </Button>
        </div>
      </div>
      <div className={styles.divider}></div>
      <div className={styles.footWrapper}>
        <span>{t("terms of service")}</span>
        <span>{t("privacy policy")}</span>
      </div>
    </div>
  );
};

export default HelpContent;
