import React, { useContext } from "react";
import Modal from "../Modal";
import styles from "./AddRulesModal.module.scss";
import { I18nContext } from "../../i18n/I18nContext";
import FieldBuilder from "../FieldBuilder";

const formId = "edit-asset-property";

const EditAssetPropertyModal = ({
  onClose,
  isOpen,
  property,
  procedureIds,
  propertyNames,
  onSubmit,
  index,
  formEdit,
}) => {
  const { t } = useContext(I18nContext);

  return (
    <Modal
      className={styles.modal}
      isOpen={isOpen}
      onClose={onClose}
      classNameTitle={styles.title}
      title={t("edit property")}
      form={"edit-proptotype-property"}
      divider={false}
      editMode
    >
      <FieldBuilder
        onSubmit={onSubmit}
        hideTags={true}
        propertyNames={propertyNames}
        assetView
        property={property}
        procedures={procedureIds}
        formEdit={formEdit}
        hideUpdatable={formEdit}
      />
    </Modal>
  );
};

export default EditAssetPropertyModal;
