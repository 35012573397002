import Actions from "../actions";
import axios from "../auth";
import { createToast } from "../toast/toastActions";

const fetchUnitsSuccess = (units) => {
  return {
    type: Actions.FETCH_UNITS_SUCCESS,
    payload: units,
  };
};

const fetchUnitsFailure = (error) => {
  return {
    type: Actions.FETCH_UNITS_FAILURE,
    payload: error,
  };
};

export const fetchUnitsList = () => {
  return (dispatch) => {
    return axios
      .get(`/units`)
      .then((response) => {
        dispatch(fetchUnitsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchUnitsFailure(error));
      });
  };
};

export const addUnit = (t, unit) => {
  return (dispatch) => {
    return axios.post(`/units`, unit).then((response) => {
      dispatch(
        createToast({
          type: "success",
          title: t("successfully added new unit"),
        })
      );
    });
  };
};

export const editUnit = (t, unit) => {
  return (dispatch) => {
    return axios.put(`/units`, unit).then((response) => {
      dispatch(
        createToast({
          type: "success",
          title: t("successfully edited unit"),
        })
      );
    });
  };
};

export const deleteUnit = (t, id) => {
  return (dispatch) => {
    return axios.delete(`/units/${id}`).then((response) => {
      dispatch(
        createToast({
          type: "success",
          title: t("successfully deleted unit"),
        })
      );
    });
  };
};
