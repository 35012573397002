import { combineReducers } from "redux";
import assetsReducer from "./assets/assetsReducer";
import profileReducer from "./profile/profileReducer";
import prototypesReducer from "./prototypes/prototypesReducer";
import rulesReducer from "./rules/rulesReducer";
import authReducer from "./auth/authReducer";
import taskPrototypesReducer from "./taskPrototypes/taskPrototypesReducer";
import companiesReducer from "./companies/companiesReducer";
import regionsReducer from "./regions/regionsReducer";
import passwordReducer from "./password/passwordReducer";
import tasksReducer from "./tasks/tasksReducer";
import rightsReducer from "./rights/rightsReducer";
import unitReducer from "./units/unitReducer";
import accessReducer from "./access/accessReducer";
import maintainersReducer from "./maintainers/maintainersReducer";
import licenseReducer from "./license/licenseReducer";
import toastReducer from "./toast/toastReducers";
import formReducer from "./forms/formsReducer";
import buildingsReducer from "./buidings/buildingsReducer";
import iconsReducer from "./icons/iconsReducer";
import attachmentsReducer from "./attachments/attachmentsReducer";
import reportsReducer from "./reports/reportsReducer";
import notificationGroupsReducer from "../components/NotificationGroups/notificationGroupsReducer";

const appReducer = combineReducers({
  assets: assetsReducer,
  auth: authReducer,
  rules: rulesReducer,
  profile: profileReducer,
  prototypes: prototypesReducer,
  taskPrototypes: taskPrototypesReducer,
  forms: formReducer,
  tasks: tasksReducer,
  rights: rightsReducer,
  companies: companiesReducer,
  regions: regionsReducer,
  toast: toastReducer,
  password: passwordReducer,
  units: unitReducer,
  access: accessReducer,
  maintainers: maintainersReducer,
  license: licenseReducer,
  buildings: buildingsReducer,
  icons: iconsReducer,
  attachments: attachmentsReducer,
  reports: reportsReducer,
  notificationGroups: notificationGroupsReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
