import React, { useContext, useRef, useState } from "react";
import cx from "classnames";
import { useDetectOutsideClick } from "../../hooks/useDetectOutsideClick";
import Icon from "../Icon";
import OneOfTaskModal from "../Tasks/OneOfTaskModal";
import RecurringTaskModal from "../Tasks/RecurringTaskModal";
import { I18nContext } from "../../i18n/I18nContext";
import styles from "./Meatball.module.scss";

const MeatballTask = ({
  currentDetails,
  fetchRecurrenceTasksForCurrentAsset,
}) => {
  const { t } = useContext(I18nContext);
  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);
  const [openOneOfTaskModal, setOpenOneOfTaskModal] = useState(false);
  const [openRecurringTaskModal, setOpenRecurringTaskModal] = useState(false);

  const onClick = (event) => {
    event.stopPropagation();
    setIsActive(!isActive);
  };

  const options = [
    {
      label: t("one of task"),
      onClick: () => setOpenOneOfTaskModal(true),
    },
    {
      label: t("recurring task"),
      onClick: () => setOpenRecurringTaskModal(true),
    },
  ];

  const classOpenMeatball = cx(styles.menu, {
    [styles.active]: isActive,
    [styles.inactive]: !isActive,
  });

  return (
    <>
      <div className={styles.menu_container} onClick={onClick}>
        <div className={styles.menu_trigger}>
          <Icon name="add" className={styles.icon} />
        </div>
        <div ref={dropdownRef} className={classOpenMeatball}>
          {options.map((item, idx) => (
            <div
              className={styles.item_container}
              key={idx}
              onClick={item.onClick}
            >
              <span className={styles.label_menu}>{item.label}</span>
            </div>
          ))}
        </div>
      </div>
      {!!openOneOfTaskModal && (
        <OneOfTaskModal
          isOpen={openOneOfTaskModal}
          currentDetails={currentDetails}
          onClose={() => setOpenOneOfTaskModal(false)}
        />
      )}
      {!!openRecurringTaskModal && (
        <RecurringTaskModal
          isOpen={openRecurringTaskModal}
          currentDetails={currentDetails}
          onClose={() => setOpenRecurringTaskModal(false)}
          fetchRecurrenceTasksForCurrentAsset={
            fetchRecurrenceTasksForCurrentAsset
          }
        />
      )}
    </>
  );
};

export default MeatballTask;
