import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Icon from "../Icon";
import styles from "./AssetHeaderDetails.module.scss";
import { appUrls } from "../../urls";

const AssetHeaderDetails = ({
  children,
  title,
  path,
  protoName,
  protoDesc,
  closeModal,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.container_title}>
        {!!closeModal ? (
          <div className={styles.link_return} onClick={closeModal}>
            <Icon name="chevron-left" />
          </div>
        ) : (
          <Link className={styles.link_return} to={appUrls.ASSETS}>
            <Icon name="chevron-left" />
          </Link>
        )}
        <span className={styles.path}>
          {path ? path + " / " : ""}
          <span className={styles.title}>{title}</span>
        </span>
      </div>
      <div className={styles.prototype_info}>
        <span>
          {protoName}{" "}
          {!!protoDesc && (
            <span className={styles.prototype_desc}>({protoDesc})</span>
          )}
        </span>
      </div>
    </div>
  );
};

AssetHeaderDetails.propTypes = {
  text: PropTypes.object,
  title: PropTypes.string,
};

export default AssetHeaderDetails;
