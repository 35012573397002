import React, { useContext, useState } from "react";

import Modal from "../Modal";
import TaskPrototypeForm from "./TaskPrototypeForm";
import { I18nContext } from "../../i18n/I18nContext";
import Button from "../Button";
import styles from "./TaskPrototypeModal.module.scss";

const formId = "prototype-modal-form";

const TaskPrototypeModal = ({
  onClose,
  isOpen,
  recurranceRequired,
  taskPrototype,
  preview,
}) => {
  const { t } = useContext(I18nContext);
  const [rulesEditorOpen, setRulesEditorOpen] = useState(false);

  const toggleRulesEditorOpen = (state) => {
    setRulesEditorOpen(state);
  };

  return (
    <Modal
      className={styles.modal}
      isOpen={isOpen}
      onClose={onClose}
      title={
        preview
          ? t("preview task type")
          : taskPrototype
          ? t("edit task template")
          : t("add new task template")
      }
      form={formId}
      divider={false}
      footer={
        <div className={styles.btn_section}>
          <Button
            onClick={onClose}
            type="reset"
            form={formId}
            variant="ghost"
            size="s"
          >
            {t(!preview ? "cancel" : "close")}
          </Button>
          {!taskPrototype && (
            <Button
              className={styles.btn_separator}
              type="submit"
              variant="orange"
              size="s"
              form={formId}
            >
              {t("add")}
            </Button>
          )}

          {taskPrototype && !preview && (
            <Button
              className={styles.btn_separator}
              type="submit"
              variant={rulesEditorOpen ? "disabled" : "orange"}
              size="s"
              form={formId}
              disabled={rulesEditorOpen}
              disabledTooltipText={t(
                "changes to the prototype cannot be saved if the rules are being edited - please save / close all rules before proceeding"
              )}
            >
              {t("save changes", "title")}
            </Button>
          )}
        </div>
      }
    >
      <TaskPrototypeForm
        recurranceRequired={recurranceRequired}
        allowEditingTags={!preview}
        onClose={onClose}
        formId={formId}
        showEditing={false}
        taskPrototype={taskPrototype}
        allowEditingAttachments={!preview}
        showSelectForms
        disabled
        createPrototype
        previewMode={preview}
        readOnly={preview}
        toggleRulesEditorOpen={toggleRulesEditorOpen}
      />
    </Modal>
  );
};

export default TaskPrototypeModal;
