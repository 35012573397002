import React, { useContext, useState } from "react";
import { I18nContext } from "../../i18n/I18nContext";
import styles from "./RuleCondition.module.scss";
import Select from "react-select";
import { ComparisonOperation } from "../../constants/ComparisonOperation";
import Icon from "../Icon";

const NumericCondition = (props) => {
  const { condition, readOnly, selectStyles, selectCondition } = props;
  const { t } = useContext(I18nContext);

  const operatorOptions = [
    {
      label: t("greater than or equal to"),
      value: ComparisonOperation.GTE,
    },
    { label: t("greater than"), value: ComparisonOperation.GT },
    {
      label: t("less than or equal to"),
      value: ComparisonOperation.LTE,
    },
    { label: t("less than"), value: ComparisonOperation.LT },
    { label: t("equal to"), value: ComparisonOperation.E },
    { label: t("not equal to"), value: ComparisonOperation.NE },
  ];

  const [conditionValue, setConditionValue] = useState(
    condition && condition.comparisons
      ? condition.comparisons[0].comparedValue
      : condition && condition.value
      ? condition.value
      : null
  );
  const [operatorValue, setOperatorValue] = useState(
    condition && condition.comparisons
      ? operatorOptions.find(
          (option) => option.value === condition.comparisons[0].operation
        )
      : condition && condition.operator
      ? condition.operator
      : operatorOptions[0]
  );

  return (
    <div className={styles.condition}>
      <div className={styles.property_value}>{t("property value")}</div>
      <Select
        styles={selectStyles}
        menuPosition={"relative"}
        isDisabled={readOnly}
        value={operatorValue}
        maxMenuHeight={200}
        onChange={(selected) => setOperatorValue(selected)}
        options={operatorOptions}
        placeholder={t("select")}
        noOptionsMessage={() => t("no options")}
      />
      <input
        className={styles.number_input}
        type="number"
        onInput={(e) => setConditionValue(e.target.value)}
        disabled={readOnly}
        defaultValue={conditionValue}
      />
      {!condition && !!conditionValue && (
        <button
          className={styles.add_btn}
          onClick={() =>
            selectCondition({
              value: conditionValue,
              operator: operatorValue,
              type: "numerical_precondition",
            })
          }
        >
          <Icon className={styles.add_icon} name="add-form-modal" />
        </button>
      )}
      {!!condition &&
        ((!!condition.comparisons &&
          (conditionValue !== condition.comparisons[0].comparedValue ||
            operatorValue.value !== condition.comparisons[0].operation)) ||
          (!!condition.value &&
            (conditionValue !== condition.value ||
              operatorValue.value !== condition.operator.value))) && (
          <button
            className={styles.add_btn}
            onClick={() => {
              selectCondition({
                value: conditionValue,
                operator: operatorValue,
                type: "numerical_precondition",
              });
            }}
          >
            <Icon className={styles.add_icon} name="add-form-modal" />
          </button>
        )}
    </div>
  );
};

export default NumericCondition;
