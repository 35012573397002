import React, { useContext } from "react";
import Button from "../Button";
import Modal from "../Modal";
import styles from "./DeleteRuleModal.module.scss";
import { I18nContext } from "../../i18n/I18nContext";

const DeleteRuleModal = ({ isOpen, onClose, rule, onDelete }) => {
  const { t } = useContext(I18nContext);

  const handleClear = () => {
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={`${t("delete rule")}?`}
      icon
      footer={
        <div className={styles.button_section}>
          <Button
            className={styles.btn_separator}
            onClick={handleClear}
            type="reset"
            variant="ghost"
            size="s"
          >
            {t("cancel")}
          </Button>
          <Button type="submit" variant="orange" size="s" onClick={onDelete}>
            {t("delete")}
          </Button>
        </div>
      }
    >
      <div className={styles.delete_txt}>
        {t("are you sure you want to permanently delete rule")}
        <div className={styles.spacing_txt}>{rule?.name} ?</div>
      </div>
    </Modal>
  );
};

export default DeleteRuleModal;
