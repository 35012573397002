import React, { useContext } from "react";
import { components } from "react-select";
import Createable from "react-select/creatable";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { I18nContext } from "../../i18n/I18nContext";

const SortableMultiValue = SortableElement((props) => {
  const onMouseDown = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const innerProps = { onMouseDown };
  return <components.MultiValue {...props} innerProps={innerProps} />;
});
const SortableSelect = SortableContainer(Createable);

const sortableSelectStyles = {
  control: (styles, { isSelected, data }) => {
    return {
      ...styles,
      cursor: "pointer",
    };
  },
  option: (styles, { isSelected, data }) => {
    return {
      ...styles,
      backgroundColor: data.global ? "#fff8f1" : "#ffffff",
      cursor: "pointer",
    };
  },
  menu: (styles, { isSelected, data }) => {
    return {
      ...styles,
      zIndex: "10",
      backgroundColor: "#ffffff",
    };
  },
};

export default function MultiSelectSort({
  onSortEnd,
  options,
  selected,
  onChange,
  disabled,
}) {
  const { t } = useContext(I18nContext);
  return (
    <SortableSelect
      // react-sortable-hoc props:
      axis="xy"
      onSortEnd={onSortEnd}
      distance={4}
      // small fix for https://github.com/clauderic/react-sortable-hoc/pull/352:
      getHelperDimensions={({ node }) => node.getBoundingClientRect()}
      // react-select props:
      isMulti
      options={options}
      value={selected}
      onChange={onChange}
      components={{
        MultiValue: SortableMultiValue,
      }}
      closeMenuOnSelect={false}
      styles={sortableSelectStyles}
      placeholder={t("select")}
      isDisabled={disabled}
      noOptionsMessage={() => t("no options")}
    />
  );
}
