import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import cx from "classnames";
import { I18nContext } from "../../i18n/I18nContext";
import MeatballMenu from "../Access/MeatballMenu";
import Icon from "../Icon";
import DeletePrototypeModal from "./DeletePrototypeModal";
import AssetPrototypeModal from "./AssetPrototypeModal";
import styles from "./FilteredProptypes.module.scss";

const FilteredProptypes = ({ items, canEdit }) => {
  const { t } = useContext(I18nContext);
  const [openDeleteAssetPrototypeModal, setOpenDeleteAssetPrototypeModal] =
    useState(false);
  const [openEditAssetPrototypeModal, setOpenEditAssetPrototypeModal] =
    useState(false);
  const [selectedPrototype, setSelectedPrototype] = useState(null);
  const [meatballOpened, setMeatballOpened] = useState(null);
  const isPrivileged = useSelector(
    (state) => state.auth.info?.companyPrivileged
  );

  useEffect(() => {
    if (!!selectedPrototype) {
      setSelectedPrototype(items.find((x) => x.id === selectedPrototype.id));
    }
  }, [items]);

  const meatballMenuItems = (prototype) => [
    {
      label: t("edit"),
      show:
        !prototype.deprecated &&
        (isPrivileged || (!isPrivileged && !prototype.global)),
      onClick: () => {
        setSelectedPrototype(prototype);
        setOpenEditAssetPrototypeModal(true);
      },
      icon: <Icon name="editPencil-orange" className={styles.icon_menu} />,
    },
    {
      label: t("preview"),
      show: !isPrivileged && prototype.global,
      onClick: () => {
        setSelectedPrototype(prototype);
        setOpenEditAssetPrototypeModal(true);
      },
      icon: <Icon name="eye-orange" className={styles.icon_menu} />,
    },
    {
      label: t("delete"),
      show:
        !prototype.deprecated &&
        (isPrivileged || (!isPrivileged && !prototype.global)),
      icon: <Icon name="trash-orange" className={styles.icon_menu} />,
      onClick: () => {
        setOpenDeleteAssetPrototypeModal(true);
        setSelectedPrototype(prototype);
      },
    },
  ];

  return (
    <div className={styles.task_container}>
      {items?.map((item) => {
        return (
          <div
            key={item.id}
            className={cx(styles.row, {
              [styles.visible_for_sub_companies]: item.visibleOnAllCompanies,
            })}
          >
            <div
              className={cx(styles.title, {
                [styles.deprecated]: item.deprecated,
              })}
            >
              {item.name}
              <div className={styles.descriptionTooltip}>{item?.desc}</div>
            </div>
            {item.grouping === true && (
              <div className={styles.group}>{t("group asset")}</div>
            )}
            <div className={styles.date}>
              {new Date(item.updatedAt).toLocaleDateString(undefined, {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              })}
            </div>
            <span className={styles.meatball}>
              <MeatballMenu
                items={meatballMenuItems(item)}
                disabled={item.deprecated || !canEdit}
                id={item.id}
                meatballOpened={meatballOpened}
                meatballOpenedObserver={(state) => setMeatballOpened(state)}
              />
            </span>
          </div>
        );
      })}
      {!!openDeleteAssetPrototypeModal && (
        <DeletePrototypeModal
          key="delete-prototype"
          prototype={selectedPrototype}
          isOpen={openDeleteAssetPrototypeModal}
          onClose={() => {
            setOpenDeleteAssetPrototypeModal(false);
            setSelectedPrototype(null);
          }}
        />
      )}
      {!!openEditAssetPrototypeModal && (
        <AssetPrototypeModal
          key="edit-prototype"
          assetPrototype={selectedPrototype}
          isOpen={openEditAssetPrototypeModal}
          onClose={() => {
            setOpenEditAssetPrototypeModal(false);
            setSelectedPrototype(null);
          }}
          preview={!isPrivileged && selectedPrototype?.global}
          propertiesDisabled={true}
        />
      )}
    </div>
  );
};

FilteredProptypes.propTypes = {
  items: PropTypes.array,
};

export default FilteredProptypes;
