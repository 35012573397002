import React, { useCallback } from "react";
import { Field } from "formik";
import PropTypes from "prop-types";
import cx from "classnames";
import styles from "./FieldTextArea.module.scss";

const CustomTextAreaComponent = ({
  field,
  onChange,
  form: { touched, errors, isValid, setFieldTouched },
  disabled,
  ...props
}) => {
  const classNamesInput = cx(styles.input, {
    [styles.error]: errors[field.name],
    [styles.valid]: isValid,
  });

  const handleChange = useCallback(
    (e) => {
      if (onChange) {
        onChange(e.target.value);
      }
      field.onChange(e);
    },
    [onChange, field]
  );

  return (
    <textarea
      className={disabled ? [styles.input2] : classNamesInput}
      {...field}
      {...props}
      data-testid={`text-area-${field.name}`}
      disabled={disabled}
      onChange={handleChange}
      onBlur={() => setFieldTouched(field.name, true)}
    />
  );
};

const FieldTextArea = ({ name, rows = 2, ...rest }) => {
  return (
    <Field
      name={name}
      rows={rows}
      component={CustomTextAreaComponent}
      {...rest}
    ></Field>
  );
};

FieldTextArea.propTypes = {
  name: PropTypes.string,
  rows: PropTypes.number,
  disabled: PropTypes.bool,
};

export default FieldTextArea;
