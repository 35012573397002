import React from "react";
import Dashboard from "../../layouts/Dashboard";
import Reports from "../../components/Reports";

const ReportsView = () => {
  return (
    <Dashboard>
      <Reports />
    </Dashboard>
  );
};

export default ReportsView;
