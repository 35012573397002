import Actions from "../actions";
import axios from "../auth";

export const setChoosenBuildings = (buildingList) => {
  return {
    type: Actions.SET_BUILDINGS,
    payload: buildingList,
  };
};

export const clearChoosenBuildings = () => {
  return {
    type: Actions.CLEAR_BUILDINGS,
  };
};

export const getPropertiesByProto = async (chosenPrototypes) => {
  let properties = [];
  await axios
    .post(`/topLevelPropertiesFromPrototypes`, chosenPrototypes)
    .then((response) => {
      properties = response.data;
    });

  return properties;
};

export const searchBuildings = async (queries) => {
  let buildings = [];
  await axios
    .post(`/searchTopLevels`, queries)
    .then(({ data }) => {
      buildings = data;
    })
    .catch((error) => console.log(error));
  return buildings;
};
