import React from "react";
import { useDispatch } from "react-redux";
import styles from "./AlertsList.module.scss";

import AlertItem from "../AlertItem/AlertItem";

import { deactivateAlert } from "../../redux/assets/assetsActions";

const AlertsList = ({ alerts, fetchAlerts }) => {
  const dispatch = useDispatch();

  const handleDeactivateAlert = async (alertId) => {
    const { status } = await dispatch(deactivateAlert(alertId));
    if (status === 200) {
      fetchAlerts();
    }
  };

  return (
    <div className={styles.container}>
      {!!alerts?.length && (
        <ul className={styles.alertList}>
          {alerts.map((alert) => (
            <AlertItem
              key={alert.id}
              item={alert}
              onDeactivate={handleDeactivateAlert}
            />
          ))}
        </ul>
      )}
    </div>
  );
};

export default AlertsList;
