import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import { deleteUnit, fetchUnitsList } from "../../redux/units/unitActions";
import Button from "../Button";
import Modal from "../Modal";
import styles from "./DeleteUnitModal.module.scss";
import { I18nContext } from "../../i18n/I18nContext";

const DeleteUnitModal = ({ isOpen, onClose, unit }) => {
  const { t } = useContext(I18nContext);
  const dispatch = useDispatch();

  const handleClear = () => {
    onClose();
  };
  const handleDeleteUnit = async () => {
    await dispatch(deleteUnit(t, unit.id));
    dispatch(fetchUnitsList());
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={`${t("delete unit")}?`}
      form="delete-form-unit"
      icon
      footer={
        <div className={styles.button_section}>
          <Button
            className={styles.btn_separator}
            onClick={handleClear}
            type="reset"
            form={"delete-form-unit"}
            variant="ghost"
            size="s"
          >
            {t("cancel")}
          </Button>
          <Button
            type="submit"
            variant="orange"
            size="s"
            form={"delete-form"}
            onClick={handleDeleteUnit}
          >
            {t("delete")}
          </Button>
        </div>
      }
    >
      <div className={styles.delete_txt}>
        {t("are you sure you want to permanently delete unit")}
        <div className={styles.spacing_txt}>{unit?.name} ?</div>
      </div>
    </Modal>
  );
};

export default DeleteUnitModal;
