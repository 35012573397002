export const readyItems = [
  {
    id: 5,
    daysLeft: "20 February 2021",
    subtitle: "Read Task",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitat.",
    date: "Created: 12 March 2021",
    template: 1,
    type: "READY",
  },
  {
    id: 6,
    daysLeft: "11 February 2021",
    subtitle: "Ready Task",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitat.",
    date: "Created: 10 March 2021",
    template: 2,
    type: "UNREADY",
  },
  {
    id: 7,
    daysLeft: "20 March 2021",
    subtitle: "Read Task",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitat.",
    date: "Created: 12 March 2021",
    template: 1,
    type: "UNREADY",
  },
  {
    id: 8,
    daysLeft: "17 May 2021",
    subtitle: "Ready Task",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitat.",
    date: "Created: 10 March 2021",
    template: 2,
    type: "READY",
  },
];

export const recentItems = [
  {
    id: 1,
    daysLeft: 3,
    subtitle: "Recent Task",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitat.",
    date: "Created: 12 March 2021",
    template: 1,
    type: "NEW",
  },
  {
    id: 2,
    daysLeft: 10,
    subtitle: "Recent Task",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitat.",
    date: "Created: 10 March 2021",
    template: 2,
    type: "NEUTRAL",
  },
  {
    id: 3,
    daysLeft: 1,
    subtitle: "Recent Task",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitat.",
    date: "Created: 2 March 2021",
    template: 1,
    type: "NEW",
  },
  {
    id: 4,
    daysLeft: 44,
    subtitle: "Read Task",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitat.",
    date: "Created: 12 March 2021",
    template: 1,
    type: "OPEN",
  },
  {
    id: 5,
    daysLeft: 8,
    subtitle: "Ready Task",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitat.",
    date: "Created: 10 March 2021",
    template: 2,
    type: "NEUTRAL",
  },
];

export const sensorDataMock = [
  {
    name: "Sensor 1",
    data: [
      { label: "MON", temperature: 40, pressure: 45 },
      { label: "TUE", temperature: 40, pressure: 60 },
      { label: "WED", temperature: 30, pressure: 70 },
      { label: "THU", temperature: 12, pressure: 45 },
      { label: "FRI", temperature: 65, pressure: 40 },
      { label: "SAT", temperature: 0, pressure: 0 },
      { label: "SUN", temperature: 0, pressure: 0 },
    ],
  },
  {
    name: "Sensor 2",
    data: [
      { label: "MON", temperature: 40, pressure: 45 },
      { label: "TUE", temperature: 40, pressure: 60 },
      { label: "WED", temperature: 30, pressure: 70 },
      { label: "THU", temperature: 57, pressure: 45 },
      { label: "FRI", temperature: 15, pressure: 40 },
      { label: "SAT", temperature: 0, pressure: 0 },
      { label: "SUN", temperature: 0, pressure: 0 },
    ],
  },
  {
    name: "Sensor 3",
    data: [
      { label: "MON", temperature: 40, pressure: 45 },
      { label: "TUE", temperature: 40, pressure: 60 },
      { label: "WED", temperature: 20, pressure: 70 },
      { label: "THU", temperature: 57, pressure: 45 },
      { label: "FRI", temperature: 25, pressure: 40 },
      { label: "SAT", temperature: 0, pressure: 0 },
      { label: "SUN", temperature: 0, pressure: 0 },
    ],
  },
];

export const assetDetails = {
  id: "611688fb73545650699c5482",
  name: "ja task",
  desc: "desc",
  createdAt: "2021-08-13",
  assetProperties: [
    {
      property: {
        propertyType: "text",
        name: "txt",
        description: "some description",
        flags: { isConfigurable: true, isUpdatable: false, isMandatory: false },
        contents: "",
      },
      procedureIds: [],
    },
  ],
  isActive: true,
  assetState: "FOR_CREATION",
  childrenIds: [],
  subAssetsCount: 0,
  tasksCount: 0,
  attachments: [],
  hasPlan: false,
  parentId: "611688f273545650699c5481",
  parentName: "Building4",
  prototypeId: "61142b309ed23c378d53690d",
  prototypeName: "ja task",
  recentTasks: [
    {
      id: "6117f2729c8ac478e8e54ff5",
      createdAt: "2021-08-14T16:42:26.362",
      dueDate: "2021-08-14",
      name: "test1",
      desc: "txt",
      state: "TODO",
    },
  ],
  readyTasks: [],
  grouping: false,
};
