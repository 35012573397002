import React, { useContext } from "react";
import BooleanCondition from "./BooleanCondition";
import TextCondition from "./TextCondition";
import SelectionCondition from "./SelectionCondition";
import NumericalCondition from "./NumericalCondition";
import Button from "../Button";
import { I18nContext } from "../../i18n/I18nContext";
import Icon from "../Icon";
import {
  FieldError,
  FieldInput,
  FieldLabel,
  FieldSelect,
  FormControl,
} from "../Input";
import { FieldArray } from "formik";
import {
  PreconditionType,
  PreconditionTypeApi,
} from "../../constants/PreconditionType";
import styles from "./CompositePreconditionsForm.module.scss";

const CompositePreconditionsForm = ({ readOnly, showEditing, values }) => {
  const { t } = useContext(I18nContext);

  return (
    <>
      <FieldArray
        name={"compositeRules"}
        render={(arrayHelpers) => {
          return (
            <>
              {values.compositeRules.map((item, index) => {
                return (
                  <div className={styles.conditionWrapper} key={index}>
                    <div className={styles.rowSpace} key={index}>
                      <div className={styles.row}>
                        <FormControl>
                          <FieldLabel>
                            {t("unique key")}
                            <span className={styles.red}> *</span>
                          </FieldLabel>
                          <FieldInput
                            name={`compositeRules[${index}].uniqueKey`}
                            type="text"
                            placeholder={t("unique key of field")}
                            size="m"
                          />
                          <FieldError
                            name={`compositeRules[${index}].uniqueKey`}
                          />
                        </FormControl>
                        <FormControl className={styles.dropdown}>
                          <FieldLabel>
                            {t("field type")}
                            <span className={styles.red}> *</span>
                          </FieldLabel>
                          <FieldSelect
                            name={`compositeRules[${index}].type`}
                            size="s"
                            options={[
                              {
                                value: PreconditionType.text,
                                label: t("description"),
                              },
                              {
                                value: PreconditionType.boolean,
                                label: t("boolean"),
                              },
                              //TODO ?
                              // { value: PreconditionType.date, label: "Date" },
                              {
                                value: PreconditionType.selection_single,
                                label: t("single choice"),
                              },
                              {
                                value: PreconditionType.selection_multiple,
                                label: t("multiple choice"),
                              },
                              {
                                value: PreconditionType.selection_dropdown,
                                label: t("dropdown"),
                              },
                              {
                                value: PreconditionType.numerical,
                                label: t("numerical"),
                              },
                            ]}
                            placeholder={t("select field type")}
                          />
                          <FieldError name={`compositeRules[${index}].type`} />
                        </FormControl>
                      </div>
                      <div
                        onClick={() => arrayHelpers.remove(index)}
                        className={styles.delete_button}
                      >
                        <Icon className={styles.delete_icon} name="x-delete" />
                      </div>
                    </div>
                    <div className={styles.row}>
                      {values.compositeRules[index].type ===
                        PreconditionType.boolean && (
                        <BooleanCondition
                          readOnly={readOnly}
                          precondition={index}
                          index={index}
                          name={`compositeRules[${index}].expectedValue`}
                        />
                      )}
                      {values.compositeRules[index].type ===
                        PreconditionType.text && (
                        <TextCondition
                          readOnly={readOnly}
                          precondition={index}
                          name={`compositeRules[${index}].triggerWords`}
                          index={index}
                        />
                      )}
                      {values.compositeRules[index].type.startsWith(
                        PreconditionTypeApi.selection
                      ) && (
                        <SelectionCondition
                          key={index}
                          readOnly={readOnly}
                          precondition={{
                            isMultiselect:
                              values.compositeRules[index].type ===
                              PreconditionType.selection_multiple,
                            isDropdown:
                              values.compositeRules[index].type ===
                              PreconditionType.selection_dropdown,
                          }}
                          index={index}
                          showEditing={showEditing}
                          name={`compositeRules[${index}].triggeringSelections`}
                        />
                      )}
                      {values.compositeRules[index].type ===
                        PreconditionType.numerical && (
                        <NumericalCondition
                          key={index}
                          readOnly={readOnly}
                          precondition={index}
                          index={index}
                          name={`compositeRules[${index}].comparisons[0]`}
                        />
                      )}
                    </div>
                  </div>
                );
              })}
              <div className={styles.button}>
                <Button
                  type="button"
                  variant="orange"
                  size="s"
                  onClick={() => {
                    arrayHelpers.push({
                      uniqueKey: "",
                      type: "",
                    });
                  }}
                >
                  + {t("add condition")}
                </Button>
              </div>
            </>
          );
        }}
      />
    </>
  );
};

export default CompositePreconditionsForm;
