import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { store } from "./redux";
import { Router } from "react-router-dom";
import I18nContextProvider from "./i18n/I18nContext";
import history from "./history";
import { createInterceptors } from "./redux/auth";
import "external-svg-loader";

import App from "./App";
import "./index.scss";

createInterceptors(store);

ReactDOM.render(
  <Router history={history}>
    <Provider store={store}>
      <I18nContextProvider>
        <App />
      </I18nContextProvider>
    </Provider>
  </Router>,
  document.getElementById("root")
);
