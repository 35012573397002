import React, { useContext, useEffect, useState } from "react";
import Button from "../Button";
import { Tab, Tabs, TabContent, TabsBar } from "../Tabs";
import FilteredPrototypes from "./FilteredPrototypes";
import AssetPrototypeModal from "./AssetPrototypeModal";
import AddUnitsModal from "./AddUnitsModal";
import AddIconModal from "./AddIconModal";
import UnitContent from "./UnitContent";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAssetPrototypes,
  fetchTasksPrototypesList,
  fetchForms,
  fetchCompanies,
  fetchIconsList,
  fetchUnitsList,
  fetchProfileInfo,
  fetchAttachmentsFoldersList,
  fetchAttachmentsTypesList,
  fetchTaskTypesWithRulesForSelectedCompany,
} from "../../redux";
import FilteredTasks from "./FilteredTasks";
import styles from "./Configuration.module.scss";
import TaskPrototypeModal from "./TaskPrototypeModal";
import { I18nContext } from "../../i18n/I18nContext";
import Forms from "./Forms";
import FormModal from "./FormModal";
import RulesEditor from "../RulesEditor/RulesEditor";
import Select from "react-select";
import Loader from "../Loader";
import IconsContent from "./IconsContent";
import jwtDecode from "jwt-decode";
import AttachmentsContent from "./AttachmentsContent";
import AddAttachmentsFolderModal from "./AddAttachmentsFolderModal";
import AddAttachmentsTypeModal from "./AddAttachmentsTypeModal";
import useDeepCompareEffect from "use-deep-compare-effect";
import { fetchNotificationGroups } from "../NotificationGroups/notificationGroupActions";

const SORT_VALUE = {
  ASC_DATE: "ASC_DATE",
  DESC_DATE: "DESC_DATE",
  NAME_AZ: "NAME_AZ",
  NAME_ZA: "NAME_ZA",
  DEPRECATED: "DEPRECATED",
};

const sorters = {
  [SORT_VALUE.ASC_DATE]: (a, b) =>
    new Date(a.updatedAt) - new Date(b.updatedAt),
  [SORT_VALUE.DESC_DATE]: (a, b) =>
    new Date(b.updatedAt) - new Date(a.updatedAt),
  [SORT_VALUE.NAME_AZ]: (a, b) => a.name.localeCompare(b.name),
  [SORT_VALUE.NAME_ZA]: (a, b) => b.name.localeCompare(a.name),
  [SORT_VALUE.DEPRECATED]: (a, b) => a.deprecated - b.deprecated,
  getOrDefault(sort, defaultSorter) {
    return this[sort] ?? defaultSorter;
  },
};

const sortItems = (items = [], sort) => {
  const newList = [...items];
  return newList.sort(sorters.getOrDefault(sort, (a, b) => 0));
};

const Configuration = ({ id }) => {
  const { t } = useContext(I18nContext);
  const dispatch = useDispatch();

  const info = useSelector((state) => state.auth.info);
  const role = info?.role;

  const isPrivileged = useSelector(
    (state) => state.auth.info?.companyPrivileged
  );
  const isSuperAdmin = info?.token
    ? jwtDecode(info?.token).role === "SUPER_ADMIN"
    : false;
  const canEdit = !(isPrivileged && role === "ADMIN");

  const [selectedTab, setSelectedTab] = useState("task");
  const [openAddAssetPrototypesModal, setOpenAddAssetPrototypeModal] =
    useState(false);
  const [openTaskPrototypeModal, setOpenTaskPrototypeModal] = useState(false);
  const [openAddUnitsModal, setOpenAddUnitsModal] = useState(false);
  const [openAddAttachmentsFolderModal, setOpenAddAttachmentsFolderModal] =
    useState(false);
  const [openAddAttachmentsTypeModal, setOpenAddAttachmentsTypeModal] =
    useState(false);
  const [openAddIconModal, setOpenAddIconModal] = useState(false);
  const [openFormsModal, setOpenFormsModal] = useState(false);

  const assetPrototypes = useSelector((state) => state.prototypes.items);
  const taskPrototypes = useSelector((state) => state.taskPrototypes.items);
  const units = useSelector((state) => state.units.items);
  const icons = useSelector((state) => state.icons.items);
  const forms = useSelector((state) => state.forms.items);
  const taskTemplatesWithRules = useSelector(
    (state) => state.rules.taskTypesWitheRulesForCompany
  );
  const rulesLoading = useSelector((state) => state.rules.loadingTypes);
  const companies = useSelector((state) => state.companies.items);
  const attachmentsFolders = useSelector((state) => state.attachments.folders);
  const attachmentsTypes = useSelector((state) => state.attachments.types);
  const notificationGroups = useSelector(
    (state) => state.notificationGroups.items
  );

  const [filteredPrototype, setFilteredPrototype] = useState([]);
  const [filteredTasks, setFilteredTasks] = useState([]);
  const [filteredBlockedTasks, setFilteredBlockedTasks] = useState([]);
  const [filteredUnits, setFilteredUnits] = useState([]);
  const [filteredIcons, setFilteredIcons] = useState([]);
  const [filteredForms, setFilteredForms] = useState([]);
  const [selectedTaskTemplates, setSelectedTaskTemplates] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [filteredTaskTemplates, setFilteredTaskTemplates] = useState([]);

  const [sort, setSort] = useState(SORT_VALUE.NAME_AZ);

  const tabsOptions = [
    { label: t("task templates"), value: "task" },
    { label: t("forms"), value: "forms" },
    { label: t("units"), value: "units" },
    { label: t("rules"), value: "rules" },
    { label: t("asset prototypes"), value: "prototypes" },
    { label: t("attachments"), value: "attachments" },
  ];

  const handleChange = (newValue) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    dispatch(fetchCompanies());
    dispatch(fetchForms());
    dispatch(fetchProfileInfo());
    dispatch(fetchNotificationGroups());
  }, [dispatch]);

  useEffect(() => {
    if (selectedTab === "rules") {
      dispatch(fetchTasksPrototypesList());
    } else if (selectedTab === "prototypes") {
      dispatch(fetchAssetPrototypes());
    } else if (selectedTab === "task") {
      dispatch(fetchTasksPrototypesList());
    } else if (selectedTab === "units") {
      dispatch(fetchUnitsList());
    } else if (selectedTab === "icons") {
      dispatch(fetchIconsList());
    } else if (selectedTab === "attachments") {
      dispatch(fetchAttachmentsFoldersList());
      dispatch(fetchAttachmentsTypesList());
    }
  }, [selectedTab, dispatch]);

  useEffect(() => {
    if (assetPrototypes) {
      setFilteredPrototype(
        sortItems(
          assetPrototypes.filter((prototype) => !prototype.deprecated),
          sort
        )
      );
    }
  }, [assetPrototypes, sort]);

  useEffect(() => {
    if (taskPrototypes) {
      setFilteredTasks(
        sortItems(
          taskPrototypes.filter((prototype) => !prototype.deprecated),
          sort
        )
      );
      setFilteredBlockedTasks(
        sortItems(
          taskPrototypes.filter((prototype) => !!prototype.deprecated),
          sort
        )
      );
    }
  }, [taskPrototypes, sort]);

  useEffect(() => {
    if (units) {
      setFilteredUnits(sortItems(units, sort));
    }
  }, [units, sort]);

  useEffect(() => {
    if (forms) {
      setFilteredForms(
        sortItems(
          forms.filter((form) => !form.deprecated),
          sort
        )
      );
    }
  }, [forms, sort]);

  useEffect(() => {
    if (icons) {
      setFilteredIcons(sortItems(icons, sort));
    }
  }, [icons, sort]);

  useEffect(() => {
    if (!!selectedCompany?.value.id) {
      dispatch(
        fetchTaskTypesWithRulesForSelectedCompany(
          selectedCompany.value.id,
          selectedTaskTemplates
            ? selectedTaskTemplates.map((proto) => proto.value.id)
            : []
        )
      );
    }
  }, [selectedCompany, selectedTaskTemplates]);

  useDeepCompareEffect(() => {
    setFilteredTaskTemplates(taskTemplatesWithRules);
  }, [taskTemplatesWithRules]);

  useEffect(() => {
    setSort(SORT_VALUE.NAME_AZ);
  }, [selectedTab]);

  const taskTemplateOptions = taskPrototypes
    .filter((taskPrototype) => !taskPrototype.deprecated)
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((taskPrototype) => {
      return {
        value: taskPrototype,
        label: taskPrototype.name,
        global: taskPrototype.global,
      };
    });

  const companyOptions = companies
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((company) => {
      return {
        value: company,
        label: company.name,
      };
    });

  const selectCompany = (selected) => {
    setSelectedCompany(selected);
    setSelectedTaskTemplates(null);
  };

  const selectStyles = {
    option: (styles, { isSelected, data }) => {
      return {
        ...styles,
        display: "flex",
        alignItems: "center",
        color: "#393939",
        backgroundColor: data.global ? "#fff8f1" : "#ffffff",
        fontWeight: isSelected ? "500" : "400",
        height: "auto",
        "&:hover": {
          backgroundColor: "#eaeaea",
          color: "#393939",
          fontWeight: "500",
          cursor: "pointer",
        },
      };
    },
    control: (base, state, provided) => ({
      ...provided,
      paddingLeft: "0px",
      fontWeight: "400",
      fontSize: "14px",
      minWidth: "200px",
      height: "38px",
      minHeight: "36px",
      ...base,
      boxShadow: state.isFocused ? 0 : 0,
      borderColor: state.isFocused ? "#EE7203" : base.borderColor,
      "&:hover": {
        borderColor: state.isFocused ? "#EE7203" : base.borderColor,
        cursor: "pointer",
      },
    }),
    valueContainer: (base, state, provided) => ({
      ...provided,
      ...base,
      backgroundColor: state.isDisabled ? "#f4f4f4" : "#ffffff",
      borderRadius: "3px",
      height: "100%",
      overflowY: "auto",
    }),
    multiValue: (styles) => {
      return {
        ...styles,
        backgroundColor: "#D8E6FF",
      };
    },
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      backgroundColor: "#D8E6FF",
      color: "#191919",
      "&:hover": {
        backgroundColor: "#D8E6FF",
        color: "#191919",
      },
    }),
    menu: (provided) => {
      return {
        ...provided,
        zIndex: "10",
        border: "1px solid #EE7203",
      };
    },
    singleValue: (provided) => {
      return {
        ...provided,
        marginLeft: "2px",
        lineHeight: "16px",
      };
    },
    indicatorSeparator: (provided) => {
      return {
        ...provided,
        backgroundColor: "none",
      };
    },
    dropdownIndicator: (base, state) => {
      return {
        ...base,
        color: state.isFocused ? "#EE7203" : "#AAAAAA",
      };
    },
    placeholder: (provided) => {
      return {
        ...provided,
        marginLeft: "2px",
        color: "#AAAAAA",
        fontWeight: "400",
      };
    },
  };

  return (
    <>
      <div className={styles.header_section}>
        <span className={styles.title}>{t("configurations")}</span>
      </div>
      <Tabs onChange={handleChange} initialValue={selectedTab}>
        <TabsBar>
          {tabsOptions.map((tab, idx) => (
            <Tab key={idx} value={tab.value}>
              {t(tab.label, "title")}
            </Tab>
          ))}
          {isSuperAdmin && isPrivileged && (
            <Tab value={"icons"}>{t("icons", "title")}</Tab>
          )}
        </TabsBar>

        <TabContent index="rules">
          <div className={styles.filter_section}>
            <Select
              styles={selectStyles}
              menuPosition={"relative"}
              value={selectedCompany}
              maxMenuHeight={200}
              onChange={(selected) => selectCompany(selected)}
              options={companyOptions}
              placeholder={t("choose company")}
              noOptionsMessage={() => t("no options")}
            />
            {rulesLoading && <Loader />}
          </div>
          <div className={styles.filter_section}>
            <Select
              styles={selectStyles}
              menuPosition={"relative"}
              value={selectedTaskTemplates}
              maxMenuHeight={200}
              onChange={(selected) => setSelectedTaskTemplates(selected)}
              options={taskTemplateOptions}
              placeholder={t("select task type")}
              noOptionsMessage={() => t("no options")}
              isDisabled={!selectedCompany || !taskTemplateOptions.length}
              isMulti={true}
              closeMenuOnSelect={false}
            />
          </div>
          <div>
            {!!selectedCompany &&
              filteredTaskTemplates
                .filter((template) => !!template.procedures.length)
                .map((template) => (
                  <div className={styles.template_label} key={template.id}>
                    <div>{template.name}</div>
                    <RulesEditor
                      prototype={template}
                      readOnly
                      showLocalLabels
                      toggleRulesEditorOpen={() => null}
                      notificationGroups={notificationGroups}
                    />
                  </div>
                ))}
          </div>
        </TabContent>
        <TabContent index="forms">
          <div className={styles.filter_section}>
            <Button
              variant="orange"
              size="s"
              onClick={() => {
                if (sort === SORT_VALUE.NAME_AZ) {
                  setSort(SORT_VALUE.DESC_DATE);
                } else {
                  setSort(SORT_VALUE.NAME_AZ);
                }
              }}
            >
              {sort === SORT_VALUE.NAME_AZ
                ? `${t("sort by update date")}`
                : `${t("sort by name A-Z")}`}
            </Button>
            <div></div>
            {canEdit && (
              <Button
                className={styles.formsAddButton}
                variant="orange"
                size="s"
                onClick={() => setOpenFormsModal(true)}
              >
                {`+ ${t("add new form")}`}
              </Button>
            )}
            {!!openFormsModal && (
              <FormModal
                isOpen={openFormsModal}
                onClose={() => setOpenFormsModal(false)}
              />
            )}
          </div>
          <div>
            <Forms items={filteredForms} canEdit={canEdit} />
          </div>
        </TabContent>
        <TabContent index="prototypes">
          <div className={styles.filter_section}>
            <Button
              variant="orange"
              size="s"
              onClick={() => {
                if (sort === SORT_VALUE.NAME_AZ) {
                  setSort(SORT_VALUE.DESC_DATE);
                } else {
                  setSort(SORT_VALUE.NAME_AZ);
                }
              }}
            >
              {sort === SORT_VALUE.NAME_AZ
                ? `${t("sort by update date")}`
                : `${t("sort by name A-Z")}`}
            </Button>
            {canEdit && (
              <Button
                variant="orange"
                size="s"
                onClick={() => setOpenAddAssetPrototypeModal(true)}
              >
                {`+ ${t("add new asset prototype")}`}
              </Button>
            )}
            {!!openAddAssetPrototypesModal && (
              <AssetPrototypeModal
                isOpen={openAddAssetPrototypesModal}
                onClose={() => setOpenAddAssetPrototypeModal(false)}
                propertiesDisabled={true}
              />
            )}
          </div>
          <div className={styles.prototypes_container}>
            <FilteredPrototypes items={filteredPrototype} canEdit={canEdit} />
          </div>
        </TabContent>
        <TabContent index="task">
          <div className={styles.filter_section}>
            <Button
              variant="orange"
              size="s"
              onClick={() => {
                if (sort === SORT_VALUE.NAME_AZ) {
                  setSort(SORT_VALUE.DESC_DATE);
                } else {
                  setSort(SORT_VALUE.NAME_AZ);
                }
              }}
            >
              {sort === SORT_VALUE.NAME_AZ
                ? `${t("sort by update date")}`
                : `${t("sort by name A-Z")}`}
            </Button>
            {canEdit && (
              <Button
                variant="orange"
                size="s"
                onClick={() => setOpenTaskPrototypeModal(true)}
              >
                {`+ ${t("add new task template")}`}
              </Button>
            )}
            {!!openTaskPrototypeModal && (
              <TaskPrototypeModal
                allowEditingTags={true}
                recurranceRequired={false}
                isOpen={openTaskPrototypeModal}
                onClose={() => setOpenTaskPrototypeModal(false)}
              />
            )}
          </div>
          <div className={styles.prototypes_container}>
            <FilteredTasks
              items={filteredTasks}
              blockedItems={filteredBlockedTasks}
              canEdit={canEdit}
            />
          </div>
        </TabContent>
        <TabContent index="units">
          <div className={styles.filter_section}>
            <Button
              variant="orange"
              size="s"
              onClick={() => {
                if (sort === SORT_VALUE.NAME_AZ) {
                  setSort(SORT_VALUE.DESC_DATE);
                } else {
                  setSort(SORT_VALUE.NAME_AZ);
                }
              }}
            >
              {sort === SORT_VALUE.NAME_AZ
                ? `${t("sort by update date")}`
                : `${t("sort by name A-Z")}`}
            </Button>
            {canEdit && (
              <Button
                variant="orange"
                size="s"
                onClick={() => setOpenAddUnitsModal(true)}
              >
                {`+ ${t("add new unit")}`}
              </Button>
            )}
            <AddUnitsModal
              isOpen={openAddUnitsModal}
              onClose={() => setOpenAddUnitsModal(false)}
            />
          </div>
          <div>
            <UnitContent items={filteredUnits} canEdit={canEdit} />
          </div>
        </TabContent>
        <TabContent index="icons">
          <div className={styles.filter_section}>
            <Button
              variant="orange"
              size="s"
              onClick={() => {
                if (sort === SORT_VALUE.NAME_AZ) {
                  setSort(SORT_VALUE.DESC_DATE);
                } else {
                  setSort(SORT_VALUE.NAME_AZ);
                }
              }}
            >
              {sort === SORT_VALUE.NAME_AZ
                ? `${t("sort by update date")}`
                : `${t("sort by name A-Z")}`}
            </Button>
            {canEdit && (
              <Button
                variant="orange"
                size="s"
                onClick={() => setOpenAddIconModal(true)}
              >
                {`+ ${t("add new icon")}`}
              </Button>
            )}
            <AddIconModal
              isOpen={openAddIconModal}
              onClose={() => setOpenAddIconModal(false)}
            />
          </div>
          <div>
            <IconsContent items={filteredIcons} canEdit={canEdit} />
          </div>
        </TabContent>
        <TabContent index="attachments">
          <div className={styles.filter_section}>
            {canEdit && (
              <Button
                variant="orange"
                size="s"
                onClick={() => setOpenAddAttachmentsFolderModal(true)}
              >
                {`+ ${t("add new folder")}`}
              </Button>
            )}
            {canEdit && (
              <Button
                variant="orange"
                size="s"
                onClick={() => setOpenAddAttachmentsTypeModal(true)}
              >
                {`+ ${t("add new type")}`}
              </Button>
            )}
            <AddAttachmentsFolderModal
              isOpen={openAddAttachmentsFolderModal}
              onClose={() => setOpenAddAttachmentsFolderModal(false)}
              folderNames={attachmentsFolders.map((folder) => folder.name)}
            />
            <AddAttachmentsTypeModal
              isOpen={openAddAttachmentsTypeModal}
              onClose={() => setOpenAddAttachmentsTypeModal(false)}
              typeNames={attachmentsTypes.map((type) => type.name)}
            />
          </div>
          <div>
            <AttachmentsContent
              folders={attachmentsFolders}
              types={attachmentsTypes}
              canEdit={canEdit}
            />
          </div>
        </TabContent>
      </Tabs>
    </>
  );
};
export default Configuration;
