import React, { useState } from "react";
import CheckboxTreeV from "react-checkbox-tree";
import Icon from "../Icon";
import "./CheckboxTreeTask.scss";
import useDeepCompareEffect from "use-deep-compare-effect";

const CheckboxTreeTask = ({ tree, onCheck, checked = [] }) => {
  const [expanded, setExpanded] = useState([]);
  const [allSelectedAssetIds, setAllSelectedAssetIds] = useState([]);

  useDeepCompareEffect(() => {
    setAllSelectedAssetIds(getAllCheckedAssetIds(tree));
  }, [checked]);

  const handleCheck = (selected) => {
    let checkedGroupingAssets = getSelectedGroupingAssets(tree, selected);
    onCheck(checkedGroupingAssets);
  };

  const getSelectedGroupingAssets = (assets, selected) => {
    let selectedGroupingAssets = [];

    assets.forEach((asset) => {
      if (verifyIfAssetSelected(asset.value, asset.children, selected)) {
        selectedGroupingAssets.push(asset.value);
      } else {
        selectedGroupingAssets = [
          ...selectedGroupingAssets,
          ...getSelectedGroupingAssets(asset.children, selected),
        ];
      }
    });

    return selectedGroupingAssets;
  };

  const verifyIfAssetSelected = (assetId, children, selected) =>
    (!children.length && selected.includes(assetId)) ||
    (!!children.length &&
      children.filter((child) =>
        verifyIfAssetSelected(child.value, child.children, selected)
      ).length === children.length);

  const getAllCheckedAssetIds = (assets) => {
    let checkedAssets = [];
    assets.forEach((asset) => {
      if (checked.includes(asset.value)) {
        checkedAssets.push(asset.value);
        if (asset.children.length) {
          asset.children.forEach((child) => {
            checkedAssets.push(child.value);
            checkedAssets = [...checkedAssets, ...getAllSubAssetIds(child)];
          });
        }
      } else {
        if (asset.children.length) {
          checkedAssets = [
            ...checkedAssets,
            ...getAllCheckedAssetIds(asset.children),
          ];
        }
      }
    });
    return checkedAssets;
  };

  const getAllSubAssetIds = (asset) => {
    let ids = [];
    asset.children.forEach((child) => {
      ids.push(child.value);
      ids = [...ids, ...getAllSubAssetIds(child)];
    });
    return ids;
  };

  return (
    <div className="filter-container">
      <CheckboxTreeV
        icons={{
          check: <Icon name="checked-group-task" />,
          uncheck: <Icon name="unchecked-group-task" />,
          halfCheck: <Icon name="half-checked-group-task" />,
          expandClose: <Icon name="chevron-right" />,
          expandOpen: <Icon name="chevron-down" />,
        }}
        showNodeIcon={false}
        nodes={tree}
        checked={allSelectedAssetIds}
        expanded={expanded}
        onCheck={(checked) => {
          handleCheck(checked);
        }}
        onExpand={(expanded) => setExpanded(expanded)}
      />
    </div>
  );
};

export default CheckboxTreeTask;
