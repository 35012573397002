import React from "react";

export const AccordionContext = React.createContext({
  open: [],
  toggle: () => {},
});

export const useAccordion = () => {
  const context = React.useContext(AccordionContext);
  return context;
};

export const AccordionItemContext = React.createContext({
  isOpen: false,
  tapable: true,
  toggleItem: () => {},
});

export const useAccordionItem = () => {
  const context = React.useContext(AccordionItemContext);
  return context;
};
