import styles from "./DroppedFile.module.scss";
import Icon from "../Icon";
import axios from "../../redux/auth";

const DroppedFile = ({ file, onDelete, disabled, view }) => {
  const openAttachment = (attachment) => {
    axios
      .get(`/files/getBytes?key=${attachment.key}&name=${attachment.name}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const url = URL.createObjectURL(
          new Blob([response.data], {
            type:
              attachment.attachmentFileType === "DOCUMENT" &&
              attachment.key.split(".").pop() !== "gif"
                ? "application/pdf"
                : `image/${attachment.key.split(".").pop()}`,
          })
        );
        window.open(url);
      })
      .catch((error) => console.log(error));
  };

  const downloadAttachment = (attachment) => {
    axios
      .get(`/files/getBytes?key=${attachment.key}&name=${attachment.name}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.download = attachment.name.includes(".")
          ? attachment.name
          : attachment.name + "." + attachment.key.split(".").pop();
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => console.log(error));
  };

  return (
    <div className={disabled ? [styles.input_box2] : [styles.input_box]}>
      <span className={styles.input_name}>{file.name}</span>
      <div className={styles.input}>
        <Icon name="attached-files" className={styles.icon} />
        <span className={styles.input_size}>{file.size}</span>
      </div>
      <div
        className={styles.trash_white}
        data-testid={`upload-file-remove-${file.name}`}
      >
        {view &&
          (file.attachmentFileType === "IMAGE" ||
            (file.attachmentFileType === "DOCUMENT" &&
              file.key.split(".").pop() === "pdf") ||
            (file.attachmentFileType === "DOCUMENT" &&
              file.key.split(".").pop() === "gif")) && (
            <Icon
              name="eye-white"
              className={styles.view_icon}
              onClick={() => {
                openAttachment(file);
              }}
            />
          )}
        {view && (
          <Icon
            name="download-white"
            className={styles.download_icon}
            onClick={() => {
              downloadAttachment(file);
            }}
          />
        )}
      </div>
      <div
        className={styles.trash_white}
        data-testid={`upload-file-remove-${file.name}`}
      >
        {!disabled && (
          <Icon
            name="trash-white"
            className={styles.trash_icon}
            onClick={() => {
              onDelete(file);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default DroppedFile;
