import React, { useState, useEffect, useContext } from "react";
import axios from "../../redux/auth";
import SensorChartItem from "./subComponents/SensorChartItem";
import { I18nContext } from "../../i18n/I18nContext";
import Button from "../Button";
import styles from "./SensorsPartContent.module.scss";
import { createSensorsOptions, createPropertiesOptions } from "./utils";
import { FilterDropdown } from "../Dropdown";

const SensorsPartContent = ({ chosenBuildingId }) => {
  const { t } = useContext(I18nContext);
  const [sensorsList, setSensorsList] = useState([]);
  const [sensorProperties, setSensorProperties] = useState([]);
  const [selectedSensor, setSelectedSensor] = useState("");
  const [selectedProperty, setSelectedProperty] = useState("");
  const [sensorsData, setSensorsData] = useState(null);
  const [sensorsPeriod, setSensorsPeriod] = useState(7);
  const [savedSensors, setSavedSensors] = useState(null);

  const timeOptions = [
    { label: t("last 7 days"), value: 7 },
    { label: t("last 14 days"), value: 14 },
    { label: t("last 30 days"), value: 30 },
    { label: t("last 90 days"), value: 90 },
  ];

  const fetchAllSensorsList = async () => {
    const url = `/sensors/getByAssetId?assetId=${chosenBuildingId}`;
    try {
      const response = await axios.get(url);
      const sensorsList = response.data;
      setSensorsList(sensorsList);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchSensorsData = async () => {
    if (!selectedProperty) {
      return;
    }
    const url = `/sensors/getDashboardData?sensorId=${selectedSensor}&Property=${selectedProperty}&days=${sensorsPeriod}`;
    try {
      const response = await axios.get(url);
      const sensorsData = response.data;

      setSensorsData({ sensorId: selectedSensor, data: sensorsData });
    } catch (err) {
      console.log(err);
    }
  };

  const fetchSensorProperties = async () => {
    const url = `/sensors/getProperties?sensorId=${selectedSensor}`;
    try {
      const response = await axios.get(url);
      const sensorsData = response.data;
      const parsedData = Object.entries(sensorsData).map((el) => ({
        property: el[0],
        unit: el[1],
      }));

      setSensorProperties(parsedData);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchSavedSensors = async () => {
    const sensors = JSON.parse(localStorage.getItem("savedSensors"));

    if (!sensors) {
      return;
    }

    let fetchedSensors = [];

    for (const sensor of sensors) {
      const url = `/sensors/getDashboardData?sensorId=${sensor.sensorId}&Property=${sensor.property}&days=${sensorsPeriod}`;

      try {
        const response = await axios.get(url);
        const sensorData = response.data;

        fetchedSensors = [
          ...fetchedSensors,
          {
            sensorId: sensor.sensorId,
            data: sensorData,
            property: sensor.property,
          },
        ];
      } catch (err) {
        console.log(err);
      }
    }
    setSavedSensors(fetchedSensors);
  };

  useEffect(() => {
    setSelectedSensor(sensorsList[0]?.id);
  }, [sensorsList]);

  useEffect(() => {
    if (chosenBuildingId) {
      fetchAllSensorsList();
      fetchSavedSensors();
    }
  }, [chosenBuildingId]);

  useEffect(() => {
    if (selectedSensor) {
      fetchSensorProperties();
      setSensorsData(null);
    }
  }, [selectedSensor]);

  return (
    <div className={styles.contentWrapper}>
      <div className={styles.contained_cell}>
        <div className={styles.containedHeader}>
          <div className={styles.headerTitle}>
            <span>{t("sensors")}</span>
          </div>
          <div className={styles.selectionWrapper}>
            {!!sensorsList.length && (
              <FilterDropdown
                options={createSensorsOptions(sensorsList)}
                onSelect={(value) => setSelectedSensor(value.value)}
                isClearable={false}
                withDefault
              />
            )}
            <FilterDropdown
              options={createPropertiesOptions(sensorProperties)}
              onSelect={(value) => setSelectedProperty(value.value)}
              isClearable={false}
              placeholder={t("choose property")}
              withDefault
            />
            <FilterDropdown
              options={timeOptions}
              withDefault
              isClearable={false}
              onSelect={(value) => setSensorsPeriod(value.value)}
            />
            <Button variant="orange" size="s" onClick={fetchSensorsData}>
              {t("search")}
            </Button>
          </div>
        </div>
        <div className={styles.sensors}>
          {savedSensors?.length == 0 && !sensorsData && (
            <p className={styles.txt}>
              {t("please choose your sensor options and search.")}
            </p>
          )}
          {savedSensors &&
            !!sensorsList.length &&
            savedSensors.map((sensor) => {
              return (
                <SensorChartItem
                  sensorName={
                    sensorsList.find((el) => el.id === sensor.sensorId).name
                  }
                  data={sensor.data}
                  sensorId={sensor.sensorId}
                  property={sensor.property}
                />
              );
            })}
          {sensorsData && !!sensorsList.length && (
            <SensorChartItem
              sensorName={
                sensorsList.find((el) => el.id === sensorsData.sensorId).name
              }
              data={sensorsData.data}
              sensorId={sensorsData.sensorId}
              property={selectedProperty}
              unit={
                sensorProperties.find((el) => el.property === selectedProperty)
                  .unit
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SensorsPartContent;
