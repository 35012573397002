import React, { useContext } from "react";
import Modal from "../Modal";
import styles from "./AddRulesModal.module.scss";
import { I18nContext } from "../../i18n/I18nContext";
import FormsEditForm from "./FormsEditForm";
import Button from "../Button";

const formId = "form-modal-form";

const FormModal = ({ onClose, isOpen, form, preview }) => {
  const { t } = useContext(I18nContext);
  return (
    <Modal
      className={styles.modal}
      isOpen={isOpen}
      onClose={onClose}
      classNameTitle={styles.title}
      title={
        preview ? t("preview form") : form ? t("edit form") : t("add new form")
      }
      form={formId}
      divider={false}
      formModal={true}
      footer={
        <div className={styles.btn_section}>
          <Button
            onClick={onClose}
            type="reset"
            form={formId}
            variant="ghost"
            size="s"
          >
            {t(!preview ? "cancel" : "close")}
          </Button>
          {!form && (
            <Button
              className={styles.btn_separator}
              type="submit"
              variant="orange"
              size="s"
              form={formId}
            >
              {t("add")}
            </Button>
          )}

          {form && !preview && (
            <Button
              className={styles.btn_separator}
              type="submit"
              variant={"orange"}
              size="s"
              form={formId}
            >
              {t("save form", "title")}
            </Button>
          )}
        </div>
      }
    >
      <FormsEditForm
        onClose={onClose}
        formId={formId}
        disabled={!form && !!preview}
        formPrototype={form}
        notEditable={preview}
      />
    </Modal>
  );
};

export default FormModal;
