import axios from "../auth";
import Actions from "../actions";
import history from "../../history";
import { appUrls } from "../../urls";
import { createToast } from "../toast/toastActions";

const passwordResetSuccess = (info) => {
  return {
    type: Actions.PASSWORD_RESET_SUCCESS,
    payload: info,
  };
};

const passwordResetError = (error) => {
  return {
    type: Actions.PASSWORD_RESET_ERROR,
    payload: error,
  };
};

const passwordTokenSuccess = (info) => {
  return {
    type: Actions.PASSWORD_TOKEN_SUCCESS,
    payload: info,
  };
};

const passwordTokenError = (error) => {
  return {
    type: Actions.PASSWORD_TOKEN_ERROR,
    payload: error,
  };
};

export const resetPassword = (t, email) => {
  return async (dispatch) => {
    return axios
      .post(`/auth/password/reset?email=${email}`)
      .then(({ data }) => {
        dispatch(passwordResetSuccess(data));
        dispatch(
          createToast({
            type: "success",
            title: t("the reset link has been sent"),
          })
        );
      })
      .catch((error) => passwordResetError(error));
  };
};

export const resetPasswordToken = (t, token, password) => {
  return async (dispatch) => {
    return axios
      .put(`/auth/password/reset/${token}`, { password })
      .then(({ data }) => {
        dispatch(passwordTokenSuccess(data));
        history.push(appUrls.LOGIN);
      })
      .catch((error) => {
        passwordTokenError(error);

        if (error.response.status === 409) {
          dispatch(
            createToast({
              type: "error",
              title: t(
                "please make sure the new password has not been used within last 5 passwords"
              ),
            })
          );
        }
      });
  };
};
