import React, { useContext } from "react";
import { I18nContext } from "../../../i18n/I18nContext";
import Icon from "../../Icon";
import styles from "./HeaderItem.module.scss";

const HeaderItem = ({ name }) => {
  const { t } = useContext(I18nContext);
  return (
    <div className={styles.wrapper}>
      <div className={styles.bigCircle}>
        <Icon name="maintainer-orange" className={styles.bigIcon} />
        <div className={styles.smallCircle}></div>
      </div>
      <div className={styles.welcomeTextContainer}>
        <span className={styles.welcome}>
          {t("hello")}, {name ? name : ""}
        </span>
        <span className={styles.text}>
          {t("Welcome to T@pper2!", "asIs")}
        </span>
      </div>
    </div>
  );
};

export default HeaderItem;
