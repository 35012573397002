import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import Button from "../Button";
import Modal from "../Modal";
import * as Yup from "yup";
import PropTypes from "prop-types";
import {
  FieldError,
  FieldInput,
  FieldLabel,
  FieldSelect,
  Form,
  FormControl,
  Checkbox,
} from "../Input";
import { I18nContext } from "../../i18n/I18nContext";

import styles from "./UserFormModal.module.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  addUser,
  editUser,
  // editSelectedRights,
  fetchRegisteredUsersList,
  checkIfUserExists,
} from "../../redux";
import { Role } from "../../constants/Access";
import { getMaintainersOrganizationList } from "../../redux/maintainers/maintainersActions";
import RightsBuildingFilterModal from "./RightsBuildingFilterModal";
import Icon from "../Icon";

const UserFormModal = ({ onClose, isOpen, user, org }) => {
  const { t, language } = useContext(I18nContext);
  const dispatch = useDispatch();

  const defaultState = {
    username: "",
    email: "",
    roleId: "",
    rightIds: [],
    company: null,
    languageCode: language,
    isDisabled: false,
    delay: false,
  };

  const [initialValuesForm, setInitialValues] = useState(defaultState);
  const [openFiltersModal, setOpenFiltersModal] = useState(false);
  const [assetIds, setAssetIds] = useState([]);
  const [userExists, setUserExists] = useState(false);
  const pending = useSelector((state) => state.access.pending);
  const role = useSelector((state) => state.auth.info?.role);
  const formRef = useRef();
  const isPrivileged = useSelector(
    (state) => state.auth.info?.companyPrivileged
  );

  const languageOptions = [
    { value: "en", label: t("english") },
    { value: "nl", label: t("dutch") },
  ];

  const rightsOptions = useSelector((state) =>
    state.rights.items.map((r) => ({
      value: r.id,
      label: r.category,
    }))
  );

  const rightsOrgOption =
    org?.rights.map((r) => ({
      value: r.id,
      label: r.category,
    })) ?? [];

  const rolesOptions = useSelector((state) =>
    user?.access?.role === "MAINTAINER"
      ? state.access.rolesItems
          .filter((role) => role.role === "MAINTAINER")
          .map((r) => ({
            value: r.id,
            label: t(r.name.toLowerCase()),
            role: r.role,
          }))
      : state.access.rolesItems
          .filter((role) => role.role !== "MAINTAINER")
          .map((r) => ({
            value: r.id,
            label: t(r.name.toLowerCase()),
            role: r.role,
          }))
  );

  const rolesOptionsForAdmin = rolesOptions.filter(
    (el) => el.role !== "SUPER_ADMIN"
  );

  const rolesOptionsForCustomerAdmin = rolesOptions.filter(
    (el) => el.role !== "SUPER_ADMIN" && el.role !== "ADMIN"
  );

  const removeCustomerRolesForPrivilegedCompany = (options) => {
    if (isPrivileged) {
      return options.filter(
        (el) => el.role !== "CUSTOMER_ADMIN" && el.role !== "CUSTOMER"
      );
    } else {
      return options.filter(
        (el) => el.role !== "SUPER_ADMIN" && el.role !== "ADMIN"
      );
    }
  };

  const userEmails = useSelector((state) =>
    state.access.userItems.map((u) => u.email)
  );

  const selectUniqueAssets = (state) => {
    const assets = state.assets.searchAssets;
    const uniqueItems = [];
    for (let item of assets) {
      if (!uniqueItems.find((i) => i.id === item.id)) {
        uniqueItems.push(item);
      }
    }
    return uniqueItems;
  };
  const assetNameSearchOptions = useSelector(selectUniqueAssets);

  const handleCheck = useCallback(
    (checked) => {
      setAssetIds(checked);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setAssetIds]
  );

  const deselectGroupingAsset = (id) => {
    let newAssetIds = [...assetIds];
    const index = newAssetIds.indexOf(id);
    newAssetIds.splice(index, 1);
    setAssetIds(newAssetIds);
  };

  const validationSchema = user
    ? Yup.object({
        roleId: Yup.string().required(t("please choose role")),
        rightIds: Yup.array(),
      })
    : Yup.object({
        username: Yup.string().required(t("missing user name")),
        email: Yup.mixed()
          .notOneOf(userEmails, t("email must be unique"))
          .required(t("missing email")),
        roleId: Yup.string().required(t("please choose role")),
        rightIds: Yup.array(),
      });

  useEffect(() => {
    if (user) {
      setInitialValues({
        username: user.username,
        email: user.email,
        roleId: rolesOptions.find((o) => o.role === user.access.role)?.value,
        rightIds: user.access.rights?.map((r) => r.id),
        isDisabled: user.access.isDisabled,
        languageCode: user.languageCode,
      });
      setAssetIds(user.access.assets?.map((assetId) => assetId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleClear = () => {
    setInitialValues(defaultState);
    setAssetIds([]);
    onClose();
  };

  const handleSubmit = async (values, { resetForm }) => {
    if (user) {
      await dispatch(
        editUser(t, {
          id: user.id,
          username: values.username,
          languageCode: values.languageCode,
          access: {
            ...user.access,
            rights:
              rolesOptions.find((el) => el.value === values.roleId).role ===
              Role.SUPER_ADMIN
                ? []
                : values.rightIds,
            assets:
              rolesOptions.find((el) => el.value === values.roleId).role ===
              Role.SUPER_ADMIN
                ? []
                : assetIds,
            role: rolesOptions.find((el) => el.value === values.roleId).role,
            isDisabled: values.isDisabled,
          },
          email: values.email,
        })
      );
    } else {
      await dispatch(
        addUser(t, {
          username: values.username,
          email: values.email,
          languageCode: values.languageCode,
          roleId: values.roleId,
          rightIds:
            rolesOptions.find((el) => el.value === values.roleId).role ===
            Role.SUPER_ADMIN
              ? []
              : values.rightIds,
          assets:
            rolesOptions.find((el) => el.value === values.roleId).role ===
            Role.SUPER_ADMIN
              ? []
              : assetIds,
          maintainerOrgId: null,
          mailDelayed: !!userExists ? false : values.delay,
        })
      );
    }
    if (org) {
      dispatch(getMaintainersOrganizationList());
    } else {
      dispatch(fetchRegisteredUsersList());
    }

    resetForm();
    handleClear();
  };

  const handleCheckIfUserExists = async (email) => {
    const userExists = await checkIfUserExists(email);
    setUserExists(userExists);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={user ? t("edit user rights") : t("create user")}
      add
      form="user-form"
      footer={
        <div className={styles.button_section}>
          <Button
            onClick={handleClear}
            type="reset"
            form={"user-form"}
            variant="ghost"
            size="s"
          >
            {t("cancel")}
          </Button>
          {!user && (
            <Button
              className={styles.btn_separator}
              type="submit"
              variant={pending ? "disabled" : "orange"}
              size="s"
              form={"user-form"}
              disabled={pending}
            >
              {t("add")}
            </Button>
          )}

          {user && (
            <Button
              className={styles.btn_separator}
              type="submit"
              variant={pending ? "disabled" : "orange"}
              size="s"
              form={"user-form"}
              disabled={pending}
            >
              {t("save changes", "title")}
            </Button>
          )}
        </div>
      }
    >
      <Form
        id="user-form"
        onSubmit={handleSubmit}
        initialValues={initialValuesForm}
        validationSchema={validationSchema}
        enableReinitialize
        refForm={formRef}
      >
        {({ values }) => {
          return (
            <>
              <FormControl>
                <FieldLabel>
                  {t("name")}
                  <span className={styles.red}>*</span>
                </FieldLabel>
                <FieldInput size="l" name="username" type="text" />
                <FieldError name="username" />
              </FormControl>
              <FormControl>
                <FieldLabel>
                  {t("e-mail")}
                  <span className={styles.red}>*</span>
                </FieldLabel>
                <FieldInput
                  size="l"
                  name="email"
                  type="email"
                  disabled={
                    user &&
                    role !== "SUPER_ADMIN" &&
                    role !== "CUSTOMER_ADMIN" &&
                    role !== "ADMIN"
                  }
                  onBlur={(value) => handleCheckIfUserExists(value)}
                />
                <FieldError name="email" />
              </FormControl>

              <FormControl className={styles.dropdown}>
                <FieldLabel>{t("language")}</FieldLabel>
                <FieldSelect
                  name="languageCode"
                  size="s"
                  options={languageOptions}
                  placeholder={t("language")}
                />
                <FieldError name="language" />
              </FormControl>

              <FormControl className={styles.dropdown}>
                <FieldLabel>
                  {t("role")}
                  <span className={styles.red}>*</span>
                </FieldLabel>
                <FieldSelect
                  name="roleId"
                  size="s"
                  disabled={
                    !(
                      role == Role.SUPER_ADMIN ||
                      role == Role.ADMIN ||
                      role == Role.CUSTOMER_ADMIN
                    ) || user?.access.role == Role.MAINTAINER
                  }
                  options={
                    role == Role.ADMIN
                      ? removeCustomerRolesForPrivilegedCompany(
                          rolesOptionsForAdmin
                        )
                      : role == Role.CUSTOMER_ADMIN
                      ? removeCustomerRolesForPrivilegedCompany(
                          rolesOptionsForCustomerAdmin
                        )
                      : removeCustomerRolesForPrivilegedCompany(rolesOptions)
                  }
                  placeholder={t("role")}
                />
                <FieldError name="roleId" />
              </FormControl>

              {!isPrivileged &&
                rolesOptions.find((x) => x.value === values.roleId)?.role !==
                  Role.SUPER_ADMIN &&
                rolesOptions.find((x) => x.value === values.roleId)?.role !==
                  Role.ADMIN && (
                  <FormControl className={styles.dropdown}>
                    <FieldLabel>
                      {rolesOptions.find((x) => x.value === values.roleId)
                        ?.role === Role.CUSTOMER_ADMIN
                        ? t("receives notifications for")
                        : t("access")}
                      <span className={styles.red}>*</span>
                    </FieldLabel>
                    <FieldSelect
                      name="rightIds"
                      isMultiple={true}
                      size="s"
                      options={org ? rightsOrgOption : rightsOptions}
                      placeholder={
                        rolesOptions.find((x) => x.value === values.roleId)
                          ?.role === Role.CUSTOMER_ADMIN
                          ? t("select access rights for notifications")
                          : t("access rights")
                      }
                    />
                    <FieldError name="rightIds" />
                  </FormControl>
                )}

              {!isPrivileged &&
                rolesOptions.find((x) => x.value === values.roleId)?.role !==
                  Role.SUPER_ADMIN &&
                rolesOptions.find((x) => x.value === values.roleId)?.role !==
                  Role.ADMIN && (
                  <div className={styles.grouping_assets_access_wrapper}>
                    <div className={styles.grouping_assets_access_label}>
                      {rolesOptions.find((x) => x.value === values.roleId)
                        ?.role === Role.CUSTOMER_ADMIN
                        ? t("receives notifications for")
                        : t("grouping assets access")}
                      <span className={styles.red}>*</span>
                    </div>
                    <div className={styles.grouping_assets_access_box}>
                      <div className={styles.grouping_assets_access_input}>
                        {!assetIds.length ? (
                          <div
                            className={
                              styles.grouping_assets_access_input_placeholder
                            }
                            onClick={() => setOpenFiltersModal(true)}
                          >
                            {rolesOptions.find((x) => x.value === values.roleId)
                              ?.role === Role.CUSTOMER_ADMIN
                              ? t("select grouping assets")
                              : t("choose grouping assets to give rights")}
                          </div>
                        ) : (
                          <div
                            className={
                              styles.grouping_assets_access_input_values
                            }
                          >
                            {assetIds.map((assetId) => (
                              <div
                                className={
                                  styles.grouping_assets_access_input_value
                                }
                                key={assetId}
                              >
                                <div
                                  className={
                                    styles.grouping_assets_access_input_value_label
                                  }
                                >
                                  {
                                    assetNameSearchOptions.find(
                                      (x) => x.id === assetId
                                    ).name
                                  }
                                </div>
                                <div
                                  className={
                                    styles.grouping_assets_access_input_value_icon
                                  }
                                  onClick={() => deselectGroupingAsset(assetId)}
                                >
                                  <Icon name="x-delete-black" />
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                      <div
                        className={styles.grouping_assets_access_icon}
                        onClick={() => setOpenFiltersModal(true)}
                      >
                        <Icon name="editPencil-orange" />
                      </div>
                    </div>
                  </div>
                )}
              {!!user && (
                <Checkbox
                  name="isDisabled"
                  label={t("disable access rights")}
                />
              )}

              {!user && !userExists && (
                <>
                  <Checkbox name="delay" label={t("delay invitation")} />
                </>
              )}
            </>
          );
        }}
      </Form>
      {!!openFiltersModal && (
        <RightsBuildingFilterModal
          isOpen={openFiltersModal}
          onCheck={handleCheck}
          checked={assetIds}
          onClose={() => setOpenFiltersModal(false)}
        />
      )}
    </Modal>
  );
};

UserFormModal.propTypes = {
  remove: PropTypes.bool,
  add: PropTypes.bool,
};

export default UserFormModal;
