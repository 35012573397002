import React, { useContext, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import cx from "classnames";
import { I18nContext } from "../../i18n/I18nContext";
import MeatballMenu from "../Access/MeatballMenu";
import Icon from "../Icon";
import DeleteTaskTemplateModal from "./DeleteTaskTemplateModal";
import TaskPrototypeModal from "./TaskPrototypeModal";
import styles from "./FilteredProptypes.module.scss";
import { unblockTaskPrototype, fetchTasksPrototypesList } from "../../redux";

const FilteredTasks = ({ items, blockedItems, canEdit }) => {
  const { t } = useContext(I18nContext);
  const dispatch = useDispatch();
  const [openDeleteTaskPrototypeModal, setOpenDeleteTaskPrototypeModal] =
    useState(false);
  const [openEditAssetPrototypeModal, setOpenEditTaskPrototypeModal] =
    useState(false);
  const [meatballOpened, setMeatballOpened] = useState(null);
  const [selectedPrototype, setSelectedPrototype] = useState(null);
  const isPrivileged = useSelector(
    (state) => state.auth.info?.companyPrivileged
  );

  useEffect(() => {
    if (!!selectedPrototype) {
      setSelectedPrototype(items.find((x) => x.id === selectedPrototype.id));
    }
  }, [items]);

  const handleUnblockTaskTemplate = async (taskTemplate) => {
    await dispatch(unblockTaskPrototype(t, taskTemplate.id));
    await dispatch(fetchTasksPrototypesList());
  };

  const meatballMenuItems = (prototype) => [
    {
      label: t("edit"),
      show:
        !prototype.deprecated &&
        (isPrivileged || (!isPrivileged && !prototype.global)),
      onClick: () => {
        setSelectedPrototype(prototype);
        setOpenEditTaskPrototypeModal(true);
      },
      icon: <Icon name="editPencil-orange" className={styles.icon_menu} />,
    },
    {
      label: t("preview"),
      show: !isPrivileged && prototype.global,
      onClick: () => {
        setSelectedPrototype(prototype);
        setOpenEditTaskPrototypeModal(true);
      },
      icon: <Icon name="eye-orange" className={styles.icon_menu} />,
    },
    {
      label: t("block"),
      show:
        !prototype.deprecated &&
        (isPrivileged || (!isPrivileged && !prototype.global)),
      icon: <Icon name="trash-orange" className={styles.icon_menu} />,
      onClick: () => {
        setOpenDeleteTaskPrototypeModal(true);
        setSelectedPrototype(prototype);
      },
    },
    {
      label: t("unblock"),
      show:
        !!prototype.deprecated &&
        (isPrivileged || (!isPrivileged && !prototype.global)),
      icon: <Icon name="invert" className={styles.icon_menu} />,
      onClick: () => {
        handleUnblockTaskTemplate(prototype);
      },
    },
  ];

  return (
    <div>
      {items?.map((item) => {
        return (
          <div
            key={item.id}
            className={cx(styles.row, {
              [styles.visible_for_sub_companies]: item.global,
            })}
          >
            {item.deprecated && (
              <div className={styles.title2}>
                {item?.name}
                <div className={styles.descriptionTooltip}>{item?.desc}</div>
              </div>
            )}
            {!item.deprecated && (
              <div className={styles.title}>
                {item?.name}
                <div className={styles.descriptionTooltip}>{item?.desc}</div>
              </div>
            )}
            {item.recurrence && (
              <div className={styles.group}>
                {t("recurrence")} - {item.recurrence.recurringInterval}{" "}
                {t(item.recurrence.intervalUnit)}
              </div>
            )}
            <div className={styles.date}>
              {new Date(item.updatedAt).toLocaleDateString(undefined, {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              })}
            </div>
            <span className={styles.meatball}>
              <MeatballMenu
                items={meatballMenuItems(item)}
                disabled={item.deprecated || !canEdit}
                id={item.id}
                meatballOpened={meatballOpened}
                meatballOpenedObserver={(state) => setMeatballOpened(state)}
              />
            </span>
          </div>
        );
      })}
      {blockedItems?.map((item) => {
        return (
          <div
            key={item.id}
            className={cx(styles.row, {
              [styles.visible_for_sub_companies]: item.global,
            })}
          >
            {item.deprecated && (
              <div className={styles.title2}>
                {item?.name}
                <div className={styles.descriptionTooltip}>{item?.desc}</div>
              </div>
            )}
            {!item.deprecated && (
              <div className={styles.title}>
                {item?.name}
                <div className={styles.descriptionTooltip}>{item?.desc}</div>
              </div>
            )}
            {item.recurrence && (
              <div className={styles.group}>
                {t("recurrence")} - {item.recurrence.recurringInterval}{" "}
                {t(item.recurrence.intervalUnit)}
              </div>
            )}
            <div className={styles.date}>
              {new Date(item.updatedAt).toLocaleDateString(undefined, {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              })}
            </div>
            <span className={styles.meatball}>
              <MeatballMenu
                items={meatballMenuItems(item)}
                disabled={!canEdit}
                id={item.id}
                meatballOpened={meatballOpened}
                meatballOpenedObserver={(state) => setMeatballOpened(state)}
              />
            </span>
          </div>
        );
      })}
      {!!openDeleteTaskPrototypeModal && (
        <DeleteTaskTemplateModal
          key="delete-prototype"
          taskTemplate={selectedPrototype}
          isOpen={openDeleteTaskPrototypeModal}
          onClose={() => {
            setOpenDeleteTaskPrototypeModal(false);
            setSelectedPrototype(null);
          }}
        />
      )}
      {!!openEditAssetPrototypeModal && (
        <TaskPrototypeModal
          key="edit-prototype"
          taskPrototype={selectedPrototype}
          isOpen={openEditAssetPrototypeModal}
          onClose={() => {
            setOpenEditTaskPrototypeModal(false);
            setSelectedPrototype(null);
          }}
          preview={!isPrivileged && selectedPrototype?.global}
        />
      )}
    </div>
  );
};

FilteredTasks.propTypes = {
  items: PropTypes.array,
};

export default FilteredTasks;
