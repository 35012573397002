import styles from "./AlertItem.module.scss";

import Icon from "../Icon/Icon";
import Locales from "../../i18n/locales";

const AlertItem = ({ item, onDeactivate }) => {
  const handleDeactivate = () => {
    onDeactivate(item.id);
  };
  return (
    <li>
      <div className={styles.container}>
        <span className={styles[`dot_${item.criticality}`]}></span>
        <span>
          {new Date(item.issuedAt).toLocaleDateString("UTC", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          })}{" "}
          {new Date(item.issuedAt).toLocaleTimeString("UTC", {
            hour12: false,
          })}
          {" - "}
          {item.procedureName}
        </span>
        {/*<div onClick={handleDeactivate} className={styles.deleteButton}>*/}
        {/*  <Icon name="x-delete" />*/}
        {/*</div>*/}
      </div>
    </li>
  );
};

export default AlertItem;
