import Icon from "../Icon";
import { FieldInput, FieldLabel, FieldSelect, FormControl } from "../Input";
import styles from "./PreconditionsForm.module.scss";
import { I18nContext } from "../../i18n/I18nContext";
import React, { useContext } from "react";

const BooleanCondition = ({
  readOnly,
  precondition,
  index,
  onDeletePrecondition,
  name,
}) => {
  const { t } = useContext(I18nContext);

  return (
    <div className={styles.row} key={index}>
      <FormControl>
        <FieldLabel>
          {t("condition")} {index + 1}
        </FieldLabel>
        <FieldInput
          size="s"
          type="text"
          placeholder={t("input value", "title")}
          name={`${index}_boolean`}
          disabled
        />
      </FormControl>
      <FormControl className={styles.condition_dropdown}>
        <FieldLabel></FieldLabel>
        <FieldSelect
          name={name ? name : `${index}_expectedValue`}
          size="s"
          disabled={readOnly}
          options={[
            { value: false, label: t("false") },
            { value: true, label: t("true") },
          ]}
          placeholder={t("false")}
        />
      </FormControl>
      <span className={styles.delete_btn}>
        {onDeletePrecondition && !readOnly && (
          <div onClick={() => onDeletePrecondition(precondition)}>
            <Icon className={styles.delete_icon} name="x-delete" />
          </div>
        )}
      </span>
    </div>
  );
};

export default BooleanCondition;
