import Icon from "../Icon";
import { FieldInput, FieldLabel, FormControl } from "../Input";
import styles from "./PreconditionsForm.module.scss";
import { I18nContext } from "../../i18n/I18nContext";
import { useContext } from "react";

const TextCondition = ({
  readOnly,
  precondition,
  index,
  onDeletePrecondition,
  name,
}) => {
  const { t } = useContext(I18nContext);

  return (
    <FormControl className={styles.spacer_top} key={index}>
      <div className={styles.row}>
        <FormControl>
          <FieldLabel>
            {t("condition")} {index + 1}
          </FieldLabel>
          <FieldInput
            size="s"
            type="text"
            placeholder={t("input value", "title")}
            name={`${index}_comparedInput`}
            disabled
          />
        </FormControl>
        <div className={styles.contains_txt}>{t("contains", "lower")}</div>
        <FormControl>
          <FieldLabel></FieldLabel>
          <FieldInput
            name={name ? name : `${index}_triggerWords`}
            type="text"
            placeholder={t("phrase, sentence, key word")}
            size="m"
            disabled={readOnly}
          />
        </FormControl>
        <span className={styles.delete_btn}>
          {onDeletePrecondition && !readOnly && (
            <div onClick={() => onDeletePrecondition(precondition)}>
              <Icon name="x-delete" />
            </div>
          )}
        </span>
      </div>
    </FormControl>
  );
};

export default TextCondition;
