import React, { useContext, useEffect, useState } from "react";
import { I18nContext } from "../../i18n/I18nContext";
import { useDispatch, useSelector } from "react-redux";
import SectionHeader from "../SectionHeader";
import { Tab, TabContent, Tabs, TabsBar } from "../Tabs";
import styles from "./NotificationGroup.module.scss";

import {
  fetchNotificationGroups,
  fetchNotificationGroupUsers,
} from "./notificationGroupActions";
import Button from "../Button";
import AccessTitles from "../Access/AccessTitles";
import CompanyRowForm from "../Access/CompanyRowForm";
import cx from "classnames";
import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
} from "../Accordion";
import MeatballMenu from "../Access/MeatballMenu";

import NotificationGroupModal from "./NotificationGroupModal";
import Icon from "../Icon";
import NotificationGroupDeleteModal from "./NotificationGroupDeleteModal";

const NotificationGroup = ({ items = [] }) => {
  const { t } = useContext(I18nContext);
  const [openAddNotificationGroupModal, setOpenAddNotificationGroupModal] =
    useState(false);
  const [openEditNotificationGroupModal, setOpenEditNotificationGroupModal] =
    useState(false);
  const [notificationGroups, setNotificationGroups] = useState(items);
  const [selectedNotificationGroup, setSelectedNotificationGroup] =
    useState(null);
  const [meatballOpened, setMeatballOpened] = useState(null);
  const [deleteModalOpened, setDeleteModalOpened] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchNotificationGroupUsers());
    setNotificationGroups(items);
  }, [dispatch, items]);

  const meatBallsItems = (notificationGroup) => [
    {
      label: t("edit"),
      show: true,
      onClick: () => {
        setSelectedNotificationGroup(notificationGroup);
        setOpenEditNotificationGroupModal(true);
      },
      icon: <Icon name="editPencil-orange" className={styles.icon_menu} />,
    },
    {
      label: t("delete"),
      show: true,
      onClick: () => {
        setSelectedNotificationGroup(notificationGroup);
        setDeleteModalOpened(true);
      },
      icon: <Icon name="trash-orange" className={styles.icon_menu} />,
    },
  ];
  return (
    <>
      <div className={styles.column}>
        <div className={styles.filters_section}>
          <div></div>
          <div className={styles.row}>
            <Button
              variant="orange"
              size="s"
              onClick={() => {
                setOpenAddNotificationGroupModal(true);
              }}
              disabled={false}
            >
              + {t("add new notification group")}
            </Button>
          </div>
        </div>

        <Accordion>
          {notificationGroups.map((notificationGroup) => {
            return (
              <AccordionItem
                key={notificationGroup.id}
                id={notificationGroup.id}
                className={cx(styles.container_item)}
              >
                <AccordionHeader
                  text={notificationGroup.name}
                  className={styles.accordion_header}
                  hasItem={true}
                  rightIcon={
                    <MeatballMenu
                      items={meatBallsItems(notificationGroup)}
                      id={notificationGroup.id}
                      meatballOpened={meatballOpened}
                      meatballOpenedObserver={(state) => {
                        setMeatballOpened(state);
                        setSelectedNotificationGroup(notificationGroup);
                      }}
                    />
                  }
                ></AccordionHeader>
                <AccordionPanel>
                  <div className={styles.table1}>
                    {notificationGroup.users?.map((user, key) => {
                      return (
                        <div className={styles.title} key={key}>
                          <div> {user.userName}</div>
                        </div>
                      );
                    })}
                  </div>
                </AccordionPanel>
              </AccordionItem>
            );
          })}
        </Accordion>

        {!!openAddNotificationGroupModal && (
          <NotificationGroupModal
            key="add-group"
            isOpen={openAddNotificationGroupModal}
            onClose={() => {
              setOpenAddNotificationGroupModal(false);
              dispatch(fetchNotificationGroups());
            }}
          />
        )}

        {!!openEditNotificationGroupModal && (
          <NotificationGroupModal
            key="add-group"
            isOpen={openEditNotificationGroupModal}
            notificationGroup={selectedNotificationGroup}
            onClose={() => {
              setOpenEditNotificationGroupModal(false);
              dispatch(fetchNotificationGroups());
            }}
          />
        )}

        {!!deleteModalOpened && (
          <NotificationGroupDeleteModal
            isOpen={deleteModalOpened}
            onClose={() => {
              setDeleteModalOpened(false);
              dispatch(fetchNotificationGroups());
            }}
            notificationGroup={selectedNotificationGroup}
          />
        )}
      </div>
    </>
  );
};

export default NotificationGroup;
