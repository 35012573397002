import React, { useContext } from "react";
import { useSelector } from "react-redux";
import Button from "../Button";
import Modal from "../Modal";
import styles from "../Configuration/DeleteTaskTemplateModal.module.scss";
import { I18nContext } from "../../i18n/I18nContext";

const DeleteAccountModal = ({ isOpen, onClose, form, onDelete }) => {
  const { t } = useContext(I18nContext);
  const userData = useSelector((state) => state.profile.profile);

  const handleClear = () => {
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={`${t("delete account")}?`}
      icon
      footer={
        <div className={styles.button_section}>
          <Button
            className={styles.btn_separator}
            onClick={handleClear}
            type="reset"
            variant="ghost"
            size="s"
          >
            {t("cancel")}
          </Button>
          <Button type="submit" variant="orange" size="s" onClick={onDelete}>
            {t("delete")}
          </Button>
        </div>
      }
    >
      <div className={styles.delete_txt}>
        {t("are you sure you want to delete the account")}
        <strong> {userData.username} </strong>
        {t("with", "lower")}
        <strong> {userData.email}</strong>?
      </div>
    </Modal>
  );
};

export default DeleteAccountModal;
