import Actions from "../actions";
import axios from "../auth";
import { createToast } from "../toast/toastActions";

const fetchTaskPrototypesRequest = () => {
  return {
    type: Actions.FETCH_TASK_PROTOTYPES_REQUEST,
  };
};

const fetchTaskPrototypesSuccess = (tasks) => {
  return {
    type: Actions.FETCH_TASK_PROTOTYPES_SUCCESS,
    tasks,
  };
};

const fetchTaskPrototypesError = () => {
  return {
    type: Actions.FETCH_TASK_PROTOTYPES_ERROR,
  };
};

const taskPrototypesRequestFailure = (status, error) => {
  return {
    type: Actions.TASK_PROTOTYPES_REQUEST_FAILURE,
    status,
    error,
  };
};

export const fetchTasksPrototypesList = () => {
  return (dispatch) => {
    dispatch(fetchTaskPrototypesRequest());
    return axios
      .get("/taskPrototypes")
      .then((response) => {
        dispatch(fetchTaskPrototypesSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchTaskPrototypesError(error));
      });
  };
};

export const addTaskPrototype = (t, payload) => {
  return (dispatch) => {
    return axios.post("/taskPrototypes", payload).then((response) => {
      dispatch(
        createToast({
          type: "success",
          title: t("successfully added new task template"),
        })
      );
      return response;
    });
  };
};

export const fetchTaskPrototype = (id) => {
  return () => {
    return axios.get(`/taskPrototypes/${id}`);
  };
};

export const updateTaskPrototype = (t, taskPrototype) => {
  return (dispatch) => {
    return axios
      .put(`/taskPrototypes/${taskPrototype.id}`, taskPrototype)
      .then((response) => {
        dispatch(
          createToast({
            type: "success",
            title: t("successfully edited task template"),
          })
        );
        return response;
      })
      .catch((error) => {
        console.log("ERROR", error.response.status, error.response.data);
        dispatch(
          taskPrototypesRequestFailure(
            error.response.status,
            error.response.data
          )
        );
      });
  };
};

export const taskPrototypesEditErrorClear = () => {
  return {
    type: Actions.TASK_PROTOTYPES_EDIT_ERROR_CLEAR,
  };
};

export const addTaskPrototypeAttachment = (id, files) => {
  var formData = new FormData();
  files.forEach((file) => {
    formData.append("files", file, file.name);
  });

  return () => {
    return axios.post(`/taskPrototypes/${id}/attachments`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  };
};

export const addTaskAttachment = (id, files) => {
  var formData = new FormData();
  files.forEach((file) => {
    formData.append("files", file, file.name);
  });

  return () => {
    return axios.post(`/tasks/${id}/attachments`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  };
};

export const deleteTaskPrototypeAttachment = (id, key) => {
  return (dispatch) => {
    return axios
      .delete(`/taskPrototypes/${id}/attachment?key=${key}`)
      .then(() => {
        dispatch(
          createToast({
            type: "success",
            title: `Successfully deleted attachment`,
          })
        );
      });
  };
};

export const blockTaskPrototype = (t, id) => {
  return (dispatch) => {
    return axios.delete(`/taskPrototypes/${id}`).then((res) => {
      if (res.data === true) {
        dispatch(
          createToast({
            type: "success",
            title: t("successfully blocked task template"),
          })
        );
      } else {
        dispatch(
          createToast({
            type: "error",
            title: t(
              "this task type cannot be blocked as there are open tasks which are based on it"
            ),
          })
        );
      }
    });
  };
};

export const unblockTaskPrototype = (t, id) => {
  return (dispatch) => {
    return axios.post(`taskPrototypes/revokeDelete/${id}`).then((res) => {
      dispatch(
        createToast({
          type: "success",
          title: t("successfully unblocked task template"),
        })
      );
    });
  };
};
