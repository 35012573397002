import React, { useEffect, useState } from "react";
import { TabContext } from "./TabContext";
import PropTypes from "prop-types";

const Tabs = ({ onChange, classNames, children, initialValue = null }) => {
  const [currentValue, setValue] = useState(initialValue);

  const contextValue = {
    currentValue,
    changeTab: setValue,
  };

  useEffect(() => {
    onChange(currentValue);
  }, [onChange, currentValue]);

  return (
    <TabContext.Provider value={contextValue}>
      <div className={classNames}>{children}</div>
    </TabContext.Provider>
  );
};

Tabs.propTypes = {
  onChange: PropTypes.func,
  classNames: PropTypes.string,
};

export default Tabs;
