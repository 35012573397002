import Actions from "../actions";

export const createToast = (payload) => ({
  type: Actions.CREATE_TOAST,
  payload,
});

export const deleteToast = (id) => ({
  type: Actions.DELETE_TOAST,
  id,
});
