import React from "react";
import PropTypes from "prop-types";

import styles from "./RadioField.module.scss";

const RadioBase = ({ label, children, disabled }) => {
  return (
    <label className={styles.container}>
      <span className={styles.txt}>{label}</span>
      {children}
      <span
        className={disabled ? [styles.checkmark2] : [styles.checkmark]}
      ></span>
    </label>
  );
};

RadioBase.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
};

export default RadioBase;
