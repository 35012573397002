import React from "react";
import PropTypes from "prop-types";
import Icon from "../../components/Icon";
import styles from "./Task.module.scss";

const Task = ({ text, type, badge, level }) => {
  let icon;
  let backgroundIcon;
  switch (type) {
    case "warning":
      backgroundIcon = "asset-orange";
      icon = "tap-orange";
      break;
    case "critical":
      backgroundIcon = "asset-red";
      icon = "tap-red";
      break;
    case "info":
      backgroundIcon = "asset-green";
      icon = "tap-green";
      break;
    default:
      backgroundIcon = "asset-yellow";
      icon = "tap-yellow";
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.section_left} style={{ marginLeft: level * 50 }}>
        <span className={styles.divider}></span>
        <div className={styles.joined_icons}>
          <Icon className={styles.background_icon} name={backgroundIcon} />
          <Icon className={styles.top_icon} name={icon} />
        </div>
        <span className={styles.txt}>{text}</span>
      </div>
      {badge !== undefined && <div className={styles.badge}>{badge}</div>}
    </div>
  );
};

Task.propTypes = {
  text: PropTypes.string,
  type: PropTypes.string,
  badge: PropTypes.number,
  level: PropTypes.number,
};

export default Task;
