import React, { useContext } from "react";
import { Link } from "react-router-dom";
import styles from "./InstallApp.module.scss";
import { I18nContext } from "../../i18n/I18nContext";
import { appUrls } from "../../urls";
import Entry from "../../layouts/Entry";
import QR from "../../assets/image/qr-code.png";
import GOOGLE from "../../assets/image/google_play.png";
import APPLE from "../../assets/image/apple_app_store.png";

const InstallApp = () => {
  const { t } = useContext(I18nContext);

  return (
    <Entry>
      <div className={styles.container}>
        <span className={styles.header}>{t("install app")}</span>
        <div className={styles.content}>
          <div className={styles.qr_container}>
            <img src={GOOGLE} className={styles.logo_image} />
            <img src={QR} className={styles.qr_image} />
          </div>
          <div className={styles.qr_container}>
            <img src={APPLE} className={styles.logo_image} />
            <img src={QR} className={styles.qr_image} />
          </div>
        </div>
        <Link className={styles.skip_link} to={appUrls.COMPANY}>
          {t("skip")}
        </Link>
      </div>
    </Entry>
  );
};

export default InstallApp;
