import React, { useContext, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import Button from "../Button";
import {
  FieldError,
  FieldInput,
  FieldLabel,
  FieldSelect,
  Form,
  FormControl,
} from "../Input";
import Modal from "../Modal";
import styles from "./NotificationGroupModal.module.scss";
import { I18nContext } from "../../i18n/I18nContext";
import { useState } from "react";
import {
  addNotificationGroup,
  editNotificationGroup,
  fetchNotificationGroups,
  fetchNotificationGroupUsers,
} from "./notificationGroupActions";

const NotificationGroupModal = ({ onClose, isOpen, notificationGroup }) => {
  const { t } = useContext(I18nContext);
  const dispatch = useDispatch();
  const [progress, setProgress] = useState(false);
  const formRef = useRef();
  const users = useSelector((state) => {
    return state.notificationGroups.users.map((r) => ({
      label: r.userName,
      value: r.userId,
    }));
  });

  useEffect(() => {
    dispatch(fetchNotificationGroupUsers());
  }, [dispatch]);

  const defaultInitialValues = {
    name: notificationGroup?.name ?? "",
    users:
      notificationGroup?.users.map((r) => ({
        label: r.userName,
        value: r.userId,
      })) ?? [],
  };

  const [initialValues, setInitialValues] = useState(defaultInitialValues);

  const validationSchema = Yup.object({
    name: Yup.string().required(t("missing name")),
    users: Yup.array().required(t("please choose one option")),
  });

  const handleClear = () => {
    onClose();
  };

  const handleSubmit = async (values, { resetForm }) => {
    if (progress) {
      return;
    }
    if (notificationGroup) {
      setProgress(true);
      await dispatch(
        editNotificationGroup(t, {
          id: notificationGroup.id,
          name: values.name,
          userIds: values.users,
        })
      );
    } else {
      setProgress(true);
      await dispatch(
        addNotificationGroup(t, {
          name: values.name,
          userIds: values.users,
        })
      );
    }
    setProgress(false);
    dispatch(fetchNotificationGroups());
    resetForm();
    onClose();
  };
  useEffect(() => {
    if (notificationGroup) {
      setInitialValues({
        name: notificationGroup?.name ?? "",
        users: notificationGroup?.users.map((item) => {
          return item.userId;
        }),
      });
    }
  }, [notificationGroup]);
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={
        notificationGroup ? t("edit item") : t("add new notification group")
      }
      form="add-group"
      footer={
        <div className={styles.button_section}>
          <Button
            onClick={handleClear}
            type="reset"
            form={"add-group"}
            variant="ghost"
            size="s"
          >
            {t("cancel")}
          </Button>
          <Button
            className={styles.btn_separator}
            type="submit"
            variant="orange"
            size="s"
            form={"add-group"}
          >
            {!!notificationGroup ? t("save") : t("add")}
          </Button>
        </div>
      }
    >
      <Form
        id="add-group"
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        initialValues={initialValues}
        refForm={formRef}
        enableReinitialize
      >
        <FormControl className={styles.dropdown}>
          <FieldLabel>
            {t("name")}
            <span className={styles.red}>*</span>
          </FieldLabel>
          <FieldInput name="name" size="l" placeholder={t("name")} />
          <FieldError name="name" />
        </FormControl>
        <FormControl className={styles.dropdown}>
          <FieldLabel>
            {t("users")}
            <span className={styles.red}>*</span>
          </FieldLabel>
          <FieldSelect
            name="users"
            // size="s"
            isMultiple
            options={users}
            placeholder={t("users")}
          />
          <FieldError name="notificationGroups" />
        </FormControl>
      </Form>
    </Modal>
  );
};

export default NotificationGroupModal;
