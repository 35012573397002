import React, { useContext, useEffect, useState } from "react";
import Modal from "../Modal";
import styles from "./TaskPreviewModal.module.scss";
import { generatePath } from "react-router-dom";
import { I18nContext } from "../../i18n/I18nContext";
import {
  addTaskAttachment,
  fetchTasksList,
  getSingleTask,
  updateBatchTask,
  fetchAssetDetails,
  cancelTask,
  fetchGroupTasks,
  updateFinishedTask,
  updateState,
} from "../../redux";
import TaskPrototypeForm from "../Configuration/TaskPrototypeForm";
import { useDispatch, useSelector } from "react-redux";
import Button from "../Button";
import { TaskState } from "../../constants/Tasks";
import UndoneTaskForm from "../Configuration/UndoneTaskForm";
import CancelTaskModal from "./CancelTaskModal";
import TaskHistory from "./TaskHistory";
import CannotBeDoneTaskForm from "../Configuration/CannotBeDoneTaskForm";
import jwtDecode from "jwt-decode";
import { formatDate } from "../../helpers/helpers";
import history from "../../history";
import TaskStateChangeForm from "../Tasks/TaskStateChangeForm";

const TaskPreviewModal = ({
  onClose,
  isOpen,
  itemId,
  disabled,
  assetId,
  getAssetDetails,
  isFinishedEditable,
}) => {
  const { t } = useContext(I18nContext);
  const dispatch = useDispatch();
  const [taskObject, setTaskObject] = useState(null);
  const [taskEdited, setTaskEdited] = useState(null);
  const [isUndoneModalOpen, setIsUndoneModalOpen] = useState(false);
  const [isCancelTaskModalOpen, setIsCancelTaskModalOpen] = useState(false);
  const [isCannotBeDoneModalOpen, setIsCannotBeDoneModalOpen] = useState(false);
  const [significantAttachmentsFromAsset, setSignificantAttachmentsFromAsset] =
    useState([]);

  const role = useSelector((state) => state.auth.info?.role);
  const token = useSelector((state) => state.auth.info?.token);
  const allChosen = useSelector((state) => state.buildings.choosenBuildings);
  const loading = useSelector((state) => state.tasks.loading);
  const assetDetails = useSelector((state) => state.assets.details);
  const [isUpdateStateModalOpen, setIsUpdateStateModalOpen] = useState(false);

  const today = new Date();
  const todayDate = formatDate(today);
  const isEditable =
    (!disabled && (isFinishedEditable === undefined || isFinishedEditable)) ||
    (disabled && isFinishedEditable);
  const fetchTask = async (id) => {
    try {
      const { data } = await getSingleTask(id);
      if (data) setTaskObject(data);
    } catch (err) {}
  };

  useEffect(() => {
    setTaskObject(null);
    if (itemId) {
      fetchTask(itemId);
    }
  }, [itemId]);

  useEffect(() => {
    if (taskObject?.assetId) {
      dispatch(fetchAssetDetails(taskObject.assetId));
    }
  }, [taskObject]);

  useEffect(() => {
    if (assetDetails?.attachments) {
      setSignificantAttachmentsFromAsset(
        assetDetails.attachments.filter((att) => att.isSignificant)
      );
    }
  }, [assetDetails]);

  const validateTaskObject = (taskObject) => {
    const errors = [];

    taskObject.forms.forEach((form) => {
      form.properties.forEach((field) => {
        if (!!field.flags.isMandatory) {
          if (field.propertyType === "boolean" && !field.state) {
            errors.push("error");
          }
          if (field.propertyType === "text" && !field.contents) {
            errors.push("error");
          }
          if (
            field.propertyType === "selection" &&
            (!field?.chosenOptions.length ||
              (field?.chosenOptions.length === 1 && !field?.chosenOptions[0]))
          ) {
            errors.push("error");
          }
          if (field.propertyType === "date" && !field?.selectedDate) {
            errors.push("error");
          }
          if (field.propertyType === "numeric" && !field?.value) {
            errors.push("error");
          }
        }
      });
    });

    taskObject.forms.forEach((form) => {
      form.properties.forEach((field) => {
        if (field.unit) {
          if (
            field.value < field.unit.range.lowerBound ||
            field.value > field.unit.range.upperBound
          ) {
            errors.push("error");
          }
        }
      });
    });

    return errors;
  };

  const clearEditedTask = () => {
    setTaskEdited(null);
    setTaskObject(null);
  };
  const handleSubmit = async () => {
    if (validateTaskObject(taskEdited).length > 0) return;
    if (!isFinishedEditable)
      await dispatch(
        updateBatchTask(t, {
          assetNodes: null,
          task: {
            assetId: taskEdited.assetId,
            taskId: taskEdited.id,
            taskName: taskEdited.name,
            forms: taskEdited.forms,
          },
        })
      );
    else
      await dispatch(
        updateFinishedTask(t, {
          assetNodes: null,
          task: {
            assetId: taskEdited.assetId,
            taskId: taskEdited.id,
            taskName: taskEdited.name,
            forms: taskEdited.forms,
          },
        })
      );
    const newAttachments = taskEdited.attachments.filter((file) => !file.key);
    if (newAttachments.length) {
      await dispatch(addTaskAttachment(taskEdited.id, newAttachments));
    }
    if (assetId) {
      dispatch(fetchAssetDetails(assetId));
    }
    dispatch(
      fetchTasksList(
        TaskState.TODO,
        allChosen.map((el) => el.id)
      )
    );
    dispatch(
      fetchTasksList(
        TaskState.DONE,
        allChosen.map((el) => el.id)
      )
    );
    dispatch(fetchGroupTasks(allChosen.map((el) => el.id)));
    clearEditedTask();
    onClose();
  };

  const handleCancelTask = async () => {
    try {
      const response = await dispatch(cancelTask(t, itemId));

      dispatch(
        fetchTasksList(
          TaskState.TODO,
          allChosen.map((el) => el.id)
        )
      );
      dispatch(
        fetchTasksList(
          TaskState.DONE,
          allChosen.map((el) => el.id)
        )
      );
      dispatch(fetchGroupTasks(allChosen.map((el) => el.id)));
      if (!!getAssetDetails) {
        getAssetDetails();
      }

      setIsCancelTaskModalOpen(false);
      clearEditedTask();
      onClose();
    } catch (err) {
      console.log(err);
    }
  };

  const handleOpenDetails = (assetId) => {
    history.push(generatePath("/assets/details/:assetId", { assetId }));
  };

  return (
    <Modal
      className={styles.modal}
      isOpen={isOpen}
      onClose={onClose}
      form={"task-prototype-form"}
      classNameTitle={styles.title}
      title={taskObject?.name}
      divider={false}
      previewMode={!isEditable}
      footer={
        isEditable ? (
          <div className={styles.btn_section}>
            <Button onClick={onClose} type="reset" variant="ghost" size="s">
              {t("cancel")}
            </Button>
            {(role === "SUPER_ADMIN" ||
              role === "CUSTOMER_ADMIN" ||
              role === "ADMIN") &&
              !isFinishedEditable &&
              taskObject?.state !== TaskState.SUSPENDED && (
                <Button
                  className={styles.btn_separator}
                  onClick={() => setIsCancelTaskModalOpen(true)}
                  type="reset"
                  variant="ghost"
                  size="s"
                >
                  {t("cancel task")}
                </Button>
              )}
            {!isFinishedEditable &&
              taskObject?.state !== TaskState.SUSPENDED && (
                <Button
                  className={styles.btn_separator}
                  onClick={() => setIsCannotBeDoneModalOpen(true)}
                  type="reset"
                  variant="ghost"
                  size="s"
                >
                  {t("cannot be done")}
                </Button>
              )}
            {taskObject?.state !== TaskState.SUSPENDED && (
              <Button
                className={styles.btn_separator}
                type="submit"
                variant={loading ? "disabled" : "orange"}
                size="s"
                disabled={loading}
                onClick={handleSubmit}
                form="task-prototype-form"
              >
                {!isFinishedEditable && t("finish task", "title")}
                {isFinishedEditable && t("edit task", "title")}
              </Button>
            )}
            {isFinishedEditable && (
              <Button
                className={styles.btn_separator}
                type="task-state-change"
                variant={loading ? "disabled" : "orange"}
                size="s"
                onClick={() => setIsUpdateStateModalOpen(true)}
              >
                {t("change state", "title")}
              </Button>
            )}
          </div>
        ) : (
          <div className={styles.btn_section}>
            {jwtDecode(token).id === taskObject?.history.at(-1)?.user?.userId &&
              taskObject?.history.at(-1)?.occurredAt?.split("T").shift() ===
                todayDate &&
              taskObject?.state !== TaskState.SUSPENDED && (
                <Button
                  onClick={() => setIsUndoneModalOpen(true)}
                  type="reset"
                  variant="ghost"
                  size="s"
                >
                  {t("undo task")}
                </Button>
              )}
            <Button
              onClick={onClose}
              type="reset"
              variant="ghost"
              size="s"
              className={styles.btn_separator}
            >
              {t("cancel")}
            </Button>
          </div>
        )
      }
    >
      {taskObject && taskObject.assetName && (
        <div className={styles.asset_info}>
          {!!taskObject.assetPath &&
            !!taskObject.assetPath.length &&
            taskObject.assetPath.length > 1 && (
              <div className={styles.info_item}>
                <div className={styles.asset_path}>
                  {taskObject.assetPath.slice(0, -1).join(" / ") + " / "}
                </div>
                <div
                  className={styles.asset_name}
                  onClick={() => handleOpenDetails(taskObject.assetId)}
                >
                  {taskObject.assetPath.at(-1)}
                </div>
              </div>
            )}
          {!!taskObject.assetPath &&
            !!taskObject.assetPath.length &&
            taskObject.assetPath.length === 1 && (
              <div className={styles.info_item}>
                <span className={styles.asset_name}>
                  {taskObject.assetPath.at(-1)}
                </span>
              </div>
            )}
        </div>
      )}
      {taskObject && (
        <div className={styles.row}>
          <div className={styles.column}>
            {(taskObject.state === TaskState.TODO ||
              taskObject.state === TaskState.OVERDUE ||
              taskObject.state === TaskState.SUSPENDED) && (
              <span className={styles.date}>
                {t("due date")}:{" "}
                {new Date(
                  taskObject.dueDate.split("T").shift()
                ).toLocaleDateString(undefined, {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                })}
                {" / "}
                {Math.abs(taskObject.daysLeft)}{" "}
                {taskObject.daysLeft >= 0 ? t("days left") : t("days ago")}
              </span>
            )}
          </div>
          <div className={styles.column}>
            <TaskHistory
              created={taskObject?.createdAt}
              createdBy={taskObject?.createdBy}
              history={taskObject?.history}
            />
          </div>
        </div>
      )}
      {taskObject && (
        <TaskPrototypeForm
          disabled={!isEditable || taskObject?.state === TaskState.SUSPENDED}
          formId="task-prototype-form"
          taskPrototype={taskObject}
          onEdit={setTaskEdited}
          allowEditingAttachments={
            isEditable && taskObject?.state !== TaskState.SUSPENDED
          }
          showSelectForms={false}
          allowEditingTags={false}
          allowDeletingProperties={false}
          readOnly={!isEditable}
          preview={!isEditable}
          viewFiles={true}
          noRulesEditor={true}
          noDefaultDateValue={true}
          assetAttachments={significantAttachmentsFromAsset}
          taskPreview
        />
      )}
      {!!isUndoneModalOpen && (
        <Modal
          className={styles.modal}
          isOpen={isUndoneModalOpen}
          onClose={() => {
            onClose();
            clearEditedTask();
            setIsUndoneModalOpen(false);
          }}
          form={"undone-task"}
          classNameTitle={styles.title}
          title={t("undone task")}
          divider={false}
          previewMode={!isEditable}
          footer={
            <div className={styles.btn_section}>
              <Button
                type="submit"
                variant="ghost"
                size="s"
                form={"undone-task"}
              >
                {t("undo task")}
              </Button>
              <Button
                onClick={() => setIsUndoneModalOpen(false)}
                type="reset"
                variant="ghost"
                size="s"
                className={styles.btn_separator}
              >
                {t("cancel")}
              </Button>
            </div>
          }
        >
          <UndoneTaskForm
            formId={"undone-task"}
            itemId={itemId}
            onClose={() => {
              onClose();
              setIsUndoneModalOpen(false);
            }}
            getAssetDetails={getAssetDetails}
          />
        </Modal>
      )}
      {isUpdateStateModalOpen && (
        <Modal
          className={styles.modal}
          isOpen={isUpdateStateModalOpen}
          onClose={() => {
            onClose();
            clearEditedTask();
            setIsUndoneModalOpen(false);
          }}
          form={"task-state-change"}
          classNameTitle={styles.title}
          title={t("task state change")}
          divider={false}
          previewMode={!isEditable}
          footer={
            <div className={styles.btn_section}>
              <Button
                type="submit"
                variant="ghost"
                size="s"
                form={"task-state-change"}
              >
                {t("change task state")}
              </Button>
              <Button
                onClick={() => setIsUpdateStateModalOpen(false)}
                type="reset"
                variant="ghost"
                size="s"
                className={styles.btn_separator}
              >
                {t("cancel")}
              </Button>
            </div>
          }
        >
          <TaskStateChangeForm
            formId={"task-state-change"}
            itemId={itemId}
            onClose={() => {
              onClose();
              setIsUndoneModalOpen(false);
            }}
            getAssetDetails={getAssetDetails}
          />
        </Modal>
      )}
      {!!isCannotBeDoneModalOpen && (
        <Modal
          className={styles.modal}
          isOpen={isCannotBeDoneModalOpen}
          onClose={() => {
            onClose();
            clearEditedTask();
            setIsCannotBeDoneModalOpen(false);
          }}
          form={"cannot-be-done-task"}
          classNameTitle={styles.title}
          title={t("task cannot be done")}
          divider={false}
          previewMode={isEditable}
          footer={
            <div className={styles.btn_section}>
              <Button
                type="submit"
                variant={loading ? "disabled" : "ghost"}
                size="s"
                form={"cannot-be-done-task"}
                disabled={loading}
              >
                {t("confirm")}
              </Button>
              <Button
                onClick={() => setIsCannotBeDoneModalOpen(false)}
                type="reset"
                variant="ghost"
                size="s"
                className={styles.btn_separator}
              >
                {t("cancel")}
              </Button>
            </div>
          }
        >
          <CannotBeDoneTaskForm
            formId={"cannot-be-done-task"}
            itemId={itemId}
            onClose={() => {
              onClose();
              setIsCannotBeDoneModalOpen(false);
            }}
            getAssetDetails={getAssetDetails}
          />
        </Modal>
      )}
      {!!isCancelTaskModalOpen && (
        <CancelTaskModal
          isOpen={isCancelTaskModalOpen}
          onClose={() => setIsCancelTaskModalOpen(false)}
          onCancel={handleCancelTask}
        />
      )}
    </Modal>
  );
};

export default TaskPreviewModal;
