import React, { useContext, useState } from "react";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import history from "../../history";
import { I18nContext } from "../../i18n/I18nContext";
import { logout } from "../../redux";
import { appUrls } from "../../urls";
import { deleteProfile, editProfileInfo } from "../../redux";
import {
  FieldError,
  FieldLabel,
  FieldSelect,
  Form,
  FormControl,
} from "../Input";
import Button from "../Button";
import styles from "./EditPasswordProfile.module.scss";
import DeleteAccountModal from "./DeleteAccountModal";
import { world_timezones } from "../../helpers/helpers";

const SettingsPreferences = () => {
  const { t, changeLanguage, language } = useContext(I18nContext);
  const { profile } = useSelector((state) => state.profile);
  const dispatch = useDispatch();
  const [deleteAccountModalVisibility, setDeleteAccountModalVisibility] =
    useState(false);

  const initialValues = {};

  const validationSchema = Yup.object({});

  const handleSubmit = (values, type) => {
    const userData = { ...profile, [type]: values };
    if (type === "languageCode") {
      changeLanguage(values);
    }
    dispatch(editProfileInfo(t, userData));
  };

  const handleDeleteProfile = async () => {
    await dispatch(deleteProfile());
    dispatch(logout());
    history.push(appUrls.LOGIN);
  };

  const languageOptions = [
    { value: "en", label: t("english") },
    { value: "nl", label: t("dutch") },
  ];

  const timeZoneOptions = world_timezones.map((zone) => ({
    value: zone,
    label: zone,
  }));

  return (
    <div className={styles.container}>
      <div className={styles.header}>{t("account preferences")}</div>
      <span className={styles.subtitle}>
        {t("control the settings associated with an account.")}
        <span className={styles.title1}>{t("language and region")}</span>
      </span>
      <Form
        // onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
      >
        <FormControl className={styles.dropdown}>
          <FieldLabel>{t("language")}</FieldLabel>
          <FieldSelect
            name="language"
            size="s"
            options={languageOptions}
            singleValue={language}
            onChange={(e) => handleSubmit(e, "languageCode")}
          />
          <FieldError name="language" />
        </FormControl>
        <FormControl className={styles.dropdown}>
          <FieldLabel>{t("time zone")}</FieldLabel>
          <FieldSelect
            name="timeZone"
            size="s"
            options={timeZoneOptions}
            singleValue={profile.timeZone}
            onChange={(e) => handleSubmit(e, "timeZone")}
          />
          <FieldError name="timeZone" />
        </FormControl>
        <hr className={styles.separator1}></hr>
        <span className={styles.title1}>{t("delete account")}</span>
        <div className={styles.txt_delete}>
          <span>
            {t(
              "deleting your account will result in the loss of access to services related to your T@pper account.",
              "lower"
            )}
          </span>
          <span>
            &nbsp;
            {t("you can cancel the deletion within 14 days.")}
          </span>
        </div>
      </Form>
      <Button
        className={styles.btn_delete}
        type="submit"
        variant="ghost"
        size="s"
        onClick={() => setDeleteAccountModalVisibility(true)}
      >
        {t("delete account")}
      </Button>

      <DeleteAccountModal
        onClose={() => setDeleteAccountModalVisibility(false)}
        isOpen={deleteAccountModalVisibility}
        onDelete={handleDeleteProfile}
      />
    </div>
  );
};

export default SettingsPreferences;
