import React, { useContext, useEffect, useState } from "react";
import * as Yup from "yup";
import Modal from "../Modal";
import {
  Checkbox,
  DatePicker,
  FieldError,
  FieldInput,
  FieldLabel,
  FieldSelect,
  Form,
  FormControl,
} from "../Input";
import Button from "../Button";
import styles from "./AttachmentModal.module.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  addAttachment,
  editAttachment,
  fetchAttachmentsList,
  fetchAttachmentsFoldersList,
  fetchAttachmentsTypesList,
} from "../../redux";
import { I18nContext } from "../../i18n/I18nContext";
import { formatDate } from "../../helpers/helpers";

const AttachmentModal = ({ onClose, isOpen, attachment, assetId }) => {
  const { t } = useContext(I18nContext);
  const dispatch = useDispatch();
  const [selectedFile, setSelectedFile] = useState(null);
  const [noFileError, setNoFileError] = useState(false);
  const folders = useSelector((state) => state.attachments.folders).sort(
    (a, b) => -1 * a.name.localeCompare(b.name)
  );
  const types = useSelector((state) => state.attachments.types).sort(
    (a, b) => -1 * a.name.localeCompare(b.name)
  );

  const initialValues = {
    name: attachment?.name || "",
    folderId: attachment?.attachmentFolder?.id || "",
    typeId: attachment?.type?.id || "",
    date: attachment?.date ? new Date(attachment.date) : new Date(),
    isSignificant: attachment?.isSignificant || false,
  };

  useEffect(() => {
    dispatch(fetchAttachmentsFoldersList());
    dispatch(fetchAttachmentsTypesList());
  }, [dispatch]);

  const foldersOptions = folders.map((folder) => ({
    value: folder.id,
    label: folder.name,
  }));

  const typesOptions = types.map((type) => ({
    value: type.id,
    label: type.name,
  }));

  const validationSchema = Yup.object({
    // name: Yup.string().required(t("missing file name")),
    // folderId: Yup.string().required(t("please select one of the options")),
    // typeId: Yup.string().required(t("please select one of the options")),
    date: Yup.date().required(t("date is required")),
  });

  const clearForm = (formik) => {
    formik?.resetForm();
  };

  const handleClose = () => {
    clearForm();
    onClose();
  };
  const handleSubmit = async (values, formik) => {
    if (attachment) {
      await dispatch(
        editAttachment(t, {
          id: attachment.id,
          name: values.name,
          folderId: values.folderId,
          typeId: values.typeId,
          date: formatDate(values.date),
          isSignificant: values.isSignificant,
        })
      );
    } else {
      if (!selectedFile) {
        setNoFileError(true);
      } else {
        await dispatch(
          addAttachment(t, assetId, selectedFile, {
            name: values.name,
            folderId: values.folderId,
            typeId: values.typeId,
            date: values.date,
            isSignificant: values.isSignificant,
          })
        );
      }
    }
    setSelectedFile(null);
    setNoFileError(false);
    dispatch(fetchAttachmentsList(assetId));
    clearForm(formik);
    onClose();
  };

  const fileChangeHandler = (e) => {
    if (e.target.files.length) {
      setNoFileError(false);
    }
    setSelectedFile(e.target.files[0]);
    e.target.parentElement.children[0].innerText = `Added: ${
      e.target.files[0] ? e.target.files[0].name : selectedFile.name
    }`;
  };

  return (
    <Modal
      className={styles.modal}
      isOpen={isOpen}
      onClose={onClose}
      classNameTitle={styles.title}
      title={
        !attachment ? t("add attachment") : t("edit attachment information")
      }
      form="attachmentEditId"
      divider={false}
      footer={
        <div className={styles.button_section}>
          <Button
            onClick={handleClose}
            type="reset"
            form={"attachmentEditId"}
            variant="ghost"
            size="s"
          >
            {t("cancel")}
          </Button>
          <Button
            className={styles.btn_separator}
            type="submit"
            variant="orange"
            size="s"
            form={"attachmentEditId"}
          >
            {!attachment ? t("add") : t("save changes")}
          </Button>
        </div>
      }
    >
      {!attachment && (
        <label className={styles.addFileLabel} htmlFor="addFile">
          <span>+ {t("click to Add")}</span>
          <input
            className={styles.addFileInput}
            name="addFile"
            id="addFile"
            type="file"
            onChange={fileChangeHandler}
          />
        </label>
      )}
      {!!noFileError && (
        <div className={styles.noFileErrorMessage}>{t("file not added")}</div>
      )}
      <Form
        id="attachmentEditId"
        onSubmit={
          !attachment && !selectedFile
            ? () => setNoFileError(true)
            : handleSubmit
        }
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        <FormControl className={styles.dropdown}>
          <FieldLabel>{t("attachment name")}</FieldLabel>
          <FieldInput name="name" size="l" placeholder={t("attachment name")} />
          <FieldError name="name" />
        </FormControl>
        <FormControl className={styles.dropdown}>
          <FieldLabel>{t("attachment folder")}</FieldLabel>
          <FieldSelect
            name="folderId"
            isMultiple={false}
            size="l"
            options={foldersOptions}
            placeholder={t("select folder")}
          />
          <FieldError name="folderId" />
        </FormControl>
        <FormControl className={styles.dropdown}>
          <FieldLabel>{t("attachment type")}</FieldLabel>
          <FieldSelect
            name="typeId"
            isMultiple={false}
            size="l"
            options={typesOptions}
            placeholder={t("select type")}
          />
          <FieldError name="typeId" />
        </FormControl>
        <FormControl className={styles.dropdown}>
          <FieldLabel>
            {t("attachment date")} <span className={styles.red}>*</span>
          </FieldLabel>
          <DatePicker name="date" withTime={false} />
          <FieldError name="date" />
        </FormControl>
        <Checkbox
          disabled={false}
          name="isSignificant"
          label={t("significant")}
        />
      </Form>
    </Modal>
  );
};

export default AttachmentModal;
