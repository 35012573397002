import React, { useContext } from "react";
import Button from "../Button";
import Modal from "../Modal";
import styles from "./DeleteTaskTemplateModal.module.scss";
import { I18nContext } from "../../i18n/I18nContext";
import { useDispatch } from "react-redux";
import { deleteForm, fetchForms } from "../../redux";

const DeleteFormModal = ({ isOpen, onClose, form }) => {
  const { t } = useContext(I18nContext);
  const dispatch = useDispatch();

  const handleClear = () => {
    onClose();
  };

  const handleDeleteForm = async () => {
    await dispatch(deleteForm(t, form.id));
    await dispatch(fetchForms());
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={`${t("delete form template")}?`}
      icon
      footer={
        <div className={styles.button_section}>
          <Button
            className={styles.btn_separator}
            onClick={handleClear}
            type="reset"
            variant="ghost"
            size="s"
          >
            {t("cancel")}
          </Button>
          <Button
            type="submit"
            variant="orange"
            size="s"
            onClick={handleDeleteForm}
          >
            {t("delete")}
          </Button>
        </div>
      }
    >
      <div className={styles.delete_txt}>
        {t("are you sure you want to permanently delete form template")}
        <div className={styles.spacing_txt}>{form?.name} ?</div>
      </div>
    </Modal>
  );
};

export default DeleteFormModal;
