import Actions from "../../redux/actions";

const notificationGroups = {
  loading: false,
  loading2: false,
  error: null,
  items: [],
  users: [],
};

const notificationGroupsReducer = (state = notificationGroups, action) => {
  switch (action.type) {
    case Actions.FETCH_NOTIFICATION_GROUP_SUCCESS:
      return {
        ...state,
        loading: true,
        items: action.payload,
      };
    case Actions.FETCH_NOTIFICATION_GROUP_ERROR:
      return {
        ...state,
        loading: false,
      };
    case Actions.NOTIFICATION_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case Actions.FETCH_NOTIFICATION_GROUP_USERS_SUCCESS:
      console.log(action.payload);
      return {
        ...state,
        loading2: true,
        users: action.payload,
      };
    case Actions.FETCH_NOTIFICATION_GROUP_USERS_FAILURE:
      return {
        ...state,
        loading2: false,
      };
    default:
      return state;
  }
};

export default notificationGroupsReducer;
