import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import cx from "classnames";
import PropTypes from "prop-types";
import styles from "./Forms.module.scss";
import { I18nContext } from "../../i18n/I18nContext";
import Loader from "../Loader";
import Icon from "../Icon";
import MeatballMenu from "../Access/MeatballMenu";
import DeleteFormModal from "./DeleteFormModal";
import FormEditErrorModal from "./FormEditErrorModal";
import { formsEditErrorClear } from "../../redux";
import FormModal from "./FormModal";

const Forms = ({ items = [], canEdit }) => {
  const { t } = useContext(I18nContext);
  const dispatch = useDispatch();
  const [selectedForm, setSelectedForm] = useState(null);
  const [meatballOpened, setMeatballOpened] = useState(null);
  const [openEditFormModal, setOpenEditFormModal] = useState(null);
  const [openDeleteFormModal, setOpenDeleteFormModal] = useState(null);
  const [showFormDeleteErrorModal, setShowFormDeleteErrorModal] =
    useState(false);

  const isPrivileged = useSelector(
    (state) => state.auth.info?.companyPrivileged
  );
  const formsLoading = useSelector((state) => state.forms.loading);
  const formEditErrorStatus = useSelector(
    (state) => state.forms.formEditErrorStatus
  );
  const formEditError = useSelector((state) => state.forms.formEditError);

  useEffect(() => {
    if (!!selectedForm) {
      setSelectedForm(items.find((x) => x.id === selectedForm.id));
    }
  }, [items]);

  useEffect(() => {
    dispatch(formsEditErrorClear());
  }, []);

  useEffect(() => {
    if (formEditErrorStatus === 400) {
      setShowFormDeleteErrorModal(true);
    } else {
      dispatch(formsEditErrorClear());
    }
  }, [formEditError]);

  const meatballMenuItems = (form) => [
    {
      label: t("edit"),
      show:
        !form.deprecated && (isPrivileged || (!isPrivileged && !form.global)),
      onClick: () => {
        setSelectedForm(form);
        setOpenEditFormModal(true);
      },
      icon: <Icon name="editPencil-orange" className={styles.icon_menu} />,
    },
    {
      label: t("preview"),
      show: !isPrivileged && form.global,
      onClick: () => {
        setSelectedForm(form);
        setOpenEditFormModal(true);
      },
      icon: <Icon name="eye-orange" className={styles.icon_menu} />,
    },
    {
      label: t("delete"),
      show:
        !form.deprecated && (isPrivileged || (!isPrivileged && !form.global)),
      icon: <Icon name="trash-orange" className={styles.icon_menu} />,
      onClick: () => {
        setSelectedForm(form);
        setOpenDeleteFormModal(true);
      },
    },
  ];

  return (
    <div>
      {formsLoading && <Loader />}
      {items.map((item) => {
        return (
          <div
            key={item.id}
            className={cx(styles.row, {
              [styles.visible_for_sub_companies]:
                item.global && item !== selectedForm,
            })}
          >
            <div
              className={
                item.deprecated ? styles.title_deprecated : styles.title
              }
            >
              {item.name}
            </div>
            <div className={styles.date}>
              {item.updatedAt
                ? new Date(item.updatedAt).toLocaleDateString(undefined, {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                  })
                : null}
            </div>
            <span className={styles.meatball}>
              <MeatballMenu
                items={meatballMenuItems(item)}
                disabled={item.deprecated || !canEdit}
                id={item.id}
                meatballOpened={meatballOpened}
                meatballOpenedObserver={(state) => setMeatballOpened(state)}
              />
            </span>
          </div>
        );
      })}

      {selectedForm && !!formEditError && showFormDeleteErrorModal && (
        <FormEditErrorModal
          onClose={() => {
            dispatch(formsEditErrorClear());
            setShowFormDeleteErrorModal(false);
          }}
          isOpen={showFormDeleteErrorModal}
          form={selectedForm}
          errors={formEditError}
        />
      )}

      {!!openDeleteFormModal && (
        <DeleteFormModal
          onClose={() => {
            setOpenDeleteFormModal(false);
            setSelectedForm(null);
          }}
          isOpen={openDeleteFormModal}
          form={selectedForm}
        />
      )}

      {!!openEditFormModal && (
        <FormModal
          isOpen={openEditFormModal}
          form={selectedForm}
          preview={
            selectedForm.deprecated ||
            (!isPrivileged && selectedForm.global) ||
            !canEdit
          }
          onClose={() => {
            setOpenEditFormModal(false);
            setSelectedForm(null);
          }}
        />
      )}
    </div>
  );
};

Forms.propTypes = {
  items: PropTypes.array,
};

export default Forms;
