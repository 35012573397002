import { ComparisonOperation } from "../../constants/ComparisonOperation";
import { I18nContext } from "../../i18n/I18nContext";
import Icon from "../Icon";
import { FieldInput, FieldLabel, FieldSelect, FormControl } from "../Input";
import styles from "./PreconditionsForm.module.scss";
import { useContext } from "react";

const NumericalCondition = ({
  readOnly,
  precondition,
  index,
  onDeletePrecondition,
  name,
}) => {
  const { t } = useContext(I18nContext);

  return (
    <div className={styles.row} key={index}>
      <FormControl>
        <FieldLabel>
          {t("condition")} {index + 1}
        </FieldLabel>
        <FieldInput
          size="s"
          type="text"
          placeholder={t("input value", "title")}
          name={`${index}_disabledInput`}
          disabled
        />
      </FormControl>
      <FormControl className={styles.select}>
        <FieldLabel></FieldLabel>
        <FieldSelect
          size="s"
          disabled={readOnly}
          options={[
            {
              label: t("greater than or equal to"),
              value: ComparisonOperation.GTE,
            },
            { label: t("greater than"), value: ComparisonOperation.GT },
            {
              label: t("less than or equal to"),
              value: ComparisonOperation.LTE,
            },
            { label: t("less than"), value: ComparisonOperation.LT },
            { label: t("equal to"), value: ComparisonOperation.E },
            { label: t("not equal to"), value: ComparisonOperation.NE },
          ]}
          placeholder={t("operator")}
          name={name ? `${name}.operation` : `${index}_operation`}
        />
      </FormControl>
      <FormControl>
        <FieldLabel></FieldLabel>
        <FieldInput
          size="xxs"
          type="number"
          placeholder={t("number")}
          disabled={readOnly}
          name={name ? `${name}.comparedValue` : `${index}_comparedValue`}
        />
      </FormControl>
      <span className={styles.delete_btn}>
        {onDeletePrecondition && !readOnly && (
          <div onClick={() => onDeletePrecondition(precondition)}>
            <Icon name="x-delete" />
          </div>
        )}
      </span>
    </div>
  );
};

export default NumericalCondition;
