import React from "react";
import LoginForm from "./LoginForm";
import Entry from "../../layouts/Entry";

const Login = () => {
  return (
    <Entry>
      <LoginForm />
    </Entry>
  );
};

export default Login;
