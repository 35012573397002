import React from "react";
import { Field } from "formik";
import PropTypes from "prop-types";
import styles from "./CheckboxGroup.module.scss";

const CheckboxGroup = ({
  options,
  name,
  disabled: disabledGroup,
  taskPreview,
}) => {
  return (
    <div
      className={!taskPreview ? styles.container : styles.container_vertical}
    >
      {options.map(({ value, label, disabled: disbaledOption }) => (
        <label key={`field-${value}`} className={styles.label}>
          <Field
            type="checkbox"
            name={name}
            value={value}
            disabled={disbaledOption || disabledGroup}
            data-testid={`checkbox-${name}-${value}`}
          />
          <span
            className={
              disbaledOption || disabledGroup
                ? [styles.checkmark2]
                : [styles.checkmark]
            }
            data-testid={`checkmark-${name}-${value}`}
          />
          {label}
        </label>
      ))}
    </div>
  );
};

CheckboxGroup.propTypes = {
  options: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

export default CheckboxGroup;
