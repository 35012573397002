import React, { useCallback, useContext, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { addRight, editRight, fetchRightsList } from "../../redux";
import Button from "../Button";
import {
  FieldError,
  FieldInput,
  FieldLabel,
  FieldSelect,
  Form,
  FormControl,
} from "../Input";
import Modal from "../Modal";
import styles from "./CategoryFormModal.module.scss";
import { I18nContext } from "../../i18n/I18nContext";
import { useState } from "react";

const selectUniqueAssets = (state) => {
  const assets = state.assets.searchAssets;
  const uniqueItems = [];
  for (let item of assets) {
    if (!uniqueItems.find((i) => i.id === item.id)) {
      uniqueItems.push(item);
    }
  }
  return uniqueItems;
};

const CategoryFormModal = ({ onClose, isOpen, right }) => {
  const { t } = useContext(I18nContext);
  const dispatch = useDispatch();
  const [progress, setProgress] = useState(false);
  const formRef = useRef();

  const defaultInitialValues = {
    rightName: right?.category ?? "",
    taskTemplates: right?.taskTemplates.map((r) => r) ?? [],
    assetTemplates: right?.assetTemplates.map((r) => r) ?? [],
    assetIds: right?.assets?.map((r) => r.id) ?? [],
  };

  const [initialValues, setInitialValues] = useState(defaultInitialValues);

  const taskTemplates = useSelector((state) =>
    state.rights.taskTemplateList
      .filter((x) => !x.deprecated)
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((p) => ({
        label: p.name,
        value: p.id,
        global: p.global,
      }))
  );

  const assetTemplates = useSelector((state) =>
    state.rights.assetTemplateList
      .filter((x) => !x.deprecated)
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((p) => ({
        label: p.name,
        value: p.id,
        global: p.visibleOnAllCompanies,
      }))
  );

  const assetNameSearchOptions = useSelector(selectUniqueAssets);

  const validationSchema = Yup.object({
    rightName: Yup.string().required(t("missing category name")),
    taskTemplates: Yup.array().required(t("please choose one option")),
    assetTemplates: Yup.array().required(t("please choose one option")),
  });

  const handleClear = () => {
    onClose();
  };

  const handleSubmit = async (values, { resetForm }) => {
    if (progress) {
      return;
    }
    if (right) {
      setProgress(true);
      await dispatch(
        editRight(t, {
          rightId: right.id,
          assetTemplates: values.assetTemplates,
          taskTemplates: values.taskTemplates,
          assets: values.assetIds,
          category: values.rightName,
        })
      );
    } else {
      setProgress(true);
      await dispatch(
        addRight(t, {
          rightName: values.rightName,
          assetTemplates: values.assetTemplates,
          taskTemplates: values.taskTemplates,
          assets: values.assetIds,
        })
      );
    }
    setProgress(false);
    dispatch(fetchRightsList());
    resetForm();
    onClose();
  };

  const handleCheck = useCallback(
    (checked) => {
      setInitialValues({
        ...formRef.current.values,
        assetIds: checked,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setInitialValues]
  );

  useEffect(() => {
    if (right) {
      setInitialValues({
        rightName: right?.category ?? "",
        taskTemplates: right?.taskTemplates.map((r) => r) ?? [],
        assetTemplates: right?.assetTemplates.map((r) => r) ?? [],
        assetIds: right?.assets?.map((r) => r.id) ?? [],
      });
    }
  }, [right]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={right ? t("edit item") : t("add new category")}
      form="add-new-category"
      footer={
        <div className={styles.button_section}>
          <Button
            onClick={handleClear}
            type="reset"
            form={"add-new-category"}
            variant="ghost"
            size="s"
          >
            {t("cancel")}
          </Button>
          <Button
            className={styles.btn_separator}
            type="submit"
            variant="orange"
            size="s"
            form={"add-new-category"}
          >
            {!!right ? t("save") : t("add")}
          </Button>
        </div>
      }
    >
      <Form
        id="add-new-category"
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        initialValues={initialValues}
        refForm={formRef}
        enableReinitialize
      >
        <FormControl className={styles.dropdown}>
          <FieldLabel>
            {t("name")}
            <span className={styles.red}>*</span>
          </FieldLabel>
          <FieldInput
            name="rightName"
            size="l"
            placeholder={t("category name")}
          />
          <FieldError name="rightName" />
        </FormControl>
        <FormControl className={styles.dropdown}>
          <FieldLabel>
            {t("task template")}
            <span className={styles.red}>*</span>
          </FieldLabel>
          <FieldSelect
            name="taskTemplates"
            size="s"
            isMultiple
            options={taskTemplates}
            placeholder={t("task template")}
          />
          <FieldError name="taskTemplates" />
        </FormControl>
        <FormControl className={styles.dropdown}>
          <FieldLabel>
            {t("asset prototype")}
            <span className={styles.red}>*</span>
          </FieldLabel>
          <FieldSelect
            name="assetTemplates"
            size="s"
            isMultiple
            options={assetTemplates}
            placeholder={t("asset prototype")}
          />
          <FieldError name="assetTemplates" />
        </FormControl>
      </Form>
    </Modal>
  );
};

export default CategoryFormModal;
