import React, { useContext } from "react";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import styles from "./DeleteAttachmentsModal.module.scss";
import { I18nContext } from "../../i18n/I18nContext";

const DeleteAttachmentsModal = ({
  isOpen,
  onClose,
  onDeleteAll,
  selected,
  pending,
}) => {
  const { t } = useContext(I18nContext);
  const handleClear = () => {
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={
        selected
          ? t(`delete selected attachments`)
          : t(`delete all attachments`)
      }
      icon
      footer={
        <div className={styles.button_section}>
          <Button
            className={styles.btn_separator}
            onClick={handleClear}
            type="reset"
            variant="ghost"
            size="s"
          >
            {t("cancel")}
          </Button>
          <Button
            type="submit"
            variant={!pending ? "orange" : "disabled"}
            size="s"
            onClick={!pending ? onDeleteAll : null}
          >
            {t("delete")}
          </Button>
        </div>
      }
    >
      <div className={styles.delete_txt}>
        {selected
          ? t(
              "are you sure you want to permanently delete selected attachments"
            )
          : t("are you sure you want to permanently delete all attachments")}
        ?
      </div>
    </Modal>
  );
};

export default DeleteAttachmentsModal;
