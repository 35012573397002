export const TaskState = {
  TODO: "TODO",
  CANCELLED: "CANCELLED",
  COMPLETED: "COMPLETED",
  MISSED: "MISSED",
  OVERDUE: "OVERDUE",
  CANT_DO: "CANT_DO",
  DONE: "DONE",
  SUSPENDED: "SUSPENDED",
  DONE_TOO_LATE: "DONE_TOO_LATE",
};
