const PropertyType = {
  composite: "composite",
  boolean: "boolean",
  text: "text",
  selection: "selection",
  date: "date",
  numeric: "numeric",
};

export default PropertyType;
