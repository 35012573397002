import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import { blockTaskPrototype, fetchTasksPrototypesList } from "../../redux";
import Button from "../Button";
import Modal from "../Modal";
import styles from "./DeleteTaskTemplateModal.module.scss";
import { I18nContext } from "../../i18n/I18nContext";

const DeleteTaskTemplateModal = ({ isOpen, onClose, taskTemplate }) => {
  const { t } = useContext(I18nContext);
  const dispatch = useDispatch();

  const handleClear = () => {
    onClose();
  };
  const handleDeleteTaskTemplate = async () => {
    await dispatch(blockTaskPrototype(t, taskTemplate.id));
    await dispatch(fetchTasksPrototypesList());
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={`${t("block task type")}?`}
      form="delete-form-task-template"
      icon
      footer={
        <div className={styles.button_section}>
          <Button
            className={styles.btn_separator}
            onClick={handleClear}
            type="reset"
            form={"delete-form-task-template"}
            variant="ghost"
            size="s"
          >
            {t("cancel")}
          </Button>
          <Button
            type="submit"
            variant="orange"
            size="s"
            form={"delete-form-task-template"}
            onClick={handleDeleteTaskTemplate}
          >
            {t("block")}
          </Button>
        </div>
      }
    >
      <div className={styles.delete_txt}>
        {t("are you sure you want to block task type")}
        <div className={styles.spacing_txt}>{taskTemplate?.name} ?</div>
      </div>
    </Modal>
  );
};

export default DeleteTaskTemplateModal;
