import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { appUrls } from "./urls";

import Company from "./views/Login/CompanySelect";
import Region from "./views/Login/RegionSelect";
import Assets from "./views/Assets";
import AssetDetails from "./views/AssetDetails";
import Dashboard from "./views/Dashboard";
import Task from "./views/Task";
import Configuration from "./views/Configuration";
import Access from "./views/Access";
import License from "./views/License";
import Settings from "./views/Settings/Settings";
import Help from "./views/Help";
import Profile from "./views/Profile";
import Reports from "./views/Reports";
import styles from "./MainApp.module.scss";

const MainApp = () => {
  const role = useSelector((state) => state.auth.info?.role);
  return (
    <div className={styles.main_app}>
      <Route exact path={appUrls.COMPANY} component={Company} />
      <Route exact path={appUrls.REGION} component={Region} />
      <Route exact path={appUrls.ASSETS} component={Assets} />
      <Route exact path={appUrls.ASSETS_DETAILS} component={AssetDetails} />
      <Route exact path={appUrls.DASHBOARD} component={Dashboard} />
      <Route exact path={appUrls.TASK} component={Task} />
      {!(role === "CUSTOMER" || role === "MAINTAINER") && (
        <Route exact path={appUrls.CONFIGURATION} component={Configuration} />
      )}
      {role !== "MAINTAINER" && (
        <Route exact path={appUrls.ACCESS} component={Access} />
      )}
      {role !== "MAINTAINER" && (
        <Route exact path={appUrls.REPORTS} component={Reports} />
      )}
      {!(role === "CUSTOMER" || role === "MAINTAINER") && (
        <Route exact path={appUrls.LICENSE} component={License} />
      )}
      <Route exact path={appUrls.HELP} component={Help} />
      <Route exact path={appUrls.PROFILE} component={Profile} />
      <Route path={appUrls.SETTINGS} component={Settings} />
      <Route
        exact
        path="/"
        render={() => <Redirect to={appUrls.DASHBOARD} />}
      />
    </div>
  );
};

export default MainApp;
